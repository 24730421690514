/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePaymentSettingsAPIModel
 */
export interface UpdatePaymentSettingsAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePaymentSettingsAPIModel
     */
    compensateDirect: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePaymentSettingsAPIModel
     */
    deferredAllowed: boolean;
}

/**
 * Check if a given object implements the UpdatePaymentSettingsAPIModel interface.
 */
export function instanceOfUpdatePaymentSettingsAPIModel(value: object): boolean {
    if (!('compensateDirect' in value)) return false;
    if (!('deferredAllowed' in value)) return false;
    return true;
}

export function UpdatePaymentSettingsAPIModelFromJSON(json: any): UpdatePaymentSettingsAPIModel {
    return UpdatePaymentSettingsAPIModelFromJSONTyped(json, false);
}

export function UpdatePaymentSettingsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePaymentSettingsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'compensateDirect': json['compensateDirect'],
        'deferredAllowed': json['deferredAllowed'],
    };
}

export function UpdatePaymentSettingsAPIModelToJSON(value?: UpdatePaymentSettingsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'compensateDirect': value['compensateDirect'],
        'deferredAllowed': value['deferredAllowed'],
    };
}

