import type {
  BlockchainTypeAPIModel,
  GasWalletDerivedAPIModel,
  GasWalletFullDetailsAPIModel,
  TransactionStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingPartialDataState } from '@/infrastructure/model/partial/types';
import type { SingleState } from '@/infrastructure/model/single/types';

import type BigNumber from 'bignumber.js';
import type { Address, Hash } from 'viem';

export const NAMESPACE = 'gas-wallets';

export const refundingGasWalletsTaskType = 'gas-wallets.refunding';

export interface GasWalletTransaction {
  txId: string;
  wallet?: string;
  blockchain: BlockchainTypeAPIModel;
  status: TransactionStatusAPIModel;
  failCount: number;
  sentAt?: Date;
  hash?: string;
  blockNum?: string;
  errorReason?: string;
}

export interface GasWalletDerived extends Omit<GasWalletDerivedAPIModel, 'address' | 'nativeBalance' | 'refundHash'> {
  bt: BlockchainTypeAPIModel;
  address: Address;
  nativeBalance: BigNumber;
  refundHash?: Hash;
}

export interface GasWallet
  extends Omit<GasWalletFullDetailsAPIModel, 'address' | 'derived' | 'lowWatermark' | 'refundHash'> {
  bt: BlockchainTypeAPIModel;
  address: Address;
  derived: GasWalletDerived[];
  lowWatermark?: BigNumber;
  refundHash?: Hash;

  derivedBalance: BigNumber;
  isAnyOutOfService: boolean;
  isAllOutOfService: boolean;
  isAnyRefunding: boolean;
  isDerivedBalanceEnough: boolean;
  isReadyForAction: boolean;
}

export interface NewGasWallet {
  bt: BlockchainTypeAPIModel;
}

export interface GasWalletsState {
  entities: SingleState<GasWallet, BlockchainTypeAPIModel>;
  wallets: LoadingPartialDataState<BlockchainTypeAPIModel>;
}
