import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectSubscriptionPlanListData,
  makeSelectSubscriptionPlanListParameters,
  makeSelectSubscriptionPlan,
  makeSelectDirtySubscriptionPlanIds,
  makeSelectMultipleSubscriptionPlan,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state) => state[NAMESPACE].entities,
  'SubscriptionPlan' as const,
  undefined,
);

export const makeSelectSubscriptionPlanListParametersWithNetwork: typeof makeSelectSubscriptionPlanListParameters =
  () =>
    createSelector(
      makeSelectSubscriptionPlanListParameters(),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkType,
      ): ReturnType<ReturnType<typeof makeSelectSubscriptionPlanListParameters>> => ({
        ...parameters,
        filter: { ...filter, networkType },
      }),
    );
