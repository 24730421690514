/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantWalletDeployTransactionAPIModel } from './MerchantWalletDeployTransactionAPIModel';
import {
    MerchantWalletDeployTransactionAPIModelFromJSON,
    MerchantWalletDeployTransactionAPIModelFromJSONTyped,
    MerchantWalletDeployTransactionAPIModelToJSON,
} from './MerchantWalletDeployTransactionAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletDeployTransactionsAPIModel
 */
export interface MerchantWalletDeployTransactionsAPIModel {
    /**
     * 
     * @type {Array<MerchantWalletDeployTransactionAPIModel>}
     * @memberof MerchantWalletDeployTransactionsAPIModel
     */
    transactions?: Array<MerchantWalletDeployTransactionAPIModel>;
}

/**
 * Check if a given object implements the MerchantWalletDeployTransactionsAPIModel interface.
 */
export function instanceOfMerchantWalletDeployTransactionsAPIModel(value: object): boolean {
    return true;
}

export function MerchantWalletDeployTransactionsAPIModelFromJSON(json: any): MerchantWalletDeployTransactionsAPIModel {
    return MerchantWalletDeployTransactionsAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletDeployTransactionsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletDeployTransactionsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'transactions': json['transactions'] == null ? undefined : ((json['transactions'] as Array<any>).map(MerchantWalletDeployTransactionAPIModelFromJSON)),
    };
}

export function MerchantWalletDeployTransactionsAPIModelToJSON(value?: MerchantWalletDeployTransactionsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'transactions': value['transactions'] == null ? undefined : ((value['transactions'] as Array<any>).map(MerchantWalletDeployTransactionAPIModelToJSON)),
    };
}

