import type { Layout } from './types';
import type { DescriptionsItemProps } from 'antd/es/descriptions/Item';

export const defaultDialogFormLayout: Layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 26 },
};

export const defaultDialogFormTailLayout: Layout = {
  wrapperCol: { offset: 6, span: 27 },
};

export const defaultPageFormLayout: Layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export const defaultPageFormTailLayout: Layout = {
  wrapperCol: { sm: { offset: 6, span: 18 } },
};

export const noLabelFormLayout: Layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

export const defaultViewColumns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 };

export const descriptionStyles: DescriptionsItemProps['styles'] = {
  content: { fontWeight: 'bold', overflow: 'hidden' },
};

export const descriptionItemStyles: DescriptionsItemProps['styles'] = {
  content: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
