import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import SettlementIntentStatusView from '@/features/settlements/components/SettlementIntentStatusView';
import { SettlementIntentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { SettlementIntentStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SettlementIntentStatusFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: SettlementIntentStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_FILTER_FORM_STATUS_LABEL} />}
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      mode="multiple"
      disabled={readonly}
      placeholder={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_FILTER_FORM_STATUS_PLACEHOLDER} />}
      options={useMemo(
        () =>
          enumValues(SettlementIntentStatusAPIModel).map((code) => ({
            value: code,
            label: (
              <SettlementIntentStatusView
                value={code}
                mode="full"
                data-test={dataTest && `${dataTest}-status-${code}`}
              />
            ),
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const SettlementIntentStatusFilterItemMemo = React.memo(
  SettlementIntentStatusFilterItem,
) as typeof SettlementIntentStatusFilterItem;

export default SettlementIntentStatusFilterItemMemo;
