import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPendingIntents } from '@/features/settlements/actions';
import { makeSelectPendingIntents } from '@/features/settlements/selectors';
import type { SettlementIntent } from '@/features/settlements/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<SettlementIntent[]>, Parameters<typeof fetchPendingIntents>[0]> =>
  fetchPendingIntents({ force });

const selectFetching = makeSelectPending(fetchPendingIntents);
const selectData = makeSelectPendingIntents();

export default function usePendingIntents() {
  return useAppLoadableData(fetchFactory, selectData, selectFetching);
}
