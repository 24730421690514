/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerklePayoutSignatureDataAPIModel,
  NewPayoutAPIModel,
  PayoutBatchAPIModel,
  PayoutDestinationAPIModel,
  PayoutFilterAPIModel,
  PayoutSummaryAPIModel,
  PayoutTitleAPIModel,
  PayoutUpdateAPIModel,
  SlicePayoutSummaryPayoutSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerklePayoutSignatureDataAPIModelFromJSON,
    MerklePayoutSignatureDataAPIModelToJSON,
    NewPayoutAPIModelFromJSON,
    NewPayoutAPIModelToJSON,
    PayoutBatchAPIModelFromJSON,
    PayoutBatchAPIModelToJSON,
    PayoutDestinationAPIModelFromJSON,
    PayoutDestinationAPIModelToJSON,
    PayoutFilterAPIModelFromJSON,
    PayoutFilterAPIModelToJSON,
    PayoutSummaryAPIModelFromJSON,
    PayoutSummaryAPIModelToJSON,
    PayoutTitleAPIModelFromJSON,
    PayoutTitleAPIModelToJSON,
    PayoutUpdateAPIModelFromJSON,
    PayoutUpdateAPIModelToJSON,
    SlicePayoutSummaryPayoutSortByAPIModelFromJSON,
    SlicePayoutSummaryPayoutSortByAPIModelToJSON,
} from '../models/index';

export interface CreatePayoutRequest {
    newPayoutAPIModel: NewPayoutAPIModel;
}

export interface DeletePayoutRequest {
    payoutId: string;
}

export interface GetPayoutRequest {
    payoutId: string;
}

export interface GetPayoutBatchRequest {
    settlementId: string;
}

export interface GetPayoutBatchesRequest {
    payoutId: string;
}

export interface GetPayoutDestinationsRequest {
    payoutId: string;
}

export interface SearchPayoutsRequest {
    payoutFilterAPIModel: PayoutFilterAPIModel;
}

export interface StartPayoutRequest {
    merklePayoutSignatureDataAPIModel: MerklePayoutSignatureDataAPIModel;
}

export interface UpdatePayoutRequest {
    payoutUpdateAPIModel: PayoutUpdateAPIModel;
}

export interface UpdatePayoutTitleRequest {
    payoutId: string;
    payoutTitleAPIModel: PayoutTitleAPIModel;
}

/**
 * PayoutApi - interface
 * 
 * @export
 * @interface PayoutApiInterface
 */
export interface PayoutApiInterface {
    /**
     * Creates new payouts
     * @param {NewPayoutAPIModel} newPayoutAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    createPayoutRaw(requestParameters: CreatePayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>>;

    /**
     * Creates new payouts
     */
    createPayout(newPayoutAPIModel: NewPayoutAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel>;

    /**
     * Deletes payout by id
     * @param {string} payoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    deletePayoutRaw(requestParameters: DeletePayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>>;

    /**
     * Deletes payout by id
     */
    deletePayout(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object>;

    /**
     * Returns payout by id
     * @param {string} payoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    getPayoutRaw(requestParameters: GetPayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>>;

    /**
     * Returns payout by id
     */
    getPayout(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel>;

    /**
     * Returns payout batches by settlement id
     * @param {string} settlementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    getPayoutBatchRaw(requestParameters: GetPayoutBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutBatchAPIModel>>;

    /**
     * Returns payout batches by settlement id
     */
    getPayoutBatch(settlementId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutBatchAPIModel>;

    /**
     * Returns payout batches by id
     * @param {string} payoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    getPayoutBatchesRaw(requestParameters: GetPayoutBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutBatchAPIModel>>>;

    /**
     * Returns payout batches by id
     */
    getPayoutBatches(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutBatchAPIModel>>;

    /**
     * Returns payout destinations by id
     * @param {string} payoutId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    getPayoutDestinationsRaw(requestParameters: GetPayoutDestinationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutDestinationAPIModel>>>;

    /**
     * Returns payout destinations by id
     */
    getPayoutDestinations(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutDestinationAPIModel>>;

    /**
     * Returns payouts according to filter
     * @param {PayoutFilterAPIModel} payoutFilterAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    searchPayoutsRaw(requestParameters: SearchPayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SlicePayoutSummaryPayoutSortByAPIModel>>;

    /**
     * Returns payouts according to filter
     */
    searchPayouts(payoutFilterAPIModel: PayoutFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SlicePayoutSummaryPayoutSortByAPIModel>;

    /**
     * Start payout
     * @param {MerklePayoutSignatureDataAPIModel} merklePayoutSignatureDataAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    startPayoutRaw(requestParameters: StartPayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>>;

    /**
     * Start payout
     */
    startPayout(merklePayoutSignatureDataAPIModel: MerklePayoutSignatureDataAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel>;

    /**
     * Updates payout
     * @param {PayoutUpdateAPIModel} payoutUpdateAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    updatePayoutRaw(requestParameters: UpdatePayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>>;

    /**
     * Updates payout
     */
    updatePayout(payoutUpdateAPIModel: PayoutUpdateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel>;

    /**
     * Updates payout title
     * @param {string} payoutId 
     * @param {PayoutTitleAPIModel} payoutTitleAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutApiInterface
     */
    updatePayoutTitleRaw(requestParameters: UpdatePayoutTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>>;

    /**
     * Updates payout title
     */
    updatePayoutTitle(payoutId: string, payoutTitleAPIModel: PayoutTitleAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel>;

}

/**
 * 
 */
export class PayoutApi extends runtime.BaseAPI implements PayoutApiInterface {

    /**
     * Creates new payouts
     */
    async createPayoutRaw(requestParameters: CreatePayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>> {
        if (requestParameters['newPayoutAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newPayoutAPIModel',
                'Required parameter "newPayoutAPIModel" was null or undefined when calling createPayout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPayoutAPIModelToJSON(requestParameters['newPayoutAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayoutSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new payouts
     */
    async createPayout(newPayoutAPIModel: NewPayoutAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel> {
        const response = await this.createPayoutRaw({ newPayoutAPIModel: newPayoutAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Deletes payout by id
     */
    async deletePayoutRaw(requestParameters: DeletePayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['payoutId'] == null) {
            throw new runtime.RequiredError(
                'payoutId',
                'Required parameter "payoutId" was null or undefined when calling deletePayout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/{payoutId}`.replace(`{${"payoutId"}}`, encodeURIComponent(String(requestParameters['payoutId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Deletes payout by id
     */
    async deletePayout(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.deletePayoutRaw({ payoutId: payoutId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns payout by id
     */
    async getPayoutRaw(requestParameters: GetPayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>> {
        if (requestParameters['payoutId'] == null) {
            throw new runtime.RequiredError(
                'payoutId',
                'Required parameter "payoutId" was null or undefined when calling getPayout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/{payoutId}`.replace(`{${"payoutId"}}`, encodeURIComponent(String(requestParameters['payoutId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayoutSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns payout by id
     */
    async getPayout(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel> {
        const response = await this.getPayoutRaw({ payoutId: payoutId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns payout batches by settlement id
     */
    async getPayoutBatchRaw(requestParameters: GetPayoutBatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutBatchAPIModel>> {
        if (requestParameters['settlementId'] == null) {
            throw new runtime.RequiredError(
                'settlementId',
                'Required parameter "settlementId" was null or undefined when calling getPayoutBatch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/batches/{settlementId}`.replace(`{${"settlementId"}}`, encodeURIComponent(String(requestParameters['settlementId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayoutBatchAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns payout batches by settlement id
     */
    async getPayoutBatch(settlementId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutBatchAPIModel> {
        const response = await this.getPayoutBatchRaw({ settlementId: settlementId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns payout batches by id
     */
    async getPayoutBatchesRaw(requestParameters: GetPayoutBatchesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutBatchAPIModel>>> {
        if (requestParameters['payoutId'] == null) {
            throw new runtime.RequiredError(
                'payoutId',
                'Required parameter "payoutId" was null or undefined when calling getPayoutBatches().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/{payoutId}/batches`.replace(`{${"payoutId"}}`, encodeURIComponent(String(requestParameters['payoutId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayoutBatchAPIModelFromJSON));
    }

    /**
     * Returns payout batches by id
     */
    async getPayoutBatches(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutBatchAPIModel>> {
        const response = await this.getPayoutBatchesRaw({ payoutId: payoutId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns payout destinations by id
     */
    async getPayoutDestinationsRaw(requestParameters: GetPayoutDestinationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PayoutDestinationAPIModel>>> {
        if (requestParameters['payoutId'] == null) {
            throw new runtime.RequiredError(
                'payoutId',
                'Required parameter "payoutId" was null or undefined when calling getPayoutDestinations().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/{payoutId}/destinations`.replace(`{${"payoutId"}}`, encodeURIComponent(String(requestParameters['payoutId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayoutDestinationAPIModelFromJSON));
    }

    /**
     * Returns payout destinations by id
     */
    async getPayoutDestinations(payoutId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PayoutDestinationAPIModel>> {
        const response = await this.getPayoutDestinationsRaw({ payoutId: payoutId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns payouts according to filter
     */
    async searchPayoutsRaw(requestParameters: SearchPayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SlicePayoutSummaryPayoutSortByAPIModel>> {
        if (requestParameters['payoutFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'payoutFilterAPIModel',
                'Required parameter "payoutFilterAPIModel" was null or undefined when calling searchPayouts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayoutFilterAPIModelToJSON(requestParameters['payoutFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SlicePayoutSummaryPayoutSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns payouts according to filter
     */
    async searchPayouts(payoutFilterAPIModel: PayoutFilterAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SlicePayoutSummaryPayoutSortByAPIModel> {
        const response = await this.searchPayoutsRaw({ payoutFilterAPIModel: payoutFilterAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Start payout
     */
    async startPayoutRaw(requestParameters: StartPayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>> {
        if (requestParameters['merklePayoutSignatureDataAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merklePayoutSignatureDataAPIModel',
                'Required parameter "merklePayoutSignatureDataAPIModel" was null or undefined when calling startPayout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/sign`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerklePayoutSignatureDataAPIModelToJSON(requestParameters['merklePayoutSignatureDataAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayoutSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Start payout
     */
    async startPayout(merklePayoutSignatureDataAPIModel: MerklePayoutSignatureDataAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel> {
        const response = await this.startPayoutRaw({ merklePayoutSignatureDataAPIModel: merklePayoutSignatureDataAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates payout
     */
    async updatePayoutRaw(requestParameters: UpdatePayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>> {
        if (requestParameters['payoutUpdateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'payoutUpdateAPIModel',
                'Required parameter "payoutUpdateAPIModel" was null or undefined when calling updatePayout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PayoutUpdateAPIModelToJSON(requestParameters['payoutUpdateAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayoutSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates payout
     */
    async updatePayout(payoutUpdateAPIModel: PayoutUpdateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel> {
        const response = await this.updatePayoutRaw({ payoutUpdateAPIModel: payoutUpdateAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates payout title
     */
    async updatePayoutTitleRaw(requestParameters: UpdatePayoutTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PayoutSummaryAPIModel>> {
        if (requestParameters['payoutId'] == null) {
            throw new runtime.RequiredError(
                'payoutId',
                'Required parameter "payoutId" was null or undefined when calling updatePayoutTitle().'
            );
        }

        if (requestParameters['payoutTitleAPIModel'] == null) {
            throw new runtime.RequiredError(
                'payoutTitleAPIModel',
                'Required parameter "payoutTitleAPIModel" was null or undefined when calling updatePayoutTitle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payouts/{payoutId}/title`.replace(`{${"payoutId"}}`, encodeURIComponent(String(requestParameters['payoutId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PayoutTitleAPIModelToJSON(requestParameters['payoutTitleAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayoutSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates payout title
     */
    async updatePayoutTitle(payoutId: string, payoutTitleAPIModel: PayoutTitleAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PayoutSummaryAPIModel> {
        const response = await this.updatePayoutTitleRaw({ payoutId: payoutId, payoutTitleAPIModel: payoutTitleAPIModel }, initOverrides);
        return await response.value();
    }

}
