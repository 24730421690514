import type { Donation, DonationAddress, DonationTransaction } from '@/features/donations/types';
import type {
  DonationAddressAPIModel,
  DonationAPIModel,
  DonationPredicatesAPIModel,
  DonationSortByAPIModel,
  DonationTransactionAPIModel,
  DonationTransactionPredicatesAPIModel,
  NewDonationAPIModel,
  PushTransactionSortByAPIModel,
  UpdateDonationAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { CollectableKindAPIModel, DonationApi, DonationTransactionApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import { amountFromAPI, pageToAPI, type SliceDataRequestWithTotal, sliceFromAPI } from '@/infrastructure/model/api';

const donationApi = new DonationApi(coreConfigurationFactory('auth'));
const donationTransactionApi = new DonationTransactionApi(coreConfigurationFactory('auth'));

export const parseDonationAddress = ({
  balance,
  defaultAmounts,
  ...donation
}: DonationAddressAPIModel): DonationAddress => ({
  ...donation,
  defaultAmounts: (defaultAmounts ?? []).map(amountFromAPI),
  balance: amountFromAPI(balance),
});

export const parseDonation = ({ addresses, ...donation }: DonationAPIModel): Donation => ({
  ...donation,
  addresses: addresses?.map(parseDonationAddress) ?? [],
});

export const parseDonationTransaction = ({
  amount,
  kind,
  ...tx
}: DonationTransactionAPIModel): DonationTransaction => ({
  ...tx,
  amount: amountFromAPI(amount),
  kind: CollectableKindAPIModel.Donation,
});

export const queryDonation = async (donationId: string, initOverrides?: RequestInit) =>
  parseDonation(await donationApi.getDonation(donationId, initOverrides));

export const queryDonations = async (
  { page, filter, orderBy, withTotal }: SliceDataRequestWithTotal<DonationPredicatesAPIModel, DonationSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await donationApi.searchDonations(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseDonation,
  );

export const queryDonationDeployTransaction = async (donationId: string, asset: string, initOverrides?: RequestInit) =>
  await donationApi.getDonationAssetDeployTransaction(donationId, asset, initOverrides);

export const queryDonationTransaction = async (txId: string, initOverrides?: RequestInit) =>
  parseDonationTransaction(await donationTransactionApi.getDonationTransaction(txId, initOverrides));

export const queryDonationTransactions = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<DonationTransactionPredicatesAPIModel, PushTransactionSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await donationTransactionApi.searchDonationTransactions(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseDonationTransaction,
  );

export const requestCreateDonation = async (donation: NewDonationAPIModel, initOverrides?: RequestInit) =>
  parseDonation(await donationApi.createDonation(donation, initOverrides));

export const requestUpdateDonation = async (
  donationId: string,
  donation: UpdateDonationAPIModel,
  initOverrides?: RequestInit,
) => parseDonation(await donationApi.updateDonation(donationId, donation, initOverrides));

export const requestUpdateDonationImage = async (donationId: string, imageKey: string, initOverrides?: RequestInit) =>
  parseDonation(await donationApi.updateDonationImage(donationId, { imageKey }, initOverrides));

export const requestUpdateDonationStatus = async (donationId: string, isActive: boolean, initOverrides?: RequestInit) =>
  parseDonation(await donationApi.updateDonationStatus(donationId, { isActive }, initOverrides));
