import React, { useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage, OperationRefresh } from '@/components';
import { markCollectTasksDependentDataDirty } from '@/features/collectable/actions';
import { useCollectTask } from '@/features/collectable/hooks';
import type { CollectTask } from '@/features/collectable/types';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { suppressPromise } from '@/infrastructure/utils/functions';

import type { OperationRefreshCollectTaskProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_MESSAGE_SUCCESS} />,
  },
};

const OperationRefreshCollectTask: React.FC<OperationRefreshCollectTaskProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  taskId,
}) => {
  const { data, forceRefresh } = useCollectTask(taskId);
  const dispatch = useAppDispatch();
  const refreshDependantData = useCallback(
    (newData: LoadingStateWithDirty<CollectTask>) => {
      if (
        newData.data
        && (newData.data.status !== data.data?.status || !isEqual(newData.data.collectedAmount, data.data.collectedAmount))
      ) {
        suppressPromise(dispatch(markCollectTasksDependentDataDirty([newData.data.id])));
      }
    },
    [data.data?.collectedAmount, data.data?.status, dispatch],
  );
  return (
    <OperationRefresh
      refresh={forceRefresh}
      data-test={dataTest}
      mode={mode}
      messages={messages}
      onSuccess={refreshDependantData}
    />
  );
};

const OperationRefreshCollectTaskMemo = React.memo(OperationRefreshCollectTask);

export default OperationRefreshCollectTaskMemo;
