import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useCompanyPermissions } from '@/features/company/hooks';
import type { NoPermissionUnavailabilityReason } from '@/features/company/types';
import { useActionPending } from '@/features/global/hooks';
import { updateSettlementSchedule } from '@/features/settlements/actions';
import type { SettlementSchedule, SettlementScheduleUpdate } from '@/features/settlements/types';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useSettlementSchedule from '../useSettlementSchedule';

export type SetUnavailabilityReason = 'loading' | NoPermissionUnavailabilityReason;
export type CancelUnavailabilityReason = 'loading' | 'no-data' | NoPermissionUnavailabilityReason;

export interface UseSettlementScheduleActionsType {
  set: HookAction<[SettlementSchedule], SettlementSchedule, SetUnavailabilityReason>;
  cancel: HookAction<[], SettlementSchedule, CancelUnavailabilityReason>;
}

export default function useSettlementScheduleActions(): UseSettlementScheduleActionsType {
  const schedule = useSettlementSchedule();
  const { data: permissions } = useCompanyPermissions();
  const { withExtractDataDispatch } = useAppDispatch();

  const pending = useActionPending(updateSettlementSchedule);
  const setUnavailabilityReason: SetUnavailabilityReason | undefined = useMemo(() => {
    if (schedule.loading || schedule.data.isDirty) return 'loading';
    if (!permissions.data?.settleManagement) return 'no-permissions';
    return undefined;
  }, [permissions.data?.settleManagement, schedule.data.isDirty, schedule.loading]);
  const set: UseSettlementScheduleActionsType['set'] = {
    act: useCallback(
      (newSchedule: SettlementScheduleUpdate) => withExtractDataDispatch(updateSettlementSchedule)(newSchedule),
      [withExtractDataDispatch],
    ),
    inAction: pending,
    unavailabilityReason: setUnavailabilityReason,
    available: !setUnavailabilityReason,
  };

  const cancelUnavailabilityReason: UseSettlementScheduleActionsType['cancel']['unavailabilityReason'] = useMemo(() => {
    if (setUnavailabilityReason) return setUnavailabilityReason;
    if (!schedule.data.data || schedule.data.data.period === WithdrawalPeriodAPIModel.NotSet) return 'no-data';
    return undefined;
  }, [schedule.data, setUnavailabilityReason]);
  const cancel: UseSettlementScheduleActionsType['cancel'] = {
    act: useCallback(() => withExtractDataDispatch(updateSettlementSchedule)(undefined), [withExtractDataDispatch]),
    inAction: pending,
    unavailabilityReason: cancelUnavailabilityReason,
    available: !cancelUnavailabilityReason,
  };

  return { set, cancel };
}
