import { Descriptions } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { CollectableContractDeployStatusView } from '@/features/collectable/components';
import { AssetAmount, AddressLink } from '@/features/dictionary/blockchain/components';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';

import type { RechargeAddressViewProps } from './types';

const RechargeAddressView: React.FC<RechargeAddressViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={
      <CollectableContractDeployStatusView
        value={data.deployStatus}
        data-test={dataTest && `${dataTest}-deployStatus`}
      />
    }
    column={columns}
  >
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_ADDRESS} />}
      styles={descriptionItemStyles}
    >
      <AddressLink bt={data.blockchain} address={data.address} data-test={dataTest && `${dataTest}-address`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_CID} />}>
      <ReadonlyComponent value={data.cid} data-test={dataTest && `${dataTest}-cid`} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_BALANCE} />}
      styles={descriptionItemStyles}
    >
      <AssetAmount
        value={data.balance.value}
        assetId={data.balance.asset}
        withCurrency
        withBlockchainMark
        currencyMode="long"
        data-test={dataTest && `${dataTest}-balance`}
      />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureRecharges.LABELS_ADDRESS_ROW_TITLE_CREATED_AT} />}>
      <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
    </Descriptions.Item>
  </Descriptions>
);

const RechargeAddressViewMemo = React.memo(RechargeAddressView);

export default RechargeAddressViewMemo;
