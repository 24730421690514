import { Descriptions } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { AddressLink, AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import PayoutBatchStatusView from '@/features/payouts/components/PayoutBatchStatusView';
import { PayoutBatchStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutBatchViewProps } from './types';

const PayoutBatchView: React.FC<PayoutBatchViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  SettlementLink,
  columns = defaultViewColumns,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={<PayoutBatchStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_ORDER} />}>
      <ReadonlyComponent value={data.num} data-test={dataTest && `${dataTest}-num`} copyable={false} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_TOTAL_AMOUNT} />}
      styles={descriptionItemStyles}
    >
      <AssetAmount
        data-test={dataTest && `${dataTest}-amount`}
        value={data.amount.value}
        assetId={data.amount.asset}
        withCurrency
        withBlockchainMark
        currencyMode="long"
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_WALLET} />}
      styles={descriptionItemStyles}
    >
      <AddressLink address={data.wallet} assetId={data.amount.asset} data-test={dataTest && `${dataTest}-wallet`} />
    </Descriptions.Item>
    {data.hash && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_TX} />}
        styles={descriptionItemStyles}
      >
        <TransactionLink data-test={dataTest && `${dataTest}-transaction`} assetId={data.amount.asset} tx={data.hash} />
      </Descriptions.Item>
    )}
    {data.status !== PayoutBatchStatusAPIModel.Succeeded && data.status !== PayoutBatchStatusAPIModel.Created ? (
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_ATTEMPTS} />}>
        <ReadonlyComponent value={data.attempts} data-test={dataTest && `${dataTest}-attempts`} />
      </Descriptions.Item>
    ) : null}
    {data.status !== PayoutBatchStatusAPIModel.Succeeded
    && data.status !== PayoutBatchStatusAPIModel.Created
    && data.nextAttemptAt ? (
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_NEXT_ATTEMPT_AT} />}>
        <ReadonlyDateTime value={data.nextAttemptAt} data-test={dataTest && `${dataTest}-nextAttemptAt`} />
      </Descriptions.Item>
    ) : null}
    {data.broadcastAt && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_SENT_AT} />}>
        <ReadonlyDateTime value={data.broadcastAt} data-test={dataTest && `${dataTest}-broadcastAt`} />
      </Descriptions.Item>
    )}
    {data.settlementId && SettlementLink && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_SETTLEMENT} />}>
        <SettlementLink value={data.settlementId} data-test={dataTest && `${dataTest}-settlement`} />
      </Descriptions.Item>
    )}
    {data.failureReason ? (
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_FAILURE_REASON} />}>
        <ReadonlyComponent value={data.failureReason} data-test={dataTest && `${dataTest}-failure`} />
      </Descriptions.Item>
    ) : null}
  </Descriptions>
);

const PayoutBatchViewMemo = React.memo(PayoutBatchView) as typeof PayoutBatchView;

export default PayoutBatchViewMemo;
