import React, { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage, Operation } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { MerchantWalletIssueBanner } from '@/features/merchant-wallets/components';
import { useSettlementAssetActions } from '@/features/settlements/hooks';
import type { SettlementIntent } from '@/features/settlements/types';
import OperationRefreshBalances from '@/features/statistics/components/OperationRefreshBalances';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';
import SettlementIntentLink from '@/pages/settlements/intent-view/components/SettlementIntentLink';

import SettlementDocumentationLink from '../SettlementDocumentationLink';
import SettlementsIcon from '../SettlementsIcon';

import type { OperationSettleNowProps } from './types';

const selectAuthToken = makeSelectAuthToken();

const OperationSettleNow: React.FC<OperationSettleNowProps> = ({ 'data-test': dataTest, mode = 'inline', assetId }) => {
  const login = useAppSelector(selectAuthToken);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { settleNow } = useSettlementAssetActions(assetId);

  const {
    data: { data: asset },
  } = useAsset(assetId);
  const bt = asset?.blockchain;

  const disabledMessage = useMemo(() => {
    switch (settleNow.unavailabilityReason) {
      case 'empty-balance':
        return (
          <FormattedMessage
            id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_EMPTY_BALANCE}
            values={{
              ln: (text: React.ReactNode) => (
                <OperationRefreshBalances
                  data-test={dataTest && `${dataTest}-disable-opRefresh`}
                  mode="link"
                  title={text}
                />
              ),
            }}
          />
        );
      case 'no-data':
        return <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_LOADING} />;
      case 'in-progress':
        return <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_IN_PROGRESS} />;
      case 'no-deployed-wallet':
        return bt ? <MerchantWalletIssueBanner bt={bt} mode="alert" type="link" /> : undefined;
      case undefined:
        return undefined;
    }
  }, [settleNow.unavailabilityReason, bt, dataTest]);

  const tooltip = useMemo(
    () => (
      <FormattedMessage
        id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_TOOLTIP}
        values={{
          asset: <AssetLabel data-test={dataTest && `${dataTest}-tooltip-asset`} value={assetId} mode="short" />,
          address: login.data?.info.address,
          help: (text: React.ReactNode) => (
            <SettlementDocumentationLink data-test={dataTest && `${dataTest}-docs`} mode="text" title={text} />
          ),
        }}
      />
    ),
    [assetId, dataTest, login.data?.info.address],
  );

  const doSettle = useMemo(
    () =>
      withSuppressError(
        withSuccess(
          withDefaultError(settleNow.act, () => (
            <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_MESSAGE_ERROR} />
          )),
          (intent: SettlementIntent) => ({
            message: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_SUCCESS_MESSAGE} />,
            description: (
              <FormattedMessage
                id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_SUCCESS_DESCRIPTION}
                values={{
                  ln: (text: React.ReactNode) => (
                    <SettlementIntentLink
                      value={intent.id}
                      data-test={dataTest && `${dataTest}-link`}
                      mode="text"
                      title={text}
                    />
                  ),
                }}
              />
            ),
          }),
        ),
      ),
    [dataTest, withDefaultError, withSuccess, settleNow.act],
  );

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SETTLE_NOW_TITLE} />}
      tooltip={tooltip}
      disabled={!settleNow.available || !!disabledMessage}
      disabledMessage={disabledMessage}
      icon={<SettlementsIcon />}
      onClick={doSettle}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationSettleNowMemo = React.memo(withCompanyPermissionsGuard('settleManagement')(OperationSettleNow));

export default OperationSettleNowMemo;
