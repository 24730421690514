/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum WithdrawalPeriodAPIModel {
    NotSet = 'NotSet',
    FirstDayOfWeek = 'FirstDayOfWeek',
    FirstDayOfMonth = 'FirstDayOfMonth'
}


export function WithdrawalPeriodAPIModelFromJSON(json: any): WithdrawalPeriodAPIModel {
    return WithdrawalPeriodAPIModelFromJSONTyped(json, false);
}

export function WithdrawalPeriodAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalPeriodAPIModel {
    return json as WithdrawalPeriodAPIModel;
}

export function WithdrawalPeriodAPIModelToJSON(value?: WithdrawalPeriodAPIModel | null): any {
    return value as any;
}

