import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import {
  OperationCollectRechargeAddressNow,
  OperationGenerateRechargeURL,
  OperationRefreshRechargeAddress,
  RechargesDocumentationLink,
} from '@/features/recharges/components';
import { useRechargeAddress } from '@/features/recharges/hooks';
import type { RechargeAddress } from '@/features/recharges/types';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withRechargeAddressDataLoading = <
  Original extends { data: RechargeAddress } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { addressId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithRechargeAddressDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useRechargeAddress(props.addressId);
    return withCardDataLoading<RechargeAddress, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageRecharges.ADDRESS_VIEW_COMPONENTS_ADDRESS_CARD_TITLE}
          values={{ id: props.addressId }}
        />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <RechargesDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationCollectRechargeAddressNow
              rechargeId={props.addressId}
              data-test={dataTest && `${dataTest}-collectOp`}
              mode="inline"
            />
            <OperationGenerateRechargeURL
              rechargeId={props.addressId}
              data-test={dataTest && `${dataTest}-generateOp`}
              mode="inline"
            />
            <OperationRefreshRechargeAddress
              data-test={dataTest && `${dataTest}-opRefresh`}
              addressId={props.addressId}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withRechargeAddressDataLoading;
