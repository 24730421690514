import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

import { withSuppressPromise } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

export default function useRunOnTimer(action: Func, runActionAt: Date | undefined, runOnce = true) {
  const isActionExecuted = useRef(false);
  useEffect(() => {
    if (runOnce && isActionExecuted.current) {
      return;
    }
    if (!runActionAt) {
      return;
    }
    const diff = dayjs(runActionAt).diff(new Date(), 'ms');
    if (diff > 0) {
      const timeout = setTimeout(() => {
        if (runOnce) {
          isActionExecuted.current = true;
        }
        withSuppressPromise(action)();
      }, diff);
      return (): void => clearTimeout(timeout);
    }
    return undefined;
  }, [action, runActionAt, runOnce]);
}
