import { PlusOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import CompanyPermissionRestrictedMessage from '@/features/company/components/CompanyPermissionRestrictedMessage';
import { useMerchantGasWalletsActions } from '@/features/gas-wallets/hooks';
import { I18nCommon, I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationCreateGasWalletProps } from './types';

const OperationCreateGasWallet: React.FC<OperationCreateGasWalletProps> = ({
  'data-test': dataTest,
  title,
  bt,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { create: createAction } = useMerchantGasWalletsActions();
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const doCreate = useMemo(
    () =>
      withSuppressError(
        withSuccess(
          withDefaultError(
            withOnSuccess(async () => createAction.act(bt), onSuccess),
            () => <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CREATE_WALLET_MESSAGE_ERROR} />,
          ),
          () => <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CREATE_WALLET_MESSAGE_SUCCESS} />,
        ),
      ),
    [withSuccess, withDefaultError, onSuccess, createAction, bt],
  );

  const disabledMessage = useMemo(() => {
    switch (createAction.unavailabilityReason) {
      case 'no-data':
        return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
      case 'no-permissions':
        return <CompanyPermissionRestrictedMessage permission="gasWalletsManagement" />;
      case undefined:
        return undefined;
    }
  }, [createAction.unavailabilityReason]);

  return (
    <Operation
      title={title || <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CREATE_WALLET_TITLE} />}
      icon={<PlusOutlined />}
      onClick={doCreate}
      disabled={!!disabledMessage || !createAction.available}
      disabledMessage={disabledMessage}
      inProgress={createAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationCreateGasWalletMemo = React.memo(OperationCreateGasWallet);

export default OperationCreateGasWalletMemo;
