/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SettlementIntentTransactionSortByAPIModel } from './SettlementIntentTransactionSortByAPIModel';
import {
    SettlementIntentTransactionSortByAPIModelFromJSON,
    SettlementIntentTransactionSortByAPIModelFromJSONTyped,
    SettlementIntentTransactionSortByAPIModelToJSON,
} from './SettlementIntentTransactionSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeSettlementIntentTransactionSortByAPIModel
 */
export interface SortAttributeSettlementIntentTransactionSortByAPIModel {
    /**
     * 
     * @type {SettlementIntentTransactionSortByAPIModel}
     * @memberof SortAttributeSettlementIntentTransactionSortByAPIModel
     */
    attr: SettlementIntentTransactionSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeSettlementIntentTransactionSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeSettlementIntentTransactionSortByAPIModel interface.
 */
export function instanceOfSortAttributeSettlementIntentTransactionSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeSettlementIntentTransactionSortByAPIModelFromJSON(json: any): SortAttributeSettlementIntentTransactionSortByAPIModel {
    return SortAttributeSettlementIntentTransactionSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeSettlementIntentTransactionSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeSettlementIntentTransactionSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': SettlementIntentTransactionSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeSettlementIntentTransactionSortByAPIModelToJSON(value?: SortAttributeSettlementIntentTransactionSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': SettlementIntentTransactionSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

