/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { SubscriptionPlanStatusAPIModel } from './SubscriptionPlanStatusAPIModel';
import {
    SubscriptionPlanStatusAPIModelFromJSON,
    SubscriptionPlanStatusAPIModelFromJSONTyped,
    SubscriptionPlanStatusAPIModelToJSON,
} from './SubscriptionPlanStatusAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionPlanPredicatesAPIModel
 */
export interface SubscriptionPlanPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPlanPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanPredicatesAPIModel
     */
    asset?: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof SubscriptionPlanPredicatesAPIModel
     */
    blockchain?: BlockchainTypeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof SubscriptionPlanPredicatesAPIModel
     */
    networkType?: BlockchainNetworkTypeAPIModel;
    /**
     * 
     * @type {Array<SubscriptionPlanStatusAPIModel>}
     * @memberof SubscriptionPlanPredicatesAPIModel
     */
    statusIn?: Array<SubscriptionPlanStatusAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionPlanPredicatesAPIModel
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPlanPredicatesAPIModel
     */
    name?: string;
}

/**
 * Check if a given object implements the SubscriptionPlanPredicatesAPIModel interface.
 */
export function instanceOfSubscriptionPlanPredicatesAPIModel(value: object): boolean {
    return true;
}

export function SubscriptionPlanPredicatesAPIModelFromJSON(json: any): SubscriptionPlanPredicatesAPIModel {
    return SubscriptionPlanPredicatesAPIModelFromJSONTyped(json, false);
}

export function SubscriptionPlanPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPlanPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'asset': json['asset'] == null ? undefined : json['asset'],
        'blockchain': json['blockchain'] == null ? undefined : BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'networkType': json['networkType'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkType']),
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(SubscriptionPlanStatusAPIModelFromJSON)),
        'tags': json['tags'] == null ? undefined : json['tags'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function SubscriptionPlanPredicatesAPIModelToJSON(value?: SubscriptionPlanPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'asset': value['asset'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'networkType': BlockchainNetworkTypeAPIModelToJSON(value['networkType']),
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(SubscriptionPlanStatusAPIModelToJSON)),
        'tags': value['tags'],
        'name': value['name'],
    };
}

