import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import React, { useMemo } from 'react';

import CollectScheduleIcon from '@/features/collectable/components/CollectScheduleIcon';
import CollectTaskPendingBackgroundTaskTitle from '@/features/collectable/components/CollectTaskPendingBackgroundTaskTitle';
import usePendingCollectTasksCount from '@/features/collectable/hooks/usePendingCollectTasksCount';
import { pendingCollectTasksBackgroundTaskType } from '@/features/collectable/types';
import { useCompanyPermissions } from '@/features/company/hooks';
import type { MerchantPermissions } from '@/features/company/types';
import QRDelegateContractDeployingBackgroundTaskTitle from '@/features/company-settings/components/QRDelegateContractDeployingBackgroundTaskTitle';
import QRPaymentIcon from '@/features/company-settings/components/QRPaymentIcon';
import { useDeployingQRDelegateContractsCount } from '@/features/company-settings/hooks';
import { deployingQRDelegateContractsBackgroundTaskType } from '@/features/company-settings/types';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import GasHistoryReportParametersView from '@/features/gas-history/components/GasHistoryReportParametersView';
import GasHistoryReportScheduleParametersForm from '@/features/gas-history/components/GasHistoryReportScheduleParametersForm';
import GasHistoryReportScheduleParametersView from '@/features/gas-history/components/GasHistoryReportScheduleParametersView';
import GasHistoryReportTitle from '@/features/gas-history/components/GasHistoryReportTitle';
import { gasHistoryExportReport } from '@/features/gas-history/types';
import GasWalletRefundingTaskTitle from '@/features/gas-wallets/components/GasWalletRefundingTaskTitle';
import { makeSelectRefundingGasWalletsCount } from '@/features/gas-wallets/selectors';
import { refundingGasWalletsTaskType } from '@/features/gas-wallets/types';
import MerchantWalletRunningBalancesReportParametersView from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesReportParametersView';
import MerchantWalletRunningBalancesScheduleParametersForm from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesReportScheduleParametersForm';
import MerchantWalletRunningBalancesReportTitle from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesReportTitle';
import MerchantWalletRunningBalancesScheduleParametersView from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalancesScheduleParametersView';
import { merchantWalletRunningBalanceExportReport } from '@/features/merchant-wallet-balance/types';
import MerchantWalletTransfersReportParametersView from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersReportParametersView';
import MerchantWalletTransfersScheduleParametersForm from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersReportScheduleParametersForm';
import MerchantWalletTransfersReportTitle from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersReportTitle';
import MerchantWalletTransfersScheduleParametersView from '@/features/merchant-wallet-transfers/components/MerchantWalletTransfersScheduleParametersView';
import { merchantWalletTransfersExportReport } from '@/features/merchant-wallet-transfers/types';
import MerchantWalletDeployingBackgroundTaskTitle from '@/features/merchant-wallets/components/MerchantWalletDeployingBackgroundTaskTitle';
import MerchantWalletIcon from '@/features/merchant-wallets/components/MerchantWalletIcon';
import { useDeployingMerchantWalletsCount } from '@/features/merchant-wallets/hooks';
import { deployingMerchantWalletBackgroundTaskType } from '@/features/merchant-wallets/types';
import PaymentsReportParametersTitle from '@/features/payments/components/PaymentsReportParametersTitle';
import PaymentsReportParametersView from '@/features/payments/components/PaymentsReportParametersView';
import PaymentsReportScheduleParametersForm from '@/features/payments/components/PaymentsReportScheduleParametersForm';
import PaymentsReportScheduleParametersView from '@/features/payments/components/PaymentsReportScheduleParametersView';
import { paymentExportReport } from '@/features/payments/types';
import ReportGeneratingBackgroundTaskTitle from '@/features/reports/components/ReportGeneratingBackgroundTaskTitle';
import ReportsIcon from '@/features/reports/components/ReportsIcon';
import { useGeneratingReportsCount } from '@/features/reports/hooks';
import { generatingReportsBackgroundTaskType } from '@/features/reports/types';
import SettlementReconciliationReportParametersView from '@/features/settlement-reconciliations/components/SettlementReconciliationReportParametersView';
import SettlementReconciliationScheduleParametersForm from '@/features/settlement-reconciliations/components/SettlementReconciliationScheduleParametersForm';
import SettlementReconciliationScheduleParametersView from '@/features/settlement-reconciliations/components/SettlementReconciliationScheduleParametersView';
import SettlementReconciliationsReportTitle from '@/features/settlement-reconciliations/components/SettlementReconciliationsReportTitle';
import SettlementReconciliationTransfersReportTitle from '@/features/settlement-reconciliations/components/SettlementReconciliationTransfersReportTitle';
import {
  settlementReconciliationsExportReport,
  settlementReconciliationTransfersExportReport,
} from '@/features/settlement-reconciliations/types';
import SettlementIntentPendingBackgroundTaskTitle from '@/features/settlements/components/SettlementIntentPendingBackgroundTaskTitle';
import SettlementsIcon from '@/features/settlements/components/SettlementsIcon';
import usePendingIntentsWithActiveTransactionsCount from '@/features/settlements/hooks/usePendingIntentsWithActiveTransactionsCount';
import { pendingSettlementIntentsBackgroundTaskType } from '@/features/settlements/types';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import DeployingMerchantWalletsBackgroundLink from '@/pages/audit/deploying-wallets/components/DeployingMerchantWalletsBackgroundLink';
import DeployingQRDelegateContractsBackgroundLink from '@/pages/audit/qr-contracts/components/DeployingQRDelegateContractsBackgroundLink';
import CollectPendingTasksBackgroundLink from '@/pages/collectable/pending-tasks/components/CollectPendingTasksBackgroundLink';
import SettlementPendingIntentsBackgroundLink from '@/pages/settlements/pending-intents/components/SettlementPendingIntentsBackgroundLink';

import HandlersContext from './context';

import type { HandlersContextType } from './context';

const createContext = (
  featureFlags: FeatureFlags | undefined,
  permissions: MerchantPermissions | undefined,
): HandlersContextType => ({
  alerts: [],
  background: [
    {
      type: refundingGasWalletsTaskType,
      Message: GasWalletRefundingTaskTitle,
      Icon: CloudDownloadOutlined,
      selector: makeSelectRefundingGasWalletsCount(),
    },
    {
      type: pendingSettlementIntentsBackgroundTaskType,
      Message: SettlementIntentPendingBackgroundTaskTitle,
      Extra: SettlementPendingIntentsBackgroundLink,
      Icon: SettlementsIcon,
      usePayload: usePendingIntentsWithActiveTransactionsCount,
    },
    {
      type: pendingCollectTasksBackgroundTaskType,
      Message: CollectTaskPendingBackgroundTaskTitle,
      Extra: CollectPendingTasksBackgroundLink,
      Icon: CollectScheduleIcon,
      usePayload: usePendingCollectTasksCount,
    },
    {
      type: deployingMerchantWalletBackgroundTaskType,
      Message: MerchantWalletDeployingBackgroundTaskTitle,
      Extra: DeployingMerchantWalletsBackgroundLink,
      Icon: MerchantWalletIcon,
      usePayload: useDeployingMerchantWalletsCount,
    },
    {
      type: deployingQRDelegateContractsBackgroundTaskType,
      Message: QRDelegateContractDeployingBackgroundTaskTitle,
      Extra: DeployingQRDelegateContractsBackgroundLink,
      Icon: QRPaymentIcon,
      usePayload: useDeployingQRDelegateContractsCount,
    },
    {
      type: generatingReportsBackgroundTaskType,
      Message: ReportGeneratingBackgroundTaskTitle,
      Icon: ReportsIcon,
      usePayload: useGeneratingReportsCount,
    },
  ],
  reports: [
    // payments
    {
      template: paymentExportReport,
      Title: PaymentsReportParametersTitle,
      ParametersView: PaymentsReportParametersView,
    },
    // running balance
    {
      template: merchantWalletRunningBalanceExportReport,
      Title: MerchantWalletRunningBalancesReportTitle,
      ParametersView: MerchantWalletRunningBalancesReportParametersView,
    },
    // wallet transfers
    {
      template: merchantWalletTransfersExportReport,
      Title: MerchantWalletTransfersReportTitle,
      ParametersView: MerchantWalletTransfersReportParametersView,
    },
    // reconciliation
    {
      template: settlementReconciliationTransfersExportReport,
      Title: SettlementReconciliationTransfersReportTitle,
      ParametersView: SettlementReconciliationScheduleParametersView,
    },
    {
      template: settlementReconciliationsExportReport,
      Title: SettlementReconciliationsReportTitle,
      ParametersView: SettlementReconciliationReportParametersView,
    },
    // gas history
    ...(featureFlags?.enableGasHistory && permissions?.gasWalletsManagement
      ? [
          {
            template: gasHistoryExportReport,
            Title: GasHistoryReportTitle,
            ParametersView: GasHistoryReportParametersView,
          },
        ]
      : []),
  ],
  reportSchedules: [
    // payments
    {
      template: paymentExportReport,
      Title: PaymentsReportParametersTitle,
      ParametersView: PaymentsReportScheduleParametersView,
      ParametersForm: PaymentsReportScheduleParametersForm,
    },
    // running balance
    {
      template: merchantWalletRunningBalanceExportReport,
      Title: MerchantWalletRunningBalancesReportTitle,
      ParametersView: MerchantWalletRunningBalancesScheduleParametersView,
      ParametersForm: MerchantWalletRunningBalancesScheduleParametersForm,
    },
    // merchant wallets
    {
      template: merchantWalletTransfersExportReport,
      Title: MerchantWalletTransfersReportTitle,
      ParametersView: MerchantWalletTransfersScheduleParametersView,
      ParametersForm: MerchantWalletTransfersScheduleParametersForm,
    },
    // reconciliations
    ...(featureFlags?.enableReconciliation
      ? [
          {
            template: settlementReconciliationTransfersExportReport,
            Title: SettlementReconciliationTransfersReportTitle,
            ParametersView: SettlementReconciliationScheduleParametersView,
            ParametersForm: SettlementReconciliationScheduleParametersForm,
          },
          {
            template: settlementReconciliationsExportReport,
            Title: SettlementReconciliationsReportTitle,
            ParametersView: SettlementReconciliationScheduleParametersView,
            ParametersForm: SettlementReconciliationScheduleParametersForm,
          },
        ]
      : []),
    // gas history
    ...(featureFlags?.enableGasHistory && permissions?.gasWalletsManagement
      ? [
          {
            template: gasHistoryExportReport,
            Title: GasHistoryReportTitle,
            ParametersView: GasHistoryReportScheduleParametersView,
            ParametersForm: GasHistoryReportScheduleParametersForm,
          },
        ]
      : []),
  ],
});

const withHandlerRegistration = <P extends EmptyObject>(Component: React.ComponentType<P>) =>
  namedHOC(
    Component,
    'WithHandlerRegistration',
  )((props) => {
    const {
      data: { data: featureFlags },
    } = useFeatureToggle();
    const {
      data: { data: permissions },
    } = useCompanyPermissions();
    const context = useMemo(() => createContext(featureFlags, permissions), [featureFlags, permissions]);
    return (
      <HandlersContext.Provider value={context}>
        <Component {...props} />
      </HandlersContext.Provider>
    );
  });

export default withHandlerRegistration;
