import React from 'react';

import { OperationRefresh } from '@/components';
import { useSettlementIntentTransactionDetails } from '@/features/settlements/hooks';

import type { OperationRefreshSettlementIntentTransactionDetailsProps } from './types';

const OperationRefreshSettlementIntentTransactionDetails: React.FC<
  OperationRefreshSettlementIntentTransactionDetailsProps
> = ({ 'data-test': dataTest, mode = 'inline', transactionId }) => {
  const { forceRefresh } = useSettlementIntentTransactionDetails(transactionId);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshSettlementIntentTransactionDetailsMemo = React.memo(
  OperationRefreshSettlementIntentTransactionDetails,
);

export default OperationRefreshSettlementIntentTransactionDetailsMemo;
