import { noIdNestedFullFetchResultFn, useAppNestedFullDataFiltered } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import type { MerchantWalletTransfer } from '@/features/merchant-wallet-transfers/types';
import { fetchTransfersForReconciliation } from '@/features/settlement-reconciliations/actions';
import {
  makeSelectIncomingForReconciliationFilteredData,
  makeSelectIncomingForReconciliationFullParameters,
  makeSelectOutgoingForReconciliationFilteredData,
  makeSelectOutgoingForReconciliationFullParameters,
} from '@/features/settlement-reconciliations/selectors';
import { MerchantWalletTransferDirectionAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetchFactory =
  (settlementId: string) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<
    LoadingStateWithDirty<MerchantWalletTransfer[]>,
    Parameters<typeof fetchTransfersForReconciliation>[0]
  > =>
    fetchTransfersForReconciliation({ settlementId, force });

const pendingSelectorCreator = (settlementId: string) =>
  makeSelectPending(fetchTransfersForReconciliation, settlementId);

export default function useSettlementReconciliationMWTransfers(
  direction: MerchantWalletTransferDirectionAPIModel,
  settlementId: string | undefined,
) {
  return useAppNestedFullDataFiltered(
    fetchFactory,
    direction === MerchantWalletTransferDirectionAPIModel.Outgoing
      ? makeSelectOutgoingForReconciliationFullParameters
      : makeSelectIncomingForReconciliationFullParameters,
    pendingSelectorCreator,
    direction === MerchantWalletTransferDirectionAPIModel.Outgoing
      ? makeSelectOutgoingForReconciliationFilteredData
      : makeSelectIncomingForReconciliationFilteredData,
    noIdNestedFullFetchResultFn(),
    settlementId,
  );
}
