import { Form } from 'antd';
import isArray from 'lodash-es/isArray';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { AssetAmountItem as BaseAssetAmountItem } from '@/features/dictionary/blockchain/components';
import { I18nComponents, I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { asType, nameof } from '@/infrastructure/utils/ts';

import AssetSelectItem from '../AssetSelectItem';

import { withDefaultAmountLimits } from './hocs';

import type { AssetAmountSelectItemProps, AssetAmountSelectItemValue } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AssetAmountItem = withDefaultAmountLimits(BaseAssetAmountItem) as typeof BaseAssetAmountItem;

const AssetAmountSelectItem = <Values extends Store = Store, Issue extends string | undefined = undefined>({
  'data-test': dataTest,
  baseName,
  name,
  selectedAssets,
  assets,
  Issues,
  validation,
  messages,
  required,
  readonly,
}: AssetAmountSelectItemProps<Values, Issue>) => {
  const assetId = Form.useWatch<string | undefined>(
    useMemo(
      () => [
        // eslint-disable-next-line no-nested-ternary,@typescript-eslint/no-unsafe-argument
        ...asType<string[]>(baseName ? (isArray(baseName) ? baseName : [baseName]) : []),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        ...asType<string[]>(isArray(name) ? name : [name]),
        nameof<AssetAmountSelectItemValue>('asset'),
      ],
      [baseName, name],
    ),
  );
  const assetName = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    () => [...asType<string[]>(isArray(name) ? name : [name]), nameof<AssetAmountSelectItemValue>('asset')],
    [name],
  );
  const amountName = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    () => [...asType<string[]>(isArray(name) ? name : [name]), nameof<AssetAmountSelectItemValue>('amount')],
    [name],
  );
  return (
    <>
      <AssetSelectItem
        data-test={dataTest && `${dataTest}-asset`}
        name={assetName}
        assets={assets}
        Issues={Issues}
        required={required}
        messages={messages}
        readonly={readonly}
        validation={validation}
        selectedAssets={selectedAssets}
      />
      {assetId ? (
        <AssetAmountItem name={amountName} data-test={dataTest && `${dataTest}-amount`} assetId={assetId} required />
      ) : (
        <Form.Item<Values> label={<FormattedMessage id={I18nComponents.MONEY_AMOUNT_ITEM_LABEL} />}>
          <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSETS_SELECT_AMOUNT_ITEM_SELECT_ASSET} />
        </Form.Item>
      )}
    </>
  );
};

const AssetAmountSelectItemMemo = React.memo(AssetAmountSelectItem) as typeof AssetAmountSelectItem;

export default AssetAmountSelectItemMemo;
