import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureReports } from '@/generated/i18n/i18n';

import type { ReportGeneratingBackgroundTaskTitleProps } from './types';

const ReportGeneratingBackgroundTaskTitle: React.FC<ReportGeneratingBackgroundTaskTitleProps> = ({ payload }) => (
  <FormattedMessage
    id={I18nFeatureReports.MESSAGES_GENERATING_TASK_MESSAGE}
    values={{ count: payload && typeof payload === 'number' ? payload : 0 }}
  />
);

const ReportGeneratingBackgroundTaskTitleMemo = React.memo(ReportGeneratingBackgroundTaskTitle);

export default ReportGeneratingBackgroundTaskTitleMemo;
