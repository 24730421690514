import dayjs from 'dayjs';
import { useEffect } from 'react';

import { useStateMountSafe } from '@/hooks/index';

// rounds UP
const calculateDiff = (till?: Date) => {
  if (!till) {
    return 0;
  }
  const diff = dayjs(till).diff(new Date(), 's', true);
  return diff > 0 ? Math.ceil(diff) : 0;
};

export default function useTimer(till: Date | undefined): [number | undefined] {
  const [secondsTil, setSecondsTil] = useStateMountSafe<number | undefined>(() => {
    const diff = calculateDiff(till);
    return diff > 0 ? diff : undefined;
  });
  useEffect(() => {
    if (!till) {
      setSecondsTil(undefined);
      return undefined;
    }
    const diff = calculateDiff(till);
    if (diff > 0) {
      setSecondsTil(diff);
      const interval = setInterval(() => {
        const diff = calculateDiff(till);
        setSecondsTil(diff > 0 ? diff : 0);
        if (diff <= 0) {
          clearInterval(interval);
        }
      }, 1000);
      return (): void => clearInterval(interval);
    }
    setSecondsTil(undefined);
    return undefined;
  }, [setSecondsTil, till]);
  return [secondsTil];
}
