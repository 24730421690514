import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectMerchantWalletTransferListParameters,
  makeSelectMerchantWalletTransferListData,
  makeSelectMerchantWalletTransfer,
  makeSelectMultipleMerchantWalletTransfer,
  makeSelectDirtyMerchantWalletTransferIds,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state: AppRootState) => state[NAMESPACE].entities,
  'MerchantWalletTransfer' as const,
  undefined,
);

export const makeSelectMerchantWalletTransferListParametersWithNetwork: typeof makeSelectMerchantWalletTransferListParameters =
  () =>
    createSelector(
      makeSelectMerchantWalletTransferListParameters(),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkEq,
      ): ReturnType<ReturnType<typeof makeSelectMerchantWalletTransferListParameters>> => ({
        ...parameters,
        filter: { ...filter, networkEq },
      }),
    );
