import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useSettlementIntentTransaction } from '@/features/settlements/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import SettlementIntentTransactionsBreadcrumb from '@/pages/settlements/intent-transactions/components/SettlementIntentTransactionsBreadcrumb';
import SettlementScheduleBreadcrumb from '@/pages/settlements/scheduled/components/SettlementScheduleBreadcrumb';

import SettlementIntentTransactionLink from '../SettlementIntentTransactionLink';

import type { SettlementIntentTransactionBreadcrumbProps } from './types';

const SettlementIntentTransactionBreadcrumb: React.FC<SettlementIntentTransactionBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  transactionId,
  items: baseItems,
}) => {
  const { data: intent } = useSettlementIntentTransaction(transactionId);
  const intentId = intent.data?.intentId;
  const items = useMemo(
    () => [
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_BREADCRUMB_TITLE}
            values={{ id: transactionId }}
          />
        ) : (
          <SettlementIntentTransactionLink
            value={transactionId}
            mode="text"
            title={
              <FormattedMessage
                id={I18nPageSettlements.INTENT_TRANSACTION_VIEW_COMPONENTS_BREADCRUMB_TITLE}
                values={{ id: transactionId }}
              />
            }
          />
        ),
      },
      ...(baseItems ?? []),
    ],
    [baseItems, disabled, transactionId],
  );
  return intentId ? (
    <SettlementIntentTransactionsBreadcrumb data-test={dataTest} intentId={intentId} items={items} />
  ) : (
    <SettlementScheduleBreadcrumb data-test={dataTest} items={items} />
  );
};

const SettlementIntentTransactionBreadcrumbMemo = React.memo(SettlementIntentTransactionBreadcrumb);

export default SettlementIntentTransactionBreadcrumbMemo;
