import React from 'react';

import { OperationRefresh } from '@/components';
import { usePendingCollectTaskSummaries } from '@/features/collectable/hooks';

import type { OperationRefreshPendingCollectTaskSummariesProps } from './types';

const OperationRefreshPendingCollectTaskSummaries: React.FC<OperationRefreshPendingCollectTaskSummariesProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = usePendingCollectTaskSummaries();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshPendingCollectTaskSummariesMemo = React.memo(OperationRefreshPendingCollectTaskSummaries);

export default OperationRefreshPendingCollectTaskSummariesMemo;
