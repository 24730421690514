/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportScheduleUpdateAPIModel
 */
export interface ReportScheduleUpdateAPIModel {
    /**
     * 
     * @type {string}
     * @memberof ReportScheduleUpdateAPIModel
     */
    cron: string;
    /**
     * 
     * @type {any}
     * @memberof ReportScheduleUpdateAPIModel
     */
    parameters?: any;
}

/**
 * Check if a given object implements the ReportScheduleUpdateAPIModel interface.
 */
export function instanceOfReportScheduleUpdateAPIModel(value: object): boolean {
    if (!('cron' in value)) return false;
    return true;
}

export function ReportScheduleUpdateAPIModelFromJSON(json: any): ReportScheduleUpdateAPIModel {
    return ReportScheduleUpdateAPIModelFromJSONTyped(json, false);
}

export function ReportScheduleUpdateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportScheduleUpdateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'cron': json['cron'],
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
    };
}

export function ReportScheduleUpdateAPIModelToJSON(value?: ReportScheduleUpdateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cron': value['cron'],
        'parameters': value['parameters'],
    };
}

