import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import type { MerchantPermission } from '@/features/company/types';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import type { CompanyPermissionLabelProps } from './types';

export const i18n: Record<MerchantPermission, I18nFeatureCompany> = {
  assetsManagement: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_ASSETSMANAGEMENT,
  brandingConfiguration: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_BRANDINGCONFIGURATION,
  collectManagement: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_COLLECTMANAGEMENT,
  gasWalletsManagement: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_GASWALLETSMANAGEMENT,
  integrationManagement: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_INTEGRATIONMANAGEMENT,
  settleManagement: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_SETTLEMANAGEMENT,
  termsOfServiceSet: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_TERMSOFSERVICESET,
  usersManagement: I18nFeatureCompany.COMPONENTS_PERMISSION_LABEL_USERSMANAGEMENT,
};

const CompanyPermissionLabel: React.FC<CompanyPermissionLabelProps> = ({ value }) =>
  value ? <FormattedMessageWithMarkup id={i18n[value]} /> : null;

const CompanyPermissionLabelMemo = React.memo(CompanyPermissionLabel);

export default CompanyPermissionLabelMemo;
