import { useCallback } from 'react';

import { useAppDispatch, useAppLoadableData, useAppSelector } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchDeployingMerchantWallets } from '@/features/merchant-wallets/actions';
import {
  makeSelectDeployingMerchantWallets,
  makeSelectDeployingMerchantWalletsRefreshableAfter,
} from '@/features/merchant-wallets/selectors';
import type { MerchantWallet } from '@/features/merchant-wallets/types';
import { useRunOnTimer } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<MerchantWallet[]>, Parameters<typeof fetchDeployingMerchantWallets>[0]> =>
  fetchDeployingMerchantWallets({ force });

const selectFetching = makeSelectPending(fetchDeployingMerchantWallets);
const selectData = makeSelectDeployingMerchantWallets();
const selectNextDataUpdateAt = makeSelectDeployingMerchantWalletsRefreshableAfter();

export default function useDeployingMerchantWallets() {
  const { withExtractDataDispatch } = useAppDispatch();
  const nextUpdateAt = useAppSelector(selectNextDataUpdateAt);
  const data = useAppLoadableData(fetchFactory, selectData, selectFetching);
  const refresh = useCallback(() => {
    if (data.data.isDirty || data.data.data) {
      withSuppressPromise(withExtractDataDispatch(fetchDeployingMerchantWallets))({});
    }
  }, [data.data.data, data.data.isDirty, withExtractDataDispatch]);
  useRunOnTimer(refresh, nextUpdateAt, false);
  return useAppLoadableData(fetchFactory, selectData, selectFetching);
}
