import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementIntentTransactionWalletLikeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_TRANSACTION_FILTER_FORM_WALLET_LABEL} />,
};

const SettlementIntentTransactionWalletLikeFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: SettlementIntentTransactionWalletLikeFilterItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      messages={messages}
      placeholder={formatMessage({
        id: I18nFeatureSettlements.COMPONENTS_INTENT_TRANSACTION_FILTER_FORM_WALLET_PLACEHOLDER,
      })}
      {...ItemProps}
    />
  );
};

const SettlementIntentTransactionWalletLikeFilterItemMemo = React.memo(
  SettlementIntentTransactionWalletLikeFilterItem,
) as typeof SettlementIntentTransactionWalletLikeFilterItem;

export default SettlementIntentTransactionWalletLikeFilterItemMemo;
