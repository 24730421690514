import React, { useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage, OperationRefresh } from '@/components';
import { markDonationsDependentDataDirty } from '@/features/donations/actions';
import { useDonation } from '@/features/donations/hooks';
import type { Donation } from '@/features/donations/types';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { suppressPromise } from '@/infrastructure/utils/functions';

import type { OperationRefreshDonationProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_SUCCESS_MESSAGE} />,
  },
};

const OperationRefreshDonation: React.FC<OperationRefreshDonationProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  donationId,
}) => {
  const { data, forceRefresh } = useDonation(donationId);

  const dispatch = useAppDispatch();
  const refreshDependantData = useCallback(
    (newData: LoadingStateWithDirty<Donation>) => {
      if (
        newData.data?.addresses.reduce(
          (result, address) =>
            result
            || !isEqual(
              address,
              data.data?.addresses.find(({ id }) => address.id === id),
            ),
          false,
        )
      ) {
        suppressPromise(dispatch(markDonationsDependentDataDirty([newData.data.id])));
      }
    },
    [data.data?.addresses, dispatch],
  );
  return (
    <OperationRefresh
      data-test={dataTest}
      mode={mode}
      refresh={forceRefresh}
      messages={messages}
      disabled={!donationId}
      onSuccess={refreshDependantData}
    />
  );
};

const OperationRefreshDonationMemo = React.memo(OperationRefreshDonation);

export default OperationRefreshDonationMemo;
