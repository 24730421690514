import { useMemo } from 'react';

import { noSingleDataIdFn, type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonationDeployTransaction } from '@/features/donations/actions';
import { makeSelectDonationAssetDeployTransaction } from '@/features/donations/selectors';
import type { DonationAssetId } from '@/features/donations/types';
import { donationAssetIdToKey } from '@/features/donations/utils';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseDonationAssetDeployTransaction = UseAppSingleData<GasWalletTransaction>;

const fetchFactory = (
  { donationId, asset }: DonationAssetId,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<GasWalletTransaction>,
  Parameters<typeof fetchDonationDeployTransaction>[0]
> => fetchDonationDeployTransaction({ donationId, asset, force });

export default function useDonationAddressDeployTransaction(
  donationId: string | undefined,
  asset: string | undefined,
): UseDonationAssetDeployTransaction {
  const id = useMemo(() => (donationId && asset ? { donationId, asset } : undefined), [asset, donationId]);
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchDonationDeployTransaction, id ? donationAssetIdToKey(id) : undefined),
    [id],
  );
  return useAppSingleData(
    fetchFactory,
    makeSelectDonationAssetDeployTransaction,
    noSingleDataIdFn(),
    id,
    dataFetchingSelector,
  );
}
