import { useMemo } from 'react';

import { noSingleDataIdFn, type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectCollectEntityProcessTransaction } from '@/features/collectable/selectors';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRechargeAddressDeployTransaction } from '@/features/recharges/actions';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseRechargeAddressDeployTransaction = UseAppSingleData<GasWalletTransaction>;

const fetchFactory = (
  addressId: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<GasWalletTransaction>,
  Parameters<typeof fetchRechargeAddressDeployTransaction>[0]
> => fetchRechargeAddressDeployTransaction({ addressId, force });

export default function useRechargeAddressDeployTransaction(
  addressId: string | undefined,
): UseRechargeAddressDeployTransaction {
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchRechargeAddressDeployTransaction, addressId),
    [addressId],
  );
  return useAppSingleData(
    fetchFactory,
    makeSelectCollectEntityProcessTransaction,
    noSingleDataIdFn(),
    addressId,
    dataFetchingSelector,
  );
}
