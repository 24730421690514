/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { PayoutStatusAPIModel } from './PayoutStatusAPIModel';
import {
    PayoutStatusAPIModelFromJSON,
    PayoutStatusAPIModelFromJSONTyped,
    PayoutStatusAPIModelToJSON,
} from './PayoutStatusAPIModel';

/**
 * 
 * @export
 * @interface PayoutPredicatesAPIModel
 */
export interface PayoutPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof PayoutPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PayoutPredicatesAPIModel
     */
    titleLike?: string;
    /**
     * 
     * @type {Array<PayoutStatusAPIModel>}
     * @memberof PayoutPredicatesAPIModel
     */
    statusIn?: Array<PayoutStatusAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PayoutPredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof PayoutPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof PayoutPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof PayoutPredicatesAPIModel
     */
    createdAtRange?: DateTimeRangeAPIModel;
}

/**
 * Check if a given object implements the PayoutPredicatesAPIModel interface.
 */
export function instanceOfPayoutPredicatesAPIModel(value: object): boolean {
    return true;
}

export function PayoutPredicatesAPIModelFromJSON(json: any): PayoutPredicatesAPIModel {
    return PayoutPredicatesAPIModelFromJSONTyped(json, false);
}

export function PayoutPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'titleLike': json['titleLike'] == null ? undefined : json['titleLike'],
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(PayoutStatusAPIModelFromJSON)),
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
        'createdAtRange': json['createdAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdAtRange']),
    };
}

export function PayoutPredicatesAPIModelToJSON(value?: PayoutPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'titleLike': value['titleLike'],
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(PayoutStatusAPIModelToJSON)),
        'assetIn': value['assetIn'],
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
        'createdAtRange': DateTimeRangeAPIModelToJSON(value['createdAtRange']),
    };
}

