import dayjs from 'dayjs';
import React, { useCallback } from 'react';

import type { MerchantWallet } from '@/features/merchant-wallets/types';
import { useRunOnTimer, useStateMountSafe } from '@/hooks';

import { MerchantWalletDeployingNonStaleIssueBanner, MerchantWalletDeployingStaleIssueBanner } from './components';

import type { MerchantWalletDeployingIssueBannerProps } from './types';

const calculateIdStale = (wallet: MerchantWallet) => wallet.staleAt && dayjs().isBefore(wallet.staleAt);

const MerchantWalletDeployingIssueBanner: React.FC<MerchantWalletDeployingIssueBannerProps> = ({
  wallet,
  ...props
}) => {
  const [isStale, setIsStale] = useStateMountSafe(() => calculateIdStale(wallet));
  const refreshStale = useCallback(() => setIsStale(calculateIdStale(wallet)), [setIsStale, wallet]);
  useRunOnTimer(refreshStale, wallet.staleAt);
  return isStale ? (
    <MerchantWalletDeployingNonStaleIssueBanner {...props} />
  ) : (
    <MerchantWalletDeployingStaleIssueBanner {...props} />
  );
};

const MerchantWalletDeployingIssueBannerMemo = React.memo(
  MerchantWalletDeployingIssueBanner,
) as typeof MerchantWalletDeployingIssueBanner;

export default MerchantWalletDeployingIssueBannerMemo;
