import { Descriptions } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyDateTime,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { AddressLink, BlockchainLabel, TransactionLink } from '@/features/dictionary/blockchain/components';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import MerchantWalletDeployTransactionStatusView from '../MerchantWalletDeployTransactionStatusView';

import type { MerchantWalletDeployTransactionViewProps } from './types';

const MerchantWalletDeployTransactionView: React.FC<MerchantWalletDeployTransactionViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
  WalletLink,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={
      <MerchantWalletDeployTransactionStatusView data-test={dataTest && `${dataTest}-status`} value={data.status} />
    }
    column={columns}
  >
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_DEPLOY_TX_ADDRESS} />}
      styles={descriptionItemStyles}
    >
      <AddressLink data-test={dataTest && `${dataTest}-sender`} bt={data.blockchain} address={data.address} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_DEPLOY_TX_BLOCKCHAIN} />}
      styles={descriptionItemStyles}
    >
      <BlockchainLabel data-test={dataTest && `${dataTest}-blockchain`} bt={data.blockchain} />
    </Descriptions.Item>
    {data.hash && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_DEPLOY_TX_TX} />}
        styles={descriptionItemStyles}
      >
        <TransactionLink data-test={dataTest && `${dataTest}-tx`} bt={data.blockchain} tx={data.hash} />
      </Descriptions.Item>
    )}
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_DEPLOY_TX_UPDATED_AT} />}
    >
      <ReadonlyDateTime value={data.updatedAt} data-test={dataTest && `${dataTest}-updatedAt`} />
    </Descriptions.Item>
    {WalletLink && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWallets.LABELS_ROW_TITLE_DEPLOY_TX_WALLET} />}>
        <WalletLink data-test={dataTest && `${dataTest}-wallet`} value={data.blockchain} mode="text" />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const MerchantWalletDeployTransactionViewMemo = React.memo(MerchantWalletDeployTransactionView);

export default MerchantWalletDeployTransactionViewMemo;
