import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import CollectPendingTasksLink from '../CollectPendingTasksLink';

import type { CollectPendingTasksBackgroundLinkProps } from './types';

const CollectPendingTasksBackgroundLink: React.FC<CollectPendingTasksBackgroundLinkProps> = (props) => (
  <CollectPendingTasksLink
    title={<FormattedMessage id={I18nFeatureCollectable.MESSAGES_PENDING_TASK_EXTRA} />}
    mode="text"
    {...props}
  />
);

export default CollectPendingTasksBackgroundLink;
