import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementIntentPendingBackgroundTaskTitleProps } from './types';

const SettlementIntentPendingBackgroundTaskTitle: React.FC<SettlementIntentPendingBackgroundTaskTitleProps> = ({
  payload,
}) => (
  <FormattedMessage
    id={I18nFeatureSettlements.MESSAGES_PENDING_TASK_MESSAGE}
    values={{ count: payload && typeof payload === 'number' ? payload : 0 }}
  />
);

const SettlementIntentPendingBackgroundTaskTitleMemo = React.memo(SettlementIntentPendingBackgroundTaskTitle);

export default SettlementIntentPendingBackgroundTaskTitleMemo;
