/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSettlementIntentTransactionSortByAPIModel } from './PageSettlementIntentTransactionSortByAPIModel';
import {
    PageSettlementIntentTransactionSortByAPIModelFromJSON,
    PageSettlementIntentTransactionSortByAPIModelFromJSONTyped,
    PageSettlementIntentTransactionSortByAPIModelToJSON,
} from './PageSettlementIntentTransactionSortByAPIModel';
import type { SettlementIntentTransactionAPIModel } from './SettlementIntentTransactionAPIModel';
import {
    SettlementIntentTransactionAPIModelFromJSON,
    SettlementIntentTransactionAPIModelFromJSONTyped,
    SettlementIntentTransactionAPIModelToJSON,
} from './SettlementIntentTransactionAPIModel';

/**
 * 
 * @export
 * @interface SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel
 */
export interface SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel {
    /**
     * 
     * @type {Array<SettlementIntentTransactionAPIModel>}
     * @memberof SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel
     */
    list?: Array<SettlementIntentTransactionAPIModel>;
    /**
     * 
     * @type {PageSettlementIntentTransactionSortByAPIModel}
     * @memberof SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel
     */
    page: PageSettlementIntentTransactionSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel interface.
 */
export function instanceOfSliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModelFromJSON(json: any): SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel {
    return SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModelFromJSONTyped(json, false);
}

export function SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(SettlementIntentTransactionAPIModelFromJSON)),
        'page': PageSettlementIntentTransactionSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModelToJSON(value?: SliceSettlementIntentTransactionSettlementIntentTransactionSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(SettlementIntentTransactionAPIModelToJSON)),
        'page': PageSettlementIntentTransactionSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

