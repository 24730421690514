import React from 'react';

import { GasWalletTransactionView } from '@/features/gas-wallets/components';

import { withRechargeAddressDataLoading, withRechargeAddressDeployTransactionAppearance } from './hocs';

const RechargeAddressDeployTransactionCard = withRechargeAddressDeployTransactionAppearance(
  withRechargeAddressDataLoading((props) => <GasWalletTransactionView {...props} columns={1} />),
);

const RechargeAddressDeployTransactionCardMemo = React.memo(
  RechargeAddressDeployTransactionCard,
) as typeof RechargeAddressDeployTransactionCard;

export default RechargeAddressDeployTransactionCardMemo;
