import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import type { MerchantWalletDeployingBackgroundTaskTitleProps } from './types';

const MerchantWalletDeployingBackgroundTaskTitle: React.FC<MerchantWalletDeployingBackgroundTaskTitleProps> = ({
  payload,
}) => (
  <FormattedMessage
    id={I18nFeatureMerchantWallets.MESSAGES_DEPLOYING_TASK_MESSAGE}
    values={{ count: payload && typeof payload === 'number' ? payload : 0 }}
  />
);

const MerchantWalletDeployingBackgroundTaskTitleMemo = React.memo(MerchantWalletDeployingBackgroundTaskTitle);

export default MerchantWalletDeployingBackgroundTaskTitleMemo;
