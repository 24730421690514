import { useCallback } from 'react';

import { useAppDispatch, useAppLoadableData, useAppSelector } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchPendingCollectTaskSummaries } from '@/features/collectable/actions';
import {
  makeSelectPendingCollectTaskSummaries,
  makeSelectPendingCollectTasksRefreshableAfter,
} from '@/features/collectable/selectors';
import type { CollectTaskSummary } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import { useRunOnTimer } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<CollectTaskSummary[]>,
  Parameters<typeof fetchPendingCollectTaskSummaries>[0]
> => fetchPendingCollectTaskSummaries({ force });

const selectFetching = makeSelectPending(fetchPendingCollectTaskSummaries);
const selectData = makeSelectPendingCollectTaskSummaries();
const selectNextDataUpdateAt = makeSelectPendingCollectTasksRefreshableAfter();

export default function usePendingCollectTaskSummaries() {
  const { withExtractDataDispatch } = useAppDispatch();
  const nextUpdateAt = useAppSelector(selectNextDataUpdateAt);
  const data = useAppLoadableData(fetchFactory, selectData, selectFetching);
  const refresh = useCallback(() => {
    if (data.data.isDirty || data.data.data) {
      withSuppressPromise(withExtractDataDispatch(fetchPendingCollectTaskSummaries))({});
    }
  }, [data.data.data, data.data.isDirty, withExtractDataDispatch]);
  useRunOnTimer(refresh, nextUpdateAt, false);
  return data;
}
