import React from 'react';

import { OperationRefresh } from '@/components';
import { usePendingIntents } from '@/features/settlements/hooks';

import type { OperationRefreshPendingIntentsProps } from './types';

const OperationRefreshPendingIntents: React.FC<OperationRefreshPendingIntentsProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = usePendingIntents();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshPendingIntentsMemo = React.memo(OperationRefreshPendingIntents);

export default OperationRefreshPendingIntentsMemo;
