import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { extractEntityTransactionId } from '@/features/collectable/utils';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import {
  createNestedNormalizedListSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';

import { defaultRechargeTransactionListState, NAMESPACE } from './types';

import type { RechargeTransactionListState } from './types';

export const { makeSelectRechargeAddress, makeSelectRechargeAddressListData, makeSelectRechargeAddressListParameters } =
  createNormalizedListSelectors(
    (state: AppRootState) => state[NAMESPACE].list,
    (state) => state[NAMESPACE].entities,
    'RechargeAddress' as const,
    undefined,
  );

export const makeSelectRechargeAddressListParametersWithNetwork: typeof makeSelectRechargeAddressListParameters =
  () =>
    createSelector(
      makeSelectRechargeAddressListParameters(),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkEq,
      ): ReturnType<ReturnType<typeof makeSelectRechargeAddressListParameters>> => ({
        ...parameters,
        filter: { ...filter, networkEq },
      }),
    );

export const { makeSelectRechargeTransactionsListData, makeSelectRechargeTransactionsListParameters } =
  createNormalizedListSelectors(
    {
      listNoColumn: (state: AppRootState) => state[NAMESPACE].transactions.list,
      columnState: (state: AppRootState) => state[NAMESPACE].transactions.columnState,
    },
    (state) => state.collectable.transactions,
    'RechargeTransactions' as const,
    extractEntityTransactionId,
  );

export const makeSelectRechargeTransactionsListParametersWithNetwork: typeof makeSelectRechargeTransactionsListParameters =
  () =>
    createSelector(
      makeSelectRechargeTransactionsListParameters(),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkEq,
      ): ReturnType<ReturnType<typeof makeSelectRechargeTransactionsListParameters>> => ({
        ...parameters,
        filter: { ...filter, networkEq },
      }),
    );

export const {
  makeSelectRechargeTransactionForAddressListData,
  makeSelectRechargeTransactionForAddressListParameters: baseMakeSelectRechargeTransactionForAddressListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, addressId: string | undefined): RechargeTransactionListState =>
    state[NAMESPACE].transactions.byAddress[addressId!] ?? defaultRechargeTransactionListState,
  (state) => state[NAMESPACE].transactions.columnState,
  (state) => state.collectable.transactions,
  'RechargeTransactionForAddress' as const,
);

export const makeSelectRechargeTransactionForAddressListParameters: typeof baseMakeSelectRechargeTransactionForAddressListParameters =
  (addressId) =>
    createSelector(
      baseMakeSelectRechargeTransactionForAddressListParameters(addressId),
      ({
        filter,
        ...parameters
      }): ReturnType<ReturnType<typeof baseMakeSelectRechargeTransactionForAddressListParameters>> => ({
        ...parameters,
        filter: { ...filter, rechargeAddressIn: [addressId!] },
      }),
    );
