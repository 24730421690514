/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportDownloadLinkAPIModel
 */
export interface ReportDownloadLinkAPIModel {
    /**
     * 
     * @type {string}
     * @memberof ReportDownloadLinkAPIModel
     */
    link: string;
}

/**
 * Check if a given object implements the ReportDownloadLinkAPIModel interface.
 */
export function instanceOfReportDownloadLinkAPIModel(value: object): boolean {
    if (!('link' in value)) return false;
    return true;
}

export function ReportDownloadLinkAPIModelFromJSON(json: any): ReportDownloadLinkAPIModel {
    return ReportDownloadLinkAPIModelFromJSONTyped(json, false);
}

export function ReportDownloadLinkAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportDownloadLinkAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'link': json['link'],
    };
}

export function ReportDownloadLinkAPIModelToJSON(value?: ReportDownloadLinkAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'link': value['link'],
    };
}

