import { useAppSelector } from '@/app/hooks';
import useAuthActions from '@/features/auth/hooks/useAuthActions';
import { makeSelectAuthStatus, makeSelectAuthToken } from '@/features/auth/selectors';
import { makeSelectUser } from '@/features/user/selectors';
import { useFetching } from '@/hooks';

const selectAuthStatus = makeSelectAuthStatus();
const selectAuth = makeSelectAuthToken();
const selectUser = makeSelectUser();

export default function useAuth() {
  const { tryInitJWT } = useAuthActions();
  const status = useAppSelector(selectAuthStatus);
  const auth = useAppSelector(selectAuth);
  const user = useAppSelector(selectUser);
  useFetching(tryInitJWT.act, tryInitJWT.available, tryInitJWT.inAction);

  return { status, auth, user };
}
