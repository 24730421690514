import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import AuditBreadcrumb from '@/pages/audit/components/AuditBreadcrumb';

import DeployingMerchantWalletsLink from '../DeployingMerchantWalletsLink';

import type { DeployingMerchantWalletsBreadcrumbProps } from './types';

const DeployingMerchantWalletsBreadcrumb: React.FC<DeployingMerchantWalletsBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <AuditBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.WALLETS_DEPLOYING_COMPONENTS_BREADCRUMB} />
        ) : (
          <DeployingMerchantWalletsLink
            mode="text"
            title={<FormattedMessage id={I18nPageAudit.WALLETS_DEPLOYING_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const DeployingMerchantWalletsBreadcrumbMemo = React.memo(DeployingMerchantWalletsBreadcrumb);

export default DeployingMerchantWalletsBreadcrumbMemo;
