import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import CollectTasksBreadcrumb from '@/pages/collectable/tasks/components/CollectTasksBreadcrumb';

import CollectPendingTasksLink from '../CollectPendingTasksLink';

import type { CollectPendingTasksBreadcrumbProps } from './types';

const CollectPendingTasksBreadcrumbRaw: React.FC<CollectPendingTasksBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <CollectTasksBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageCollectable.PENDING_COMPONENTS_BREADCRUMB} />
        ) : (
          <CollectPendingTasksLink
            mode="text"
            title={<FormattedMessage id={I18nPageCollectable.PENDING_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const CollectPendingTasksBreadcrumb = React.memo(CollectPendingTasksBreadcrumbRaw);

export default CollectPendingTasksBreadcrumb;
