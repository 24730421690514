import { HourglassOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge, Dropdown, type DropdownProps } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { useHandlers } from '@/features/global/hooks';
import type { UserAlertPayload } from '@/features/global/types';
import { useStateMountSafe } from '@/hooks';
import { notEmpty } from '@/infrastructure/utils/ts';

import { BackgroundTaskPayloadSelect, BackgroundTaskPayloadHook } from './components';

import type { BackgroundTasksDropdownProps } from './types';

const BackgroundTasksDropdown: React.FC<BackgroundTasksDropdownProps> = ({ 'data-test': dataTest, title }) => {
  const handlers = useHandlers('background');

  const [payloads, setPayloads] = useStateMountSafe<{ type: string; payload: UserAlertPayload }[]>([]);
  const setPayload = useCallback(
    (type: string, payload: UserAlertPayload) =>
      setPayloads((previous) => {
        const exists = !!previous.find((old) => old.type === type);
        return exists
          ? previous.map((old) => (old.type === type ? { type, payload } : old))
          : [...previous, { type, payload }];
      }),
    [setPayloads],
  );

  const items = useMemo<NonNullable<DropdownProps['menu']>['items']>(
    () =>
      handlers
        .map(({ selector, usePayload, ...handler }) => {
          const payload = payloads.find((p) => p.type === handler.type)?.payload;
          return payload ? { ...handler, payload } : undefined;
        })
        .filter(notEmpty)
        .map(({ payload, type, Icon, Message, Extra }) => ({
          'data-test': dataTest && `${dataTest}-item-${type}`,
          key: type,
          icon: <Icon />,
          label: <Message payload={payload} />,
          extra: Extra && <Extra payload={payload} />,
        })),
    [dataTest, handlers, payloads],
  );
  const menu = useMemo(() => ({ items, selectedKeys: [] }), [items]);

  return (
    <>
      {
        // hooks can't be rendered conditionally that's why we wrap it with some BackgroundTaskPayload Component
        handlers.map(({ type, selector, usePayload }) =>
          selector ? (
            <BackgroundTaskPayloadSelect
              key={type}
              onChange={(payload) => setPayload(type, payload)}
              selector={selector}
            />
          ) : (
            <BackgroundTaskPayloadHook
              key={type}
              onChange={(payload) => setPayload(type, payload)}
              usePayload={usePayload}
            />
          ),
        )
      }

      {!!items?.length && (
        <Dropdown menu={menu}>
          {title ?? (
            <Badge
              count={<SyncOutlined spin style={{ fontSize: 'xx-small' }} />}
              offset={[-5, 5]}
              showZero
              styles={{ root: { color: 'inherit' } }}
            >
              <HourglassOutlined />
            </Badge>
          )}
        </Dropdown>
      )}
    </>
  );
};

const BackgroundTasksDropdownMemo = React.memo(BackgroundTasksDropdown);

export default BackgroundTasksDropdownMemo;
