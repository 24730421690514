import React from 'react';

import { OperationRefresh } from '@/components';
import { useDeployingQRDelegateContracts } from '@/features/company-settings/hooks';

import type { OperationRefreshDeployingQRDelegateContractsProps } from './types';


const OperationRefreshDeployingQRDelegateContracts: React.FC<OperationRefreshDeployingQRDelegateContractsProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useDeployingQRDelegateContracts();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshDeployingQRDelegateContractsMemo = React.memo(OperationRefreshDeployingQRDelegateContracts);

export default OperationRefreshDeployingQRDelegateContractsMemo;
