/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PagePayoutSortByAPIModel } from './PagePayoutSortByAPIModel';
import {
    PagePayoutSortByAPIModelFromJSON,
    PagePayoutSortByAPIModelFromJSONTyped,
    PagePayoutSortByAPIModelToJSON,
} from './PagePayoutSortByAPIModel';
import type { PayoutSummaryAPIModel } from './PayoutSummaryAPIModel';
import {
    PayoutSummaryAPIModelFromJSON,
    PayoutSummaryAPIModelFromJSONTyped,
    PayoutSummaryAPIModelToJSON,
} from './PayoutSummaryAPIModel';

/**
 * 
 * @export
 * @interface SlicePayoutSummaryPayoutSortByAPIModel
 */
export interface SlicePayoutSummaryPayoutSortByAPIModel {
    /**
     * 
     * @type {Array<PayoutSummaryAPIModel>}
     * @memberof SlicePayoutSummaryPayoutSortByAPIModel
     */
    list?: Array<PayoutSummaryAPIModel>;
    /**
     * 
     * @type {PagePayoutSortByAPIModel}
     * @memberof SlicePayoutSummaryPayoutSortByAPIModel
     */
    page: PagePayoutSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SlicePayoutSummaryPayoutSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SlicePayoutSummaryPayoutSortByAPIModel interface.
 */
export function instanceOfSlicePayoutSummaryPayoutSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SlicePayoutSummaryPayoutSortByAPIModelFromJSON(json: any): SlicePayoutSummaryPayoutSortByAPIModel {
    return SlicePayoutSummaryPayoutSortByAPIModelFromJSONTyped(json, false);
}

export function SlicePayoutSummaryPayoutSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SlicePayoutSummaryPayoutSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(PayoutSummaryAPIModelFromJSON)),
        'page': PagePayoutSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SlicePayoutSummaryPayoutSortByAPIModelToJSON(value?: SlicePayoutSummaryPayoutSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(PayoutSummaryAPIModelToJSON)),
        'page': PagePayoutSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

