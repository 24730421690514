/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SubscriptionPlanStatusAPIModel } from './SubscriptionPlanStatusAPIModel';
import {
    SubscriptionPlanStatusAPIModelFromJSON,
    SubscriptionPlanStatusAPIModelFromJSONTyped,
    SubscriptionPlanStatusAPIModelToJSON,
} from './SubscriptionPlanStatusAPIModel';

/**
 * 
 * @export
 * @interface UpdateSubscriptionPlanStatusAPIModel
 */
export interface UpdateSubscriptionPlanStatusAPIModel {
    /**
     * 
     * @type {SubscriptionPlanStatusAPIModel}
     * @memberof UpdateSubscriptionPlanStatusAPIModel
     */
    newStatus: SubscriptionPlanStatusAPIModel;
}

/**
 * Check if a given object implements the UpdateSubscriptionPlanStatusAPIModel interface.
 */
export function instanceOfUpdateSubscriptionPlanStatusAPIModel(value: object): boolean {
    if (!('newStatus' in value)) return false;
    return true;
}

export function UpdateSubscriptionPlanStatusAPIModelFromJSON(json: any): UpdateSubscriptionPlanStatusAPIModel {
    return UpdateSubscriptionPlanStatusAPIModelFromJSONTyped(json, false);
}

export function UpdateSubscriptionPlanStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSubscriptionPlanStatusAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'newStatus': SubscriptionPlanStatusAPIModelFromJSON(json['newStatus']),
    };
}

export function UpdateSubscriptionPlanStatusAPIModelToJSON(value?: UpdateSubscriptionPlanStatusAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newStatus': SubscriptionPlanStatusAPIModelToJSON(value['newStatus']),
    };
}

