/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum BlockchainTypeAPIModel {
    TronMainNet = 'TronMainNet',
    TronNile = 'TronNile',
    TronShasta = 'TronShasta',
    BinanceMainNet = 'BinanceMainNet',
    BinanceTestNet = 'BinanceTestNet',
    EthereumMainNet = 'EthereumMainNet',
    EthereumGoerli = 'EthereumGoerli',
    EthereumRopsten = 'EthereumRopsten',
    EthereumSepolia = 'EthereumSepolia',
    PolygonMainNet = 'PolygonMainNet',
    PolygonMumbaiNet = 'PolygonMumbaiNet',
    PolygonAmoy = 'PolygonAmoy',
    ArbitrumMainNet = 'ArbitrumMainNet',
    ArbitrumTestNet = 'ArbitrumTestNet',
    ArbitrumSepolia = 'ArbitrumSepolia',
    BitcoinMainNet = 'BitcoinMainNet',
    BitcoinTestNet = 'BitcoinTestNet'
}


export function BlockchainTypeAPIModelFromJSON(json: any): BlockchainTypeAPIModel {
    return BlockchainTypeAPIModelFromJSONTyped(json, false);
}

export function BlockchainTypeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockchainTypeAPIModel {
    return json as BlockchainTypeAPIModel;
}

export function BlockchainTypeAPIModelToJSON(value?: BlockchainTypeAPIModel | null): any {
    return value as any;
}

