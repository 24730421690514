import type { MerchantReportAPIModel, MerchantReportPredicatesAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { ReportSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { ListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'reports';

export const generatingReportsBackgroundTaskType = 'reports.generating';

export interface Report extends Omit<MerchantReportAPIModel, 'parameters'> {
  parameters: Record<string, unknown>;
}

export type ReportFilterPredicate = MerchantReportPredicatesAPIModel;

export const defaultReportSortBy = { [ReportSortByAPIModel.createdAt]: 'DESC' as const };

export interface ReportsState {
  list: ListState<string, ReportFilterPredicate, ReportSortByAPIModel>;
  entities: SingleState<Report>; // key - reportHid

  generating: {
    refreshableAfter: Date;
    initialized: boolean;
  };
}
