import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import { collectPendingTaskLink } from '@/pages/collectable/routes';

import type { CollectPendingTasksLinkProps } from './types';

const CollectPendingTasksLink: React.FC<CollectPendingTasksLinkProps> = ({ title, ...props }) => (
  <Link
    to={collectPendingTaskLink()}
    title={title || <FormattedMessage id={I18nPageCollectable.PENDING_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const CollectPendingTasksLinkMemo = React.memo(CollectPendingTasksLink);

export default CollectPendingTasksLinkMemo;
