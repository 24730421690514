import { useSettlementIntentTransaction } from '@/features/settlements/hooks';
import type { SettlementIntentTransaction } from '@/features/settlements/types';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

const withSettlementIntentTransactionLoad = <T extends { transaction: SettlementIntentTransaction }>(
  Component: React.ComponentType<Omit<T, 'transaction'> & { transaction: SettlementIntentTransaction }>,
  EmptyComponent: React.ComponentType<Omit<T, 'transaction'>>,
) =>
  namedHOC<
    Omit<T, 'transaction'> & { transaction: SettlementIntentTransaction },
    Omit<T, 'transaction'> & { transactionId: string }
  >(
    Component,
    'WithSettlementIntentTransactionLoad',
  )((props) => {
    const { transactionId } = props;
    const transaction = useSettlementIntentTransaction(transactionId);

    return transaction.data.data ? (
      <Component {...props} transaction={transaction.data.data} />
    ) : (
      <EmptyComponent {...props} />
    );
  });

const withSettlementIntentTransaction =
  <T extends { transaction: SettlementIntentTransaction }>(
    EmptyComponent: React.ComponentType<Omit<T, 'transaction'>> = emptyComponent,
  ) =>
  (Component: React.ComponentType<Omit<T, 'transaction'> & { transaction: SettlementIntentTransaction }>) => {
    const LoadComponent = withSettlementIntentTransactionLoad<T>(Component, EmptyComponent);
    return namedHOC<
      Omit<T, 'transaction'> & { transaction: SettlementIntentTransaction },
      T | (Omit<T, 'transaction'> & { transactionId: string })
    >(
      Component,
      'WithSettlementIntentTransaction',
    )((props) => ('transaction' in props ? <Component {...props} /> : <LoadComponent {...props} />));
  };

export default withSettlementIntentTransaction;
