/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { SettlementIntentStatusAPIModel } from './SettlementIntentStatusAPIModel';
import {
    SettlementIntentStatusAPIModelFromJSON,
    SettlementIntentStatusAPIModelFromJSONTyped,
    SettlementIntentStatusAPIModelToJSON,
} from './SettlementIntentStatusAPIModel';

/**
 * 
 * @export
 * @interface SettlementIntentAPIModel
 */
export interface SettlementIntentAPIModel {
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentAPIModel
     */
    id: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof SettlementIntentAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentAPIModel
     */
    asset: string;
    /**
     * 
     * @type {SettlementIntentStatusAPIModel}
     * @memberof SettlementIntentAPIModel
     */
    status: SettlementIntentStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof SettlementIntentAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SettlementIntentAPIModel
     */
    processAt: Date;
    /**
     * 
     * @type {number}
     * @memberof SettlementIntentAPIModel
     */
    transactionsCount: number;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentAPIModel
     */
    settled?: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentAPIModel
     */
    failureReason?: string;
}

/**
 * Check if a given object implements the SettlementIntentAPIModel interface.
 */
export function instanceOfSettlementIntentAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('processAt' in value)) return false;
    if (!('transactionsCount' in value)) return false;
    return true;
}

export function SettlementIntentAPIModelFromJSON(json: any): SettlementIntentAPIModel {
    return SettlementIntentAPIModelFromJSONTyped(json, false);
}

export function SettlementIntentAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementIntentAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'asset': json['asset'],
        'status': SettlementIntentStatusAPIModelFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'processAt': (new Date(json['processAt'])),
        'transactionsCount': json['transactionsCount'],
        'settled': json['settled'] == null ? undefined : json['settled'],
        'failureReason': json['failureReason'] == null ? undefined : json['failureReason'],
    };
}

export function SettlementIntentAPIModelToJSON(value?: SettlementIntentAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'asset': value['asset'],
        'status': SettlementIntentStatusAPIModelToJSON(value['status']),
        'createdAt': ((value['createdAt']).toISOString()),
        'processAt': ((value['processAt']).toISOString()),
        'transactionsCount': value['transactionsCount'],
        'settled': value['settled'],
        'failureReason': value['failureReason'],
    };
}

