import BigNumber from 'bignumber.js';
import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AssetAmount, AssetLabel } from '@/features/dictionary/blockchain/components';
import type { SettlementIntent } from '@/features/settlements/types';
import { SettlementIntentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import SettlementIntentStatusView from '../SettlementIntentStatusView';
import SettlementIntentView from '../SettlementIntentView';

import type { BaseSettlementIntentFilter, SettlementIntentsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<SettlementIntent>('createdAt')]: SettlementIntentSortByAPIModel.CreatedAt,
  [nameof<SettlementIntent>('processAt')]: SettlementIntentSortByAPIModel.ProcessAt,
};
const extractKey = ({ id }: SettlementIntent) => id;
const sortColumnToModel = (s: string) => sortMapping[s];

const emptyAmount = BigNumber(0);

const SettlementIntentsTable = <FilterType extends BaseSettlementIntentFilter = BaseSettlementIntentFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  IntentLink,
  Operations,
}: SettlementIntentsTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<SettlementIntent>[] = useMemo(
    () => [
      {
        key: nameof<SettlementIntent>('processAt'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_PROCESS_AT} />,
        dataIndex: nameof<SettlementIntent>('processAt'),
        sortOrder: sortOrderToTable(sortBy.ProcessAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, processAt }) => (
          <ReadonlyDateTime value={processAt} data-test={dataTest && `${dataTest}-${id}-processAt`} />
        ),
      },
      {
        key: nameof<SettlementIntent>('asset'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_ASSET} />,
        dataIndex: nameof<SettlementIntent>('asset'),
        render: (_, { id, asset }) => (
          <AssetLabel data-test={dataTest && `${dataTest}-${id}-asset`} value={asset} withBlockchainMark mode="short" />
        ),
      },
      {
        key: nameof<SettlementIntent>('settled'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_SETTLED_AMOUNT} />,
        dataIndex: nameof<SettlementIntent>('settled'),
        render: (_, { id, asset, settled }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                data-test={dataTest && `${dataTest}-${id}-settled`}
                assetId={asset}
                value={settled?.value ?? emptyAmount}
                withBlockchainMark
                withCurrency
              />
            }
            copyable={false}
          />
        ),
      },
      {
        key: nameof<SettlementIntent>('transactionsCount'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTIONS_COUNT} />,
        dataIndex: nameof<SettlementIntent>('transactionsCount'),
        render: (_, { id, transactionsCount }) => (
          <ReadonlyComponent
            data-test={dataTest && `${dataTest}-${id}-transactions`}
            value={transactionsCount}
            copyable={false}
          />
        ),
      },
      {
        key: nameof<SettlementIntent>('status'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_STATUS} />,
        dataIndex: nameof<SettlementIntent>('status'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { id, status }) => (
          <SettlementIntentStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        width: 60,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {IntentLink && (
              <IntentLink value={value.id} data-test={dataTest && `${dataTest}-${value.id}-link`} mode="inline" />
            )}
            {Operations && <Operations value={value} data-test={dataTest && `${dataTest}-${value.id}-op`} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [IntentLink, Operations, dataTest, sortBy.ProcessAt],
  );

  const preview = useCallback(
    (value: SettlementIntent) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureSettlements.COMPONENTS_INTENT_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            IntentLink && (
              <IntentLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <SettlementIntentView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [IntentLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'processAtRange', 'processAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey={nameof<FilterType, 'processAtRange'>('processAtRange')}
              relativeKey={nameof<FilterType, 'processAtRangeRelative'>('processAtRangeRelative')}
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      ExportOperation,
      FilterForm,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<SettlementIntent, FilterType, SettlementIntentSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const SettlementIntentsTableMemo = React.memo(SettlementIntentsTable);

export default SettlementIntentsTableMemo;
