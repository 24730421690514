/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum MerchantWalletStatusAPIModel {
    Defined = 'Defined',
    Deploying = 'Deploying',
    Deployed = 'Deployed',
    DeployFailed = 'DeployFailed',
    Invalid = 'Invalid'
}


export function MerchantWalletStatusAPIModelFromJSON(json: any): MerchantWalletStatusAPIModel {
    return MerchantWalletStatusAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletStatusAPIModel {
    return json as MerchantWalletStatusAPIModel;
}

export function MerchantWalletStatusAPIModelToJSON(value?: MerchantWalletStatusAPIModel | null): any {
    return value as any;
}

