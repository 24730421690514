import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { QRDelegateContractDeployingBackgroundTaskTitleProps } from './types';

const QRDelegateContractDeployingBackgroundTaskTitle: React.FC<QRDelegateContractDeployingBackgroundTaskTitleProps> = ({
  payload,
}) => (
  <FormattedMessage
    id={I18nFeatureCompanySettings.MESSAGES_QR_DEPLOYING_TASK_MESSAGE}
    values={{ count: payload && typeof payload === 'number' ? payload : 0 }}
  />
);

const QRDelegateContractDeployingBackgroundTaskTitleMemo = React.memo(QRDelegateContractDeployingBackgroundTaskTitle);

export default QRDelegateContractDeployingBackgroundTaskTitleMemo;
