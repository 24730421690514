import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditWalletsDeployingLink } from '@/pages/audit/routes';

import type { DeployingMerchantWalletsLinkProps } from './types';

const DeployingMerchantWalletsLink: React.FC<DeployingMerchantWalletsLinkProps> = ({ title, ...props }) => (
  <Link
    to={auditWalletsDeployingLink()}
    title={title || <FormattedMessage id={I18nPageAudit.WALLETS_DEPLOYING_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const DeployingMerchantWalletsLinkMemo = React.memo(DeployingMerchantWalletsLink);

export default DeployingMerchantWalletsLinkMemo;
