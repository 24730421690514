/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionStatusAPIModel {
    Draft = 'Draft',
    Trial = 'Trial',
    Pending = 'Pending',
    Active = 'Active',
    Suspended = 'Suspended',
    PendingPause = 'PendingPause',
    Paused = 'Paused',
    PendingUnPause = 'PendingUnPause',
    Finished = 'Finished',
    PendingCancel = 'PendingCancel',
    Cancelled = 'Cancelled',
    Error = 'Error'
}


export function SubscriptionStatusAPIModelFromJSON(json: any): SubscriptionStatusAPIModel {
    return SubscriptionStatusAPIModelFromJSONTyped(json, false);
}

export function SubscriptionStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStatusAPIModel {
    return json as SubscriptionStatusAPIModel;
}

export function SubscriptionStatusAPIModelToJSON(value?: SubscriptionStatusAPIModel | null): any {
    return value as any;
}

