import React from 'react';

import { GasWalletTransactionView } from '@/features/gas-wallets/components';

import { withDonationAssetDeployTransactionDataLoading, withDonationAssetDeployTransactionAppearance } from './hocs';

const DonationAddressDeployTransactionCard = withDonationAssetDeployTransactionAppearance(
  withDonationAssetDeployTransactionDataLoading((props) => <GasWalletTransactionView {...props} columns={1} />),
);

const DonationAddressDeployTransactionCardMemo = React.memo(
  DonationAddressDeployTransactionCard,
) as typeof DonationAddressDeployTransactionCard;

export default DonationAddressDeployTransactionCardMemo;
