import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetworkBlockchains } from '@/features/dictionary/blockchain/selectors';
import { ForwarderDelegateStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type LoadingStateWithDirty, mapStoredState } from '@/infrastructure/model';

import { NAMESPACE } from './types';

import type { QRDelegateContract } from './types';

export const makeSelectCompanySettings = () => (state: AppRootState) => state[NAMESPACE].settings;
export const makeSelectWebhooks = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].settings,
    (state) => mapStoredState(state, ({ webhooks }) => webhooks),
  );

export const makeSelectStatisticsAsset = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].settings,
    (state) => mapStoredState(state, ({ statisticsAsset }) => statisticsAsset),
  );

export const makeSelectQRDelegateContracts = () => (state: AppRootState) => state[NAMESPACE].qr.delegates;

export const makeSelectDeployingQRDelegateContractsRefreshableAfter = () => (state: AppRootState) =>
  state[NAMESPACE].qr.refreshableAfter;
export const makeSelectDeployingQRDelegateContracts = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].qr.delegates,
    makeSelectSelectedNetworkBlockchains(),
    makeSelectDeployingQRDelegateContractsRefreshableAfter(),
    (contracts, blockchains, refreshableAfter): LoadingStateWithDirty<QRDelegateContract[]> => ({
      ...mapStoredState(contracts, (data) =>
        data
          .filter((contract) => contract.status === ForwarderDelegateStatusAPIModel.Pending)
          .filter((contract) => blockchains.includes(contract.blockchain)),
      ),
      isDirty: dayjs(refreshableAfter).isBefore(),
    }),
  );

export const makeSelectAPIKeys = () => (state: AppRootState) => state[NAMESPACE].keys;
