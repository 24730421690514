import React, { useEffect } from 'react';

import { usePrevious } from '@/hooks';

import type { BackgroundTaskPayloadHookProps } from './types';

const BackgroundTaskPayloadHook: React.FC<BackgroundTaskPayloadHookProps> = ({ onChange, usePayload }) => {
  const data = usePayload();
  const previous = usePrevious(data);
  useEffect(() => {
    if (data !== previous) {
      onChange(data);
    }
  }, [data, onChange, previous]);
  return null;
};

const BackgroundTaskPayloadHookMemo = React.memo(BackgroundTaskPayloadHook);

export default BackgroundTaskPayloadHookMemo;
