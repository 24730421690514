import { ExceptionOutlined, FileDoneOutlined, FileUnknownOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge } from 'antd';


import { StatusViewElement } from '@/components';
import { MerchantWalletRunningBalanceConfirmationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWalletBalance } from '@/generated/i18n/i18n';

import type { MerchantWalletRunningBalanceConfirmationStatusViewProps } from './types';
import type React from 'react';

const icons: Record<MerchantWalletRunningBalanceConfirmationStatusAPIModel, React.ReactNode> = {
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Unchecked]: <FileUnknownOutlined style={{ color: 'blue' }} />,
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Retrying]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <ExceptionOutlined style={{ color: 'orange' }} />
    </Badge>
  ),
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Failed]: <ExceptionOutlined style={{ color: 'red' }} />,
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Confirmed]: <FileDoneOutlined style={{ color: 'green' }} />,
};

const i18n: Record<MerchantWalletRunningBalanceConfirmationStatusAPIModel, I18nFeatureMerchantWalletBalance> = {
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Unchecked]:
    I18nFeatureMerchantWalletBalance.COMPONENTS_CONFIRMATION_STATUS_VIEW_UNCHECKED,
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Retrying]:
    I18nFeatureMerchantWalletBalance.COMPONENTS_CONFIRMATION_STATUS_VIEW_RETRYING,
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Failed]:
    I18nFeatureMerchantWalletBalance.COMPONENTS_CONFIRMATION_STATUS_VIEW_FAILED,
  [MerchantWalletRunningBalanceConfirmationStatusAPIModel.Confirmed]:
    I18nFeatureMerchantWalletBalance.COMPONENTS_CONFIRMATION_STATUS_VIEW_CONFIRMED,
};

const MerchantWalletRunningBalanceConfirmationStatusView: React.FC<
  MerchantWalletRunningBalanceConfirmationStatusViewProps
> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

export default MerchantWalletRunningBalanceConfirmationStatusView;
