import React from 'react';

import { GasWalletTransactionView } from '@/features/gas-wallets/components';

import { withCollectTaskTransactionDataLoading } from './hocs';

const CollectTaskTransactionCard = withCollectTaskTransactionDataLoading((props) => (
  <GasWalletTransactionView {...props} columns={1} />
));
const CollectTaskTransactionCardMemo = React.memo(CollectTaskTransactionCard) as typeof CollectTaskTransactionCard;

export default CollectTaskTransactionCardMemo;
