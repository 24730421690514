import { FormattedMessage } from '@/components';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import { OperationRefreshRechargeAddressDeployTransaction } from '@/features/recharges/components';
import { useRechargeAddressDeployTransaction } from '@/features/recharges/hooks';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withRechargeAddressDeployTransactionDataLoading = <
  Original extends { data: GasWalletTransaction } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { addressId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithRechargeAddressDeployTransactionDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useRechargeAddressDeployTransaction(props.addressId);
    return withCardDataLoading<GasWalletTransaction, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageRecharges.ADDRESS_VIEW_COMPONENTS_DEPLOY_TRANSACTION_CARD_TITLE}
          values={{ id: props.addressId }}
        />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <OperationRefreshRechargeAddressDeployTransaction
            data-test={dataTest && `${dataTest}-opRefresh`}
            addressId={props.addressId}
          />
        ),
      },
    })(Component)(props);
  });

export default withRechargeAddressDeployTransactionDataLoading;
