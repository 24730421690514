import React from 'react';

import { OperationRefresh } from '@/components';
import { useDeployingMerchantWallets } from '@/features/merchant-wallets/hooks';

import type { OperationRefreshDeployingMerchantWalletProps } from './types';

const OperationRefreshDeployingMerchantWallet: React.FC<OperationRefreshDeployingMerchantWalletProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useDeployingMerchantWallets();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshDeployingMerchantWalletMemo = React.memo(OperationRefreshDeployingMerchantWallet);

export default OperationRefreshDeployingMerchantWalletMemo;
