import { Space, Spin } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { OperationRefreshPendingIntents, SettlementIntentsList } from '@/features/settlements/components';
import { usePendingIntents } from '@/features/settlements/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';

import type { SettlementPendingIntentsCardProps } from './types';

const SettlementPendingIntentsCard: React.FC<SettlementPendingIntentsCardProps> = (props) => {
  const { data, loading } = usePendingIntents();
  const intents = useMemo(() => data.data ?? [], [data.data]);

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageSettlements.PENDING_COMPONENTS_INTENTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
        </Space>
      }
      extra={<OperationRefreshPendingIntents mode="inline" data-test={dataTest && `${dataTest}-opRefresh`} />}
      cardSize="big"
    >
      <Spin spinning={loading}>
        <SettlementIntentsList {...props} data={intents} />
      </Spin>
    </PageCard>
  );
};

const SettlementPendingIntentsCardMemo = React.memo(SettlementPendingIntentsCard);

export default SettlementPendingIntentsCardMemo;
