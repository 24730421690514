import { List, Space } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage, ReadonlyDate } from '@/components';
import { AssetIcon, AssetLabel } from '@/features/dictionary/blockchain/components';
import { MerchantWalletIssueBanner } from '@/features/merchant-wallets/components';
import type { SettlementIntent } from '@/features/settlements/types';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import SettlementIntentStatusBanner from '../SettlementIntentStatusBanner';

import type { SettlementIntentsListProps } from './types';
import type { ListProps } from 'antd/es/list';

const SettlementIntentsList: React.FC<SettlementIntentsListProps> = ({
  'data-test': dataTest,
  data,
  SettlementIntentLink,
  Operations,
}) => (
  <List<SettlementIntent>
    itemLayout="horizontal"
    loadMore={false}
    dataSource={data}
    renderItem={useCallback<NonNullable<ListProps<SettlementIntent>['renderItem']>>(
      (item) => (
        <List.Item
          key={item.id}
          actions={[
            ...(SettlementIntentLink
              ? [
                  <SettlementIntentLink
                    key={`${item.id}-link`}
                    value={item.id}
                    data-test={dataTest && `${dataTest}-${item.id}-link`}
                  />,
                ]
              : []),
            ...(Operations
              ? [<Operations key={`${item.id}-op`} value={item} data-test={dataTest && `${dataTest}-${item.id}-op`} />]
              : []),
          ]}
        >
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={
              <AssetIcon
                data-test={dataTest && `${dataTest}-${item.asset}-icon`}
                type={item.asset}
                style={{ fontSize: 32 }}
              />
            }
            title={
              <Space>
                <AssetLabel data-test={dataTest && `${dataTest}-${item.asset}-label`} value={item.asset} mode="short" />
                <SettlementIntentStatusBanner
                  data-test={dataTest && `${dataTest}-${item.asset}-intentStatus`}
                  intent={item}
                  mode="tag"
                />
                <MerchantWalletIssueBanner
                  data-test={dataTest && `${dataTest}-${item.asset}-walletStatus`}
                  assetId={item.asset}
                  mode="tag"
                  type="link"
                />
              </Space>
            }
            description={
              <FormattedMessage
                id={I18nFeatureSettlements.COMPONENTS_INTENT_LIST_DESCRIPTION}
                tagName="span"
                values={{
                  datetime: (
                    <ReadonlyDate
                      data-test={dataTest && `${dataTest}-${item.asset}-processAt`}
                      value={item.processAt}
                      plain
                    />
                  ),
                }}
              />
            }
          />
        </List.Item>
      ),
      [SettlementIntentLink, Operations, dataTest],
    )}
  />
);

const SettlementIntentsListMemo = React.memo(SettlementIntentsList);

export default SettlementIntentsListMemo;
