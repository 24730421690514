import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectTaskProcessTransaction } from '@/features/collectable/actions';
import { makeSelectCollectEntityProcessTransaction } from '@/features/collectable/selectors';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseCollectTaskProcessTransaction = UseAppSingleData<GasWalletTransaction>;

const fetchFactory = (
  txId: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<GasWalletTransaction>,
  Parameters<typeof fetchCollectTaskProcessTransaction>[0]
> => fetchCollectTaskProcessTransaction({ taskId: txId, force });

const useCollectTaskProcessTransaction = (taskId: string | undefined): UseCollectTaskProcessTransaction => {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchCollectTaskProcessTransaction, taskId), [taskId]);
  return useAppSingleData(
    fetchFactory,
    makeSelectCollectEntityProcessTransaction,
    noSingleDataIdFn(),
    taskId,
    dataFetchingSelector,
  );
};

export default useCollectTaskProcessTransaction;
