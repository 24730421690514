import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementIntentTransactionViewLink } from '@/pages/settlements/routes';

import type { SettlementIntentTransactionLinkProps } from './types';

const SettlementIntentTransactionLink: React.FC<SettlementIntentTransactionLinkProps> = ({
  value,
  title,
  ...props
}) => (
  <Link
    to={settlementIntentTransactionViewLink(value)}
    title={title ?? <FormattedMessage id={I18nPageSettlements.INTENT_TRANSACTION_VIEW_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SettlementIntentTransactionLinkMemo = React.memo(SettlementIntentTransactionLink);

export default SettlementIntentTransactionLinkMemo;
