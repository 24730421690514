import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlementReconciliation } from '@/features/settlement-reconciliations/actions';
import { makeSelectSettlementReconciliation } from '@/features/settlement-reconciliations/selectors';
import type { SettlementReconciliation } from '@/features/settlement-reconciliations/types';
import { storedDataError } from '@/infrastructure/model';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export type UseSettlementReconciliation = UseAppSingleData<SettlementReconciliation>;

const noData = storedDataError<SettlementReconciliation>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<SettlementReconciliation>,
  Parameters<typeof fetchSettlementReconciliation>[0]
> => fetchSettlementReconciliation({ id, force });

export default function useSettlementReconciliation(id: string | undefined): UseSettlementReconciliation {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSettlementReconciliation, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectSettlementReconciliation, noData, id, dataFetchingSelector);
}
