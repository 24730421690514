import { useCallback } from 'react';

import { useAppDispatch, useAppLoadableData, useAppSelector } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchGeneratingReports } from '@/features/reports/actions';
import { makeSelectGeneratingReports, makeSelectGeneratingReportsRefreshableAfter } from '@/features/reports/selectors';
import type { Report } from '@/features/reports/types';
import { useRunOnTimer } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Report[]>, Parameters<typeof fetchGeneratingReports>[0]> =>
  fetchGeneratingReports({ force });

const selectFetching = makeSelectPending(fetchGeneratingReports);
const selectData = makeSelectGeneratingReports();
const selectNextDataUpdateAt = makeSelectGeneratingReportsRefreshableAfter();

export default function useGeneratingReports() {
  const { withExtractDataDispatch } = useAppDispatch();
  const nextUpdateAt = useAppSelector(selectNextDataUpdateAt);
  const data = useAppLoadableData(fetchFactory, selectData, selectFetching);
  const refresh = useCallback(() => {
    if (data.data.data?.length) {
      withSuppressPromise(withExtractDataDispatch(fetchGeneratingReports))({});
    }
  }, [data.data.data, withExtractDataDispatch]);
  useRunOnTimer(refresh, nextUpdateAt, false);
  return data;
}
