/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PaymentTransactionBlockchainIdAPIModel } from './PaymentTransactionBlockchainIdAPIModel';
import {
    PaymentTransactionBlockchainIdAPIModelFromJSON,
    PaymentTransactionBlockchainIdAPIModelFromJSONTyped,
    PaymentTransactionBlockchainIdAPIModelToJSON,
} from './PaymentTransactionBlockchainIdAPIModel';
import type { PaymentTransactionSearchIdAPIModel } from './PaymentTransactionSearchIdAPIModel';
import {
    PaymentTransactionSearchIdAPIModelFromJSON,
    PaymentTransactionSearchIdAPIModelFromJSONTyped,
    PaymentTransactionSearchIdAPIModelToJSON,
} from './PaymentTransactionSearchIdAPIModel';

/**
 * 
 * @export
 * @interface PaymentTransactionPredicatesAPIModel
 */
export interface PaymentTransactionPredicatesAPIModel {
    /**
     * 
     * @type {Array<PaymentTransactionSearchIdAPIModel>}
     * @memberof PaymentTransactionPredicatesAPIModel
     */
    idIn?: Array<PaymentTransactionSearchIdAPIModel>;
    /**
     * 
     * @type {Array<PaymentTransactionBlockchainIdAPIModel>}
     * @memberof PaymentTransactionPredicatesAPIModel
     */
    transactionIdIn?: Array<PaymentTransactionBlockchainIdAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionPredicatesAPIModel
     */
    paymentId?: string;
}

/**
 * Check if a given object implements the PaymentTransactionPredicatesAPIModel interface.
 */
export function instanceOfPaymentTransactionPredicatesAPIModel(value: object): boolean {
    return true;
}

export function PaymentTransactionPredicatesAPIModelFromJSON(json: any): PaymentTransactionPredicatesAPIModel {
    return PaymentTransactionPredicatesAPIModelFromJSONTyped(json, false);
}

export function PaymentTransactionPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : ((json['idIn'] as Array<any>).map(PaymentTransactionSearchIdAPIModelFromJSON)),
        'transactionIdIn': json['transactionIdIn'] == null ? undefined : ((json['transactionIdIn'] as Array<any>).map(PaymentTransactionBlockchainIdAPIModelFromJSON)),
        'paymentId': json['paymentId'] == null ? undefined : json['paymentId'],
    };
}

export function PaymentTransactionPredicatesAPIModelToJSON(value?: PaymentTransactionPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'] == null ? undefined : ((value['idIn'] as Array<any>).map(PaymentTransactionSearchIdAPIModelToJSON)),
        'transactionIdIn': value['transactionIdIn'] == null ? undefined : ((value['transactionIdIn'] as Array<any>).map(PaymentTransactionBlockchainIdAPIModelToJSON)),
        'paymentId': value['paymentId'],
    };
}

