/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SettlementIntentTransactionStatusAPIModel } from './SettlementIntentTransactionStatusAPIModel';
import {
    SettlementIntentTransactionStatusAPIModelFromJSON,
    SettlementIntentTransactionStatusAPIModelFromJSONTyped,
    SettlementIntentTransactionStatusAPIModelToJSON,
} from './SettlementIntentTransactionStatusAPIModel';

/**
 * 
 * @export
 * @interface SettlementIntentTransactionAPIModel
 */
export interface SettlementIntentTransactionAPIModel {
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionAPIModel
     */
    intentId: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionAPIModel
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionAPIModel
     */
    wallet: string;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionAPIModel
     */
    destination: string;
    /**
     * 
     * @type {SettlementIntentTransactionStatusAPIModel}
     * @memberof SettlementIntentTransactionAPIModel
     */
    status: SettlementIntentTransactionStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof SettlementIntentTransactionAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionAPIModel
     */
    settlementId?: string;
    /**
     * 
     * @type {number}
     * @memberof SettlementIntentTransactionAPIModel
     */
    attempts: number;
    /**
     * 
     * @type {Date}
     * @memberof SettlementIntentTransactionAPIModel
     */
    nextAttemptAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SettlementIntentTransactionAPIModel
     */
    failureReason?: string;
}

/**
 * Check if a given object implements the SettlementIntentTransactionAPIModel interface.
 */
export function instanceOfSettlementIntentTransactionAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('intentId' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('wallet' in value)) return false;
    if (!('destination' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('attempts' in value)) return false;
    return true;
}

export function SettlementIntentTransactionAPIModelFromJSON(json: any): SettlementIntentTransactionAPIModel {
    return SettlementIntentTransactionAPIModelFromJSONTyped(json, false);
}

export function SettlementIntentTransactionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SettlementIntentTransactionAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'intentId': json['intentId'],
        'asset': json['asset'],
        'wallet': json['wallet'],
        'destination': json['destination'],
        'status': SettlementIntentTransactionStatusAPIModelFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'settlementId': json['settlementId'] == null ? undefined : json['settlementId'],
        'attempts': json['attempts'],
        'nextAttemptAt': json['nextAttemptAt'] == null ? undefined : (new Date(json['nextAttemptAt'])),
        'failureReason': json['failureReason'] == null ? undefined : json['failureReason'],
    };
}

export function SettlementIntentTransactionAPIModelToJSON(value?: SettlementIntentTransactionAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'intentId': value['intentId'],
        'asset': value['asset'],
        'wallet': value['wallet'],
        'destination': value['destination'],
        'status': SettlementIntentTransactionStatusAPIModelToJSON(value['status']),
        'createdAt': ((value['createdAt']).toISOString()),
        'settlementId': value['settlementId'],
        'attempts': value['attempts'],
        'nextAttemptAt': value['nextAttemptAt'] == null ? undefined : ((value['nextAttemptAt']).toISOString()),
        'failureReason': value['failureReason'],
    };
}

