import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectCollectEntityProcessTransaction } from '@/features/collectable/selectors';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { mapStoredState } from '@/infrastructure/model';
import {
  createNestedNormalizedListSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { notEmpty } from '@/infrastructure/utils/ts';

import { defaultDonationTransactionForDonationListState, NAMESPACE } from './types';
import { donationAssetIdToKey } from './utils';

import type { DonationTransactionListState, DonationAssetId } from './types';

export const { makeSelectDonation, makeSelectDonationListData, makeSelectDonationListParameters } =
  createNormalizedListSelectors(
    (state: AppRootState) => state[NAMESPACE].list,
    (state) => state[NAMESPACE].entities,
    'Donation' as const,
    undefined,
  );

export const makeSelectDonationAsset = ({ donationId, asset }: DonationAssetId) =>
  createSelector(makeSelectDonation(donationId), (donation) =>
    mapStoredState(donation, ({ addresses }) => addresses.find((address) => address.asset === asset)),
  );

export const makeSelectDonationAssetDeployTransaction = (id: DonationAssetId) =>
  makeSelectCollectEntityProcessTransaction(donationAssetIdToKey(id));

export const makeSelectDonationsListParametersWithNetwork: typeof makeSelectDonationListParameters = () =>
  createSelector(
    makeSelectDonationListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, networkEq): ReturnType<ReturnType<typeof makeSelectDonationListParameters>> => ({
      ...parameters,
      filter: { ...filter, networkEq },
    }),
  );

export const {
  makeSelectDonationTransaction,
  makeSelectDonationTransactionListData,
  makeSelectDonationTransactionListParameters,
} = createNormalizedListSelectors(
  {
    listNoColumn: (state: AppRootState) => state[NAMESPACE].transactions.list,
    columnState: (state: AppRootState) => state[NAMESPACE].transactions.columnState,
  },
  (state) => state[NAMESPACE].transactions.entities,
  'DonationTransaction' as const,
  undefined,
);

export const makeSelectDonationTransactionListParametersWithNetwork: typeof makeSelectDonationTransactionListParameters =
  () =>
    createSelector(
      makeSelectDonationTransactionListParameters(),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkEq,
      ): ReturnType<ReturnType<typeof makeSelectDonationTransactionListParameters>> => ({
        ...parameters,
        filter: { ...filter, networkEq },
      }),
    );

export const {
  makeSelectDonationTransactionForDonationListData,
  makeSelectDonationTransactionForDonationListParameters: baseMakeSelectDonationTransactionForDonationListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, donationId: string | undefined): DonationTransactionListState =>
    state[NAMESPACE].transactions.byDonation[donationId!] ?? defaultDonationTransactionForDonationListState,
  (state) => state[NAMESPACE].transactions.columnState,
  (state) => state[NAMESPACE].transactions.entities,
  'DonationTransactionForDonation' as const,
);

export const makeSelectDonationTransactionForDonationListParameters: typeof baseMakeSelectDonationTransactionForDonationListParameters =
  (donationId) =>
    createSelector(
      baseMakeSelectDonationTransactionForDonationListParameters(donationId),
      ({
        filter,
        ...parameters
      }): ReturnType<ReturnType<typeof makeSelectDonationTransactionForDonationListParameters>> => ({
        ...parameters,
        filter: { ...filter, donationIdIn: [donationId!] },
      }),
    );

export const { makeSelectDonationIdByAddress, makeSelectMultipleDonationIdByAddress } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].idByAddress,
  'DonationIdByAddress' as const,
  undefined,
);

export const makeSelectDonationTransactionsForDonations = (donationIds: string[]) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].transactions.entities,
    (transactions) =>
      Object.values(transactions)
        .map((tx) => (tx?.data && donationIds.includes(tx.data.donationId) ? tx.data : undefined))
        .filter(notEmpty),
  );
