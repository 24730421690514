import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import CompanyPermissionRestrictedMessage from '@/features/company/components/CompanyPermissionRestrictedMessage';
import { useMerchantGasWalletActions } from '@/features/gas-wallets/hooks';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { isRejected } from '@/infrastructure/utils/ui';

import type { OperationDeleteGasWalletProps } from './types';

const OperationDeleteGasWallet: React.FC<OperationDeleteGasWalletProps> = ({
  'data-test': dataTest,
  onSuccess = noop,
  bt,
  mode = 'inline',
  confirmation,
}) => {
  const { deleteWallet: deleteAction } = useMerchantGasWalletActions(bt);

  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const doDelete = useMemo(
    () =>
      withSuppressError(
        withOnSuccess(
          withDefaultError(
            withSuccess(deleteAction.act, () => (
              <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_MESSAGE_SUCCESS} />
            )),
            {
              message: () => (
                <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_MESSAGE_ERROR} />
              ),
              description: (e) =>
                e && typeof e === 'object' && 'message' in e && e.message === 'GasWalletIsRefundable' ? (
                  <FormattedMessage
                    id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_MESSAGE_ERROR_DESCRIPTION_NON_EMPTY}
                  />
                ) : null,
              skip: isRejected,
            },
          ),
          onSuccess,
        ),
      ),
    [withDefaultError, withSuccess, deleteAction.act, onSuccess],
  );

  const confirmationProps = useMemo(
    () =>
      confirmation
        ? {
            title: (
              <FormattedMessageWithMarkup
                id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_CONFIRMATION_TITLE}
              />
            ),
          }
        : undefined,
    [confirmation],
  );
  const disabledMessage = useMemo(() => {
    switch (deleteAction.unavailabilityReason) {
      case 'no-data':
        return (
          <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_UNAVAILABILITY_NO_DATA} />
        );
      case 'native-balance-unknown':
        return (
          <FormattedMessage
            id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_UNAVAILABILITY_NATIVE_BALANCE_UNKNOWN}
          />
        );
      case 'non-empty-balance':
        return (
          <FormattedMessage
            id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_UNAVAILABILITY_NON_EMPTY_BALANCE}
          />
        );
      case 'no-permissions':
        return <CompanyPermissionRestrictedMessage permission="gasWalletsManagement" />;
      case 'is-refunding':
        return (
          <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_UNAVAILABILITY_IS_REFUNDING} />
        );
      case undefined:
        return undefined;
    }
  }, [deleteAction.unavailabilityReason]);

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_DELETE_WALLET_TITLE} />}
      confirmation={confirmationProps}
      icon={<DeleteOutlined />}
      onClick={doDelete}
      disabled={!deleteAction.available || !!disabledMessage}
      disabledMessage={disabledMessage}
      inProgress={deleteAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeleteGasWalletMemo: typeof OperationDeleteGasWallet = React.memo(OperationDeleteGasWallet);

export default OperationDeleteGasWalletMemo;
