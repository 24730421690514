import React from 'react';

import type { SettlementViewProps } from '@/features/settlements/components';
import { SettlementView } from '@/features/settlements/components';

import { withSettlementDataLoading } from './hocs';

const SettlementCard = withSettlementDataLoading((props: Omit<SettlementViewProps, 'columns'>) => (
  <SettlementView {...props} columns={1} />
));

const SettlementCardMemo = React.memo(SettlementCard) as typeof SettlementCard;

export default SettlementCardMemo;
