import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectTaskPendingBackgroundTaskTitleProps } from './types';

const CollectTaskPendingBackgroundTaskTitle: React.FC<CollectTaskPendingBackgroundTaskTitleProps> = ({ payload }) => (
  <FormattedMessage
    id={I18nFeatureCollectable.MESSAGES_PENDING_TASK_MESSAGE}
    values={{ count: payload && typeof payload === 'number' ? payload : 0 }}
  />
);

const CollectTaskPendingBackgroundTaskTitleMemo = React.memo(CollectTaskPendingBackgroundTaskTitle);

export default CollectTaskPendingBackgroundTaskTitleMemo;
