import { useAppSelector } from '@/app/hooks';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import type { Asset, AssetWithNetwork } from '@/features/dictionary/blockchain/types';
import { withDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const selectNetworkType = makeSelectSelectedNetwork();

const withAssetsDataLoading =
  (type: 'all' | 'supported' | 'exchangeable' = 'supported', respectNetwork?: boolean, wrapperClassName?: string) =>
  <
    Original extends { data: AssetWithNetwork[] } & TestableProps,
    Wrapper extends Omit<Original, 'data'> = Omit<Original, 'data'>,
  >(
    Component: React.ComponentType<Original>,
  ) =>
    namedHOC<Original, Wrapper>(
      Component,
      'WithAssetsDataLoading',
    )((props) => {
      const dataTest = props['data-test'];
      const network = useAppSelector(selectNetworkType);
      const {
        data: all,
        exchangeableAssets,
        supportedAssets,
        forceRefresh,
        loading,
      } = useListAssets(respectNetwork ? network : undefined);
      const data =
        // eslint-disable-next-line no-nested-ternary
        type === 'supported' ? supportedAssets : type === 'exchangeable' ? exchangeableAssets : all;

      return withDataLoading<Asset[], Original>({
        data,
        loading,
        forceRefresh,
        'data-test': dataTest,
        isNotEmptyData: (v) => !!v?.length,
      })(Component)({ ...props, wrapperClassName });
    });

export default withAssetsDataLoading;
