import React from 'react';

import { OperationRefresh } from '@/components';
import { useSettlementIntentTransaction } from '@/features/settlements/hooks';

import type { OperationRefreshSettlementIntentTransactionProps } from './types';

const OperationRefreshSettlementIntentTransaction: React.FC<OperationRefreshSettlementIntentTransactionProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  transactionId,
}) => {
  const { forceRefresh } = useSettlementIntentTransaction(transactionId);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshSettlementIntentTransactionMemo = React.memo(OperationRefreshSettlementIntentTransaction);

export default OperationRefreshSettlementIntentTransactionMemo;
