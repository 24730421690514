import type { Connector } from 'wagmi';

export enum ConnectorId {
  metaMask = 'metaMask',
  walletConnect = 'walletConnect',
}

const localConnectorInitChecker: Partial<Record<string, () => boolean>> = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  [ConnectorId.metaMask]: () => !!window.ethereum?.isMetaMask,
};

export const localConnectorSupport = (connector: Connector) => {
  const checker = localConnectorInitChecker[connector.id];
  return { isSupported: !!checker, isInitialized: !!checker?.() };
};

const disconnectSupported: Partial<Record<string, boolean>> = {
  [ConnectorId.metaMask]: true,
  [ConnectorId.walletConnect]: true,
};

export const disconnectConnectorSupport = (connector: Connector) => !!disconnectSupported[connector.id];

export const isRejectedByMetaMaskUser = (e?: unknown) =>
  !!e && typeof e === 'object' && 'code' in e && typeof e.code === 'number' && e.code === 4001;

export const isRejectedByMetaWalletConnectUser = (e?: unknown) =>
  !!e && typeof e === 'object' && 'code' in e && typeof e.code === 'number' && e.code === 4001;

export const isRejectedByWeb3WalletUser = (e?: unknown) =>
  isRejectedByMetaMaskUser(e) || isRejectedByMetaWalletConnectUser();
