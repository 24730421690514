import { Descriptions, Space } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDate,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { AddressLink, AssetLabel } from '@/features/dictionary/blockchain/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { SettlementIntentTransactionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import SettlementIntentTransactionStatusView from '../SettlementIntentTransactionStatusView';

import type { SettlementIntentTransactionViewProps } from './types';

const SettlementIntentTransactionView: React.FC<SettlementIntentTransactionViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
  MerchantWalletLink,
  IntentLink,
  SettlementLink,
}) => {
  const { data: asset } = useAsset(data.asset);
  return (
    <Descriptions
      style={style}
      className={className}
      styles={descriptionStyles}
      title={
        <Space>
          <SettlementIntentTransactionStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />
          {SettlementLink && data.settlementId && (
            <>
              (<SettlementLink data-test={dataTest && `${dataTest}-settlement`} value={data.settlementId} />)
            </>
          )}
          {IntentLink && (
            <>
              (<IntentLink data-test={dataTest && `${dataTest}-intent`} value={data.intentId} />)
            </>
          )}
        </Space>
      }
      column={columns}
    >
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_ASSET} />}
        styles={descriptionItemStyles}
      >
        <AssetLabel data-test={dataTest && `${dataTest}-asset`} value={data.asset} mode="medium" />
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_WALLET} />}
        styles={descriptionItemStyles}
      >
        {MerchantWalletLink && asset.data?.blockchain ? (
          <MerchantWalletLink
            data-test={dataTest && `${dataTest}-wallet`}
            value={{ bt: asset.data.blockchain, address: data.wallet }}
            mode="text"
          />
        ) : (
          <AddressLink data-test={dataTest && `${dataTest}-wallet`} assetId={data.asset} address={data.wallet} />
        )}
      </Descriptions.Item>
      <Descriptions.Item
        label={
          <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_DESTINATION} />
        }
        styles={descriptionItemStyles}
      >
        <AddressLink
          data-test={dataTest && `${dataTest}-destination`}
          assetId={data.asset}
          address={data.destination}
        />
      </Descriptions.Item>
      {data.nextAttemptAt && (
        <Descriptions.Item
          label={
            <FormattedMessage
              id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_NEXT_ATTEMPT_AT}
            />
          }
        >
          <ReadonlyDate value={data.nextAttemptAt} data-test={dataTest && `${dataTest}-nextAttemptAt`} />
        </Descriptions.Item>
      )}
      {data.status !== SettlementIntentTransactionStatusAPIModel.Succeeded ? (
        <Descriptions.Item
          label={
            <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_ATTEMPTS} />
          }
        >
          <ReadonlyComponent value={data.attempts} data-test={dataTest && `${dataTest}-attempts`} />
        </Descriptions.Item>
      ) : null}
      {data.failureReason ? (
        <Descriptions.Item
          label={
            <FormattedMessage
              id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_FAILURE_REASON}
            />
          }
        >
          <ReadonlyComponent value={data.failureReason} data-test={dataTest && `${dataTest}-failureReason`} />
        </Descriptions.Item>
      ) : null}
    </Descriptions>
  );
};

const SettlementIntentTransactionViewMemo = React.memo(SettlementIntentTransactionView);

export default SettlementIntentTransactionViewMemo;
