import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeSettlementIntentListParameters } from '@/features/settlements/actions';
import type { SettlementIntent, SettlementIntentFilterPredicate } from '@/features/settlements/types';
import type { SettlementIntentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useSettlementIntentList from '../useSettlementIntentList';

export type UseSettlementIntentListView = UseAppListDataView<
  SettlementIntent,
  SettlementIntentFilterPredicate,
  SettlementIntentSortByAPIModel
>;

export default function useSettlementIntentListView(): UseSettlementIntentListView {
  const state = useSettlementIntentList();
  return useAppListDataView(state, storeSettlementIntentListParameters);
}
