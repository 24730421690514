import { useMemo } from 'react';

import { noIdNestedFullFetchResultFn } from '@/app/hooks/useAppNestedFullDataFiltered';
import useAppSelector from '@/app/hooks/useAppSelector';
import type { AppAsyncThunkAction, AppRootState } from '@/app/store';
import type {
  FullParametersUpdateAction,
  NormalizedFullParametersUpdateAction,
} from '@/infrastructure/model/full/actions';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';
import { noop, withVoidOrThrow } from '@/infrastructure/utils/functions';
import { isThenable } from '@/infrastructure/utils/ts';

import useAppDispatch from './useAppDispatch';

import type { UseAppFullDataFiltered } from './useAppFullDataFiltered';
import type { PayloadAction } from '@reduxjs/toolkit';

export default function useAppNestedFullDataFilteredView<
  Value,
  Filter,
  SortBy extends string,
  FetchResult = LoadingFullDataState<Value>,
>(
  state: UseAppFullDataFiltered<Value, Filter, SortBy, FetchResult>,
  fullDataSelectorCreator: (parentId: string) => (state: AppRootState) => LoadingFullDataState<Value>,
  updateParametersFactory:
    | ((
        parameters: NormalizedFullParametersUpdateAction<string, Filter, SortBy>['payload'],
      ) => AppAsyncThunkAction<unknown, unknown>)
    | ((
        payload: NormalizedFullParametersUpdateAction<string, Filter, SortBy>['payload'],
      ) => PayloadAction<NormalizedFullParametersUpdateAction<string, Filter, SortBy>['payload']>)
    | ((parameters: NormalizedFullParametersUpdateAction<string, Filter, SortBy>['payload']) => Promise<unknown>),
  parentId: string | undefined,
) {
  const { dispatch } = useAppDispatch();
  const updateParameters = useMemo(
    () =>
      parentId
        ? withVoidOrThrow(async (parameters: FullParametersUpdateAction<Filter, SortBy>['payload']) => {
            const call = updateParametersFactory({ parentId, parameters });
            if (isThenable(call)) {
              return call;
            }
            const dispatched = dispatch(call);
            return isThenable(dispatched) ? dispatched.unwrap() : dispatched;
          })
        : noop,
    [dispatch, parentId, updateParametersFactory],
  );
  const fullDataSelector = useMemo(
    () => (parentId ? fullDataSelectorCreator(parentId) : () => noIdNestedFullFetchResultFn<Value>()),
    [fullDataSelectorCreator, parentId],
  );
  const fullData = useAppSelector(fullDataSelector);

  return { ...state, fullData, updateParameters };
}
