import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import { makeSelectPending } from '@/features/global/selectors';
import {
  fetchSettlementIntentTransactionDetails,
} from '@/features/settlements/actions';
import {
  makeSelectSettlementIntentTransactionDetails,
} from '@/features/settlements/selectors';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseSettlementIntentTransactionDetails = UseAppSingleData<GasWalletTransaction>;

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<GasWalletTransaction>,
  Parameters<typeof fetchSettlementIntentTransactionDetails>[0]
> => fetchSettlementIntentTransactionDetails({ id, force });

export default function useSettlementIntentTransactionDetails(
  id: string | undefined,
): UseSettlementIntentTransactionDetails {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSettlementIntentTransactionDetails, id), [id]);
  return useAppSingleData(
    fetchFactory,
    makeSelectSettlementIntentTransactionDetails,
    noSingleDataIdFn(),
    id,
    dataFetchingSelector,
  );
}
