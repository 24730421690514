import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeSettlementListParameters } from '@/features/settlements/actions';
import type { Settlement, SettlementFilterPredicate } from '@/features/settlements/types';
import type { WithdrawalSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useSettlementList from '../useSettlementList';

export type UseSettlementListView = UseAppListDataView<Settlement, SettlementFilterPredicate, WithdrawalSortByAPIModel>;

export default function useSettlementListView(): UseSettlementListView {
  const state = useSettlementList();
  return useAppListDataView(state, storeSettlementListParameters);
}
