import React, { useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage, OperationRefresh } from '@/components';
import { markRechargeAddressesDependentDataDirty } from '@/features/recharges/actions';
import { useRechargeAddress } from '@/features/recharges/hooks';
import type { RechargeAddress } from '@/features/recharges/types';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { suppressPromise } from '@/infrastructure/utils/functions';

import type { OperationRefreshRechargeAddressProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_TOOLTIP} />,
  success: {
    message: (
      <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_SUCCESS_MESSAGE} />
    ),
  },
};

const OperationRefreshRechargeAddress: React.FC<OperationRefreshRechargeAddressProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  addressId,
}) => {
  const { data, forceRefresh } = useRechargeAddress(addressId);
  const dispatch = useAppDispatch();
  const refreshDependantData = useCallback(
    (newData: LoadingStateWithDirty<RechargeAddress>) => {
      if (
        newData.data
        && (newData.data.deployStatus !== data.data?.deployStatus || !isEqual(newData.data.balance, data.data.balance))
      ) {
        suppressPromise(dispatch(markRechargeAddressesDependentDataDirty([newData.data.id])));
      }
    },
    [data.data?.deployStatus, data.data?.balance, dispatch],
  );
  return (
    <OperationRefresh
      data-test={dataTest}
      mode={mode}
      refresh={forceRefresh}
      messages={messages}
      disabled={!addressId}
      onSuccess={refreshDependantData}
    />
  );
};

const OperationRefreshRechargeAddressMemo = React.memo(OperationRefreshRechargeAddress);

export default OperationRefreshRechargeAddressMemo;
