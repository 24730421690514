import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useNativeBalance } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainAddress } from '@/features/dictionary/blockchain/types';
import { deleteGasWallet, refundGasWallet } from '@/features/gas-wallets/actions';
import type { GasWallet } from '@/features/gas-wallets/types';
import { useActionPending } from '@/features/global/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';

import useMerchantGasWallet from '../useMerchantGasWallet';
import useMerchantGasWalletsActions from '../useMerchantGasWalletsActions';

import type {
  RefundUnavailabilityReason as BaseRefundUnavailabilityReason,
  DeleteUnavailabilityReason as BaseDeleteUnavailabilityReason,
} from '../useMerchantGasWalletsActions';
import type { Address } from 'viem';

export type RefundUnavailabilityReason = BaseRefundUnavailabilityReason | 'empty-balance';
export type DeleteUnavailabilityReason =
  | BaseDeleteUnavailabilityReason
  | 'native-balance-unknown'
  | 'non-empty-balance';

export interface UseMerchantGasWalletActions {
  refund: HookAction<[Address], GasWallet, RefundUnavailabilityReason>;
  deleteWallet: HookAction<[], GasWallet[], DeleteUnavailabilityReason>;
}

export default function useMerchantGasWalletActions(
  bt: BlockchainTypeAPIModel | undefined,
): UseMerchantGasWalletActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useMerchantGasWallet(bt);
  const address = data.data?.address;
  const btAddress = useMemo<BlockchainAddress | undefined>(() => bt && address && { address, bt }, [address, bt]);
  const {
    data: { data: balance, isDirty: isBalanceDirty },
    loading: isBalanceLoading,
  } = useNativeBalance(btAddress);

  const { refund: refundAll, deleteWallet: deleteAll } = useMerchantGasWalletsActions();
  const refundUnavailabilityReason: UseMerchantGasWalletActions['refund']['unavailabilityReason'] = useMemo(() => {
    if (refundAll.unavailabilityReason) return refundAll.unavailabilityReason;
    if (!bt || !data.data || isBalanceDirty || isBalanceLoading) return 'no-data';
    if (!!balance?.amount.isZero() && data.data.derivedBalance.isZero()) return 'empty-balance';
    return undefined;
  }, [balance?.amount, bt, data.data, isBalanceDirty, isBalanceLoading, refundAll.unavailabilityReason]);
  const refundAction: UseMerchantGasWalletActions['refund']['act'] = useCallback(
    (to: Address) => withExtractDataDispatch(refundGasWallet)({ bt: someOrFail(bt), to }),
    [bt, withExtractDataDispatch],
  );
  const refund: UseMerchantGasWalletActions['refund'] = useMemo(
    () => ({
      act: refundAction,
      available: !refundUnavailabilityReason,
      unavailabilityReason: refundUnavailabilityReason,
      inAction: refundAll.inAction,
    }),
    [refundAction, refundAll.inAction, refundUnavailabilityReason],
  );

  const deleteUnavailabilityReason: UseMerchantGasWalletActions['deleteWallet']['unavailabilityReason'] =
    useMemo(() => {
      if (deleteAll.unavailabilityReason) return deleteAll.unavailabilityReason;
      if (!bt || !data.data) return 'no-data';
      if (!balance) return 'native-balance-unknown';
      if (!balance.amount.isZero() || !data.data.derivedBalance.isZero()) return 'non-empty-balance';
      return undefined;
    }, [balance, bt, data.data, deleteAll.unavailabilityReason]);
  const deletePending = useActionPending(deleteGasWallet, bt);
  const deleteWalletAction: UseMerchantGasWalletActions['deleteWallet']['act'] = useCallback(
    () => withExtractDataDispatch(deleteGasWallet)(someOrFail(bt)),
    [bt, withExtractDataDispatch],
  );
  const deleteWallet: UseMerchantGasWalletActions['deleteWallet'] = useMemo(
    () => ({
      act: deleteWalletAction,
      available: !deleteUnavailabilityReason,
      unavailabilityReason: deleteUnavailabilityReason,
      inAction: deletePending,
    }),
    [deleteWalletAction, deleteUnavailabilityReason, deletePending],
  );

  return { refund, deleteWallet };
}
