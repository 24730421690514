import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import {
  OperationRefreshDonation,
  DonationsDocumentationLink,
  OperationCollectDonationAssetNow,
} from '@/features/donations/components';
import OperationUpdateDonationAssetStatus from '@/features/donations/components/OperationUpdateDonationAssetStatus';
import { useDonationAsset } from '@/features/donations/hooks';
import type { DonationAddress } from '@/features/donations/types';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDonationAssetDataLoading = <
  Original extends { data: DonationAddress } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { donationId: string; asset: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithDonationAddressDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useDonationAsset(props.donationId, props.asset);
    return withCardDataLoading<DonationAddress, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageDonations.DONATION_ASSET_VIEW_COMPONENTS_DONATION_ASSET_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <DonationsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationCollectDonationAssetNow
              data-test={dataTest && `${dataTest}-opCollect`}
              donationId={props.donationId}
              assetId={props.asset}
              mode="inline"
            />
            <OperationUpdateDonationAssetStatus
              data-test={dataTest && `${dataTest}-opStatus`}
              donationId={props.donationId}
              asset={props.asset}
              mode="inline"
            />
            <OperationRefreshDonation data-test={dataTest && `${dataTest}-opRefresh`} donationId={props.donationId} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withDonationAssetDataLoading;
