import type { MerchantWallet, MerchantWalletDeployTransaction, MerchantWalletId } from './types';

export const merchantWalletIdToStoreKey = ({ bt, address }: MerchantWalletId) => `${bt}_${address}`;
export const extractWalletId = ({ blockchain, address }: MerchantWallet): MerchantWalletId => ({
  bt: blockchain,
  address,
});

export const extractWalletIdFromTransaction = ({
  blockchain,
  address,
}: MerchantWalletDeployTransaction): MerchantWalletId => ({
  bt: blockchain,
  address,
});
