import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import SettlementScheduleIcon from '@/features/settlements/components/SettlementScheduleIcon';
import SettlementsIcon from '@/features/settlements/components/SettlementsIcon';
import { I18nMenu } from '@/generated/i18n/i18n';
import type { CustomizableRoute } from '@/layouts/types';
import { AppPageRoutes, appRoute } from '@/pages/routes';
import {
  settlementsHistoryLink,
  SettlementsPageRoutes,
  settlementsRoute,
  settlementsScheduledLink,
} from '@/pages/settlements/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (selected: boolean, formatMessage: IntlFormattersArrow['formatMessage']): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.SETTLEMENTS_MAIN }),
    key: appRoute(AppPageRoutes.SETTLEMENTS),
    icon: <SettlementsIcon />,
    path: settlementsHistoryLink(),
    hideChildrenInMenu: !selected,
    children: [
      {
        name: formatMessage({ id: I18nMenu.SETTLEMENTS_HISTORY }),
        icon: <SettlementsIcon />,
        path: settlementsHistoryLink(),
        hideChildrenInMenu: true,

        children: [SettlementsPageRoutes.SETTLEMENT_VIEW]
          .map((route) => settlementsRoute(route))
          .map((path) => ({ path })),
      },
      {
        name: formatMessage({ id: I18nMenu.SETTLEMENTS_SCHEDULED }),
        icon: <SettlementScheduleIcon />,
        path: settlementsScheduledLink(),
        hideChildrenInMenu: true,

        children: [
          SettlementsPageRoutes.ASSET_SCHEDULE,
          SettlementsPageRoutes.PENDING_INTENTS,
          SettlementsPageRoutes.INTENT_VIEW,
          SettlementsPageRoutes.INTENT_TRANSACTION_VIEW,
        ]
          .map((route) => settlementsRoute(route))
          .map((path) => ({ path })),
      },
    ],
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.SETTLEMENTS));
  return useMemo(() => menuCreator(selected, formatMessage), [formatMessage, selected]);
}
