/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { ReportStatusAPIModel } from './ReportStatusAPIModel';
import {
    ReportStatusAPIModelFromJSON,
    ReportStatusAPIModelFromJSONTyped,
    ReportStatusAPIModelToJSON,
} from './ReportStatusAPIModel';
import type { ReportTypeAPIModel } from './ReportTypeAPIModel';
import {
    ReportTypeAPIModelFromJSON,
    ReportTypeAPIModelFromJSONTyped,
    ReportTypeAPIModelToJSON,
} from './ReportTypeAPIModel';

/**
 * 
 * @export
 * @interface MerchantReportAPIModel
 */
export interface MerchantReportAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantReportAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportAPIModel
     */
    template: string;
    /**
     * 
     * @type {ReportTypeAPIModel}
     * @memberof MerchantReportAPIModel
     */
    type: ReportTypeAPIModel;
    /**
     * 
     * @type {ReportStatusAPIModel}
     * @memberof MerchantReportAPIModel
     */
    status: ReportStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportAPIModel
     */
    contentType: string;
    /**
     * 
     * @type {Date}
     * @memberof MerchantReportAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportAPIModel
     */
    createdBy?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportAPIModel
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportAPIModel
     */
    fileId?: string;
    /**
     * 
     * @type {any}
     * @memberof MerchantReportAPIModel
     */
    parameters?: any;
    /**
     * 
     * @type {string}
     * @memberof MerchantReportAPIModel
     */
    error?: string;
}

/**
 * Check if a given object implements the MerchantReportAPIModel interface.
 */
export function instanceOfMerchantReportAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('template' in value)) return false;
    if (!('type' in value)) return false;
    if (!('status' in value)) return false;
    if (!('contentType' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('filename' in value)) return false;
    return true;
}

export function MerchantReportAPIModelFromJSON(json: any): MerchantReportAPIModel {
    return MerchantReportAPIModelFromJSONTyped(json, false);
}

export function MerchantReportAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantReportAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'template': json['template'],
        'type': ReportTypeAPIModelFromJSON(json['type']),
        'status': ReportStatusAPIModelFromJSON(json['status']),
        'contentType': json['contentType'],
        'createdAt': (new Date(json['createdAt'])),
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'filename': json['filename'],
        'fileId': json['fileId'] == null ? undefined : json['fileId'],
        'parameters': json['parameters'] == null ? undefined : json['parameters'],
        'error': json['error'] == null ? undefined : json['error'],
    };
}

export function MerchantReportAPIModelToJSON(value?: MerchantReportAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'template': value['template'],
        'type': ReportTypeAPIModelToJSON(value['type']),
        'status': ReportStatusAPIModelToJSON(value['status']),
        'contentType': value['contentType'],
        'createdAt': ((value['createdAt']).toISOString()),
        'createdBy': value['createdBy'],
        'filename': value['filename'],
        'fileId': value['fileId'],
        'parameters': value['parameters'],
        'error': value['error'],
    };
}

