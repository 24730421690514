import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { SettlementPendingIntentsLink } from '@/pages/settlements/pending-intents/components';

import type { SettlementPendingIntentsBackgroundLinkProps } from './types';

const SettlementPendingIntentsBackgroundLink: React.FC<SettlementPendingIntentsBackgroundLinkProps> = (props) => (
  <SettlementPendingIntentsLink
    title={<FormattedMessage id={I18nFeatureSettlements.MESSAGES_PENDING_TASK_EXTRA} />}
    mode="text"
    {...props}
  />
);

export default SettlementPendingIntentsBackgroundLink;
