import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementIntentTransactionDestinationLikeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_TRANSACTION_FILTER_FORM_DESTINATION_LABEL} />,
};

const SettlementIntentTransactionDestinationLikeFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: SettlementIntentTransactionDestinationLikeFilterItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      messages={messages}
      placeholder={formatMessage({
        id: I18nFeatureSettlements.COMPONENTS_INTENT_TRANSACTION_FILTER_FORM_DESTINATION_PLACEHOLDER,
      })}
      {...ItemProps}
    />
  );
};

const SettlementIntentTransactionDestinationLikeFilterItemMemo = React.memo(
  SettlementIntentTransactionDestinationLikeFilterItem,
) as typeof SettlementIntentTransactionDestinationLikeFilterItem;

export default SettlementIntentTransactionDestinationLikeFilterItemMemo;
