/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { MerchantWalletStatusAPIModel } from './MerchantWalletStatusAPIModel';
import {
    MerchantWalletStatusAPIModelFromJSON,
    MerchantWalletStatusAPIModelFromJSONTyped,
    MerchantWalletStatusAPIModelToJSON,
} from './MerchantWalletStatusAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletAPIModel
 */
export interface MerchantWalletAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletAPIModel
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletAPIModel
     */
    brokerAddress: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletAPIModel
     */
    factoryAddress: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof MerchantWalletAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {MerchantWalletStatusAPIModel}
     * @memberof MerchantWalletAPIModel
     */
    status: MerchantWalletStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof MerchantWalletAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantWalletAPIModel
     */
    isFactoryActive: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MerchantWalletAPIModel
     */
    deployStartedAt?: Date;
}

/**
 * Check if a given object implements the MerchantWalletAPIModel interface.
 */
export function instanceOfMerchantWalletAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('brokerAddress' in value)) return false;
    if (!('factoryAddress' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('status' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('isFactoryActive' in value)) return false;
    return true;
}

export function MerchantWalletAPIModelFromJSON(json: any): MerchantWalletAPIModel {
    return MerchantWalletAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'owner': json['owner'],
        'brokerAddress': json['brokerAddress'],
        'factoryAddress': json['factoryAddress'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'status': MerchantWalletStatusAPIModelFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'isFactoryActive': json['isFactoryActive'],
        'deployStartedAt': json['deployStartedAt'] == null ? undefined : (new Date(json['deployStartedAt'])),
    };
}

export function MerchantWalletAPIModelToJSON(value?: MerchantWalletAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'owner': value['owner'],
        'brokerAddress': value['brokerAddress'],
        'factoryAddress': value['factoryAddress'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'status': MerchantWalletStatusAPIModelToJSON(value['status']),
        'createdAt': ((value['createdAt']).toISOString()),
        'isFactoryActive': value['isFactoryActive'],
        'deployStartedAt': value['deployStartedAt'] == null ? undefined : ((value['deployStartedAt']).toISOString()),
    };
}

