/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeSubscriptionChargeSortByAPIModel } from './SortAttributeSubscriptionChargeSortByAPIModel';
import {
    SortAttributeSubscriptionChargeSortByAPIModelFromJSON,
    SortAttributeSubscriptionChargeSortByAPIModelFromJSONTyped,
    SortAttributeSubscriptionChargeSortByAPIModelToJSON,
} from './SortAttributeSubscriptionChargeSortByAPIModel';

/**
 * 
 * @export
 * @interface PageSubscriptionChargeSortByAPIModel
 */
export interface PageSubscriptionChargeSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageSubscriptionChargeSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageSubscriptionChargeSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeSubscriptionChargeSortByAPIModel>}
     * @memberof PageSubscriptionChargeSortByAPIModel
     */
    sortBy?: Array<SortAttributeSubscriptionChargeSortByAPIModel>;
}

/**
 * Check if a given object implements the PageSubscriptionChargeSortByAPIModel interface.
 */
export function instanceOfPageSubscriptionChargeSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageSubscriptionChargeSortByAPIModelFromJSON(json: any): PageSubscriptionChargeSortByAPIModel {
    return PageSubscriptionChargeSortByAPIModelFromJSONTyped(json, false);
}

export function PageSubscriptionChargeSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSubscriptionChargeSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeSubscriptionChargeSortByAPIModelFromJSON)),
    };
}

export function PageSubscriptionChargeSortByAPIModelToJSON(value?: PageSubscriptionChargeSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeSubscriptionChargeSortByAPIModelToJSON)),
    };
}

