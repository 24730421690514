import { useAppNestedListDataView } from '@/app/hooks';
import { storeSettlementIntentTransactionsForIntentListParameters } from '@/features/settlements/actions';

import useSettlementIntentTransactionForIntentList from '../useSettlementIntentTransactionForIntentList';

export default function useSettlementIntentTransactionForIntentListView(intent: string | undefined) {
  return useAppNestedListDataView(
    useSettlementIntentTransactionForIntentList(intent),
    storeSettlementIntentTransactionsForIntentListParameters,
    intent,
  );
}
