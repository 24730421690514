import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum SettlementsPageRoutes {
  HISTORY = 'history',
  PENDING_INTENTS = 'intents/pending',
  INTENT_VIEW = 'intents/view/:intentId',
  INTENT_TRANSACTION_VIEW = 'intents/transactions/view/:transactionId',
  SCHEDULED = 'schedule',
  ASSET_SCHEDULE = 'schedule/:assetId',
  SETTLEMENT_VIEW = 'view/:settlementId',
}

export const settlementsRoute = (page: SettlementsPageRoutes) => `${appRoute(AppPageRoutes.SETTLEMENTS)}/${page}`;

export const settlementsHistoryLink = () => settlementsRoute(SettlementsPageRoutes.HISTORY);
export const settlementIntentViewLink = (id: string) => `${appRoute(AppPageRoutes.SETTLEMENTS)}/intents/view/${id}`;
export const settlementIntentTransactionViewLink = (id: string) =>
  `${appRoute(AppPageRoutes.SETTLEMENTS)}/intents/transactions/view/${id}`;
export const settlementPendingIntentsLink = () => settlementsRoute(SettlementsPageRoutes.PENDING_INTENTS);
export const settlementsScheduledLink = (assetId?: string) =>
  `${settlementsRoute(SettlementsPageRoutes.SCHEDULED)}${assetId ? `/${assetId}` : ''}`;
export const settlementViewLink = (id: string) => `${appRoute(AppPageRoutes.SETTLEMENTS)}/view/${id}`;
