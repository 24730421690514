import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';

import DeployingMerchantWalletsLink from '../DeployingMerchantWalletsLink';

import type { DeployingMerchantWalletsBackgroundLinkProps } from './types';

const DeployingMerchantWalletsBackgroundLink: React.FC<DeployingMerchantWalletsBackgroundLinkProps> = (props) => (
  <DeployingMerchantWalletsLink
    title={<FormattedMessage id={I18nFeatureMerchantWallets.MESSAGES_DEPLOYING_TASK_EXTRA} />}
    mode="text"
    {...props}
  />
);

export default DeployingMerchantWalletsBackgroundLink;
