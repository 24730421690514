import { useMemo } from 'react';

import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayoutDestinations } from '@/features/payouts/actions';
import { makeSelectPayoutDestinations } from '@/features/payouts/selectors';
import type { PayoutDestination } from '@/features/payouts/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

import type { UsePayoutDestinations } from './types';

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<PayoutDestination[]>, Parameters<typeof fetchPayoutDestinations>[0]> =>
  fetchPayoutDestinations({ id, force });

export default function usePayoutDestinations(id: string | undefined): UsePayoutDestinations {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchPayoutDestinations, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectPayoutDestinations, noSingleDataIdFn(), id, dataFetchingSelector);
}
