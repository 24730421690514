import { Descriptions } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDate,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { AssetAmount, AssetLabel } from '@/features/dictionary/blockchain/components';
import { SettlementIntentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import SettlementIntentStatusBanner from '../SettlementIntentStatusBanner';
import SettlementIntentStatusView from '../SettlementIntentStatusView';

import type { SettlementIntentViewProps } from './types';

const SettlementIntentView: React.FC<SettlementIntentViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={<SettlementIntentStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    {data.status === SettlementIntentStatusAPIModel.Pending ? (
      <Descriptions.Item>
        <SettlementIntentStatusBanner data-test={dataTest && `${dataTest}-intent`} intent={data} mode="alert" noLink />
      </Descriptions.Item>
    ) : null}

    {data.settled ? (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_SETTLED_AMOUNT} />}
        styles={descriptionItemStyles}
      >
        <AssetAmount
          data-test={dataTest && `${dataTest}-settled`}
          value={data.settled.value}
          assetId={data.asset}
          currencyMode="long"
          withBlockchainMark
          withCurrency
        />
      </Descriptions.Item>
    ) : (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_ASSET} />}
        styles={descriptionItemStyles}
      >
        <AssetLabel data-test={dataTest && `${dataTest}-asset`} value={data.asset} mode="medium" />
      </Descriptions.Item>
    )}
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_PROCESS_AT} />}
    >
      <ReadonlyDate value={data.processAt} data-test={dataTest && `${dataTest}-processAt`} />
    </Descriptions.Item>
    {data.failureReason ? (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_FAILURE_REASON} />}
        styles={descriptionItemStyles}
      >
        <ReadonlyComponent value={data.failureReason} data-test={dataTest && `${dataTest}-processAt`} />
      </Descriptions.Item>
    ) : null}
  </Descriptions>
);

const SettlementIntentViewMemo = React.memo(SettlementIntentView);

export default SettlementIntentViewMemo;
