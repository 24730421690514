import { useCallback } from 'react';

import { useAppDispatch, useAppLoadableData, useAppSelector } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPendingIntents, fetchPendingIntentWithActiveTransactionsCount } from '@/features/settlements/actions';
import {
  makeSelectPendingIntentsRefreshableAfter,
  makeSelectPendingIntentWithActiveTransactionsCount,
} from '@/features/settlements/selectors';
import { useRunOnTimer } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<number>,
  Parameters<typeof fetchPendingIntentWithActiveTransactionsCount>[0]
> => fetchPendingIntentWithActiveTransactionsCount({ force });
const selectFetching = makeSelectPending(fetchPendingIntents);
const selectData = makeSelectPendingIntentWithActiveTransactionsCount();
const selectNextDataUpdateAt = makeSelectPendingIntentsRefreshableAfter();

export default function usePendingIntentsWithActiveTransactionsCount() {
  const { withExtractDataDispatch } = useAppDispatch();
  const nextUpdateAt = useAppSelector(selectNextDataUpdateAt);
  const data = useAppLoadableData(fetchFactory, selectData, selectFetching);
  const refresh = useCallback(() => {
    if (data.data.isDirty || data.data.data) {
      withSuppressPromise(withExtractDataDispatch(fetchPendingIntentWithActiveTransactionsCount))({});
    }
  }, [data.data.data, data.data.isDirty, withExtractDataDispatch]);
  useRunOnTimer(refresh, nextUpdateAt, false);
  return data.data.data ?? 0;
}
