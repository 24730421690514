/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { GrowthRatePerPlanAPIModel } from './GrowthRatePerPlanAPIModel';
import {
    GrowthRatePerPlanAPIModelFromJSON,
    GrowthRatePerPlanAPIModelFromJSONTyped,
    GrowthRatePerPlanAPIModelToJSON,
} from './GrowthRatePerPlanAPIModel';
import type { MonthlyGrowthRateRecordAPIModel } from './MonthlyGrowthRateRecordAPIModel';
import {
    MonthlyGrowthRateRecordAPIModelFromJSON,
    MonthlyGrowthRateRecordAPIModelFromJSONTyped,
    MonthlyGrowthRateRecordAPIModelToJSON,
} from './MonthlyGrowthRateRecordAPIModel';

/**
 * 
 * @export
 * @interface MonthlyGrowthRateStatisticsAPIModel
 */
export interface MonthlyGrowthRateStatisticsAPIModel {
    /**
     * 
     * @type {Array<GrowthRatePerPlanAPIModel>}
     * @memberof MonthlyGrowthRateStatisticsAPIModel
     */
    perPlan?: Array<GrowthRatePerPlanAPIModel>;
    /**
     * 
     * @type {Array<MonthlyGrowthRateRecordAPIModel>}
     * @memberof MonthlyGrowthRateStatisticsAPIModel
     */
    mean?: Array<MonthlyGrowthRateRecordAPIModel>;
    /**
     * 
     * @type {any}
     * @memberof MonthlyGrowthRateStatisticsAPIModel
     */
    median?: any;
}

/**
 * Check if a given object implements the MonthlyGrowthRateStatisticsAPIModel interface.
 */
export function instanceOfMonthlyGrowthRateStatisticsAPIModel(value: object): boolean {
    return true;
}

export function MonthlyGrowthRateStatisticsAPIModelFromJSON(json: any): MonthlyGrowthRateStatisticsAPIModel {
    return MonthlyGrowthRateStatisticsAPIModelFromJSONTyped(json, false);
}

export function MonthlyGrowthRateStatisticsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyGrowthRateStatisticsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'perPlan': json['perPlan'] == null ? undefined : ((json['perPlan'] as Array<any>).map(GrowthRatePerPlanAPIModelFromJSON)),
        'mean': json['mean'] == null ? undefined : ((json['mean'] as Array<any>).map(MonthlyGrowthRateRecordAPIModelFromJSON)),
        'median': json['median'] == null ? undefined : json['median'],
    };
}

export function MonthlyGrowthRateStatisticsAPIModelToJSON(value?: MonthlyGrowthRateStatisticsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'perPlan': value['perPlan'] == null ? undefined : ((value['perPlan'] as Array<any>).map(GrowthRatePerPlanAPIModelToJSON)),
        'mean': value['mean'] == null ? undefined : ((value['mean'] as Array<any>).map(MonthlyGrowthRateRecordAPIModelToJSON)),
        'median': value['median'],
    };
}

