/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  MerchantReportAPIModel,
  NewPaymentAPIModel,
  PaymentPredicatesAPIModel,
  PaymentSummaryAPIModel,
  PaymentsFilterAPIModel,
  SlicePaymentPaymentSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    NewPaymentAPIModelFromJSON,
    NewPaymentAPIModelToJSON,
    PaymentPredicatesAPIModelFromJSON,
    PaymentPredicatesAPIModelToJSON,
    PaymentSummaryAPIModelFromJSON,
    PaymentSummaryAPIModelToJSON,
    PaymentsFilterAPIModelFromJSON,
    PaymentsFilterAPIModelToJSON,
    SlicePaymentPaymentSortByAPIModelFromJSON,
    SlicePaymentPaymentSortByAPIModelToJSON,
} from '../models/index';

export interface CreatePaymentRequest {
    newPaymentAPIModel: NewPaymentAPIModel;
}

export interface ExportPaymentsRequest {
    paymentPredicatesAPIModel: PaymentPredicatesAPIModel;
}

export interface GetPaymentRequest {
    paymentId: string;
}

export interface SearchPaymentsRequest {
    paymentsFilterAPIModel: PaymentsFilterAPIModel;
    withTotal?: boolean;
}

/**
 * PaymentApi - interface
 * 
 * @export
 * @interface PaymentApiInterface
 */
export interface PaymentApiInterface {
    /**
     * Creates a new payment
     * @param {NewPaymentAPIModel} newPaymentAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    createPaymentRaw(requestParameters: CreatePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSummaryAPIModel>>;

    /**
     * Creates a new payment
     */
    createPayment(newPaymentAPIModel: NewPaymentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSummaryAPIModel>;

    /**
     * Exports payments as file
     * @param {PaymentPredicatesAPIModel} paymentPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    exportPaymentsRaw(requestParameters: ExportPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports payments as file
     */
    exportPayments(paymentPredicatesAPIModel: PaymentPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns a payment by id
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    getPaymentRaw(requestParameters: GetPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSummaryAPIModel>>;

    /**
     * Returns a payment by id
     */
    getPayment(paymentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSummaryAPIModel>;

    /**
     * Returns payments by a filter
     * @param {PaymentsFilterAPIModel} paymentsFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApiInterface
     */
    searchPaymentsRaw(requestParameters: SearchPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SlicePaymentPaymentSortByAPIModel>>;

    /**
     * Returns payments by a filter
     */
    searchPayments(paymentsFilterAPIModel: PaymentsFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SlicePaymentPaymentSortByAPIModel>;

}

/**
 * 
 */
export class PaymentApi extends runtime.BaseAPI implements PaymentApiInterface {

    /**
     * Creates a new payment
     */
    async createPaymentRaw(requestParameters: CreatePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSummaryAPIModel>> {
        if (requestParameters['newPaymentAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newPaymentAPIModel',
                'Required parameter "newPaymentAPIModel" was null or undefined when calling createPayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPaymentAPIModelToJSON(requestParameters['newPaymentAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates a new payment
     */
    async createPayment(newPaymentAPIModel: NewPaymentAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSummaryAPIModel> {
        const response = await this.createPaymentRaw({ newPaymentAPIModel: newPaymentAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Exports payments as file
     */
    async exportPaymentsRaw(requestParameters: ExportPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['paymentPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'paymentPredicatesAPIModel',
                'Required parameter "paymentPredicatesAPIModel" was null or undefined when calling exportPayments().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentPredicatesAPIModelToJSON(requestParameters['paymentPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports payments as file
     */
    async exportPayments(paymentPredicatesAPIModel: PaymentPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportPaymentsRaw({ paymentPredicatesAPIModel: paymentPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns a payment by id
     */
    async getPaymentRaw(requestParameters: GetPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentSummaryAPIModel>> {
        if (requestParameters['paymentId'] == null) {
            throw new runtime.RequiredError(
                'paymentId',
                'Required parameter "paymentId" was null or undefined when calling getPayment().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/payments/{paymentId}`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters['paymentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns a payment by id
     */
    async getPayment(paymentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentSummaryAPIModel> {
        const response = await this.getPaymentRaw({ paymentId: paymentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns payments by a filter
     */
    async searchPaymentsRaw(requestParameters: SearchPaymentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SlicePaymentPaymentSortByAPIModel>> {
        if (requestParameters['paymentsFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'paymentsFilterAPIModel',
                'Required parameter "paymentsFilterAPIModel" was null or undefined when calling searchPayments().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentsFilterAPIModelToJSON(requestParameters['paymentsFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SlicePaymentPaymentSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns payments by a filter
     */
    async searchPayments(paymentsFilterAPIModel: PaymentsFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SlicePaymentPaymentSortByAPIModel> {
        const response = await this.searchPaymentsRaw({ paymentsFilterAPIModel: paymentsFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
