import { PageContainer } from '@/components';
import type { DeployingMerchantWalletsCardProps } from '@/pages/audit/deploying-wallets/components/DeployingMerchantWalletsCard/types';
import BaseMerchantWalletLink from '@/pages/audit/wallet-view/components/MerchantWalletLink';

import { DeployingMerchantWalletsCard, DeployingMerchantWalletsBreadcrumb } from './components';

import type React from 'react';

const MerchantWalletLink: DeployingMerchantWalletsCardProps['WalletLink'] = (props) => (
  <BaseMerchantWalletLink {...props} mode="text" />
);

const dataTest: string | undefined = 'tasks';

const DeployingMerchantWalletsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<DeployingMerchantWalletsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <DeployingMerchantWalletsCard data-test={dataTest && `${dataTest}-card`} WalletLink={MerchantWalletLink} />
  </PageContainer>
);

export default DeployingMerchantWalletsPage;
