/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentTransactionSearchIdAPIModel
 */
export interface PaymentTransactionSearchIdAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionSearchIdAPIModel
     */
    paymentId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionSearchIdAPIModel
     */
    hash: string;
}

/**
 * Check if a given object implements the PaymentTransactionSearchIdAPIModel interface.
 */
export function instanceOfPaymentTransactionSearchIdAPIModel(value: object): boolean {
    if (!('paymentId' in value)) return false;
    if (!('hash' in value)) return false;
    return true;
}

export function PaymentTransactionSearchIdAPIModelFromJSON(json: any): PaymentTransactionSearchIdAPIModel {
    return PaymentTransactionSearchIdAPIModelFromJSONTyped(json, false);
}

export function PaymentTransactionSearchIdAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionSearchIdAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'paymentId': json['paymentId'],
        'hash': json['hash'],
    };
}

export function PaymentTransactionSearchIdAPIModelToJSON(value?: PaymentTransactionSearchIdAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'paymentId': value['paymentId'],
        'hash': value['hash'],
    };
}

