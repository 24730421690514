import { useMemo } from 'react';

import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchReport } from '@/features/reports/actions';
import { makeSelectReport } from '@/features/reports/selectors';
import type { Report } from '@/features/reports/types';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Report>, Parameters<typeof fetchReport>[0]> => fetchReport({ force, id });

export default function useReport(id: string | undefined) {
  return useAppSingleData(
    fetchFactory,
    makeSelectReport,
    noSingleDataIdFn(),
    id,
    useMemo(() => makeSelectPending(fetchReport, id), [id]),
  );
}
