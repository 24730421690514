import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCompanyPermissions } from '@/features/company/hooks';
import type { NoPermissionUnavailabilityReason } from '@/features/company/types';
import { createGasWallet, deleteGasWallet, refundGasWallet } from '@/features/gas-wallets/actions';
import { makeSelectRefundingGasWallets } from '@/features/gas-wallets/selectors';
import type { GasWallet } from '@/features/gas-wallets/types';
import { useActionPending } from '@/features/global/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useMerchantGasWallets from '../useMerchantGasWallets';

import type { Address } from 'viem';

export type CreateUnavailabilityReason = 'no-data' | NoPermissionUnavailabilityReason;
export type RefundUnavailabilityReason = 'no-data' | NoPermissionUnavailabilityReason;
export type DeleteUnavailabilityReason = 'no-data' | NoPermissionUnavailabilityReason | 'is-refunding';

export interface UseMerchantGasWalletsActions {
  create: HookAction<[BlockchainTypeAPIModel], GasWallet, CreateUnavailabilityReason>;
  refund: HookAction<[BlockchainTypeAPIModel, Address], GasWallet, RefundUnavailabilityReason>;
  deleteWallet: HookAction<[BlockchainTypeAPIModel], GasWallet[], DeleteUnavailabilityReason>;
}

const selectRefunding = makeSelectRefundingGasWallets();

export default function useMerchantGasWalletsActions(): UseMerchantGasWalletsActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data, loading } = useMerchantGasWallets();
  const { data: permissions } = useCompanyPermissions();

  const isRefunding = !!useAppSelector(selectRefunding).length;

  const createUnavailabilityReason: UseMerchantGasWalletsActions['create']['unavailabilityReason'] = useMemo(() => {
    if (!data.data || data.isDirty || loading) return 'no-data';
    if (!permissions.data?.gasWalletsManagement) return 'no-permissions';
    return undefined;
  }, [data.data, data.isDirty, loading, permissions.data?.gasWalletsManagement]);

  const createPending = useActionPending(createGasWallet);
  const createAction: UseMerchantGasWalletsActions['create']['act'] = useCallback(
    (bt: BlockchainTypeAPIModel) => withExtractDataDispatch(createGasWallet)({ newGasWallet: { bt } }),
    [withExtractDataDispatch],
  );
  const create: UseMerchantGasWalletsActions['create'] = useMemo(
    () => ({
      act: createAction,
      available: !createUnavailabilityReason,
      inAction: createPending,
      unavailable: createUnavailabilityReason,
    }),
    [createAction, createUnavailabilityReason, createPending],
  );

  const refundUnavailabilityReason: UseMerchantGasWalletsActions['refund']['unavailabilityReason'] = useMemo(() => {
    if (!data.data || data.isDirty || loading) return 'no-data';
    if (!permissions.data?.gasWalletsManagement) return 'no-permissions';
    return undefined;
  }, [data.data, data.isDirty, loading, permissions.data?.gasWalletsManagement]);
  const refundAction: UseMerchantGasWalletsActions['refund']['act'] = useCallback(
    (bt: BlockchainTypeAPIModel, to: Address) => withExtractDataDispatch(refundGasWallet)({ bt, to }),
    [withExtractDataDispatch],
  );
  const refund: UseMerchantGasWalletsActions['refund'] = useMemo(
    () => ({
      act: refundAction,
      available: !refundUnavailabilityReason,
      unavailabilityReason: refundUnavailabilityReason,
      inAction: isRefunding,
    }),
    [refundAction, refundUnavailabilityReason, isRefunding],
  );

  const deleteUnavailabilityReason: UseMerchantGasWalletsActions['deleteWallet']['unavailabilityReason'] =
    useMemo(() => {
      if (!data.data || data.isDirty || loading) return 'no-data';
      if (!permissions.data?.gasWalletsManagement) return 'no-permissions';
      if (isRefunding) return 'is-refunding';
      return undefined;
    }, [data.data, data.isDirty, isRefunding, loading, permissions.data?.gasWalletsManagement]);
  const deletePending = useActionPending(deleteGasWallet);
  const deleteWalletAction: UseMerchantGasWalletsActions['deleteWallet']['act'] = useCallback(
    (bt: BlockchainTypeAPIModel) => withExtractDataDispatch(deleteGasWallet)(bt),
    [withExtractDataDispatch],
  );
  const deleteWallet: UseMerchantGasWalletsActions['deleteWallet'] = useMemo(
    () => ({
      act: deleteWalletAction,
      available: !deleteUnavailabilityReason && !isRefunding,
      unavailabilityReason: deleteUnavailabilityReason,
      inAction: deletePending,
    }),
    [deleteWalletAction, deleteUnavailabilityReason, isRefunding, deletePending],
  );

  return { create, refund, deleteWallet };
}
