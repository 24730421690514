/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { MerchantWalletRunningBalanceConfirmationStatusAPIModel } from './MerchantWalletRunningBalanceConfirmationStatusAPIModel';
import {
    MerchantWalletRunningBalanceConfirmationStatusAPIModelFromJSON,
    MerchantWalletRunningBalanceConfirmationStatusAPIModelFromJSONTyped,
    MerchantWalletRunningBalanceConfirmationStatusAPIModelToJSON,
} from './MerchantWalletRunningBalanceConfirmationStatusAPIModel';

/**
 * 
 * @export
 * @interface MerchantWalletRunningBalanceAPIModel
 */
export interface MerchantWalletRunningBalanceAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    id: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    wallet: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    blockNum: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    balance: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    amount: string;
    /**
     * 
     * @type {Date}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    broadcastAt?: Date;
    /**
     * 
     * @type {MerchantWalletRunningBalanceConfirmationStatusAPIModel}
     * @memberof MerchantWalletRunningBalanceAPIModel
     */
    confirmation: MerchantWalletRunningBalanceConfirmationStatusAPIModel;
}

/**
 * Check if a given object implements the MerchantWalletRunningBalanceAPIModel interface.
 */
export function instanceOfMerchantWalletRunningBalanceAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('wallet' in value)) return false;
    if (!('blockNum' in value)) return false;
    if (!('balance' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('confirmation' in value)) return false;
    return true;
}

export function MerchantWalletRunningBalanceAPIModelFromJSON(json: any): MerchantWalletRunningBalanceAPIModel {
    return MerchantWalletRunningBalanceAPIModelFromJSONTyped(json, false);
}

export function MerchantWalletRunningBalanceAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantWalletRunningBalanceAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'wallet': json['wallet'],
        'blockNum': json['blockNum'],
        'balance': json['balance'],
        'amount': json['amount'],
        'broadcastAt': json['broadcastAt'] == null ? undefined : (new Date(json['broadcastAt'])),
        'confirmation': MerchantWalletRunningBalanceConfirmationStatusAPIModelFromJSON(json['confirmation']),
    };
}

export function MerchantWalletRunningBalanceAPIModelToJSON(value?: MerchantWalletRunningBalanceAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'wallet': value['wallet'],
        'blockNum': value['blockNum'],
        'balance': value['balance'],
        'amount': value['amount'],
        'broadcastAt': value['broadcastAt'] == null ? undefined : ((value['broadcastAt']).toISOString()),
        'confirmation': MerchantWalletRunningBalanceConfirmationStatusAPIModelToJSON(value['confirmation']),
    };
}

