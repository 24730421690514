import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import PendingTasksPage from './pending-tasks';
import { CollectablePageRoutes, collectTasksLink } from './routes';
import TaskViewPage from './task-view';
import TasksPage from './tasks';

import type React from 'react';

export const CollectableDefaultRoute: React.FC = () => <Navigate to={collectTasksLink()} replace />;

const CollectablePagesRaw: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={CollectablePageRoutes.TASKS} Component={TasksPage} />
        <Route path={CollectablePageRoutes.TASK_VIEW} Component={TaskViewPage} />
        <Route path={CollectablePageRoutes.PENDING_TASKS} Component={PendingTasksPage} />
        <Route path="*" Component={CollectableDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const CollectablePages: React.FC = withBasicPageGuard(CollectablePagesRaw);

export default (
  <Route key={AppPageRoutes.COLLECTABLE} path={`${AppPageRoutes.COLLECTABLE}/*`} Component={CollectablePages} />
);
