import { Descriptions } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyDateTime,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nFeatureMerchantWalletBalance } from '@/generated/i18n/i18n';

import MerchantWalletRunningBalanceConfirmationStatusView from '../MerchantWalletRunningBalanceConfirmationStatusView';

import type { MerchantWalletRunningBalanceViewProps } from './types';

const MerchantWalletRunningBalanceView: React.FC<MerchantWalletRunningBalanceViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={
      <FormattedMessage
        id={I18nFeatureMerchantWalletBalance.COMPONENTS_BALANCE_VIEW_TITLE}
        values={{ block: data.blockNum }}
      />
    }
    column={columns}
  >
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_WALLET} />}
      styles={descriptionItemStyles}
    >
      <AddressLink data-test={dataTest && `${dataTest}-wallet`} bt={data.blockchain} address={data.wallet} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_AMOUNT} />}
      styles={descriptionItemStyles}
    >
      <AssetAmount
        value={data.amount.value}
        assetId={data.amount.asset}
        withCurrency
        currencyMode="long"
        data-test={dataTest && `${dataTest}-amount`}
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_BALANCE} />}
      styles={descriptionItemStyles}
    >
      <AssetAmount
        value={data.balance.value}
        assetId={data.balance.asset}
        withCurrency
        currencyMode="long"
        data-test={dataTest && `${dataTest}-balance`}
      />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_BROADCAST_AT} />}>
      <ReadonlyDateTime value={data.broadcastAt} data-test={dataTest && `${dataTest}-broadcastAt`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWalletBalance.LABELS_ROW_TITLE_CONFIRMATION} />}>
      <MerchantWalletRunningBalanceConfirmationStatusView
        data-test={dataTest && `${dataTest}-confirmationLink`}
        value={data.confirmation}
        mode="full"
      />
    </Descriptions.Item>
  </Descriptions>
);

const MerchantWalletRunningBalanceViewMemo = React.memo(MerchantWalletRunningBalanceView);

export default MerchantWalletRunningBalanceViewMemo;
