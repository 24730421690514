import { useCallback, useContext } from 'react';

import NotificationContext from '@/features/global/hocs/withGlobalNotifications/context';
import type { Func } from '@/infrastructure/utils/ts';

import type { NotificationInstance } from 'antd/es/notification/interface';
import type { ReactNode } from 'react';

export interface NotificationFullOptions {
  message: ReactNode;
  description?: ReactNode;
  duration?: number;
}

export type NotificationErrorFullOptions = NotificationFullOptions;
export type NotificationSuccessFullOptions = NotificationFullOptions;

type NotificationErrorOptions<T extends unknown[]> = (e: unknown, args: T) => NotificationErrorFullOptions | ReactNode;
type NotificationSuccessOptions<T extends unknown[], R> = (
  result: R,
  args: T,
) => NotificationSuccessFullOptions | ReactNode;

export interface NotificationOptions<T extends unknown[] = unknown[], R = unknown> {
  success?: NotificationSuccessOptions<T, R>;
  error?: NotificationErrorOptions<T>;
}

export default function useNotification(): {
  notification?: NotificationInstance;
  withError: <V extends unknown[], R>(
    func: Func<V, R>,
    options: NotificationErrorOptions<V>,
  ) => (...args: V) => Promise<R>;
  withSuccess: <V extends unknown[], R>(
    func: Func<V, R>,
    options: NotificationSuccessOptions<V, R>,
  ) => (...args: V) => Promise<R>;
  withNotification: <V extends unknown[], R>(
    func: Func<V, R>,
    options: NotificationOptions<V, R>,
  ) => (...args: V) => Promise<R>;
} {
  const api = useContext(NotificationContext);
  const withError = useCallback(
    <V extends unknown[], R>(func: Func<V, R>, options: NotificationErrorOptions<V>) =>
      async (...params: V) => {
        try {
          return await func(...params);
        } catch (e) {
          if (api) {
            try {
              const errorNotification = options(e, params);
              if (errorNotification) {
                api.error({
                  message:
                    typeof errorNotification === 'object' && 'message' in errorNotification
                      ? errorNotification.message
                      : errorNotification,
                  description:
                    typeof errorNotification === 'object' && 'description' in errorNotification
                      ? errorNotification.description
                      : undefined,
                  duration:
                    typeof errorNotification === 'object' && 'duration' in errorNotification
                      ? errorNotification.duration
                      : 3,
                  showProgress: true,
                });
              }
            } catch (e2) {
              console.error(e2, (e2 as Error | undefined)?.stack);
            }
          }
          throw e;
        }
      },
    [api],
  );
  const withSuccess = useCallback(
    <V extends unknown[], R>(func: Func<V, R>, options: NotificationSuccessOptions<V, R>) =>
      async (...params: V) => {
        const result = await func(...params);
        if (api) {
          const successNotification = options(result, params);
          if (successNotification) {
            try {
              api.success({
                message:
                  typeof successNotification === 'object' && 'message' in successNotification
                    ? successNotification.message
                    : successNotification,
                description:
                  typeof successNotification === 'object' && 'description' in successNotification
                    ? successNotification.description
                    : undefined,
                duration:
                  typeof successNotification === 'object' && 'duration' in successNotification
                    ? successNotification.duration
                    : 3,
                showProgress: true,
              });
            } catch (e2) {
              console.error(e2, (e2 as Error | undefined)?.stack);
            }
          }
        }
        return result;
      },
    [api],
  );

  const withNotification = useCallback(
    <V extends unknown[], R>(
      func: Func<V, R>,
      options: { success?: NotificationSuccessOptions<V, R>; error?: NotificationErrorOptions<V> },
    ) =>
      async (...params: V) => {
        let wrapped = options.success ? withSuccess(func, options.success) : func;
        wrapped = options.error ? withError(wrapped, options.error) : wrapped;
        return wrapped(...params);
      },
    [withError, withSuccess],
  );

  return { notification: api, withError, withSuccess, withNotification };
}
