import { QuestionCircleOutlined } from '@ant-design/icons';
import { Descriptions, Space, Tooltip } from 'antd';
import React from 'react';

import {
  defaultViewColumns,
  descriptionStyles,
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
} from '@/components';
import { AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import { OperationDownloadReport } from '@/features/reports/components';
import { WithdrawalReconciliationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';

import SettlementReconciliationBanner from '../SettlementReconciliationBanner';
import SettlementReconciliationStatusView from '../SettlementReconciliationStatusView';

import { SettlementReconciliationBlockBalanceView } from './components';

import type { SettlementReconciliationViewProps } from './types';

const SettlementReconciliationView: React.FC<SettlementReconciliationViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
  SettlementLink,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={
      <SettlementReconciliationStatusView
        value={data.status}
        nextAttempt={data.nextAttempt}
        data-test={dataTest && `${dataTest}-status`}
      />
    }
    column={columns}
  >
    {[
      WithdrawalReconciliationStatusAPIModel.Failed,
      WithdrawalReconciliationStatusAPIModel.Retrying,
      WithdrawalReconciliationStatusAPIModel.Unchecked,
    ].includes(data.status) ? (
      <Descriptions.Item>
        <SettlementReconciliationBanner
          data={data.status}
          settlementId={data.id}
          companyId={data.companyId}
          mode="alert"
        />
      </Descriptions.Item>
    ) : null}
    {SettlementLink && (
      <Descriptions.Item
        label={
          <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_VIEW_SETTLEMENT_LABEL} />
        }
      >
        <SettlementLink data-test={dataTest && `${dataTest}-settlement`} value={data.id} />
      </Descriptions.Item>
    )}
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureSettlementReconciliations.LABELS_ROW_TITLE_EXPECTED_AMOUNT} />}
      styles={descriptionItemStyles}
    >
      <AssetAmount
        value={data.expectedAmount.value}
        assetId={data.expectedAmount.asset}
        withCurrency
        currencyMode="long"
        data-test={dataTest && `${dataTest}-expectedAmount`}
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={
        <Space>
          <FormattedMessage id={I18nFeatureSettlementReconciliations.LABELS_ROW_TITLE_CALCULATED_AMOUNT} />
          <Tooltip
            title={
              <FormattedMessage
                id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_VIEW_CALCULATED_AMOUNT_HELP}
              />
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
      styles={descriptionItemStyles}
    >
      <AssetAmount
        data-test={dataTest && `${dataTest}-calculatedAmount`}
        value={data.calculatedAmount.value}
        assetId={data.calculatedAmount.asset}
        withCurrency
        currencyMode="long"
      />
    </Descriptions.Item>
    {data.wallet && data.previousBlockNum ? (
      <Descriptions.Item
        label={
          <FormattedMessage
            id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_VIEW_BEFORE_SETTLEMENT_BALANCE_TITLE}
          />
        }
      >
        <SettlementReconciliationBlockBalanceView
          data-test={dataTest && `${dataTest}-previousBalance`}
          address={data.wallet}
          assetId={data.calculatedAmount.asset}
          blockNum={data.previousBlockNum}
        />
      </Descriptions.Item>
    ) : null}
    {data.wallet ? (
      <Descriptions.Item
        label={
          <FormattedMessage
            id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_VIEW_AFTER_SETTLEMENT_BALANCE_TITLE}
          />
        }
      >
        <SettlementReconciliationBlockBalanceView
          data-test={dataTest && `${dataTest}-remainingBalance`}
          address={data.wallet}
          assetId={data.calculatedAmount.asset}
          blockNum={data.blockNum}
        />
      </Descriptions.Item>
    ) : null}
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureSettlementReconciliations.LABELS_ROW_TITLE_TX} />}
      styles={descriptionItemStyles}
    >
      <TransactionLink data-test={dataTest && `${dataTest}-tx`} assetId={data.expectedAmount.asset} tx={data.hash} />
    </Descriptions.Item>
    {data.status !== WithdrawalReconciliationStatusAPIModel.Succeeded && data.nextAttempt && (
      <Descriptions.Item
        label={
          <Space>
            <FormattedMessage id={I18nFeatureSettlementReconciliations.LABELS_ROW_TITLE_NEXT_ATTEMPT} />
            <Tooltip
              title={
                <FormattedMessage
                  id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_VIEW_NEXT_ATTEMPT_HELP}
                />
              }
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
      >
        <ReadonlyDateTime value={data.nextAttempt} data-test={dataTest && `${dataTest}-nextAttempt`} />
      </Descriptions.Item>
    )}
    {data.status !== WithdrawalReconciliationStatusAPIModel.Succeeded && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementReconciliations.LABELS_ROW_TITLE_ATTEMPTS} />}
      >
        <ReadonlyComponent value={data.attempts} data-test={dataTest && `${dataTest}-attempts`} />
      </Descriptions.Item>
    )}
    {data.status !== WithdrawalReconciliationStatusAPIModel.Succeeded && data.errorCode && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementReconciliations.LABELS_ROW_TITLE_ERROR} />}
        span={2}
      >
        <ReadonlyComponent value={data.errorCode} data-test={dataTest && `${dataTest}-error`} />
      </Descriptions.Item>
    )}
    {data.reportId && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSettlementReconciliations.LABELS_ROW_TITLE_REPORT} />}
        span={2}
      >
        <OperationDownloadReport data-test={dataTest && `${dataTest}-report`} reportId={data.reportId} mode="link" />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const SettlementReconciliationViewMemo = React.memo(SettlementReconciliationView);

export default SettlementReconciliationViewMemo;
