/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  BlockchainNetworkTypeAPIModel,
  ChurnRateAPIModel,
  MonthlyGrowthRateStatisticsAPIModel,
  MonthlyRevenueStatisticsAPIModel,
  SubscriptionBriefStatsAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelToJSON,
    ChurnRateAPIModelFromJSON,
    ChurnRateAPIModelToJSON,
    MonthlyGrowthRateStatisticsAPIModelFromJSON,
    MonthlyGrowthRateStatisticsAPIModelToJSON,
    MonthlyRevenueStatisticsAPIModelFromJSON,
    MonthlyRevenueStatisticsAPIModelToJSON,
    SubscriptionBriefStatsAPIModelFromJSON,
    SubscriptionBriefStatsAPIModelToJSON,
} from '../models/index';

export interface GetBriefStatsRequest {
    network?: BlockchainNetworkTypeAPIModel;
}

export interface GetChurnRateRequest {
    network?: BlockchainNetworkTypeAPIModel;
}

export interface GetMonthlyGrowthRateRequest {
    network?: BlockchainNetworkTypeAPIModel;
}

export interface GetMonthlyRevenueRequest {
    network?: BlockchainNetworkTypeAPIModel;
}

/**
 * SubscriptionStatisticsApi - interface
 * 
 * @export
 * @interface SubscriptionStatisticsApiInterface
 */
export interface SubscriptionStatisticsApiInterface {
    /**
     * Returns subscription brief statistics
     * @param {BlockchainNetworkTypeAPIModel} [network] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionStatisticsApiInterface
     */
    getBriefStatsRaw(requestParameters: GetBriefStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionBriefStatsAPIModel>>;

    /**
     * Returns subscription brief statistics
     */
    getBriefStats(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionBriefStatsAPIModel>;

    /**
     * Returns subscriptions Churn Rate
     * @param {BlockchainNetworkTypeAPIModel} [network] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionStatisticsApiInterface
     */
    getChurnRateRaw(requestParameters: GetChurnRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChurnRateAPIModel>>;

    /**
     * Returns subscriptions Churn Rate
     */
    getChurnRate(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChurnRateAPIModel>;

    /**
     * Returns subscription monthly growth rate statistics
     * @param {BlockchainNetworkTypeAPIModel} [network] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionStatisticsApiInterface
     */
    getMonthlyGrowthRateRaw(requestParameters: GetMonthlyGrowthRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonthlyGrowthRateStatisticsAPIModel>>;

    /**
     * Returns subscription monthly growth rate statistics
     */
    getMonthlyGrowthRate(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonthlyGrowthRateStatisticsAPIModel>;

    /**
     * Returns subscription monthly revenue statistics
     * @param {BlockchainNetworkTypeAPIModel} [network] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionStatisticsApiInterface
     */
    getMonthlyRevenueRaw(requestParameters: GetMonthlyRevenueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonthlyRevenueStatisticsAPIModel>>;

    /**
     * Returns subscription monthly revenue statistics
     */
    getMonthlyRevenue(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonthlyRevenueStatisticsAPIModel>;

}

/**
 * 
 */
export class SubscriptionStatisticsApi extends runtime.BaseAPI implements SubscriptionStatisticsApiInterface {

    /**
     * Returns subscription brief statistics
     */
    async getBriefStatsRaw(requestParameters: GetBriefStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionBriefStatsAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters['network'] != null) {
            queryParameters['network'] = requestParameters['network'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionBriefStatsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription brief statistics
     */
    async getBriefStats(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionBriefStatsAPIModel> {
        const response = await this.getBriefStatsRaw({ network: network }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscriptions Churn Rate
     */
    async getChurnRateRaw(requestParameters: GetChurnRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChurnRateAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters['network'] != null) {
            queryParameters['network'] = requestParameters['network'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-statistics/churn-rate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChurnRateAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscriptions Churn Rate
     */
    async getChurnRate(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChurnRateAPIModel> {
        const response = await this.getChurnRateRaw({ network: network }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscription monthly growth rate statistics
     */
    async getMonthlyGrowthRateRaw(requestParameters: GetMonthlyGrowthRateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonthlyGrowthRateStatisticsAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters['network'] != null) {
            queryParameters['network'] = requestParameters['network'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscriptions-statistics/monthly-growth-rate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonthlyGrowthRateStatisticsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription monthly growth rate statistics
     */
    async getMonthlyGrowthRate(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonthlyGrowthRateStatisticsAPIModel> {
        const response = await this.getMonthlyGrowthRateRaw({ network: network }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscription monthly revenue statistics
     */
    async getMonthlyRevenueRaw(requestParameters: GetMonthlyRevenueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MonthlyRevenueStatisticsAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters['network'] != null) {
            queryParameters['network'] = requestParameters['network'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscription-statistics/monthly-revenue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MonthlyRevenueStatisticsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription monthly revenue statistics
     */
    async getMonthlyRevenue(network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MonthlyRevenueStatisticsAPIModel> {
        const response = await this.getMonthlyRevenueRaw({ network: network }, initOverrides);
        return await response.value();
    }

}
