import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import {
  storeCollectableTransaction,
  storeCollectEntityProcessTransaction,
  storeMultipleCollectableTransaction,
} from '@/features/collectable/actions';
import {
  makeSelectCollectableTransaction,
  makeSelectCollectEntityProcessTransaction,
} from '@/features/collectable/selectors';
import { extractEntityTransactionId } from '@/features/collectable/utils';
import { markBalancesDirty } from '@/features/statistics/actions';
import type { PushAddressSortByAPIModel, PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { storedDataLoaded, withApiRequest } from '@/infrastructure/model';
import { withAPICall } from '@/infrastructure/model/api';
import { createNestedListActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { stringFormat } from '@/infrastructure/utils/string';

import {
  queryRechargeAddress,
  queryRechargeAddressDeployTransaction,
  queryRechargeAddresses,
  queryRechargeTransaction,
  queryRechargeTransactions,
  requestCreateRechargeAddress,
} from './api';
import {
  makeSelectRechargeAddress,
  makeSelectRechargeAddressListParametersWithNetwork,
  makeSelectRechargeAddressListData,
  makeSelectRechargeTransactionForAddressListData,
  makeSelectRechargeTransactionForAddressListParameters,
  makeSelectRechargeTransactionsListData,
  makeSelectRechargeTransactionsListParametersWithNetwork,
} from './selectors';
import { NAMESPACE } from './types';
import { extractRechargeAddressId } from './utils';

import type {
  RechargeAddress,
  RechargeAddressFilterPredicate,
  RechargeTransactionFilterPredicate,
  NewRechargeAddress,
  RechargeTransaction,
} from './types';

export const {
  storeRechargeTransactionListData,
  storeRechargeTransactionListParameters,
  markRechargeTransactionListDirty,
} = createNormalizedListActions<
  RechargeTransaction,
  'RechargeTransaction',
  RechargeTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>(NAMESPACE, 'RechargeTransaction');

const rechargeTransactionFetchLimit = pLimit(1);
export const fetchRechargeTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchRechargeTransaction`,
  async ({ force, txId }: { force?: boolean; txId: string }, { dispatch, getState, signal }) =>
    rechargeTransactionFetchLimit(async () => {
      const saved = makeSelectCollectableTransaction(txId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryRechargeTransaction, 'unable to fetch transaction')(txId, { signal });
      dispatch(storeCollectableTransaction({ id: txId, data }));

      return makeSelectCollectableTransaction(txId)(getState());
    }),
  { idGenerator: ({ txId }) => txId },
);

const rechargeTransactionsFetchLimit = pLimit(1);
export const fetchRechargeTransactions = createAppAsyncThunk(
  `${NAMESPACE}/fetchRechargeTransactions`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    rechargeTransactionsFetchLimit(async () => {
      const saved = makeSelectRechargeTransactionsListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryRechargeTransactions, 'unable to fetch transactions')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectRechargeTransactionsListParametersWithNetwork()(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(storeRechargeTransactionListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));
      if (data.data) {
        dispatch(storeMultipleCollectableTransaction(sliceToMultipleEntities(data.data, extractEntityTransactionId)));
      }

      return makeSelectRechargeTransactionsListData()(getState());
    }),
);

export const {
  storeRechargeTransactionForAddressListData,
  storeRechargeTransactionForAddressListParameters,
  markRechargeTransactionForAddressListDirty,
} = createNestedListActions<
  RechargeTransaction,
  'RechargeTransactionForAddress',
  RechargeTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>(NAMESPACE, 'RechargeTransactionForAddress');

const rechargeTransactionForAddressFetchLimit = pLimit(1);
export const fetchRechargeTransactionForAddress = createAppAsyncThunk(
  `${NAMESPACE}/fetchRechargeTransactionForAddress`,
  async ({ force, addressId }: { force?: boolean; addressId: string }, { dispatch, getState, signal }) =>
    rechargeTransactionForAddressFetchLimit(async () => {
      const saved = makeSelectRechargeTransactionForAddressListData(addressId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryRechargeTransactions, 'unable to fetch transactions')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectRechargeTransactionForAddressListParameters(addressId)(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(
        storeRechargeTransactionForAddressListData({
          parentId: addressId,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(storeMultipleCollectableTransaction(sliceToMultipleEntities(data.data, extractEntityTransactionId)));
      }

      return makeSelectRechargeTransactionForAddressListData(addressId)(getState());
    }),
  { idGenerator: ({ addressId }) => addressId },
);

export const {
  storeRechargeAddress,
  storeMultipleRechargeAddress,
  storeRechargeAddressListData,
  storeRechargeAddressListParameters,
  markMultipleRechargeAddressDirty,
  markRechargeAddressDirty,
  markRechargeAddressListDirty,
} = createNormalizedListActions<
  RechargeAddress,
  'RechargeAddress',
  RechargeAddressFilterPredicate,
  PushAddressSortByAPIModel
>(NAMESPACE, 'RechargeAddress');

const rechargeAddressFetchLimit = pLimit(1);
export const fetchRechargeAddress = createAppAsyncThunk(
  `${NAMESPACE}/fetchRechargeAddress`,
  async ({ force, addressId }: { force?: boolean; addressId: string }, { dispatch, getState, signal }) =>
    rechargeAddressFetchLimit(async () => {
      const saved = makeSelectRechargeAddress(addressId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryRechargeAddress, 'unable to fetch recharge')(addressId, { signal });
      dispatch(storeRechargeAddress({ id: addressId, data }));

      return makeSelectRechargeAddress(addressId)(getState());
    }),
  { idGenerator: ({ addressId }) => addressId },
);

const rechargeAddressesFetchLimit = pLimit(1);
export const fetchRechargeAddresses = createAppAsyncThunk(
  `${NAMESPACE}/fetchRechargeAddresses`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    rechargeAddressesFetchLimit(async () => {
      const saved = makeSelectRechargeAddressListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryRechargeAddresses, 'unable to fetch recharges')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectRechargeAddressListParametersWithNetwork()(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(storeRechargeAddressListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));
      if (data.data) {
        dispatch(storeMultipleRechargeAddress(sliceToMultipleEntities(data.data, extractRechargeAddressId)));
      }

      return makeSelectRechargeAddressListData()(getState());
    }),
);

export const createRechargeAddress = createAppAsyncThunk(
  `${NAMESPACE}/createRechargeAddress`,
  async (rechargeAddress: NewRechargeAddress, { dispatch, signal }) => {
    const data = await withApiRequest(requestCreateRechargeAddress)(rechargeAddress, { signal });
    if (data.data) {
      dispatch(storeRechargeAddress({ id: data.data.id, data }));
      dispatch(markRechargeAddressListDirty());
    }
    return data;
  },
);

const rechargeAddressDeployTransactionFetchLimit = pLimit(1);
export const fetchRechargeAddressDeployTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchRechargeAddressDeployTransaction`,
  async ({ force, addressId }: { force?: boolean; addressId: string }, { dispatch, getState, signal }) =>
    rechargeAddressDeployTransactionFetchLimit(async () => {
      const saved = makeSelectCollectEntityProcessTransaction(addressId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryRechargeAddressDeployTransaction, 'unable to fetch deploy transaction')(
        addressId,
        { signal },
      );
      dispatch(storeCollectEntityProcessTransaction({ id: addressId, data }));

      return makeSelectCollectEntityProcessTransaction(addressId)(getState());
    }),
);

export const generateAddressRechargeURL = createAppAsyncThunk(
  `${NAMESPACE}/generateRechargeURL`,
  (rechargeId: string) => storedDataLoaded(stringFormat(window.env.NCPS_RECHARGES_WIDGET_URL_TEMPLATE, { rechargeId })),
);

export const markRechargeAddressesDependentDataDirty = createAppAsyncThunk(
  `${NAMESPACE}/markRechargeAddressesDependentDataDirty`,
  (rechargeIds: string[], { dispatch }) => {
    rechargeIds.forEach((rechargeId) => dispatch(markRechargeTransactionForAddressListDirty(rechargeId)));
    dispatch(markBalancesDirty());
    dispatch(markRechargeAddressListDirty());
    dispatch(markRechargeTransactionListDirty());
  },
);
