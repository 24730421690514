import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlementIntents } from '@/features/settlements/actions';
import {
  makeSelectSettlementIntentListParameters,
  makeSelectSettlementIntentListData,
} from '@/features/settlements/selectors';
import type { SettlementIntent, SettlementIntentFilterPredicate } from '@/features/settlements/types';
import type { SettlementIntentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseSettlementIntentList = UseAppListData<
  SettlementIntent,
  SettlementIntentFilterPredicate,
  SettlementIntentSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<SettlementIntent>, Parameters<typeof fetchSettlementIntents>[0]> =>
  fetchSettlementIntents({ force });

const dataSelector = makeSelectSettlementIntentListData();
const dataFetchingSelector = makeSelectPending(fetchSettlementIntents);
const parametersSelector = makeSelectSettlementIntentListParameters();

export default function useSettlementIntentList(): UseSettlementIntentList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
