
import { BrandingLang } from '@/features/branding/types';

import type { BrandingLangIconProps } from './types';
import type React from 'react';

const icons: Record<BrandingLang, string> = {
  [BrandingLang.en]: '🇬🇧',
  [BrandingLang.es]: '🇪🇸',
  [BrandingLang.fr]: '🇫🇷',
  [BrandingLang.ko]: '🇰🇷',
  [BrandingLang.it]: '🇮🇹',
  [BrandingLang.ru]: '🇷🇺',
};

const BrandingLangIcon: React.FC<BrandingLangIconProps> = ({ value }) => <>{icons[value]}</>;

export default BrandingLangIcon;
