import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { OperationCollectNow, CollectIcon } from '@/features/collectable/components';
import { useCollectThreshold } from '@/features/collectable/hooks';
import { withCompanyPermissionsGuard } from '@/features/company/hocs';
import { useRechargeAddress } from '@/features/recharges/hooks';
import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nCommon, I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { noop } from '@/infrastructure/utils/functions';

import type { OperationCollectRechargeAddressNowProps } from './types';

const OperationCollectRechargeAddressNow: React.FC<OperationCollectRechargeAddressNowProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  rechargeId,
}) => {
  const { data: recharge } = useRechargeAddress(rechargeId);
  const { data: threshold } = useCollectThreshold(BlockchainNetworkTypeAPIModel.TestNet);
  const asset = recharge.data?.asset;

  const disabledMessage = useMemo(() => {
    if (!asset) return <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />;
    const assetThreshold = threshold.data?.find((th) => th.asset === asset)?.value;
    if (assetThreshold && recharge.data?.balance.value.lt(assetThreshold))
      return (
        <FormattedMessage
          id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_DISABLED_EMPTY_BALANCE}
          values={{ ln: undefined }}
        />
      );
  }, [asset, recharge.data, threshold.data]);

  return asset && !disabledMessage ? (
    <OperationCollectNow assetId={asset} data-test={dataTest} mode={mode} />
  ) : (
    <Operation
      title={<FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_COLLECT_NOW_TITLE} />}
      disabled
      disabledMessage={disabledMessage}
      icon={<CollectIcon />}
      onClick={noop}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationCollectRechargeAddressNowMemo = React.memo(
  withCompanyPermissionsGuard('collectManagement')(OperationCollectRechargeAddressNow),
);

export default OperationCollectRechargeAddressNowMemo;
