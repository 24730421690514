import { useAppNestedListData } from '@/app/hooks';
import { noIdNestedListFetchResultFn } from '@/app/hooks/useAppNestedListData';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlementIntentTransactionsForIntent } from '@/features/settlements/actions';
import {
  makeSelectSettlementIntentTransactionsForIntentListData,
  makeSelectSettlementIntentTransactionsForIntentListParameters,
} from '@/features/settlements/selectors';
import type { SettlementIntentTransaction } from '@/features/settlements/types';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

const fetchFactory =
  (intent: string) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<
    LoadingListDataState<SettlementIntentTransaction>,
    Parameters<typeof fetchSettlementIntentTransactionsForIntent>[0]
  > =>
    fetchSettlementIntentTransactionsForIntent({ intent, force });

const pendingSelectorCreator = (intent: string) =>
  makeSelectPending(fetchSettlementIntentTransactionsForIntent, intent);

export default function useSettlementIntentTransactionForIntentList(intent: string | undefined) {
  return useAppNestedListData(
    fetchFactory,
    makeSelectSettlementIntentTransactionsForIntentListParameters,
    pendingSelectorCreator,
    makeSelectSettlementIntentTransactionsForIntentListData,
    noIdNestedListFetchResultFn(),
    intent,
  );
}
