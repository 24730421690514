import { useCallback } from 'react';

import { useAppDispatch, useAppLoadableData, useAppSelector } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDeployingQRDelegateContracts } from '@/features/company-settings/actions';
import {
  makeSelectDeployingQRDelegateContracts,
  makeSelectDeployingQRDelegateContractsRefreshableAfter,
} from '@/features/company-settings/selectors';
import type { QRDelegateContract } from '@/features/company-settings/types';
import { makeSelectPending } from '@/features/global/selectors';
import { useRunOnTimer } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<QRDelegateContract[]>,
  Parameters<typeof fetchDeployingQRDelegateContracts>[0]
> => fetchDeployingQRDelegateContracts({ force });

const selectFetching = makeSelectPending(fetchDeployingQRDelegateContracts);
const selectData = makeSelectDeployingQRDelegateContracts();
const selectNextDataUpdateAt = makeSelectDeployingQRDelegateContractsRefreshableAfter();

export default function useDeployingQRDelegateContracts() {
  const { withExtractDataDispatch } = useAppDispatch();
  const nextUpdateAt = useAppSelector(selectNextDataUpdateAt);
  const data = useAppLoadableData(fetchFactory, selectData, selectFetching);
  const refresh = useCallback(() => {
    if (data.data.isDirty || data.data.data) {
      withSuppressPromise(withExtractDataDispatch(fetchDeployingQRDelegateContracts))({});
    }
  }, [data.data.data, data.data.isDirty, withExtractDataDispatch]);
  useRunOnTimer(refresh, nextUpdateAt, false);
  return useAppLoadableData(fetchFactory, selectData, selectFetching);
}
