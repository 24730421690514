import React from 'react';

import { OperationRefresh } from '@/components';
import { useGeneratingReports } from '@/features/reports/hooks';

import type { OperationRefreshGeneratingReportsProps } from './types';

const OperationRefreshGeneratingReports: React.FC<OperationRefreshGeneratingReportsProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useGeneratingReports();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshGeneratingReportsMemo = React.memo(OperationRefreshGeneratingReports);

export default OperationRefreshGeneratingReportsMemo;
