import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlementIntentTransaction } from '@/features/settlements/actions';
import { makeSelectSettlementIntentTransaction } from '@/features/settlements/selectors';
import type { SettlementIntentTransaction } from '@/features/settlements/types';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseSettlementIntentTransaction = UseAppSingleData<SettlementIntentTransaction>;

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<SettlementIntentTransaction>,
  Parameters<typeof fetchSettlementIntentTransaction>[0]
> => fetchSettlementIntentTransaction({ id, force });

export default function useSettlementIntentTransaction(id: string | undefined): UseSettlementIntentTransaction {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSettlementIntentTransaction, id), [id]);
  return useAppSingleData(
    fetchFactory,
    makeSelectSettlementIntentTransaction,
    noSingleDataIdFn(),
    id,
    dataFetchingSelector,
  );
}
