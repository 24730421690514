/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDefaultAssetAPIModel
 */
export interface UpdateDefaultAssetAPIModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultAssetAPIModel
     */
    asset: string;
}

/**
 * Check if a given object implements the UpdateDefaultAssetAPIModel interface.
 */
export function instanceOfUpdateDefaultAssetAPIModel(value: object): boolean {
    if (!('asset' in value)) return false;
    return true;
}

export function UpdateDefaultAssetAPIModelFromJSON(json: any): UpdateDefaultAssetAPIModel {
    return UpdateDefaultAssetAPIModelFromJSONTyped(json, false);
}

export function UpdateDefaultAssetAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDefaultAssetAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'asset': json['asset'],
    };
}

export function UpdateDefaultAssetAPIModelToJSON(value?: UpdateDefaultAssetAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'asset': value['asset'],
    };
}

