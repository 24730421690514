import { Form } from 'antd';
import React, { useCallback, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
  PageLoading,
} from '@/components';
import type { NewRechargeAddress } from '@/features/recharges/types';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { type Func, nameof } from '@/infrastructure/utils/ts';

import { RechargeAddressItem, RechargeCIdItem } from './components';
import { useInitialValues } from './hooks';

import type { CreateRechargeAddressFormData, CreateRechargeAddressFormProps } from './types';
import type { NamePath } from 'rc-field-form/lib/interface';

const requiredFields: NamePath[] = [
  nameof<CreateRechargeAddressFormData>('asset'),
  nameof<CreateRechargeAddressFormData>('cid'),
];

const fieldsToValidate: NamePath[] = [];

const formMessages = {
  submit: (
    <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_SUBMIT_BUTTON} tagName="span" />
  ),
};

const CreateRechargeAddressForm: React.FC<CreateRechargeAddressFormProps> = ({
  'data-test': dataTest,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const initialValues = useInitialValues();
  const previousValues = useRef(initialValues);
  const { form, withResetForm } = useForm<CreateRechargeAddressFormData>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);
  const doSubmit = useMemo(
    () =>
      // TODO: maybe check each field somehow instead of cast? the check complexity looks like overengineering so just rely on the FormCompletenessItem
      withSubmitting(async (values: CreateRechargeAddressFormData) => {
        const result = await onSubmit(values as NewRechargeAddress);
        setTimeout(doReset, 0);
        return result;
      }),
    [doReset, onSubmit, withSubmitting],
  );
  const [doSubmitForm, setDoSubmitForm] = useStateMountSafe<Func | undefined>(undefined);

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const isValueNonInitial = !initialValues || !isEqual(initialValues, value);
      const isValueUpdated = !previousValues.current || !isEqual(previousValues.current, value);
      const isCompleteAndUpdated = complete && isValueNonInitial;

      if (isFormComplete !== isCompleteAndUpdated) {
        setFormComplete(isCompleteAndUpdated);
      }
      if (isValueUpdated) {
        previousValues.current = value;
      }
      if (isFormComplete !== complete || isValueUpdated) {
        const submitFn = complete
          ? withSubmitting(
              withVoidOrThrow(
                wrap(
                  () => doSubmit(value),
                  () => onSubmitting?.(true),
                  () => onSubmitting?.(false),
                ),
              ),
            )
          : undefined;
        submitCallback?.(submitFn);
        setDoSubmitForm(() => submitFn);
      }
    },
    [
      form,
      initialValues,
      isFormComplete,
      setFormComplete,
      withSubmitting,
      submitCallback,
      setDoSubmitForm,
      doSubmit,
      onSubmitting,
    ],
  );
  const errorText =
    error === 'RechargeCustomerAssetDuplicate' ? (
      <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_ERRORS_DUPLICATE_CID} />
    ) : (
      error
    );

  if (!initialValues) {
    return <PageLoading type="CreateRechargeAddressForm" />;
  }

  return (
    <Form<CreateRechargeAddressFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      {...layout}
      initialValues={initialValues}
      autoComplete="off"
      form={form}
      onFinish={doSubmitForm}
      onReset={doCancel}
    >
      {errorText && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorText} />}
      <RechargeAddressItem<CreateRechargeAddressFormData> data-test={dataTest && `${dataTest}-asset`} name="asset" />
      <RechargeCIdItem<CreateRechargeAddressFormData> data-test={dataTest && `${dataTest}-cid`} name="cid" required />

      <FormCompletenessItem<CreateRechargeAddressFormData>
        requiredFields={requiredFields}
        fields={fieldsToValidate}
        onChange={updateComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { display: 'none' } : undefined}
        messages={formMessages}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const CreateRechargeAddressFormMemo = React.memo(CreateRechargeAddressForm);

export default CreateRechargeAddressFormMemo;
