import { QuestionCircleOutlined } from '@ant-design/icons';
import { Descriptions, Space, Tag, Tooltip, Typography } from 'antd';
import React, { useEffect } from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import PaymentTransactionKindView from '@/features/payment-transactions/components/PaymentTransactionKindView';
import { PaymentTransactionKind } from '@/features/payment-transactions/types';
import { usePaymentActions } from '@/features/payments/hooks';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import CollectTasksLink from '@/pages/collectable/tasks/components/CollectTasksLink';

import PaymentStatusView from '../PaymentStatusView';

import type { PaymentViewProps } from './types';

const { Link } = Typography;

const PaymentViewRaw: React.FC<PaymentViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
}) => {
  const { generatePaymentURL } = usePaymentActions(data.id);
  const [paymentLink, setPaymentLink] = useStateMountSafe<string>();
  useEffect(() => {
    void (async () => {
      if (generatePaymentURL.available && !paymentLink) {
        const link = await generatePaymentURL.act();
        setPaymentLink(link);
      }
    })();
  }, [generatePaymentURL, paymentLink, setPaymentLink]);
  return (
    <Descriptions
      style={style}
      className={className}
      styles={descriptionStyles}
      title={
        <PaymentStatusView
          value={data.status}
          data-test={dataTest && `${dataTest}-status`}
          details={data.statusDetails}
        />
      }
      column={columns}
    >
      {paymentLink && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_PAYMENT_LINK} />}>
          <Link
            href={paymentLink}
            copyable={{ text: paymentLink }}
            target="_blank"
            data-test={dataTest && `${dataTest}-link`}
            data-ym-target="payment-widget-link"
          >
            <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_LINK_TITLE} />
          </Link>
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_CREATED_AT} />}>
        <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_EXPIRES_AT} />}>
        <ReadonlyDateTime value={data.expiresAt} data-test={dataTest && `${dataTest}-expiresAt`} />
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_AMOUNT} />}
        styles={descriptionItemStyles}
      >
        <AssetAmount
          value={data.amount.value}
          assetId={data.amount.asset}
          withCurrency
          currencyMode="long"
          data-test={dataTest && `${dataTest}-amount`}
        />
      </Descriptions.Item>
      {data.paidAt && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_PAID_AT} />}>
          <ReadonlyDateTime value={data.paidAt} data-test={dataTest && `${dataTest}-paidAt`} />
        </Descriptions.Item>
      )}
      {data.auxAmounts.length ? (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_AUX_AMOUNTS} />}>
          {data.auxAmounts.map(({ amount, forwarderAddress }, idx) => [
            <React.Fragment key={amount.asset}>
              <AssetAmount
                value={amount.value}
                assetId={amount.asset}
                withCurrency
                withBlockchainMark
                data-test={dataTest && `${dataTest}-auxAmount-${amount.asset}`}
              />
              {forwarderAddress && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id={I18nFeaturePayments.COMPONENTS_PAYMENT_VIEW_AUX_AMOUNTS_FORWARDER_TOOLTIP}
                      values={{
                        address: (
                          <AddressLink
                            assetId={amount.asset}
                            address={forwarderAddress}
                            data-test={dataTest && `${dataTest}-auxAmount-${amount.asset}forwarderAddress`}
                          />
                        ),
                        link: (text: React.ReactNode) => <CollectTasksLink title={text} mode="text" />,
                      }}
                    />
                  }
                >
                  <Tag color="default">
                    <PaymentTransactionKindView value={PaymentTransactionKind.FORWARDER} />
                  </Tag>
                </Tooltip>
              )}
              {idx < data.auxAmounts.length - 1 ? <>,&nbsp;</> : ''}
            </React.Fragment>,
          ])}
        </Descriptions.Item>
      ) : null}
      {data.errorCode ? (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_ERROR} />} span={2}>
          <ReadonlyComponent value={data.errorCode} data-test={dataTest && `${dataTest}-error`} />
        </Descriptions.Item>
      ) : null}
      {data.description ? (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_DESCRIPTION} />}>
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 3, expandable: true }}
            className="ym-hide-content"
            data-test={dataTest && `${dataTest}-description`}
          >
            {data.description}
          </Typography.Paragraph>
        </Descriptions.Item>
      ) : null}
      {data.metadata && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_METADATA} />}>
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 3, expandable: true }}
            className="ym-hide-content"
            data-test={dataTest && `${dataTest}-metadata`}
          >
            {typeof data.metadata === 'string' ? data.metadata : JSON.stringify(data.metadata)}
          </Typography.Paragraph>
        </Descriptions.Item>
      )}
      {!!data.forwarderAddress && (
        <Descriptions.Item
          label={
            <Space>
              <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_FORWARDER_ADDRESS} />
              <Tooltip
                title={
                  <FormattedMessage
                    id={I18nFeaturePayments.COMPONENTS_PAYMENT_VIEW_FORWARDER_PAYMENT_HELP}
                    values={{
                      link: (text: React.ReactNode) => <CollectTasksLink title={text} mode="text" />,
                    }}
                  />
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          }
        >
          <AddressLink
            assetId={data.amount.asset}
            address={data.forwarderAddress}
            data-test={dataTest && `${dataTest}-forwarderAddress`}
          />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const PaymentView = React.memo(PaymentViewRaw);

export default PaymentView;
