import React, { useCallback } from 'react';
import isEqual from 'react-fast-compare';

import { useAppDispatch } from '@/app/hooks';
import { OperationRefresh } from '@/components';
import { markSettlementIntentsDependentDataDirty } from '@/features/settlements/actions';
import { useSettlementIntent } from '@/features/settlements/hooks';
import type { SettlementIntent } from '@/features/settlements/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { suppressPromise } from '@/infrastructure/utils/functions';

import type { OperationRefreshSettlementIntentProps } from './types';

const OperationRefreshSettlementIntent: React.FC<OperationRefreshSettlementIntentProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  intentId,
}) => {
  const { data, forceRefresh } = useSettlementIntent(intentId);
  const dispatch = useAppDispatch();
  const refreshDependantData = useCallback(
    (newData: LoadingStateWithDirty<SettlementIntent>) => {
      if (
        newData.data
        && (newData.data.status !== data.data?.status || !isEqual(newData.data.settled, data.data.settled))
      ) {
        suppressPromise(dispatch(markSettlementIntentsDependentDataDirty([newData.data.id])));
      }
    },
    [data.data?.status, data.data?.settled, dispatch],
  );
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} onSuccess={refreshDependantData} />;
};

const OperationRefreshSettlementIntentMemo = React.memo(OperationRefreshSettlementIntent);

export default OperationRefreshSettlementIntentMemo;
