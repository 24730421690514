import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import type { CollectableAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import {
  CollectTaskStatusAPIModel,
  CollectableAddressBalanceStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import {
  createNestedNormalizedListSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { notEmpty } from '@/infrastructure/utils/ts';

import { defaultCollectTaskForAddressListState, NAMESPACE } from './types';
import { collectableTaskLinksToId, createCollectableBalanceKey } from './utils';

import type { CollectTaskSummary, CollectTaskForAddressListState } from './types';

export const { makeSelectCollectableTransaction } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].transactions,
  'CollectableTransaction' as const,
  undefined,
);

export const { makeSelectCollectEntityProcessTransaction } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].gwtTransactions,
  'CollectEntityProcessTransaction' as const,
  undefined,
);

export const makeSelectCollectThreshold = () => (state: AppRootState) => state[NAMESPACE].threshold;

export const { makeSelectCollectTask, makeSelectMultipleCollectTask } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].tasks.entities,
  'CollectTask' as const,
  undefined,
);

export const {
  makeSelectCollectTaskSummaryListData,
  makeSelectCollectTaskSummaryListParameters,
  makeSelectDirtyCollectTaskSummaryIds,
  makeSelectMultipleCollectTaskSummary,
} = createNormalizedListSelectors(
  (state: AppRootState) => ({ ...state[NAMESPACE].tasks.list, columnState: state[NAMESPACE].tasks.columnState }),
  (state: AppRootState) => state[NAMESPACE].tasks.summaries,
  'CollectTaskSummary' as const,
  undefined,
);

export const makeSelectCollectTaskSummaryListParametersWithNetwork = () =>
  createSelector(
    makeSelectCollectTaskSummaryListParameters(),
    makeSelectSelectedNetwork(),
    (
      { filter, ...parameters },
      networkEq,
    ): ReturnType<ReturnType<typeof makeSelectCollectTaskSummaryListParameters>> => ({
      ...parameters,
      filter: { ...filter, networkEq },
    }),
  );

export const makeSelectPendingCollectTasksRefreshableAfter = () => (state: AppRootState) =>
  state[NAMESPACE].tasks.pendingRefreshableAfter;
export const makeSelectPendingCollectTasksInitialized = () => (state: AppRootState) =>
  state[NAMESPACE].tasks.initialized;
export const makeSelectPendingCollectTaskSummaries = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].tasks.summaries,
    makeSelectPendingCollectTasksRefreshableAfter(),
    (summaries, refreshableAfter): LoadingStateWithDirty<CollectTaskSummary[]> => {
      const data = Object.values(summaries)
        .map((task) => task?.data)
        .filter(notEmpty)
        .filter(
          (task) =>
            [CollectTaskStatusAPIModel.Pending, CollectTaskStatusAPIModel.Awaiting].includes(task.status)
            && dayjs().isAfter(task.processAt),
        );
      const isDirty = dayjs(refreshableAfter).isBefore();
      return { data, isDirty };
    },
  );

export const makeSelectCollectSchedule = () => (state: AppRootState) => state[NAMESPACE].schedule;

export const {
  makeSelectCollectTasksForAddressesListData,
  makeSelectCollectTasksForAddressesListParameters: baseMakeSelectCollectTasksForAddressesListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, addressesId: CollectableAddressLinkAPIModel[] | undefined): CollectTaskForAddressListState =>
    (addressesId && state[NAMESPACE].tasks.byAddresses[collectableTaskLinksToId(addressesId)])
    ?? defaultCollectTaskForAddressListState,
  (state) => state[NAMESPACE].tasks.columnState,
  (state) => state[NAMESPACE].tasks.summaries,
  'CollectTasksForAddresses' as const,
);

export const makeSelectCollectTasksForAddressesListParameters: typeof baseMakeSelectCollectTasksForAddressesListParameters =
  (addresses: CollectableAddressLinkAPIModel[] | undefined) =>
    createSelector(
      baseMakeSelectCollectTasksForAddressesListParameters(addresses),
      makeSelectSelectedNetwork(),
      ({
        filter,
        ...parameters
      }): ReturnType<ReturnType<typeof baseMakeSelectCollectTasksForAddressesListParameters>> => ({
        ...parameters,
        filter: { ...filter, addressIn: addresses! },
      }),
    );

export const { makeSelectCollectableBalanceIsDirty, makeSelectCollectableBalance } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].balances.entities,
  'CollectableBalance',
  createCollectableBalanceKey,
);

export const { makeSelectCollectAvailableBalanceListData, makeSelectCollectAvailableBalanceListParameters } =
  createNormalizedListSelectors(
    (state: AppRootState) => state[NAMESPACE].balances.available,
    (state: AppRootState) => state[NAMESPACE].balances.entities,
    'CollectAvailableBalance' as const,
    createCollectableBalanceKey,
  );

export const makeSelectCollectAvailableBalanceListParametersWithNetwork = () =>
  createSelector(
    makeSelectCollectAvailableBalanceListParameters(),
    makeSelectSelectedNetwork(),
    (
      { filter, ...parameters },
      networkEq,
    ): ReturnType<ReturnType<typeof makeSelectCollectAvailableBalanceListParameters>> => ({
      ...parameters,
      filter: {
        ...filter,
        networkEq,
        statusEq: CollectableAddressBalanceStatusAPIModel.Collectable,
        balanceRange: { gte: 0.00001 },
      },
    }),
  );

export const { makeSelectCollectLockedBalanceListData, makeSelectCollectLockedBalanceListParameters } =
  createNormalizedListSelectors(
    (state: AppRootState) => state[NAMESPACE].balances.locked,
    (state: AppRootState) => state[NAMESPACE].balances.entities,
    'CollectLockedBalance' as const,
    createCollectableBalanceKey,
  );

export const makeSelectCollectLockedBalanceListParametersWithNetwork = () =>
  createSelector(
    makeSelectCollectLockedBalanceListParameters(),
    makeSelectSelectedNetwork(),
    (
      { filter, ...parameters },
      networkEq,
    ): ReturnType<ReturnType<typeof makeSelectCollectLockedBalanceListParameters>> => ({
      ...parameters,
      filter: {
        ...filter,
        networkEq,
        statusEq: CollectableAddressBalanceStatusAPIModel.Locked,
        balanceRange: { gte: 0.00001 },
      },
    }),
  );
