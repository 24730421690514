import { ReloadOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';
import { type DefaultErrorOpts, type NotificationFullOptions, useDefaultNotification, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import FormattedMessage from '../FormattedMessage';
import Operation from '../Operation';

import type { OperationRefreshProps } from './types';

const OperationRefresh = <R = unknown,>({
  'data-test': dataTest,
  mode = 'inline',
  refresh,
  onSuccess = noop,
  messages,
  ...rest
}: OperationRefreshProps<R>) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const successMessages = useCallback<() => NotificationFullOptions>(
    () => ({
      ...(messages?.success ?? {}),
      message: messages?.success?.message ?? <FormattedMessage id={I18nComponents.OPERATION_REFRESH_SUCCESS_MESSAGE} />,
    }),
    [messages?.success],
  );
  const errorMessages = useMemo<DefaultErrorOpts>(
    () => ({
      message: () =>
        messages?.error?.message ?? <FormattedMessage id={I18nComponents.OPERATION_REFRESH_FAILED_MESSAGE} />,
      description: messages?.error?.description ? () => messages.error?.description : undefined,
    }),
    [messages?.error],
  );
  const reload = useMemo(
    () => withSuccess(withDefaultError(withOnSuccess(refresh, onSuccess), errorMessages), successMessages),
    [withSuccess, withDefaultError, refresh, onSuccess, errorMessages, successMessages],
  );

  return (
    <Operation
      title={messages?.title ?? <FormattedMessage id={I18nComponents.OPERATION_REFRESH_TITLE} />}
      tooltip={messages?.tooltip ?? <FormattedMessage id={I18nComponents.OPERATION_REFRESH_TOOLTIP} />}
      icon={<ReloadOutlined />}
      onClick={reload}
      data-test={dataTest}
      mode={mode}
      {...rest}
    />
  );
};

const OperationRefreshMemo = React.memo(OperationRefresh) as typeof OperationRefresh;

export default OperationRefreshMemo;
