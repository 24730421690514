import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlementIntent } from '@/features/settlements/actions';
import { makeSelectSettlementIntent } from '@/features/settlements/selectors';
import type { SettlementIntent } from '@/features/settlements/types';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

export type UseSettlementIntent = UseAppSingleData<SettlementIntent>;

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<SettlementIntent>, Parameters<typeof fetchSettlementIntent>[0]> =>
  fetchSettlementIntent({ id, force });

export default function useSettlementIntent(id: string | undefined): UseSettlementIntent {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSettlementIntent, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectSettlementIntent, noSingleDataIdFn(), id, dataFetchingSelector);
}
