import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import {
  SettlementIntentStatusAPIModel,
  SettlementIntentTransactionStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';
import {
  createNormalizedListSelectors,
  createNestedNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { uniqueBy } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';

import {
  defaultSettlementIntentTransactionsForIntentListState,
  defaultSettlementsForAssetListState,
  NAMESPACE,
} from './types';
import { createDistributeFeeKey } from './utils';

import type {
  SettlementForAssetListState,
  SettlementIntentTransactionForIntentListState,
  SettlementIntent,
  SettlementIntentTransaction,
} from './types';

export const {
  makeSelectSettlement,
  makeSelectSettlementIsDirty,
  makeSelectSettlementListData,
  makeSelectSettlementListParameters,
} = createNormalizedListSelectors(
  {
    listNoColumn: (state: AppRootState) => state[NAMESPACE].settlements.list,
    columnState: (state: AppRootState) => state[NAMESPACE].settlements.columnState,
  },
  (state) => state[NAMESPACE].settlements.entities,
  'Settlement' as const,
  undefined,
);

export const makeSelectSettlementListParametersWithNetwork: typeof makeSelectSettlementListParameters = () =>
  createSelector(
    makeSelectSettlementListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, network): ReturnType<ReturnType<typeof makeSelectSettlementListParameters>> => ({
      ...parameters,
      filter: { ...filter, network },
    }),
  );

export const {
  makeSelectSettlementsForAssetListData,
  makeSelectSettlementsForAssetListParameters: baseMakeSelectSettlementsForAssetListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, settlementId: string | undefined): SettlementForAssetListState =>
    state[NAMESPACE].settlements.byAsset[settlementId!] ?? defaultSettlementsForAssetListState,
  (state) => state[NAMESPACE].settlements.columnState,
  (state) => state[NAMESPACE].settlements.entities,
  'SettlementsForAsset' as const,
);

export const makeSelectSettlementsForAssetListParameters: typeof baseMakeSelectSettlementsForAssetListParameters = (
  asset,
) =>
  createSelector(
    baseMakeSelectSettlementsForAssetListParameters(asset),
    ({ filter, ...parameters }): ReturnType<ReturnType<typeof baseMakeSelectSettlementsForAssetListParameters>> => ({
      ...parameters,
      filter: { ...filter, asset },
    }),
  );

export const {
  makeSelectSettlementIntent,
  makeSelectMultipleSettlementIntent,
  makeSelectSettlementIntentListData,
  makeSelectDirtySettlementIntentIds,
  makeSelectSettlementIntentListParameters,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].intents.list,
  (state: AppRootState) => state[NAMESPACE].intents.entities,
  'SettlementIntent' as const,
  undefined,
);

export const makeSelectSettlementIntentListParametersWithNetwork: typeof makeSelectSettlementIntentListParameters =
  () =>
    createSelector(
      makeSelectSettlementIntentListParameters(),
      makeSelectSelectedNetwork(),
      (
        { filter, ...parameters },
        networkEq,
      ): ReturnType<ReturnType<typeof makeSelectSettlementIntentListParameters>> => ({
        ...parameters,
        filter: { ...filter, networkEq },
      }),
    );

export const makeSelectPendingIntents = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].intents.entities,
    (entities): LoadingStateWithDirty<SettlementIntent[]> => {
      const intents = Object.values(entities)
        .map((intent) => (intent?.data ? { data: intent.data, isDirty: intent.isDirty } : undefined))
        .filter(notEmpty)
        .filter((intent) => intent.data.status === SettlementIntentStatusAPIModel.Pending);
      return {
        data: intents.map(({ data }) => data),
        isDirty: intents.reduce((result, { isDirty }) => result || isDirty, false),
      };
    },
  );

export const makeSelectPendingIntentsRefreshableAfter = () => (state: AppRootState) =>
  state[NAMESPACE].intents.pendingRefreshableAfter;
export const makeSelectPendingIntentsInitialized = () => (state: AppRootState) =>
  state[NAMESPACE].intents.pendingInitialized;
export const makeSelectPendingIntentTransactions = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].transactions.entities,
    makeSelectPendingIntentsRefreshableAfter(),
    (entities, refreshableAfter): LoadingStateWithDirty<SettlementIntentTransaction[]> => {
      const data = Object.values(entities)
        .map((transaction) => transaction?.data)
        .filter(notEmpty)
        .filter((transaction) =>
          [
            SettlementIntentTransactionStatusAPIModel.Awaiting,
            SettlementIntentTransactionStatusAPIModel.Pending,
          ].includes(transaction.status),
        );
      const isDirty = dayjs(refreshableAfter).isBefore();
      return { data, isDirty };
    },
  );
export const makeSelectPendingIntentWithActiveTransactionsCount = () =>
  createSelector(makeSelectPendingIntentTransactions(), (transactions) =>
    mapStoredState(transactions, (data) => data.filter(uniqueBy(({ intentId }) => intentId)).length),
  );

export const {
  makeSelectSettlementIntentTransaction,
  makeSelectMultipleSettlementIntentTransaction,
  makeSelectDirtySettlementIntentTransactionIds,
} = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].transactions.entities,
  'SettlementIntentTransaction' as const,
  undefined,
);

export const {
  makeSelectSettlementIntentTransactionsForIntentListData,
  makeSelectSettlementIntentTransactionsForIntentListParameters:
    baseMakeSelectSettlementIntentTransactionsForIntentListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, intentId: string | undefined): SettlementIntentTransactionForIntentListState =>
    state[NAMESPACE].transactions.byIntent[intentId!] ?? defaultSettlementIntentTransactionsForIntentListState,
  (state) => state[NAMESPACE].transactions.columnState,
  (state) => state[NAMESPACE].transactions.entities,
  'SettlementIntentTransactionsForIntent' as const,
);

export const makeSelectSettlementIntentTransactionsForIntentListParameters: typeof baseMakeSelectSettlementIntentTransactionsForIntentListParameters =
  (intent) =>
    createSelector(
      baseMakeSelectSettlementIntentTransactionsForIntentListParameters(intent),
      ({
        filter,
        ...parameters
      }): ReturnType<ReturnType<typeof baseMakeSelectSettlementIntentTransactionsForIntentListParameters>> => ({
        ...parameters,
        filter: { ...filter, intentIdIn: [intent!] },
      }),
    );

export const { makeSelectSettlementIntentTransactionDetails } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].transactions.details,
  'SettlementIntentTransactionDetails',
  undefined,
);

export const makeSelectSettlementIntentTransactionsForIntents = (intentIds: string[]) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].transactions.entities,
    (transactions) =>
      Object.values(transactions)
        .map((tx) => (tx?.data && intentIds.includes(tx.data.intentId) ? tx.data : undefined))
        .filter(notEmpty),
  );

export const makeSelectSettlementSchedule = () => (state: AppRootState) => state[NAMESPACE].schedule;

export const { makeSelectDistributeFee } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].distributeFees,
  'DistributeFee',
  createDistributeFeeKey,
);
