import { createReducer } from '@reduxjs/toolkit';

import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import {
  markMultiplePaymentTransactionDirty,
  markPaymentTransactionDirty,
  storeMultiplePaymentTransaction,
  storePaymentTransaction,
} from '@/features/payment-transactions/actions';
import { searchIdToStoreKey } from '@/features/payment-transactions/utils';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import type { PaymentTransactionsState } from './types';
import type { Draft } from 'immer';

const initialState: PaymentTransactionsState = {
  entities: {},
};

const {
  storeMultiplePaymentTransactionReducer,
  storePaymentTransactionReducer,
  markPaymentTransactionDirtyReducer,
  markMultiplePaymentTransactionDirtyReducer,
} = createSingleReducers(
  'PaymentTransaction',
  // this cast is workaround caused by BigNumber in the store
  (state: Draft<PaymentTransactionsState>) => state.entities as PaymentTransactionsState['entities'],
  (state, entities) => ({ ...state, entities }),
  searchIdToStoreKey,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markPaymentTransactionDirty, markPaymentTransactionDirtyReducer)
    .addCase(markMultiplePaymentTransactionDirty, markMultiplePaymentTransactionDirtyReducer)
    .addCase(storePaymentTransaction, storePaymentTransactionReducer)
    .addCase(storeMultiplePaymentTransaction, storeMultiplePaymentTransactionReducer)

    .addCase(notifyNetworkUpdated, () => initialState);
});

export default reducer;
