import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import QRDelegateContractsLink from '../QRDelegateContractsLink';

import type { DeployingQRDelegateContractsBackgroundLinkProps } from './types';

const DeployingQRDelegateContractsBackgroundLink: React.FC<DeployingQRDelegateContractsBackgroundLinkProps> = (
  props,
) => (
  <QRDelegateContractsLink
    title={<FormattedMessage id={I18nFeatureCompanySettings.MESSAGES_QR_DEPLOYING_TASK_EXTRA} />}
    mode="text"
    {...props}
  />
);

export default DeployingQRDelegateContractsBackgroundLink;
