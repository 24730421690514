/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSettlementIntentSortByAPIModel } from './PageSettlementIntentSortByAPIModel';
import {
    PageSettlementIntentSortByAPIModelFromJSON,
    PageSettlementIntentSortByAPIModelFromJSONTyped,
    PageSettlementIntentSortByAPIModelToJSON,
} from './PageSettlementIntentSortByAPIModel';
import type { SettlementIntentAPIModel } from './SettlementIntentAPIModel';
import {
    SettlementIntentAPIModelFromJSON,
    SettlementIntentAPIModelFromJSONTyped,
    SettlementIntentAPIModelToJSON,
} from './SettlementIntentAPIModel';

/**
 * 
 * @export
 * @interface SliceSettlementIntentSettlementIntentSortByAPIModel
 */
export interface SliceSettlementIntentSettlementIntentSortByAPIModel {
    /**
     * 
     * @type {Array<SettlementIntentAPIModel>}
     * @memberof SliceSettlementIntentSettlementIntentSortByAPIModel
     */
    list?: Array<SettlementIntentAPIModel>;
    /**
     * 
     * @type {PageSettlementIntentSortByAPIModel}
     * @memberof SliceSettlementIntentSettlementIntentSortByAPIModel
     */
    page: PageSettlementIntentSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceSettlementIntentSettlementIntentSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceSettlementIntentSettlementIntentSortByAPIModel interface.
 */
export function instanceOfSliceSettlementIntentSettlementIntentSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceSettlementIntentSettlementIntentSortByAPIModelFromJSON(json: any): SliceSettlementIntentSettlementIntentSortByAPIModel {
    return SliceSettlementIntentSettlementIntentSortByAPIModelFromJSONTyped(json, false);
}

export function SliceSettlementIntentSettlementIntentSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceSettlementIntentSettlementIntentSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(SettlementIntentAPIModelFromJSON)),
        'page': PageSettlementIntentSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceSettlementIntentSettlementIntentSortByAPIModelToJSON(value?: SliceSettlementIntentSettlementIntentSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(SettlementIntentAPIModelToJSON)),
        'page': PageSettlementIntentSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

