/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum LongRunningTaskStatusAPIModel {
    RechargeCollecting = 'RechargeCollecting',
    SubscriptionPausing = 'SubscriptionPausing',
    SubscriptionUnPausing = 'SubscriptionUnPausing',
    SubscriptionCancelling = 'SubscriptionCancelling'
}


export function LongRunningTaskStatusAPIModelFromJSON(json: any): LongRunningTaskStatusAPIModel {
    return LongRunningTaskStatusAPIModelFromJSONTyped(json, false);
}

export function LongRunningTaskStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LongRunningTaskStatusAPIModel {
    return json as LongRunningTaskStatusAPIModel;
}

export function LongRunningTaskStatusAPIModelToJSON(value?: LongRunningTaskStatusAPIModel | null): any {
    return value as any;
}

