import type { DateTimeRelativeQuickFilter } from '@/components';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import type {
  GetWithdrawalScheduleResponseAPIModel,
  SettlementIntentAPIModel,
  SettlementIntentPredicatesAPIModel,
  SettlementIntentTransactionAPIModel,
  SettlementIntentTransactionPredicatesAPIModel,
  UpdateWithdrawalScheduleRequestAPIModel,
  WithdrawalAPIModel,
  WithdrawalPredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import {
  SettlementIntentSortByAPIModel,
  SettlementIntentTransactionSortByAPIModel,
  WithdrawalSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { ListColumnState, ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { defaultListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

import type BigNumber from 'bignumber.js';

export const NAMESPACE = 'settlements';

export const pendingSettlementIntentsBackgroundTaskType = 'settlements.intents.pending';

export enum SettlementType {
  Payout = 'Payout',
  Settlement = 'Settlement',
}

export type Settlement = Omit<
  WithdrawalAPIModel,
  | 'estimatedAmount'
  | 'estimatedAmountInvoice'
  | 'estimatedAmountPush'
  | 'processingFeeUnpaid'
  | 'fullness'
  | 'companyId'
  | 'collectFullness'
  | 'batchCount'
  | 'collectStatus'
  | 'activeProcessingFeeTx'
  | 'mode'
> & {
  estimatedAmount: AssetAmountValue;
  totalAmount: AssetAmountValue;
  type: SettlementType;
};

export type SettlementScheduleUpdate = UpdateWithdrawalScheduleRequestAPIModel;
export type SettlementSchedule = GetWithdrawalScheduleResponseAPIModel;

export interface DistributeFeeId {
  asset: string;
  wallet: string;
  amount: BigNumber;
}

export interface FetchDistributeFeePayload extends DistributeFeeId {
  force?: boolean;
}

export type SettlementSortBy = ListSortBy<WithdrawalSortByAPIModel>;

export interface SettlementFilterPredicate
  extends Pick<
    WithdrawalPredicatesAPIModel,
    'statuses' | 'asset' | 'withdrawnAtRange' | 'reconciliationStatusIn' | 'network'
  > {
  withdrawnAtRelative?: DateTimeRelativeQuickFilter;
}

export type SettlementForAssetListState = ListStateNoColumns<
  string,
  SettlementFilterPredicate,
  WithdrawalSortByAPIModel
>;

export interface SettlementIntent extends Omit<SettlementIntentAPIModel, 'settled'> {
  staleAt: Date;
  settled?: AssetAmountValue;
}

export type SettlementIntentSortBy = ListSortBy<SettlementIntentSortByAPIModel>;

export interface SettlementIntentFilterPredicate
  extends Pick<SettlementIntentPredicatesAPIModel, 'assetIn' | 'btIn' | 'processAtRange' | 'statusIn' | 'networkEq'> {
  processAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type SettlementIntentListState = ListState<
  string,
  SettlementIntentFilterPredicate,
  SettlementIntentSortByAPIModel
>;

export type SettlementIntentTransaction = SettlementIntentTransactionAPIModel;

export type SettlementIntentTransactionFilterPredicate = Pick<
  SettlementIntentTransactionPredicatesAPIModel,
  'intentIdIn' | 'assetIn' | 'btIn' | 'destinationLike' | 'walletLike' | 'statusIn' | 'networkEq'
>;

export type SettlementIntentTransactionSortBy = ListSortBy<SettlementIntentTransactionSortByAPIModel>;

export type SettlementIntentTransactionForIntentListState = ListStateNoColumns<
  string,
  SettlementIntentTransactionFilterPredicate,
  SettlementIntentTransactionSortByAPIModel
>;

export const defaultSettlementsSortBy: SettlementSortBy = { [WithdrawalSortByAPIModel.EstimatedAt]: 'DESC' };
export const defaultSettlementsForAssetListState: SettlementForAssetListState = defaultListState(
  {},
  defaultSettlementsSortBy,
);
export const defaultSettlementsListState: ListState<string, SettlementFilterPredicate, WithdrawalSortByAPIModel> = {
  ...defaultSettlementsForAssetListState,
  columnState: {},
};

export const defaultIntentsSortBy: SettlementIntentSortBy = {
  [SettlementIntentSortByAPIModel.ProcessAt]: 'DESC',
};
export const defaultIntentsListState: SettlementIntentListState = defaultListState({}, defaultIntentsSortBy);

export const defaultSettlementIntentTransactionsSortBy: SettlementIntentTransactionSortBy = {
  [SettlementIntentTransactionSortByAPIModel.CreatedAt]: 'DESC',
};
export const defaultSettlementIntentTransactionsForIntentListState: SettlementIntentTransactionForIntentListState =
  defaultListState({}, defaultSettlementIntentTransactionsSortBy);

export interface SettlementsState {
  schedule: LoadingStateWithDirty<SettlementSchedule>;

  settlements: {
    entities: SingleState<Settlement>; // by settlementId
    list: SettlementForAssetListState;
    byAsset: Partial<Record<string, SettlementForAssetListState>>; // by asset
    columnState: ListColumnState;
  };

  intents: {
    entities: SingleState<SettlementIntent>; // by intentId
    list: SettlementIntentListState;
    pendingRefreshableAfter: Date;
    pendingInitialized: boolean;
  };

  transactions: {
    entities: SingleState<SettlementIntentTransaction>; // by intentId
    details: SingleState<GasWalletTransaction>; // by intentId
    byIntent: Partial<Record<string, SettlementIntentTransactionForIntentListState>>; // by intent
    columnState: ListColumnState;
  };

  distributeFees: SingleState<BigNumber>; // key - DistributeFeeKey
}
