import { base58 } from '@scure/base';
import { parse as uuidParse, stringify as uuidStringify } from 'uuid';

export const uuidToBase58 = (uuid: string) => {
  try {
    const bytes = uuidParse(uuid);
    return base58.encode(bytes);
  } catch (e) {
    console.warn(`Unable to transform ${uuid} to base58`, e);
    return undefined;
  }
};

export const base58ToUUID = (base58Value: string) => {
  try {
    return uuidStringify(base58.decode(base58Value));
  } catch (e) {
    console.warn(`Unable to parse ${base58Value}`, e);
    return undefined;
  }
};
