/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  AddGasWalletAPIModel,
  ApiErrorAPIModel,
  AssetBalancesAPIModel,
  AssetBalancesHistoryAPIModel,
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
  CompanyAPIModel,
  CompanySeedResponseAPIModel,
  CompanySeedStoragePolicyAPIModel,
  CompanySeedWeb3CredentialsAPIModel,
  CompanySettingsAPIModel,
  ForwarderDelegateAPIModel,
  ForwarderDelegatesAPIModel,
  GasWalletDeletePermissionAPIModel,
  GasWalletFullDetailsAPIModel,
  GasWalletsDetailsAPIModel,
  InvoicesStatsSimpleAPIModel,
  MerchantGasHistoryFilterAPIModel,
  MerchantGasHistoryPredicatesAPIModel,
  MerchantGasHistoryRowAPIModel,
  MerchantPermissionsAPIModel,
  MerchantReportAPIModel,
  NewCompanyAPIModel,
  RefundGasWalletAPIModel,
  RenewalConfirmationAPIModel,
  RenewalConfirmationResponseAPIModel,
  RenewalTokenInfoAPIModel,
  SliceMerchantGasHistoryRowGasHistorySortByAPIModel,
  UpdateActiveAssetsAPIModel,
  UpdateCompanyEmailAPIModel,
  UpdateCompanyNameAPIModel,
  UpdateCompanySeedAPIModel,
  UpdateCompanyWebsiteAPIModel,
  UpdateDefaultAssetAPIModel,
  UpdateForwarderSettingsAPIModel,
  UpdateNotificationAPIModel,
  UpdatePaymentSettingsAPIModel,
  Web3NonceAPIModel,
} from '../models/index';
import {
    AddGasWalletAPIModelFromJSON,
    AddGasWalletAPIModelToJSON,
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    AssetBalancesAPIModelFromJSON,
    AssetBalancesAPIModelToJSON,
    AssetBalancesHistoryAPIModelFromJSON,
    AssetBalancesHistoryAPIModelToJSON,
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelToJSON,
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelToJSON,
    CompanyAPIModelFromJSON,
    CompanyAPIModelToJSON,
    CompanySeedResponseAPIModelFromJSON,
    CompanySeedResponseAPIModelToJSON,
    CompanySeedStoragePolicyAPIModelFromJSON,
    CompanySeedStoragePolicyAPIModelToJSON,
    CompanySeedWeb3CredentialsAPIModelFromJSON,
    CompanySeedWeb3CredentialsAPIModelToJSON,
    CompanySettingsAPIModelFromJSON,
    CompanySettingsAPIModelToJSON,
    ForwarderDelegateAPIModelFromJSON,
    ForwarderDelegateAPIModelToJSON,
    ForwarderDelegatesAPIModelFromJSON,
    ForwarderDelegatesAPIModelToJSON,
    GasWalletDeletePermissionAPIModelFromJSON,
    GasWalletDeletePermissionAPIModelToJSON,
    GasWalletFullDetailsAPIModelFromJSON,
    GasWalletFullDetailsAPIModelToJSON,
    GasWalletsDetailsAPIModelFromJSON,
    GasWalletsDetailsAPIModelToJSON,
    InvoicesStatsSimpleAPIModelFromJSON,
    InvoicesStatsSimpleAPIModelToJSON,
    MerchantGasHistoryFilterAPIModelFromJSON,
    MerchantGasHistoryFilterAPIModelToJSON,
    MerchantGasHistoryPredicatesAPIModelFromJSON,
    MerchantGasHistoryPredicatesAPIModelToJSON,
    MerchantGasHistoryRowAPIModelFromJSON,
    MerchantGasHistoryRowAPIModelToJSON,
    MerchantPermissionsAPIModelFromJSON,
    MerchantPermissionsAPIModelToJSON,
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelToJSON,
    NewCompanyAPIModelFromJSON,
    NewCompanyAPIModelToJSON,
    RefundGasWalletAPIModelFromJSON,
    RefundGasWalletAPIModelToJSON,
    RenewalConfirmationAPIModelFromJSON,
    RenewalConfirmationAPIModelToJSON,
    RenewalConfirmationResponseAPIModelFromJSON,
    RenewalConfirmationResponseAPIModelToJSON,
    RenewalTokenInfoAPIModelFromJSON,
    RenewalTokenInfoAPIModelToJSON,
    SliceMerchantGasHistoryRowGasHistorySortByAPIModelFromJSON,
    SliceMerchantGasHistoryRowGasHistorySortByAPIModelToJSON,
    UpdateActiveAssetsAPIModelFromJSON,
    UpdateActiveAssetsAPIModelToJSON,
    UpdateCompanyEmailAPIModelFromJSON,
    UpdateCompanyEmailAPIModelToJSON,
    UpdateCompanyNameAPIModelFromJSON,
    UpdateCompanyNameAPIModelToJSON,
    UpdateCompanySeedAPIModelFromJSON,
    UpdateCompanySeedAPIModelToJSON,
    UpdateCompanyWebsiteAPIModelFromJSON,
    UpdateCompanyWebsiteAPIModelToJSON,
    UpdateDefaultAssetAPIModelFromJSON,
    UpdateDefaultAssetAPIModelToJSON,
    UpdateForwarderSettingsAPIModelFromJSON,
    UpdateForwarderSettingsAPIModelToJSON,
    UpdateNotificationAPIModelFromJSON,
    UpdateNotificationAPIModelToJSON,
    UpdatePaymentSettingsAPIModelFromJSON,
    UpdatePaymentSettingsAPIModelToJSON,
    Web3NonceAPIModelFromJSON,
    Web3NonceAPIModelToJSON,
} from '../models/index';

export interface AddGasWalletRequest {
    addGasWalletAPIModel: AddGasWalletAPIModel;
}

export interface ConfirmCompanyEmailRequest {
    companyId: number;
    renewalConfirmationAPIModel: RenewalConfirmationAPIModel;
}

export interface CreateCompanyRequest {
    newCompanyAPIModel: NewCompanyAPIModel;
}

export interface DeployActiveCompanyForwarderDelegateRequest {
    delegateId: string;
}

export interface ExportGasHistoryRequest {
    merchantGasHistoryPredicatesAPIModel: MerchantGasHistoryPredicatesAPIModel;
}

export interface GetBalancesRequest {
    blockchain?: BlockchainNetworkTypeAPIModel;
}

export interface GetBalancesByNetworkRequest {
    networkType: BlockchainNetworkTypeAPIModel;
}

export interface GetBalancesHistoryRequest {
    at: Date;
    network?: BlockchainNetworkTypeAPIModel;
}

export interface GetDashboardInfoByNetworkRequest {
    networkType: BlockchainNetworkTypeAPIModel;
}

export interface GetGasHistoryRequest {
    historyId: string;
}

export interface GetGasWalletRequest {
    type: BlockchainTypeAPIModel;
}

export interface GetSeedByWeb3NonceRequest {
    companySeedWeb3CredentialsAPIModel: CompanySeedWeb3CredentialsAPIModel;
}

export interface RefundGasWalletRequest {
    refundGasWalletAPIModel: RefundGasWalletAPIModel;
}

export interface RemoveGasWalletRequest {
    type: BlockchainTypeAPIModel;
}

export interface RemoveGasWalletPermissionRequest {
    type: BlockchainTypeAPIModel;
}

export interface ResetEmailRequest {
    body: object;
}

export interface SearchGasHistoryRequest {
    merchantGasHistoryFilterAPIModel: MerchantGasHistoryFilterAPIModel;
    withTotal?: boolean;
}

export interface UpdateActiveAssetsRequest {
    updateActiveAssetsAPIModel: UpdateActiveAssetsAPIModel;
}

export interface UpdateDefaultAssetRequest {
    updateDefaultAssetAPIModel: UpdateDefaultAssetAPIModel;
}

export interface UpdateEmailRequest {
    updateCompanyEmailAPIModel: UpdateCompanyEmailAPIModel;
}

export interface UpdateForwarderSettingsRequest {
    updateForwarderSettingsAPIModel: UpdateForwarderSettingsAPIModel;
}

export interface UpdateNameRequest {
    updateCompanyNameAPIModel: UpdateCompanyNameAPIModel;
}

export interface UpdateNotificationRequest {
    updateNotificationAPIModel: UpdateNotificationAPIModel;
}

export interface UpdatePaymentSettingsRequest {
    updatePaymentSettingsAPIModel: UpdatePaymentSettingsAPIModel;
}

export interface UpdateSeedRequest {
    updateCompanySeedAPIModel: UpdateCompanySeedAPIModel;
}

export interface UpdateWebsiteRequest {
    updateCompanyWebsiteAPIModel: UpdateCompanyWebsiteAPIModel;
}

/**
 * CompanyApi - interface
 * 
 * @export
 * @interface CompanyApiInterface
 */
export interface CompanyApiInterface {
    /**
     * Adds gas wallet PK for this company/merchant
     * @param {AddGasWalletAPIModel} addGasWalletAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    addGasWalletRaw(requestParameters: AddGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Adds gas wallet PK for this company/merchant
     */
    addGasWallet(addGasWalletAPIModel: AddGasWalletAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Confirms email renewal
     * @param {number} companyId 
     * @param {RenewalConfirmationAPIModel} renewalConfirmationAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    confirmCompanyEmailRaw(requestParameters: ConfirmCompanyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewalConfirmationResponseAPIModel>>;

    /**
     * Confirms email renewal
     */
    confirmCompanyEmail(companyId: number, renewalConfirmationAPIModel: RenewalConfirmationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewalConfirmationResponseAPIModel>;

    /**
     * Creates new company
     * @param {NewCompanyAPIModel} newCompanyAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyAPIModel>>;

    /**
     * Creates new company
     */
    createCompany(newCompanyAPIModel: NewCompanyAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyAPIModel>;

    /**
     * Clears the company email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    deleteEmailRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Clears the company email
     */
    deleteEmail(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Trigger the deployment of forwarder delegate
     * @param {string} delegateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    deployActiveCompanyForwarderDelegateRaw(requestParameters: DeployActiveCompanyForwarderDelegateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForwarderDelegateAPIModel>>;

    /**
     * Trigger the deployment of forwarder delegate
     */
    deployActiveCompanyForwarderDelegate(delegateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForwarderDelegateAPIModel>;

    /**
     * Exports gas history
     * @param {MerchantGasHistoryPredicatesAPIModel} merchantGasHistoryPredicatesAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    exportGasHistoryRaw(requestParameters: ExportGasHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>>;

    /**
     * Exports gas history
     */
    exportGasHistory(merchantGasHistoryPredicatesAPIModel: MerchantGasHistoryPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel>;

    /**
     * Returns active company forwarder delegates
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getActiveCompanyForwarderDelegatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForwarderDelegatesAPIModel>>;

    /**
     * Returns active company forwarder delegates
     */
    getActiveCompanyForwarderDelegates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForwarderDelegatesAPIModel>;

    /**
     * Returns available balance details
     * @param {BlockchainNetworkTypeAPIModel} [blockchain] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getBalancesRaw(requestParameters: GetBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetBalancesAPIModel>>;

    /**
     * Returns available balance details
     */
    getBalances(blockchain?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetBalancesAPIModel>;

    /**
     * Returns available balance details by network
     * @param {BlockchainNetworkTypeAPIModel} networkType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getBalancesByNetworkRaw(requestParameters: GetBalancesByNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetBalancesAPIModel>>;

    /**
     * Returns available balance details by network
     */
    getBalancesByNetwork(networkType: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetBalancesAPIModel>;

    /**
     * Returns available balance details at specific time
     * @param {Date} at 
     * @param {BlockchainNetworkTypeAPIModel} [network] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getBalancesHistoryRaw(requestParameters: GetBalancesHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetBalancesHistoryAPIModel>>;

    /**
     * Returns available balance details at specific time
     */
    getBalancesHistory(at: Date, network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetBalancesHistoryAPIModel>;

    /**
     * Get company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyAPIModel>>;

    /**
     * Get company
     */
    getCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyAPIModel>;

    /**
     * Returns general settings for company (merchant)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getCompanySettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Returns general settings for company (merchant)
     */
    getCompanySettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Returns statistics for merchant dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getDashboardInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesStatsSimpleAPIModel>>;

    /**
     * Returns statistics for merchant dashboard
     */
    getDashboardInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesStatsSimpleAPIModel>;

    /**
     * Returns statistics for merchant dashboard by specific network
     * @param {BlockchainNetworkTypeAPIModel} networkType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getDashboardInfoByNetworkRaw(requestParameters: GetDashboardInfoByNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesStatsSimpleAPIModel>>;

    /**
     * Returns statistics for merchant dashboard by specific network
     */
    getDashboardInfoByNetwork(networkType: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesStatsSimpleAPIModel>;

    /**
     * Returns gas history by id
     * @param {string} historyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getGasHistoryRaw(requestParameters: GetGasHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantGasHistoryRowAPIModel>>;

    /**
     * Returns gas history by id
     */
    getGasHistory(historyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantGasHistoryRowAPIModel>;

    /**
     * Loads gas wallets full details for specific blockchain
     * @param {BlockchainTypeAPIModel} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getGasWalletRaw(requestParameters: GetGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletFullDetailsAPIModel>>;

    /**
     * Loads gas wallets full details for specific blockchain
     */
    getGasWallet(type: BlockchainTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletFullDetailsAPIModel>;

    /**
     * Loads gas wallets full details for this company/merchant
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getGasWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletsDetailsAPIModel>>;

    /**
     * Loads gas wallets full details for this company/merchant
     */
    getGasWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletsDetailsAPIModel>;

    /**
     * Returns merchant permissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getMerchantPermissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantPermissionsAPIModel>>;

    /**
     * Returns merchant permissions
     */
    getMerchantPermissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantPermissionsAPIModel>;

    /**
     * Gets the company seed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getSeedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedResponseAPIModel>>;

    /**
     * Gets the company seed
     */
    getSeed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedResponseAPIModel>;

    /**
     * Retrieves the company seed by signed nonce
     * @param {CompanySeedWeb3CredentialsAPIModel} companySeedWeb3CredentialsAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getSeedByWeb3NonceRaw(requestParameters: GetSeedByWeb3NonceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedResponseAPIModel>>;

    /**
     * Retrieves the company seed by signed nonce
     */
    getSeedByWeb3Nonce(companySeedWeb3CredentialsAPIModel: CompanySeedWeb3CredentialsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedResponseAPIModel>;

    /**
     * Gets the company seed policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getSeedPolicyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedStoragePolicyAPIModel>>;

    /**
     * Gets the company seed policy
     */
    getSeedPolicy(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedStoragePolicyAPIModel>;

    /**
     * Gets the nonce to retrieve the company seed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    getSeedWeb3NonceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Web3NonceAPIModel>>;

    /**
     * Gets the nonce to retrieve the company seed
     */
    getSeedWeb3Nonce(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Web3NonceAPIModel>;

    /**
     * Refund from gas wallets for specific blockchain to provided address
     * @param {RefundGasWalletAPIModel} refundGasWalletAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    refundGasWalletRaw(requestParameters: RefundGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletFullDetailsAPIModel>>;

    /**
     * Refund from gas wallets for specific blockchain to provided address
     */
    refundGasWallet(refundGasWalletAPIModel: RefundGasWalletAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletFullDetailsAPIModel>;

    /**
     * Removes gas wallet for specific blockchain
     * @param {BlockchainTypeAPIModel} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    removeGasWalletRaw(requestParameters: RemoveGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Removes gas wallet for specific blockchain
     */
    removeGasWallet(type: BlockchainTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Get gas wallet delete permission for specific blockchain
     * @param {BlockchainTypeAPIModel} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    removeGasWalletPermissionRaw(requestParameters: RemoveGasWalletPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletDeletePermissionAPIModel>>;

    /**
     * Get gas wallet delete permission for specific blockchain
     */
    removeGasWalletPermission(type: BlockchainTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletDeletePermissionAPIModel>;

    /**
     * Removes the company seed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    removeSeedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Removes the company seed
     */
    removeSeed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Resets email to the owner email if presented
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    resetEmailRaw(requestParameters: ResetEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Resets email to the owner email if presented
     */
    resetEmail(body: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Returns gas history by filter
     * @param {MerchantGasHistoryFilterAPIModel} merchantGasHistoryFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    searchGasHistoryRaw(requestParameters: SearchGasHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantGasHistoryRowGasHistorySortByAPIModel>>;

    /**
     * Returns gas history by filter
     */
    searchGasHistory(merchantGasHistoryFilterAPIModel: MerchantGasHistoryFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantGasHistoryRowGasHistorySortByAPIModel>;

    /**
     * Updates active assets for this company/merchant
     * @param {UpdateActiveAssetsAPIModel} updateActiveAssetsAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateActiveAssetsRaw(requestParameters: UpdateActiveAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Updates active assets for this company/merchant
     */
    updateActiveAssets(updateActiveAssetsAPIModel: UpdateActiveAssetsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Updates default asset for this company/merchant
     * @param {UpdateDefaultAssetAPIModel} updateDefaultAssetAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateDefaultAssetRaw(requestParameters: UpdateDefaultAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Updates default asset for this company/merchant
     */
    updateDefaultAsset(updateDefaultAssetAPIModel: UpdateDefaultAssetAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Creates new email renewal request
     * @param {UpdateCompanyEmailAPIModel} updateCompanyEmailAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateEmailRaw(requestParameters: UpdateEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewalTokenInfoAPIModel>>;

    /**
     * Creates new email renewal request
     */
    updateEmail(updateCompanyEmailAPIModel: UpdateCompanyEmailAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewalTokenInfoAPIModel>;

    /**
     * Updates forwarder settings for this company/merchant
     * @param {UpdateForwarderSettingsAPIModel} updateForwarderSettingsAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateForwarderSettingsRaw(requestParameters: UpdateForwarderSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Updates forwarder settings for this company/merchant
     */
    updateForwarderSettings(updateForwarderSettingsAPIModel: UpdateForwarderSettingsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Updates the company name
     * @param {UpdateCompanyNameAPIModel} updateCompanyNameAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateNameRaw(requestParameters: UpdateNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyAPIModel>>;

    /**
     * Updates the company name
     */
    updateName(updateCompanyNameAPIModel: UpdateCompanyNameAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyAPIModel>;

    /**
     * Updates notification data for this company/merchant
     * @param {UpdateNotificationAPIModel} updateNotificationAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateNotificationRaw(requestParameters: UpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Updates notification data for this company/merchant
     */
    updateNotification(updateNotificationAPIModel: UpdateNotificationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Updates payment settings for this company/merchant
     * @param {UpdatePaymentSettingsAPIModel} updatePaymentSettingsAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updatePaymentSettingsRaw(requestParameters: UpdatePaymentSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Updates payment settings for this company/merchant
     */
    updatePaymentSettings(updatePaymentSettingsAPIModel: UpdatePaymentSettingsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

    /**
     * Updates the company seed
     * @param {UpdateCompanySeedAPIModel} updateCompanySeedAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateSeedRaw(requestParameters: UpdateSeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedStoragePolicyAPIModel>>;

    /**
     * Updates the company seed
     */
    updateSeed(updateCompanySeedAPIModel: UpdateCompanySeedAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedStoragePolicyAPIModel>;

    /**
     * Updates website for this company/merchant
     * @param {UpdateCompanyWebsiteAPIModel} updateCompanyWebsiteAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApiInterface
     */
    updateWebsiteRaw(requestParameters: UpdateWebsiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>>;

    /**
     * Updates website for this company/merchant
     */
    updateWebsite(updateCompanyWebsiteAPIModel: UpdateCompanyWebsiteAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel>;

}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI implements CompanyApiInterface {

    /**
     * Adds gas wallet PK for this company/merchant
     */
    async addGasWalletRaw(requestParameters: AddGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['addGasWalletAPIModel'] == null) {
            throw new runtime.RequiredError(
                'addGasWalletAPIModel',
                'Required parameter "addGasWalletAPIModel" was null or undefined when calling addGasWallet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/wallets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddGasWalletAPIModelToJSON(requestParameters['addGasWalletAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Adds gas wallet PK for this company/merchant
     */
    async addGasWallet(addGasWalletAPIModel: AddGasWalletAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.addGasWalletRaw({ addGasWalletAPIModel: addGasWalletAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Confirms email renewal
     */
    async confirmCompanyEmailRaw(requestParameters: ConfirmCompanyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewalConfirmationResponseAPIModel>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling confirmCompanyEmail().'
            );
        }

        if (requestParameters['renewalConfirmationAPIModel'] == null) {
            throw new runtime.RequiredError(
                'renewalConfirmationAPIModel',
                'Required parameter "renewalConfirmationAPIModel" was null or undefined when calling confirmCompanyEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/{companyId}/confirm-email`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenewalConfirmationAPIModelToJSON(requestParameters['renewalConfirmationAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenewalConfirmationResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Confirms email renewal
     */
    async confirmCompanyEmail(companyId: number, renewalConfirmationAPIModel: RenewalConfirmationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewalConfirmationResponseAPIModel> {
        const response = await this.confirmCompanyEmailRaw({ companyId: companyId, renewalConfirmationAPIModel: renewalConfirmationAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Creates new company
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyAPIModel>> {
        if (requestParameters['newCompanyAPIModel'] == null) {
            throw new runtime.RequiredError(
                'newCompanyAPIModel',
                'Required parameter "newCompanyAPIModel" was null or undefined when calling createCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/provision`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCompanyAPIModelToJSON(requestParameters['newCompanyAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new company
     */
    async createCompany(newCompanyAPIModel: NewCompanyAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyAPIModel> {
        const response = await this.createCompanyRaw({ newCompanyAPIModel: newCompanyAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Clears the company email
     */
    async deleteEmailRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/email`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Clears the company email
     */
    async deleteEmail(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.deleteEmailRaw(initOverrides);
        return await response.value();
    }

    /**
     * Trigger the deployment of forwarder delegate
     */
    async deployActiveCompanyForwarderDelegateRaw(requestParameters: DeployActiveCompanyForwarderDelegateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForwarderDelegateAPIModel>> {
        if (requestParameters['delegateId'] == null) {
            throw new runtime.RequiredError(
                'delegateId',
                'Required parameter "delegateId" was null or undefined when calling deployActiveCompanyForwarderDelegate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/forwarder-delegates/{delegateId}/deploy`.replace(`{${"delegateId"}}`, encodeURIComponent(String(requestParameters['delegateId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForwarderDelegateAPIModelFromJSON(jsonValue));
    }

    /**
     * Trigger the deployment of forwarder delegate
     */
    async deployActiveCompanyForwarderDelegate(delegateId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForwarderDelegateAPIModel> {
        const response = await this.deployActiveCompanyForwarderDelegateRaw({ delegateId: delegateId }, initOverrides);
        return await response.value();
    }

    /**
     * Exports gas history
     */
    async exportGasHistoryRaw(requestParameters: ExportGasHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantReportAPIModel>> {
        if (requestParameters['merchantGasHistoryPredicatesAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantGasHistoryPredicatesAPIModel',
                'Required parameter "merchantGasHistoryPredicatesAPIModel" was null or undefined when calling exportGasHistory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/gas-history/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantGasHistoryPredicatesAPIModelToJSON(requestParameters['merchantGasHistoryPredicatesAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantReportAPIModelFromJSON(jsonValue));
    }

    /**
     * Exports gas history
     */
    async exportGasHistory(merchantGasHistoryPredicatesAPIModel: MerchantGasHistoryPredicatesAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantReportAPIModel> {
        const response = await this.exportGasHistoryRaw({ merchantGasHistoryPredicatesAPIModel: merchantGasHistoryPredicatesAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns active company forwarder delegates
     */
    async getActiveCompanyForwarderDelegatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForwarderDelegatesAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/forwarder-delegates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForwarderDelegatesAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns active company forwarder delegates
     */
    async getActiveCompanyForwarderDelegates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForwarderDelegatesAPIModel> {
        const response = await this.getActiveCompanyForwarderDelegatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns available balance details
     */
    async getBalancesRaw(requestParameters: GetBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetBalancesAPIModel>> {
        const queryParameters: any = {};

        if (requestParameters['blockchain'] != null) {
            queryParameters['blockchain'] = requestParameters['blockchain'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/balances`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetBalancesAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns available balance details
     */
    async getBalances(blockchain?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetBalancesAPIModel> {
        const response = await this.getBalancesRaw({ blockchain: blockchain }, initOverrides);
        return await response.value();
    }

    /**
     * Returns available balance details by network
     */
    async getBalancesByNetworkRaw(requestParameters: GetBalancesByNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetBalancesAPIModel>> {
        if (requestParameters['networkType'] == null) {
            throw new runtime.RequiredError(
                'networkType',
                'Required parameter "networkType" was null or undefined when calling getBalancesByNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/balances/{networkType}`.replace(`{${"networkType"}}`, encodeURIComponent(String(requestParameters['networkType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetBalancesAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns available balance details by network
     */
    async getBalancesByNetwork(networkType: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetBalancesAPIModel> {
        const response = await this.getBalancesByNetworkRaw({ networkType: networkType }, initOverrides);
        return await response.value();
    }

    /**
     * Returns available balance details at specific time
     */
    async getBalancesHistoryRaw(requestParameters: GetBalancesHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssetBalancesHistoryAPIModel>> {
        if (requestParameters['at'] == null) {
            throw new runtime.RequiredError(
                'at',
                'Required parameter "at" was null or undefined when calling getBalancesHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['at'] != null) {
            queryParameters['at'] = (requestParameters['at'] as any).toISOString();
        }

        if (requestParameters['network'] != null) {
            queryParameters['network'] = requestParameters['network'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/balances-history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssetBalancesHistoryAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns available balance details at specific time
     */
    async getBalancesHistory(at: Date, network?: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssetBalancesHistoryAPIModel> {
        const response = await this.getBalancesHistoryRaw({ at: at, network: network }, initOverrides);
        return await response.value();
    }

    /**
     * Get company
     */
    async getCompanyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAPIModelFromJSON(jsonValue));
    }

    /**
     * Get company
     */
    async getCompany(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyAPIModel> {
        const response = await this.getCompanyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns general settings for company (merchant)
     */
    async getCompanySettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns general settings for company (merchant)
     */
    async getCompanySettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.getCompanySettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns statistics for merchant dashboard
     */
    async getDashboardInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesStatsSimpleAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesStatsSimpleAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns statistics for merchant dashboard
     */
    async getDashboardInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesStatsSimpleAPIModel> {
        const response = await this.getDashboardInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns statistics for merchant dashboard by specific network
     */
    async getDashboardInfoByNetworkRaw(requestParameters: GetDashboardInfoByNetworkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoicesStatsSimpleAPIModel>> {
        if (requestParameters['networkType'] == null) {
            throw new runtime.RequiredError(
                'networkType',
                'Required parameter "networkType" was null or undefined when calling getDashboardInfoByNetwork().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/dashboard/{networkType}`.replace(`{${"networkType"}}`, encodeURIComponent(String(requestParameters['networkType']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoicesStatsSimpleAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns statistics for merchant dashboard by specific network
     */
    async getDashboardInfoByNetwork(networkType: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoicesStatsSimpleAPIModel> {
        const response = await this.getDashboardInfoByNetworkRaw({ networkType: networkType }, initOverrides);
        return await response.value();
    }

    /**
     * Returns gas history by id
     */
    async getGasHistoryRaw(requestParameters: GetGasHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantGasHistoryRowAPIModel>> {
        if (requestParameters['historyId'] == null) {
            throw new runtime.RequiredError(
                'historyId',
                'Required parameter "historyId" was null or undefined when calling getGasHistory().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/gas-history/{historyId}`.replace(`{${"historyId"}}`, encodeURIComponent(String(requestParameters['historyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantGasHistoryRowAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns gas history by id
     */
    async getGasHistory(historyId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantGasHistoryRowAPIModel> {
        const response = await this.getGasHistoryRaw({ historyId: historyId }, initOverrides);
        return await response.value();
    }

    /**
     * Loads gas wallets full details for specific blockchain
     */
    async getGasWalletRaw(requestParameters: GetGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletFullDetailsAPIModel>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getGasWallet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/wallets/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GasWalletFullDetailsAPIModelFromJSON(jsonValue));
    }

    /**
     * Loads gas wallets full details for specific blockchain
     */
    async getGasWallet(type: BlockchainTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletFullDetailsAPIModel> {
        const response = await this.getGasWalletRaw({ type: type }, initOverrides);
        return await response.value();
    }

    /**
     * Loads gas wallets full details for this company/merchant
     */
    async getGasWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletsDetailsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/wallets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GasWalletsDetailsAPIModelFromJSON(jsonValue));
    }

    /**
     * Loads gas wallets full details for this company/merchant
     */
    async getGasWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletsDetailsAPIModel> {
        const response = await this.getGasWalletsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns merchant permissions
     */
    async getMerchantPermissionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MerchantPermissionsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MerchantPermissionsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns merchant permissions
     */
    async getMerchantPermissions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MerchantPermissionsAPIModel> {
        const response = await this.getMerchantPermissionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the company seed
     */
    async getSeedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/seed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySeedResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Gets the company seed
     */
    async getSeed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedResponseAPIModel> {
        const response = await this.getSeedRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the company seed by signed nonce
     */
    async getSeedByWeb3NonceRaw(requestParameters: GetSeedByWeb3NonceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedResponseAPIModel>> {
        if (requestParameters['companySeedWeb3CredentialsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'companySeedWeb3CredentialsAPIModel',
                'Required parameter "companySeedWeb3CredentialsAPIModel" was null or undefined when calling getSeedByWeb3Nonce().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/seed/web3-nonce`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompanySeedWeb3CredentialsAPIModelToJSON(requestParameters['companySeedWeb3CredentialsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySeedResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Retrieves the company seed by signed nonce
     */
    async getSeedByWeb3Nonce(companySeedWeb3CredentialsAPIModel: CompanySeedWeb3CredentialsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedResponseAPIModel> {
        const response = await this.getSeedByWeb3NonceRaw({ companySeedWeb3CredentialsAPIModel: companySeedWeb3CredentialsAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Gets the company seed policy
     */
    async getSeedPolicyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedStoragePolicyAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/seed-policy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySeedStoragePolicyAPIModelFromJSON(jsonValue));
    }

    /**
     * Gets the company seed policy
     */
    async getSeedPolicy(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedStoragePolicyAPIModel> {
        const response = await this.getSeedPolicyRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the nonce to retrieve the company seed
     */
    async getSeedWeb3NonceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Web3NonceAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/seed/web3-nonce`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Web3NonceAPIModelFromJSON(jsonValue));
    }

    /**
     * Gets the nonce to retrieve the company seed
     */
    async getSeedWeb3Nonce(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Web3NonceAPIModel> {
        const response = await this.getSeedWeb3NonceRaw(initOverrides);
        return await response.value();
    }

    /**
     * Refund from gas wallets for specific blockchain to provided address
     */
    async refundGasWalletRaw(requestParameters: RefundGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletFullDetailsAPIModel>> {
        if (requestParameters['refundGasWalletAPIModel'] == null) {
            throw new runtime.RequiredError(
                'refundGasWalletAPIModel',
                'Required parameter "refundGasWalletAPIModel" was null or undefined when calling refundGasWallet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/wallets/refund`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefundGasWalletAPIModelToJSON(requestParameters['refundGasWalletAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GasWalletFullDetailsAPIModelFromJSON(jsonValue));
    }

    /**
     * Refund from gas wallets for specific blockchain to provided address
     */
    async refundGasWallet(refundGasWalletAPIModel: RefundGasWalletAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletFullDetailsAPIModel> {
        const response = await this.refundGasWalletRaw({ refundGasWalletAPIModel: refundGasWalletAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Removes gas wallet for specific blockchain
     */
    async removeGasWalletRaw(requestParameters: RemoveGasWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling removeGasWallet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/wallets/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Removes gas wallet for specific blockchain
     */
    async removeGasWallet(type: BlockchainTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.removeGasWalletRaw({ type: type }, initOverrides);
        return await response.value();
    }

    /**
     * Get gas wallet delete permission for specific blockchain
     */
    async removeGasWalletPermissionRaw(requestParameters: RemoveGasWalletPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GasWalletDeletePermissionAPIModel>> {
        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling removeGasWalletPermission().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/wallets/{type}/delete`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GasWalletDeletePermissionAPIModelFromJSON(jsonValue));
    }

    /**
     * Get gas wallet delete permission for specific blockchain
     */
    async removeGasWalletPermission(type: BlockchainTypeAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GasWalletDeletePermissionAPIModel> {
        const response = await this.removeGasWalletPermissionRaw({ type: type }, initOverrides);
        return await response.value();
    }

    /**
     * Removes the company seed
     */
    async removeSeedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/seed`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes the company seed
     */
    async removeSeed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeSeedRaw(initOverrides);
    }

    /**
     * Resets email to the owner email if presented
     */
    async resetEmailRaw(requestParameters: ResetEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling resetEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/email/reset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Resets email to the owner email if presented
     */
    async resetEmail(body: object, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.resetEmailRaw({ body: body }, initOverrides);
        return await response.value();
    }

    /**
     * Returns gas history by filter
     */
    async searchGasHistoryRaw(requestParameters: SearchGasHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceMerchantGasHistoryRowGasHistorySortByAPIModel>> {
        if (requestParameters['merchantGasHistoryFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'merchantGasHistoryFilterAPIModel',
                'Required parameter "merchantGasHistoryFilterAPIModel" was null or undefined when calling searchGasHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/gas-history/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MerchantGasHistoryFilterAPIModelToJSON(requestParameters['merchantGasHistoryFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceMerchantGasHistoryRowGasHistorySortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns gas history by filter
     */
    async searchGasHistory(merchantGasHistoryFilterAPIModel: MerchantGasHistoryFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceMerchantGasHistoryRowGasHistorySortByAPIModel> {
        const response = await this.searchGasHistoryRaw({ merchantGasHistoryFilterAPIModel: merchantGasHistoryFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Updates active assets for this company/merchant
     */
    async updateActiveAssetsRaw(requestParameters: UpdateActiveAssetsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['updateActiveAssetsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateActiveAssetsAPIModel',
                'Required parameter "updateActiveAssetsAPIModel" was null or undefined when calling updateActiveAssets().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/active-assets`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateActiveAssetsAPIModelToJSON(requestParameters['updateActiveAssetsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates active assets for this company/merchant
     */
    async updateActiveAssets(updateActiveAssetsAPIModel: UpdateActiveAssetsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.updateActiveAssetsRaw({ updateActiveAssetsAPIModel: updateActiveAssetsAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates default asset for this company/merchant
     */
    async updateDefaultAssetRaw(requestParameters: UpdateDefaultAssetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['updateDefaultAssetAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateDefaultAssetAPIModel',
                'Required parameter "updateDefaultAssetAPIModel" was null or undefined when calling updateDefaultAsset().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/default-asset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDefaultAssetAPIModelToJSON(requestParameters['updateDefaultAssetAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates default asset for this company/merchant
     */
    async updateDefaultAsset(updateDefaultAssetAPIModel: UpdateDefaultAssetAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.updateDefaultAssetRaw({ updateDefaultAssetAPIModel: updateDefaultAssetAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Creates new email renewal request
     */
    async updateEmailRaw(requestParameters: UpdateEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewalTokenInfoAPIModel>> {
        if (requestParameters['updateCompanyEmailAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateCompanyEmailAPIModel',
                'Required parameter "updateCompanyEmailAPIModel" was null or undefined when calling updateEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/email/renew`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyEmailAPIModelToJSON(requestParameters['updateCompanyEmailAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenewalTokenInfoAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates new email renewal request
     */
    async updateEmail(updateCompanyEmailAPIModel: UpdateCompanyEmailAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewalTokenInfoAPIModel> {
        const response = await this.updateEmailRaw({ updateCompanyEmailAPIModel: updateCompanyEmailAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates forwarder settings for this company/merchant
     */
    async updateForwarderSettingsRaw(requestParameters: UpdateForwarderSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['updateForwarderSettingsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateForwarderSettingsAPIModel',
                'Required parameter "updateForwarderSettingsAPIModel" was null or undefined when calling updateForwarderSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/forwarder`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateForwarderSettingsAPIModelToJSON(requestParameters['updateForwarderSettingsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates forwarder settings for this company/merchant
     */
    async updateForwarderSettings(updateForwarderSettingsAPIModel: UpdateForwarderSettingsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.updateForwarderSettingsRaw({ updateForwarderSettingsAPIModel: updateForwarderSettingsAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates the company name
     */
    async updateNameRaw(requestParameters: UpdateNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanyAPIModel>> {
        if (requestParameters['updateCompanyNameAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateCompanyNameAPIModel',
                'Required parameter "updateCompanyNameAPIModel" was null or undefined when calling updateName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/name`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyNameAPIModelToJSON(requestParameters['updateCompanyNameAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates the company name
     */
    async updateName(updateCompanyNameAPIModel: UpdateCompanyNameAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanyAPIModel> {
        const response = await this.updateNameRaw({ updateCompanyNameAPIModel: updateCompanyNameAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates notification data for this company/merchant
     */
    async updateNotificationRaw(requestParameters: UpdateNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['updateNotificationAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateNotificationAPIModel',
                'Required parameter "updateNotificationAPIModel" was null or undefined when calling updateNotification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/notification`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotificationAPIModelToJSON(requestParameters['updateNotificationAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates notification data for this company/merchant
     */
    async updateNotification(updateNotificationAPIModel: UpdateNotificationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.updateNotificationRaw({ updateNotificationAPIModel: updateNotificationAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates payment settings for this company/merchant
     */
    async updatePaymentSettingsRaw(requestParameters: UpdatePaymentSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['updatePaymentSettingsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updatePaymentSettingsAPIModel',
                'Required parameter "updatePaymentSettingsAPIModel" was null or undefined when calling updatePaymentSettings().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/payment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePaymentSettingsAPIModelToJSON(requestParameters['updatePaymentSettingsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates payment settings for this company/merchant
     */
    async updatePaymentSettings(updatePaymentSettingsAPIModel: UpdatePaymentSettingsAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.updatePaymentSettingsRaw({ updatePaymentSettingsAPIModel: updatePaymentSettingsAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates the company seed
     */
    async updateSeedRaw(requestParameters: UpdateSeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySeedStoragePolicyAPIModel>> {
        if (requestParameters['updateCompanySeedAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateCompanySeedAPIModel',
                'Required parameter "updateCompanySeedAPIModel" was null or undefined when calling updateSeed().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/seed`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanySeedAPIModelToJSON(requestParameters['updateCompanySeedAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySeedStoragePolicyAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates the company seed
     */
    async updateSeed(updateCompanySeedAPIModel: UpdateCompanySeedAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySeedStoragePolicyAPIModel> {
        const response = await this.updateSeedRaw({ updateCompanySeedAPIModel: updateCompanySeedAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates website for this company/merchant
     */
    async updateWebsiteRaw(requestParameters: UpdateWebsiteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CompanySettingsAPIModel>> {
        if (requestParameters['updateCompanyWebsiteAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateCompanyWebsiteAPIModel',
                'Required parameter "updateCompanyWebsiteAPIModel" was null or undefined when calling updateWebsite().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/settings/website`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyWebsiteAPIModelToJSON(requestParameters['updateCompanyWebsiteAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanySettingsAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates website for this company/merchant
     */
    async updateWebsite(updateCompanyWebsiteAPIModel: UpdateCompanyWebsiteAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CompanySettingsAPIModel> {
        const response = await this.updateWebsiteRaw({ updateCompanyWebsiteAPIModel: updateCompanyWebsiteAPIModel }, initOverrides);
        return await response.value();
    }

}
