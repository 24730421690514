/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeSettlementIntentSortByAPIModel } from './SortAttributeSettlementIntentSortByAPIModel';
import {
    SortAttributeSettlementIntentSortByAPIModelFromJSON,
    SortAttributeSettlementIntentSortByAPIModelFromJSONTyped,
    SortAttributeSettlementIntentSortByAPIModelToJSON,
} from './SortAttributeSettlementIntentSortByAPIModel';

/**
 * 
 * @export
 * @interface PageSettlementIntentSortByAPIModel
 */
export interface PageSettlementIntentSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageSettlementIntentSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageSettlementIntentSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeSettlementIntentSortByAPIModel>}
     * @memberof PageSettlementIntentSortByAPIModel
     */
    sortBy?: Array<SortAttributeSettlementIntentSortByAPIModel>;
}

/**
 * Check if a given object implements the PageSettlementIntentSortByAPIModel interface.
 */
export function instanceOfPageSettlementIntentSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageSettlementIntentSortByAPIModelFromJSON(json: any): PageSettlementIntentSortByAPIModel {
    return PageSettlementIntentSortByAPIModelFromJSONTyped(json, false);
}

export function PageSettlementIntentSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageSettlementIntentSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeSettlementIntentSortByAPIModelFromJSON)),
    };
}

export function PageSettlementIntentSortByAPIModelToJSON(value?: PageSettlementIntentSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeSettlementIntentSortByAPIModelToJSON)),
    };
}

