import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FieldTimeOutlined,
  HourglassOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { SettlementIntentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementIntentStatusViewProps } from './types';

const i18n: Record<SettlementIntentStatusAPIModel, I18nMessages> = {
  [SettlementIntentStatusAPIModel.Scheduled]: I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_VIEW_SCHEDULED,
  [SettlementIntentStatusAPIModel.Pending]: I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_VIEW_PENDING,
  [SettlementIntentStatusAPIModel.Succeeded]: I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_VIEW_SUCCEEDED,
  [SettlementIntentStatusAPIModel.PartlySucceeded]:
    I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_VIEW_PARTLYSUCCEEDED,
  [SettlementIntentStatusAPIModel.Failed]: I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_VIEW_FAILED,
};

const icons: Record<SettlementIntentStatusAPIModel, React.ReactNode> = {
  [SettlementIntentStatusAPIModel.Scheduled]: <FieldTimeOutlined style={{ color: 'blue' }} />,
  [SettlementIntentStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <HourglassOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [SettlementIntentStatusAPIModel.PartlySucceeded]: (
    <Badge count={<ExclamationCircleOutlined style={{ color: 'red' }} />} showZero>
      <CheckCircleOutlined style={{ color: 'green' }} />
    </Badge>
  ),
  [SettlementIntentStatusAPIModel.Succeeded]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [SettlementIntentStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const SettlementIntentStatusViewRaw: React.FC<SettlementIntentStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const SettlementIntentStatusView = React.memo(SettlementIntentStatusViewRaw);

export default SettlementIntentStatusView;
