import React from 'react';

import { FilterForm } from '@/components';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import type { SettlementIntentFilterPredicate } from '@/features/settlements/types';
import { nameof } from '@/infrastructure/utils/ts';

import { SettlementIntentProcessAtRangeFilterItem, SettlementIntentStatusFilterItem } from './components';

import type { SettlementIntentFilterFormProps } from './types';

const fields = [
  nameof<SettlementIntentFilterPredicate>('processAtRange'),
  nameof<SettlementIntentFilterPredicate>('statusIn'),
  nameof<SettlementIntentFilterPredicate>('assetIn'),
  nameof<SettlementIntentFilterPredicate>('btIn'),
];

const SettlementIntentFilterForm: React.FC<SettlementIntentFilterFormProps> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<SettlementIntentFilterPredicate> fields={fields} {...props}>
      <SettlementIntentProcessAtRangeFilterItem<SettlementIntentFilterPredicate>
        data-test={dataTest && `${dataTest}-settledAt`}
        name={nameof<SettlementIntentFilterPredicate>('processAtRange')}
        readonly={readonly}
        required={false}
        relativeUpdateKey="processAtRangeRelative"
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<SettlementIntentFilterPredicate>('btIn')}
        network={network}
        readonly={readonly}
        required={false}
        multiselect
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<SettlementIntentFilterPredicate>('assetIn')}
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
        multiselect
      />
      <SettlementIntentStatusFilterItem
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<SettlementIntentFilterPredicate>('statusIn')}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const SettlementIntentFilterFormMemo = React.memo(SettlementIntentFilterForm);

export default SettlementIntentFilterFormMemo;
