import { noIdNestedFullFetchResultFn, useAppNestedFullDataFiltered } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchTransfersForRunningBalance } from '@/features/merchant-wallet-balance/actions';
import {
  makeSelectTransfersForRunningBalanceFilteredData,
  makeSelectTransfersForRunningBalanceFullParameters,
} from '@/features/merchant-wallet-balance/selectors';
import type { MerchantWalletTransfer } from '@/features/merchant-wallet-transfers/types';
import { type LoadingStateWithDirty } from '@/infrastructure/model';

const fetchFactory =
  (balanceId: string) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<
    LoadingStateWithDirty<MerchantWalletTransfer[]>,
    Parameters<typeof fetchTransfersForRunningBalance>[0]
  > =>
    fetchTransfersForRunningBalance({ balanceId, force });

const pendingSelectorCreator = (balanceId: string) => makeSelectPending(fetchTransfersForRunningBalance, balanceId);

export default function useMerchantWalletRunningBalanceTransfers(balanceId: string | undefined) {
  return useAppNestedFullDataFiltered(
    fetchFactory,
    makeSelectTransfersForRunningBalanceFullParameters,
    pendingSelectorCreator,
    makeSelectTransfersForRunningBalanceFilteredData,
    noIdNestedFullFetchResultFn(),
    balanceId,
  );
}
