import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetworkBlockchains } from '@/features/dictionary/blockchain/selectors';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { MerchantWalletStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { NAMESPACE } from './types';

import type { MerchantWallet } from './types';

export const { makeSelectMerchantWalletDeployTransactions } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].deploy.transactions,
  'MerchantWalletDeployTransactions' as const,
  merchantWalletIdToStoreKey,
);

export const { makeSelectMerchantWalletOwnership } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].ownership,
  'MerchantWalletOwnership' as const,
);

export const { makeSelectMerchantWallets } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].wallets,
  'MerchantWallets' as const,
);

export const makeSelectDeployingMerchantWalletsRefreshableAfter = () => (state: AppRootState) =>
  state[NAMESPACE].deploy.refreshableAfter;
export const makeSelectDeployingMerchantWallets = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].wallets,
    makeSelectSelectedNetworkBlockchains(),
    makeSelectDeployingMerchantWalletsRefreshableAfter(),
    (wallets, blockchains, refreshableAfter): LoadingStateWithDirty<MerchantWallet[]> => ({
      ...mapStoredState(wallets, (data) =>
        data
          .filter((wallet) => wallet.status === MerchantWalletStatusAPIModel.Deploying)
          .filter((wallet) => blockchains.includes(wallet.blockchain)),
      ),
      isDirty: dayjs(refreshableAfter).isBefore(),
    }),
  );
