/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { StableCoinCryptoPeggyAPIModel } from './StableCoinCryptoPeggyAPIModel';
import {
    StableCoinCryptoPeggyAPIModelFromJSON,
    StableCoinCryptoPeggyAPIModelFromJSONTyped,
    StableCoinCryptoPeggyAPIModelToJSON,
} from './StableCoinCryptoPeggyAPIModel';
import type { StableCoinFiatPeggyAPIModel } from './StableCoinFiatPeggyAPIModel';
import {
    StableCoinFiatPeggyAPIModelFromJSON,
    StableCoinFiatPeggyAPIModelFromJSONTyped,
    StableCoinFiatPeggyAPIModelToJSON,
} from './StableCoinFiatPeggyAPIModel';

/**
 * 
 * @export
 * @interface AssetFullMetaAPIModel
 */
export interface AssetFullMetaAPIModel {
    /**
     * 
     * @type {string}
     * @memberof AssetFullMetaAPIModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullMetaAPIModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AssetFullMetaAPIModel
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof AssetFullMetaAPIModel
     */
    formatDecimals: number;
    /**
     * 
     * @type {string}
     * @memberof AssetFullMetaAPIModel
     */
    address?: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof AssetFullMetaAPIModel
     */
    blockchain?: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof AssetFullMetaAPIModel
     */
    label: string;
    /**
     * 
     * @type {StableCoinFiatPeggyAPIModel}
     * @memberof AssetFullMetaAPIModel
     */
    peggedToFiat?: StableCoinFiatPeggyAPIModel;
    /**
     * 
     * @type {StableCoinCryptoPeggyAPIModel}
     * @memberof AssetFullMetaAPIModel
     */
    peggedToCrypto?: StableCoinCryptoPeggyAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof AssetFullMetaAPIModel
     */
    isSettlementSupported: boolean;
}

/**
 * Check if a given object implements the AssetFullMetaAPIModel interface.
 */
export function instanceOfAssetFullMetaAPIModel(value: object): boolean {
    if (!('code' in value)) return false;
    if (!('name' in value)) return false;
    if (!('symbol' in value)) return false;
    if (!('formatDecimals' in value)) return false;
    if (!('label' in value)) return false;
    if (!('isSettlementSupported' in value)) return false;
    return true;
}

export function AssetFullMetaAPIModelFromJSON(json: any): AssetFullMetaAPIModel {
    return AssetFullMetaAPIModelFromJSONTyped(json, false);
}

export function AssetFullMetaAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetFullMetaAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'code': json['code'],
        'name': json['name'],
        'symbol': json['symbol'],
        'formatDecimals': json['formatDecimals'],
        'address': json['address'] == null ? undefined : json['address'],
        'blockchain': json['blockchain'] == null ? undefined : BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'label': json['label'],
        'peggedToFiat': json['peggedToFiat'] == null ? undefined : StableCoinFiatPeggyAPIModelFromJSON(json['peggedToFiat']),
        'peggedToCrypto': json['peggedToCrypto'] == null ? undefined : StableCoinCryptoPeggyAPIModelFromJSON(json['peggedToCrypto']),
        'isSettlementSupported': json['isSettlementSupported'],
    };
}

export function AssetFullMetaAPIModelToJSON(value?: AssetFullMetaAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'code': value['code'],
        'name': value['name'],
        'symbol': value['symbol'],
        'formatDecimals': value['formatDecimals'],
        'address': value['address'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'label': value['label'],
        'peggedToFiat': StableCoinFiatPeggyAPIModelToJSON(value['peggedToFiat']),
        'peggedToCrypto': StableCoinCryptoPeggyAPIModelToJSON(value['peggedToCrypto']),
        'isSettlementSupported': value['isSettlementSupported'],
    };
}

