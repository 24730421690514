import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { useActionPending } from '@/features/global/hooks';
import { useIsActiveMerchantWalletDeployed } from '@/features/merchant-wallets/hooks';
import { settleNow } from '@/features/settlements/actions';
import type { SettlementIntent } from '@/features/settlements/types';
import { useActualBalances } from '@/features/statistics/hooks';
import type { HookAction } from '@/infrastructure/model';

import usePendingIntents from '../usePendingIntents';

type SettleUnavailabilityReason = 'no-data' | 'in-progress' | 'no-deployed-wallet' | 'empty-balance';

export interface UseSettlementAssetActions {
  settleNow: HookAction<[], SettlementIntent, SettleUnavailabilityReason>;
}

export default function useSettlementAssetActions(assetId: string): UseSettlementAssetActions {
  const { data: pendingIntents } = usePendingIntents();
  const { data: asset } = useAsset(assetId);
  const { data: balances, loading: balancesLoading } = useActualBalances();
  const balance = useMemo(() => balances.data?.available.find(({ asset }) => asset === assetId), [assetId, balances]);
  const { data: walletIsDeployed, loading: walletLoading } = useIsActiveMerchantWalletDeployed(asset.data?.blockchain);
  const { withExtractDataDispatch } = useAppDispatch();

  const settling = useActionPending(settleNow, assetId);
  const settleUnavailabilityReason: SettleUnavailabilityReason | undefined = useMemo(() => {
    if (walletLoading || balances.isDirty || balancesLoading) return 'no-data';
    if (balance && !balance.value.isGreaterThan(0)) return 'empty-balance';
    if (pendingIntents.data?.find((pending) => pending.asset === assetId)) return 'in-progress';
    if (!walletIsDeployed) return 'no-deployed-wallet';
    return undefined;
  }, [assetId, balance, balances.isDirty, balancesLoading, pendingIntents, walletIsDeployed, walletLoading]);
  const settleNowAction = {
    act: useCallback(() => withExtractDataDispatch(settleNow)({ asset: assetId }), [withExtractDataDispatch, assetId]),
    inAction: settling,
    available: !settleUnavailabilityReason,
    unavailabilityReason: settleUnavailabilityReason,
  };

  return { settleNow: settleNowAction };
}
