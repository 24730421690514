import React from 'react';

import type { SettlementIntentViewProps } from '@/features/settlements/components';
import SettlementIntentView from '@/features/settlements/components/SettlementIntentView';
import { withSettlementIntentTransaction } from '@/features/settlements/hocs';

import { withIntentDataLoading } from './hocs';

const SettlementIntentCard = withSettlementIntentTransaction()(
  withIntentDataLoading<SettlementIntentViewProps>((props) => <SettlementIntentView {...props} columns={1} />),
);

const SettlementIntentCardMemo = React.memo(SettlementIntentCard);

export default SettlementIntentCardMemo;
