import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import SettlementIntentBreadcrumb from '@/pages/settlements/intent-view/components/SettlementIntentBreadcrumb';

import type { SettlementIntentTransactionsBreadcrumbProps } from './types';

const SettlementIntentTransactionsBreadcrumb: React.FC<SettlementIntentTransactionsBreadcrumbProps> = ({
  'data-test': dataTest,
  intentId,
  items = [],
}) => (
  <SettlementIntentBreadcrumb
    data-test={dataTest}
    intentId={intentId}
    items={[
      {
        title: <FormattedMessage id={I18nPageSettlements.INTENT_TRANSACTIONS_COMPONENTS_BREADCRUMB_TITLE} />,
      },
      ...items,
    ]}
  />
);

const SettlementIntentTransactionsBreadcrumbMemo = React.memo(SettlementIntentTransactionsBreadcrumb);

export default SettlementIntentTransactionsBreadcrumbMemo;
