import { LogoutOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ProLayout } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { Button } from 'antd';
import { type PropsWithChildren, useCallback } from 'react';

import { useAppSelector } from '@/app/hooks';
import LogoSmallLight from '@/assets/icons/smartypay/logo-small-light';
import { DocumentationLink } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { makeSelectViewHeight } from '@/features/global/selectors';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import { SelectLang } from '@/layouts/components';
import { layoutBgToken } from '@/layouts/theme';

import type React from 'react';

export type MessageLayoutProps = PropsWithChildren<TestableProps> & { showLogo?: true };

const selectViewHeight = makeSelectViewHeight();

const MessageLayout: React.FC<MessageLayoutProps> = ({ 'data-test': dataTest, showLogo, children }) => {
  const height = useAppSelector(selectViewHeight);
  const { logout } = useAuthActions();

  return (
    <ProLayout
      layout="top"
      disableMobile
      title={showLogo ? 'Smarty Pay' : ''}
      logo={showLogo && <LogoSmallLight width={24} />}
      token={layoutBgToken}
      className={css`
        height: ${100 * height}px;

        .ant-pro-top-nav-header-main {
          padding-inline-end: 16px;
        }
      `}
      actionsRender={useCallback(
        () => [
          <SelectLang key="lang" data-test={dataTest && `${dataTest}-lang`} />,
          <DocumentationLink
            key="doc"
            style={{
              color: 'inherit',
              padding: 'inherit',
            }}
            data-test={dataTest && `${dataTest}-docs`}
            mode="inline"
            icon={<QuestionCircleOutlined />}
          />,
          ...(logout.available
            ? [
                <Button
                  key="logout"
                  type="text"
                  className={css`
                    color: inherit;
                  `}
                  onClick={() => withSuppressPromise(logout.act)()}
                >
                  <LogoutOutlined />
                </Button>,
              ]
            : []),
        ],
        [dataTest, logout.act, logout.available],
      )}
    >
      {children}
    </ProLayout>
  );
};

export default MessageLayout;
