import React from 'react';

import {
  type CompanyActiveAssetsForSelectIssue,
  CompanyActiveAssetsForSelectIssueRender,
  useActiveAssetsForSelect,
} from '@/features/company-settings/hooks';
import { AssetSelectItem } from '@/features/dictionary/blockchain/components';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { RechargeAddressItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const excludeAPI = [BlockchainAPITypeAPIModel.BTC, BlockchainAPITypeAPIModel.Tron];

const RechargeAddressItem = <Values extends Store = Store>(props: RechargeAddressItemProps<Values>) => {
  const assets = useActiveAssetsForSelect({ disableWalletWithIssue: true, excludeAPI });
  return (
    <AssetSelectItem<Values, CompanyActiveAssetsForSelectIssue | undefined>
      {...props}
      assets={assets}
      Issues={CompanyActiveAssetsForSelectIssueRender}
    />
  );
};

const RechargeAddressItemMemo = React.memo(RechargeAddressItem) as typeof RechargeAddressItem;

export default RechargeAddressItemMemo;
