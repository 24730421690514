import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlement } from '@/features/settlements/actions';
import { makeSelectSettlement } from '@/features/settlements/selectors';
import type { Settlement } from '@/features/settlements/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export type UseSettlement = UseAppSingleData<Settlement>;

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Settlement>, Parameters<typeof fetchSettlement>[0]> =>
  fetchSettlement({ id, force });

export default function useSettlement(id: string | undefined): UseSettlement {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSettlement, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectSettlement, noSingleDataIdFn(), id, dataFetchingSelector);
}
