import { Descriptions, Space } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent, defaultViewColumns, descriptionStyles } from '@/components';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';

import ReportScheduleCronView from '../ReportScheduleCronView';
import ReportScheduleStatusView from '../ReportScheduleStatusView';
import ReportScheduleTemplateTitle from '../ReportScheduleTemplateTitle';

import type { ReportScheduleViewProps } from './types';

const ReportScheduleViewRaw: React.FC<ReportScheduleViewProps> = ({
  data,
  'data-test': dataTest,
  className,
  style,
  columns = defaultViewColumns,
  Operations,
}) => {
  const { formatDateTime } = useLocaleSettings();
  return (
    <Descriptions
      style={style}
      className={className}
      styles={descriptionStyles}
      title={<ReportScheduleStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} mode="full" />}
      extra={
        Operations && (
          <Space>
            <Operations data-test={dataTest} value={data} />
          </Space>
        )
      }
      column={columns}
    >
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_TEMPLATE} />}>
        <ReportScheduleTemplateTitle value={data.template} data-test={dataTest && `${dataTest}-template`} />
      </Descriptions.Item>
      {data.disableReason && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_DISABLE_REASON} />}>
          <ReadonlyComponent
            value={data.disableReason}
            style={{ whiteSpace: 'nowrap' }}
            data-test={dataTest && `${dataTest}-disableReason`}
          />
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_CREATED_AT} />}>
        <ReadonlyComponent value={formatDateTime(data.createdAt)} data-test={dataTest && `${dataTest}-createdAt`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_CRON} />}>
        <ReportScheduleCronView value={data.cron} data-test={dataTest && `${dataTest}-cron`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureReportSchedules.LABELS_ROW_TITLE_NEXT_AT} />}>
        <ReadonlyComponent
          value={formatDateTime(data.nextGenerationAt)}
          data-test={dataTest && `${dataTest}-nextGenerationAt`}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

const ReportScheduleView = React.memo(ReportScheduleViewRaw);

export default ReportScheduleView;
