import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import MerchantPermissionLabel from '../CompanyPermissionLabel';

import type { CompanyPermissionRestrictedMessageProps } from './types';

const CompanyPermissionRestrictedMessage: React.FC<CompanyPermissionRestrictedMessageProps> = ({ permission }) => (
  <FormattedMessageWithMarkup
    id={I18nFeatureCompany.COMPONENTS_PERMISSION_RESTRICTED_MESSAGE_TEXT}
    values={{ permission: <MerchantPermissionLabel value={permission} /> }}
  />
);

const CompanyPermissionRestrictedMessageMemo = React.memo(CompanyPermissionRestrictedMessage);

export default CompanyPermissionRestrictedMessageMemo;
