import { useCallback } from 'react';

import type { SelectWithReloadingItemProps } from '@/components';
import { WarningTooltip } from '@/components';
import AssetLabel from '@/features/dictionary/blockchain/components/AssetLabel';
import type { TestableProps } from '@/infrastructure/utils/react';

import type { AssetForSelection, AssetSelectIssueRender } from '../types';
import type { Store } from 'rc-field-form/es/interface';

const useDataOptions = <Values extends Store = Store, Issue extends string | undefined = undefined>({
  'data-test': dataTest,
  Issues,
  selectedAssets,
  itemAssets,
}: {
  Issues: Issue extends undefined ? undefined : Record<Exclude<Issue, undefined>, AssetSelectIssueRender>;
  itemAssets?: string[];
  selectedAssets?: string[];
} & TestableProps) =>
  useCallback<SelectWithReloadingItemProps<Values, AssetForSelection<Issue>>['dataToOptions']>(
    ({ code, name: label, issue }) => {
      const Title = issue ? Issues?.[issue] : undefined;
      return {
        value: code,
        label: issue ? (
          <WarningTooltip
            type="error"
            title={Title ? <Title data-test={dataTest && `${dataTest}-${code}-warning`} assetId={code} /> : issue}
          >
            <div>
              <AssetLabel
                value={{ code, name: label }}
                data-test={dataTest && `${dataTest}-${code}-label`}
                mode="medium"
                withBlockchainMark
                copyable={false}
                ellipsis={false}
              />
            </div>
          </WarningTooltip>
        ) : (
          <AssetLabel
            data-test={dataTest && `${dataTest}-${code}-label`}
            value={{ code, name: label }}
            mode="medium"
            withBlockchainMark
            copyable={false}
            ellipsis={false}
          />
        ),
        disabled: !!issue || (selectedAssets?.includes(code) && !itemAssets?.includes(code)),
        inline: (
          <AssetLabel
            value={{ code, name: label }}
            data-test={dataTest && `${dataTest}-selected`}
            mode="medium"
            copyable={false}
            ellipsis={false}
          />
        ),
        search: `${code} ${label}`,
      };
    },
    [Issues, dataTest, itemAssets, selectedAssets],
  );

export default useDataOptions;
