/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonthlyGrowthRateRecordAPIModel
 */
export interface MonthlyGrowthRateRecordAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MonthlyGrowthRateRecordAPIModel
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof MonthlyGrowthRateRecordAPIModel
     */
    percentage: number;
}

/**
 * Check if a given object implements the MonthlyGrowthRateRecordAPIModel interface.
 */
export function instanceOfMonthlyGrowthRateRecordAPIModel(value: object): boolean {
    if (!('month' in value)) return false;
    if (!('percentage' in value)) return false;
    return true;
}

export function MonthlyGrowthRateRecordAPIModelFromJSON(json: any): MonthlyGrowthRateRecordAPIModel {
    return MonthlyGrowthRateRecordAPIModelFromJSONTyped(json, false);
}

export function MonthlyGrowthRateRecordAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyGrowthRateRecordAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'month': json['month'],
        'percentage': json['percentage'],
    };
}

export function MonthlyGrowthRateRecordAPIModelToJSON(value?: MonthlyGrowthRateRecordAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'month': value['month'],
        'percentage': value['percentage'],
    };
}

