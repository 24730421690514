import { PageContainer } from '@/components';
import type { CollectTaskLinkProps } from '@/pages/collectable/task-view/components';
import BaseCollectTaskLink from '@/pages/collectable/task-view/components/CollectTaskLink';

import { CollectPendingTasksCard, CollectPendingTasksBreadcrumb } from './components';

import type React from 'react';

const CollectTaskLink: React.FC<Omit<CollectTaskLinkProps, 'mode'>> = (props) => (
  <BaseCollectTaskLink {...props} mode="text" />
);
const dataTest: string | undefined = 'tasks';

const CollectPendingTasksPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<CollectPendingTasksBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <CollectPendingTasksCard data-test={dataTest && `${dataTest}-card`} TaskLink={CollectTaskLink} />
  </PageContainer>
);

export default CollectPendingTasksPage;
