import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { searchIdToStoreKey } from '@/features/payment-transactions/utils';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { notEmpty } from '@/infrastructure/utils/ts';

import { NAMESPACE } from './types';

export const {
  makeSelectPaymentTransaction,
  makeSelectMultiplePaymentTransaction,
  makeSelectDirtyPaymentTransactionIds,
} = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].entities,
  'PaymentTransaction' as const,
  searchIdToStoreKey,
);

export const makeSelectPaymentTransactionsForPayments = (paymentIds: string[]) =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].entities,
    (transactions) =>
      Object.values(transactions)
        .map((tx) => (tx?.data && paymentIds.includes(tx.data.paymentId) ? tx.data : undefined))
        .filter(notEmpty),
  );
