import { Descriptions } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import SubscriptionPlanScheduleView from '../SubscriptionPlanScheduleView';
import SubscriptionPlanStatusView from '../SubscriptionPlanStatusView';

import type { SubscriptionPlanShortViewProps } from './types';

const SubscriptionPlanShortView: React.FC<SubscriptionPlanShortViewProps> = ({
  data,
  'data-test': dataTest,
  style,
  className,
  columns = defaultViewColumns,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={<SubscriptionPlanStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} mode="full" />}
    column={columns}
  >
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_NAME} />}>
      <ReadonlyComponent value={data.description} data-test={dataTest && `${dataTest}-name`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_PERIOD} />}>
      <SubscriptionPlanScheduleView
        value={data.periodSeconds}
        data-test={dataTest && `${dataTest}-period`}
        mode="full"
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_AMOUNT} />}
      styles={descriptionItemStyles}
    >
      <AssetAmount
        withCurrency
        assetId={data.amount.asset}
        value={data.amount.value}
        currencyMode="long"
        data-test={dataTest && `${dataTest}-amount`}
      />
    </Descriptions.Item>
  </Descriptions>
);

const SubscriptionPlanShortViewMemo = React.memo(SubscriptionPlanShortView);

export default SubscriptionPlanShortViewMemo;
