import { Alert, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';

import { FormattedMessage, SupportEmail, WarningTooltip } from '@/components';
import { useActiveCompany } from '@/features/company/hooks';
import { withSettlementIntent } from '@/features/settlements/hocs';
import type { SettlementIntent } from '@/features/settlements/types';
import { SettlementIntentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useRunOnTimer, useStateMountSafe } from '@/hooks';
import SettlementIntentLink from '@/pages/settlements/intent-view/components/SettlementIntentLink';

import type { SettlementIntentStatusBannerProps } from './types';

type BannerState = 'none' | 'pending' | 'stale';
const calculateState = (intent: SettlementIntent): BannerState => {
  if (intent.status !== SettlementIntentStatusAPIModel.Pending) return 'none';
  const now = dayjs();
  if (now.isBefore(intent.processAt)) return 'none';
  if (now.isAfter(intent.staleAt)) return 'stale';
  return 'pending';
};

const SettlementIntentStatusBanner: React.FC<SettlementIntentStatusBannerProps> = ({
  'data-test': dataTest,
  intent,
  noLink,
  mode,
  style,
  className,
}) => {
  const { data: company } = useActiveCompany();
  const [state, setState] = useStateMountSafe(() => calculateState(intent));
  const refreshState = useCallback(() => {
    setState(calculateState(intent));
  }, [intent, setState]);
  useRunOnTimer(refreshState, intent.staleAt);
  useRunOnTimer(refreshState, intent.processAt);
  if (state === 'none') {
    return mode === 'tag' ? (
      <Tooltip
        title={
          <FormattedMessage
            id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_SCHEDULED_DESCRIPTION}
            values={{
              ln: (text: React.ReactNode) =>
                !noLink ? <SettlementIntentLink value={intent.id} title={text} mode="text" /> : null,
            }}
          />
        }
      >
        <Tag data-test={dataTest} color="processing" className={className} style={{ ...style, marginRight: 0 }}>
          <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_SCHEDULED_TAG} />
        </Tag>
      </Tooltip>
    ) : (
      <Alert
        data-test={dataTest}
        className={className}
        style={style}
        message={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_SCHEDULED_TITLE} />}
        description={
          <FormattedMessage
            id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_SCHEDULED_DESCRIPTION}
            values={{
              ln: (text: React.ReactNode) =>
                !noLink ? <SettlementIntentLink value={intent.id} title={text} mode="text" /> : null,
            }}
          />
        }
        type="info"
        showIcon
      />
    );
  }
  if (state === 'stale') {
    return mode === 'tag' ? (
      <WarningTooltip
        type="error"
        title={
          <FormattedMessage
            id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_STALE_DESCRIPTION}
            values={{
              ln: (text: React.ReactNode) =>
                !noLink ? <SettlementIntentLink value={intent.id} title={text} mode="text" /> : null,
            }}
          />
        }
      >
        <Tag data-test={dataTest} color="error" className={className} style={{ ...style, marginRight: 0 }}>
          <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_STALE_TAG} />
        </Tag>
      </WarningTooltip>
    ) : (
      <Alert
        data-test={dataTest}
        className={className}
        style={style}
        message={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_STALE_TITLE} />}
        description={
          <FormattedMessage
            id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_STALE_DESCRIPTION}
            values={{
              support: (text: React.ReactNode) => (
                <SupportEmail title={text} subject={`[${company.data?.id}]: Stale settlement ${intent.id}`} />
              ),
            }}
          />
        }
        type="error"
        showIcon
      />
    );
  }
  return mode === 'tag' ? (
    <Tooltip
      title={
        <FormattedMessage
          id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_PENDING_DESCRIPTION}
          values={{
            ln: (text: React.ReactNode) =>
              !noLink ? <SettlementIntentLink value={intent.id} title={text} mode="text" /> : null,
          }}
        />
      }
    >
      <Tag data-test={dataTest} color="processing" className={className} style={{ ...style, marginRight: 0 }}>
        <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_PENDING_TAG} />
      </Tag>
    </Tooltip>
  ) : (
    <Alert
      data-test={dataTest}
      className={className}
      style={style}
      message={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_PENDING_TITLE} />}
      description={
        <FormattedMessage
          id={I18nFeatureSettlements.COMPONENTS_INTENT_STATUS_BANNER_PENDING_DESCRIPTION}
          values={{
            ln: (text: React.ReactNode) =>
              !noLink ? <SettlementIntentLink value={intent.id} title={text} mode="text" /> : null,
          }}
        />
      }
      type="info"
      showIcon
    />
  );
};

const SettlementIntentStatusBannerHOC =
  withSettlementIntent<SettlementIntentStatusBannerProps>()(SettlementIntentStatusBanner);

const SettlementIntentStatusBannerMemo = React.memo(
  SettlementIntentStatusBannerHOC,
) as typeof SettlementIntentStatusBannerHOC;

export default SettlementIntentStatusBannerMemo;
