import { Alert, Tag } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, WarningTooltip } from '@/components';
import type { MerchantWalletParticularIssueBannerProps } from '@/features/merchant-wallets/components/MerchantWalletIssueBanner/types';
import OperationDeployMerchantWallet from '@/features/merchant-wallets/components/OperationDeployMerchantWallet';
import OperationRefreshMerchantWalletState from '@/features/merchant-wallets/components/OperationRefreshMerchantWalletState';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import MerchantWalletLink from '@/pages/audit/wallet-view/components/MerchantWalletLink';

const MerchantWalletNotDeployedIssueBanner: React.FC<MerchantWalletParticularIssueBannerProps> = ({
  'data-test': dataTest,
  bt,
  address,
  mode,
  style,
  className,
  type,
}) => {
  const details = useMemo(
    () =>
      type === 'action' ? (
        <FormattedMessageWithMarkup
          id={I18nFeatureMerchantWallets.COMPONENTS_WALLET_ISSUE_BANNER_ISSUES_NOT_DEPLOYED_ACTION}
          values={{
            bt,
            deploy: (text: React.ReactNode) => (
              <OperationDeployMerchantWallet
                data-test={dataTest && `${dataTest}-opDeploy`}
                bt={bt}
                mode="link"
                title={text}
              />
            ),
            check: (text: React.ReactNode) => (
              <OperationRefreshMerchantWalletState
                data-test={dataTest && `${dataTest}-opCheck`}
                bt={bt}
                address={address}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      ) : (
        <FormattedMessageWithMarkup
          id={I18nFeatureMerchantWallets.COMPONENTS_WALLET_ISSUE_BANNER_ISSUES_NOT_DEPLOYED_LINK}
          values={{
            bt,
            link: (text: React.ReactNode) => (
              <MerchantWalletLink
                data-test={dataTest && `${dataTest}-walletLink`}
                value={{ bt, address }}
                title={text}
                mode="text"
              />
            ),
            check: (text: React.ReactNode) => (
              <OperationRefreshMerchantWalletState
                data-test={dataTest && `${dataTest}-opCheck`}
                bt={bt}
                address={address}
                mode="link"
                title={text}
              />
            ),
          }}
        />
      ),
    [address, bt, dataTest, type],
  );
  switch (mode) {
    case 'tag':
      return (
        <WarningTooltip type="error" title={details}>
          <Tag data-test={dataTest} color="error" className={className} style={{ ...style, marginRight: 0 }}>
            <FormattedMessage id={I18nFeatureMerchantWallets.COMPONENTS_WALLET_ISSUE_BANNER_ISSUES_NOT_DEPLOYED_TAG} />
          </Tag>
        </WarningTooltip>
      );
    case 'alert':
      return (
        <Alert
          data-test={dataTest}
          className={className}
          style={style}
          message={
            <FormattedMessage
              id={I18nFeatureMerchantWallets.COMPONENTS_WALLET_ISSUE_BANNER_ISSUES_NOT_DEPLOYED_TITLE}
            />
          }
          description={details}
          type="error"
          showIcon
        />
      );
    case 'message':
      return details;
  }
};

const MerchantWalletNotDeployedIssueBannerMemo = React.memo(
  MerchantWalletNotDeployedIssueBanner,
) as typeof MerchantWalletNotDeployedIssueBanner;

export default MerchantWalletNotDeployedIssueBannerMemo;
