import { useMemo } from 'react';

import type { AppRootState, AppAsyncThunkAction } from '@/app/store';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataError } from '@/infrastructure/model';
import type { FullParametersState, LoadingFullDataState } from '@/infrastructure/model/full/types';

import useAppFullDataFiltered from './useAppFullDataFiltered';

import type { UseAppFullDataFiltered } from './useAppFullDataFiltered';

const noIdNestedFullFetchResult = storedDataError('noParentId');
export const noIdNestedFullFetchResultFn = <T, E = string>(): LoadingStateWithDirty<T[], E> =>
  noIdNestedFullFetchResult as LoadingStateWithDirty<T[], E>;

export default function useAppNestedFullDataFiltered<
  Value,
  Filter,
  SortBy extends string,
  FetchResult = LoadingFullDataState<Value>,
>(
  // this function should be fail-safe
  fetchActionFactoryCreator:
    | ((parentId: string) => (force: boolean) => AppAsyncThunkAction<FetchResult, unknown>)
    | ((parentId: string) => (force: boolean) => Promise<FetchResult>),
  parametersSelectorCreator: (
    parentId: string | undefined,
  ) => (state: AppRootState) => FullParametersState<Filter, SortBy>,
  dataFetchingSelectorCreator: (parentId: string) => (state: AppRootState) => boolean,
  dataSelectorCreator: (parentId: string) => (state: AppRootState) => LoadingFullDataState<Value>,
  noParentIdResult: FetchResult,
  parentId: string | undefined,
): UseAppFullDataFiltered<Value, Filter, SortBy, FetchResult> {
  const fetchActionFactory = useMemo(
    () => (parentId ? fetchActionFactoryCreator(parentId) : async () => Promise.resolve(noParentIdResult)),
    [fetchActionFactoryCreator, noParentIdResult, parentId],
  );
  const dataSelector = useMemo(
    () =>
      parentId ? dataSelectorCreator(parentId) : () => noIdNestedFullFetchResult as LoadingStateWithDirty<Value[]>,
    [dataSelectorCreator, parentId],
  );
  const parametersSelector = useMemo(() => parametersSelectorCreator(parentId), [parametersSelectorCreator, parentId]);
  const dataFetchingSelector = useMemo(
    () => (parentId ? dataFetchingSelectorCreator(parentId) : () => false),
    [dataFetchingSelectorCreator, parentId],
  );
  return useAppFullDataFiltered(fetchActionFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
