import React from 'react';

import { FilterForm } from '@/components';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import type { SettlementIntentTransactionFilterPredicate } from '@/features/settlements/types';
import { nameof } from '@/infrastructure/utils/ts';

import {
  SettlementIntentTransactionDestinationLikeFilterItem,
  SettlementIntentTransactionStatusFilterItem,
  SettlementIntentTransactionWalletLikeFilterItem,
} from './components';

import type { SettlementIntentTransactionFilterFormProps } from './types';

const fields = [
  nameof<SettlementIntentTransactionFilterPredicate>('walletLike'),
  nameof<SettlementIntentTransactionFilterPredicate>('destinationLike'),
  nameof<SettlementIntentTransactionFilterPredicate>('statusIn'),
  nameof<SettlementIntentTransactionFilterPredicate>('assetIn'),
  nameof<SettlementIntentTransactionFilterPredicate>('btIn'),
];

const SettlementIntentTransactionFilterForm: React.FC<SettlementIntentTransactionFilterFormProps> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<SettlementIntentTransactionFilterPredicate> fields={fields} {...props}>
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<SettlementIntentTransactionFilterPredicate>('btIn')}
        network={network}
        readonly={readonly}
        required={false}
        multiselect
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<SettlementIntentTransactionFilterPredicate>('assetIn')}
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
        multiselect
      />
      <SettlementIntentTransactionWalletLikeFilterItem
        data-test={dataTest && `${dataTest}-wallet`}
        name={nameof<SettlementIntentTransactionFilterPredicate>('walletLike')}
        readonly={readonly}
        required={false}
      />
      <SettlementIntentTransactionDestinationLikeFilterItem
        data-test={dataTest && `${dataTest}-destination`}
        name={nameof<SettlementIntentTransactionFilterPredicate>('destinationLike')}
        readonly={readonly}
        required={false}
      />
      <SettlementIntentTransactionStatusFilterItem
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<SettlementIntentTransactionFilterPredicate>('statusIn')}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const SettlementIntentTransactionFilterFormMemo = React.memo(SettlementIntentTransactionFilterForm);

export default SettlementIntentTransactionFilterFormMemo;
