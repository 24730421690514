/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PayoutTypeAPIModel {
    Simple = 'Simple',
    MerkleTree = 'MerkleTree'
}


export function PayoutTypeAPIModelFromJSON(json: any): PayoutTypeAPIModel {
    return PayoutTypeAPIModelFromJSONTyped(json, false);
}

export function PayoutTypeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayoutTypeAPIModel {
    return json as PayoutTypeAPIModel;
}

export function PayoutTypeAPIModelToJSON(value?: PayoutTypeAPIModel | null): any {
    return value as any;
}

