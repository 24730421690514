import React from 'react';

import { DateTimeRangeItem, FormattedMessage } from '@/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementIntentProcessAtRangeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  from: {
    label: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_FILTER_FORM_PROCESS_AT_FROM_LABEL} />,
  },
  to: {
    label: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENT_FILTER_FORM_PROCESS_AT_TO_LABEL} />,
  },
};

const SettlementIntentProcessAtRangeFilterItem = <Values extends Store = Store>(
  props: SettlementIntentProcessAtRangeFilterItemProps<Values>,
) => <DateTimeRangeItem<Values> {...props} messages={messages} />;

const SettlementIntentProcessAtRangeFilterItemMemo = React.memo(
  SettlementIntentProcessAtRangeFilterItem,
) as typeof SettlementIntentProcessAtRangeFilterItem;

export default SettlementIntentProcessAtRangeFilterItemMemo;
