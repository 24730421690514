import { createAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import ms from 'ms';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import type { ReportSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall } from '@/infrastructure/model/api';
import { createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';

import { queryReportDownloadLink, queryReport, queryReports, requestDeleteReport, requestGenerateReport } from './api';
import {
  makeSelectDirtyReportIds,
  makeSelectGeneratingReports,
  makeSelectGeneratingReportsInitialized,
  makeSelectMultipleReport,
  makeSelectReport,
  makeSelectReportListData,
  makeSelectReportListParameters,
} from './selectors';
import { NAMESPACE } from './types';
import { extractReportId } from './utils';

import type { Report, ReportFilterPredicate } from './types';

export const {
  markReportDirty,
  markReportListDirty,
  markMultipleReportDirty,
  storeReportListData,
  storeReportListParameters,
  storeReport,
  storeMultipleReport,
  storeRemoveReport,
} = createNormalizedListActions<Report, 'Report', ReportFilterPredicate, ReportSortByAPIModel>(NAMESPACE, 'Report');

const reportsFetchLimit = pLimit(1);
export const fetchReports = createAppAsyncThunk(
  `${NAMESPACE}/fetchReports`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    reportsFetchLimit(async () => {
      const saved = makeSelectReportListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }
      const data = await withAPICall(queryReports, 'unable to fetch reports')(
        listStateToSliceRequest({ data: saved, ...makeSelectReportListParameters()(getState()) }, force),
        { signal },
      );
      dispatch(storeReportListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));
      return makeSelectReportListData()(getState());
    }),
);

const reportFetchLimit = pLimit(1);
export const fetchReport = createAppAsyncThunk(
  `${NAMESPACE}/fetchReport`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    reportFetchLimit(async () => {
      const saved = makeSelectReport(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryReport, 'unable to fetch report')(id, {
        signal,
      });
      dispatch(storeReport({ id, data }));
      return makeSelectReport(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const multipleReportFetchLimit = pLimit(1);
export const fetchMultipleReport = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleReport`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleReportFetchLimit(async () => {
      const toFetch = force ? ids : makeSelectDirtyReportIds(ids)(getState());
      if (!toFetch.length) {
        return makeSelectMultipleReport(ids)(getState());
      }

      const data = await withAPICall(queryReports, 'unable to fetch report by ids')(
        { filter: { ids: toFetch }, page: defaultPageFn({ perPage: toFetch.length }) },
        { signal },
      );
      if (data.data) dispatch(storeMultipleReport(sliceToMultipleEntities(data.data, extractReportId)));

      return makeSelectMultipleReport(ids)(getState());
    }),
);

export const generateReport = createAppAsyncThunk(
  `${NAMESPACE}/generateReport`,
  async ({ template, params }: { template: string; params: Record<string, unknown> }, { dispatch, signal }) => {
    const data = await withAPICall(requestGenerateReport, 'unable to fetch report link')(template, params, {
      signal,
    });
    if (data.data) {
      dispatch(storeReport({ id: data.data.id, data }));
      dispatch(markReportListDirty());
    }
    return data;
  },
  { idGenerator: ({ template }) => template },
);

export const deleteReport = createAppAsyncThunk(
  `${NAMESPACE}/deleteReport`,
  async (id: string, { dispatch, signal }) => {
    const data = await withAPICall(requestDeleteReport, 'unable to delete report')(id, {
      signal,
    });
    if (!data.error) {
      dispatch(storeRemoveReport(id));
      dispatch(markReportListDirty());
    }
    return data;
  },
  { idGenerator: (id) => id },
);

export const generateReportDownloadLink = createAppAsyncThunk(
  `${NAMESPACE}/generateReportDownloadLink`,
  async (id: string, { signal }) =>
    mapLoadingState(
      await withAPICall(queryReportDownloadLink, 'unable to fetch report link')(id, {
        signal,
      }),
      ({ link }) => link,
    ),
  { idGenerator: (id) => id },
);

const GENERATING_REFRESH_PERIOD = ms('5s') / 1000;
export const storeGeneratingReportsRefreshableAfter = createAction<Date>(
  `${NAMESPACE}/storeGeneratingReportsRefreshableAfter`,
);
export const markGeneratingReportsInitialized = createAction<{
  refreshableAfter: Date;
}>(`${NAMESPACE}/markGeneratingReportsInitialized`);
const generatingReportsFetchLimit = pLimit(1);
const initGeneratingReports = createAppAsyncThunk(
  `${NAMESPACE}/initGeneratingReports`,
  async (_, { dispatch, getState, signal }) => {
    const data = await withAPICall(queryReports, 'unable to fetch reports')(
      {
        filter: { statuses: [ReportStatusAPIModel.Generating] },
        page: defaultPageFn({}),
      },
      { signal },
    );
    if (data.data) {
      dispatch(storeMultipleReport(sliceToMultipleEntities(data.data, extractReportId)));
    }
    dispatch(
      markGeneratingReportsInitialized({
        refreshableAfter: dayjs().add(GENERATING_REFRESH_PERIOD, 's').toDate(),
      }),
    );
    return makeSelectGeneratingReports()(getState());
  },
);

export const fetchGeneratingReports = createAppAsyncThunk(
  `${NAMESPACE}/fetchGeneratingReports`,
  async ({ force }: { force?: boolean }, { dispatch, getState }) =>
    generatingReportsFetchLimit(async () => {
      const isInitialized = makeSelectGeneratingReportsInitialized()(getState());
      if (!isInitialized) {
        return dispatch(initGeneratingReports()).unwrap();
      }

      const saved = makeSelectGeneratingReports()(getState());
      const ids = saved.data?.map(({ id }) => id) ?? [];
      if (saved.isDirty) {
        // updating the update date in advance, we don't want to see duplicate requests if something will go wrong
        dispatch(storeGeneratingReportsRefreshableAfter(dayjs().add(GENERATING_REFRESH_PERIOD, 's').toDate()));
      }
      if ((!force && !saved.isDirty) || !ids.length) {
        return saved;
      }

      await dispatch(fetchMultipleReport({ force: true, ids })).unwrap();

      return makeSelectGeneratingReports()(getState());
    }),
);
