import { Descriptions, Space } from 'antd';
import React from 'react';

import {
  defaultViewColumns,
  descriptionStyles,
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
} from '@/components';
import { AddressLink, NativeAssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import GasHistoryResultView from '@/features/gas-history/components/GasHistoryResultView';
import { I18nFeatureGasHistory } from '@/generated/i18n/i18n';

import type { GasHistoryViewProps } from './types';

const GasHistoryView: React.FC<GasHistoryViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={
      <Space>
        <GasHistoryResultView value={data.result} mode="icon" />
        <FormattedMessage
          id={I18nFeatureGasHistory.COMPONENTS_GAS_HISTORY_VIEW_TITLE}
          values={{ id: data.id }}
          tagName="span"
        />
      </Space>
    }
    column={columns}
  >
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_ADDRESS} />}
      styles={descriptionItemStyles}
    >
      <AddressLink data-test={dataTest && `${dataTest}-address`} bt={data.blockchain} address={data.address} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_SPONSOR_ADDRESS} />}
      styles={descriptionItemStyles}
    >
      <AddressLink data-test={dataTest && `${dataTest}-sponsor`} bt={data.blockchain} address={data.sponsorAddress} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_TX} />}
      styles={descriptionItemStyles}
    >
      <TransactionLink data-test={dataTest && `${dataTest}-tx`} bt={data.blockchain} tx={data.txHash} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_GAS_PRICE} />}
      styles={descriptionItemStyles}
    >
      <NativeAssetAmount
        value={data.gasPrice}
        bt={data.blockchain}
        withCurrency
        withBlockchainMark
        currencyMode="long"
        data-test={dataTest && `${dataTest}-gasPrice`}
      />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_GAS_LIMIT} />}>
      <ReadonlyComponent value={data.gasLimit} data-test={dataTest && `${dataTest}-gasLimit`} />
    </Descriptions.Item>
    {data.gasSpent && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_GAS_SPENT} />}>
        <ReadonlyComponent value={data.gasSpent} data-test={dataTest && `${dataTest}-gasSpent`} />
      </Descriptions.Item>
    )}
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_CREATED_AT} />}>
      <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasHistory.LABELS_ROW_TITLE_FUNCTION_NAME} />}>
      <ReadonlyComponent value={data.functionName} data-test={dataTest && `${dataTest}-function`} />
    </Descriptions.Item>
  </Descriptions>
);

const GasHistoryViewMemo = React.memo(GasHistoryView);

export default GasHistoryViewMemo;
