/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  CollectNowRequestAPIModel,
  CollectScheduleAPIModel,
  CollectTaskAPIModel,
  CollectTaskFilterAPIModel,
  CollectThresholdsAPIModel,
  CollectableAddressBalanceAPIModel,
  CollectableAddressBalanceFilterAPIModel,
  CollectableEntityProcessTransactionAPIModel,
  CollectableKindAPIModel,
  SliceCollectTaskSummaryCollectTaskSortByAPIModel,
  SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    CollectNowRequestAPIModelFromJSON,
    CollectNowRequestAPIModelToJSON,
    CollectScheduleAPIModelFromJSON,
    CollectScheduleAPIModelToJSON,
    CollectTaskAPIModelFromJSON,
    CollectTaskAPIModelToJSON,
    CollectTaskFilterAPIModelFromJSON,
    CollectTaskFilterAPIModelToJSON,
    CollectThresholdsAPIModelFromJSON,
    CollectThresholdsAPIModelToJSON,
    CollectableAddressBalanceAPIModelFromJSON,
    CollectableAddressBalanceAPIModelToJSON,
    CollectableAddressBalanceFilterAPIModelFromJSON,
    CollectableAddressBalanceFilterAPIModelToJSON,
    CollectableEntityProcessTransactionAPIModelFromJSON,
    CollectableEntityProcessTransactionAPIModelToJSON,
    CollectableKindAPIModelFromJSON,
    CollectableKindAPIModelToJSON,
    SliceCollectTaskSummaryCollectTaskSortByAPIModelFromJSON,
    SliceCollectTaskSummaryCollectTaskSortByAPIModelToJSON,
    SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModelFromJSON,
    SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModelToJSON,
} from '../models/index';

export interface GetCollectTaskRequest {
    collectId: string;
}

export interface GetCollectTaskProcessTransactionRequest {
    collectId: string;
}

export interface GetCollectableAddressBalanceRequest {
    kind: CollectableKindAPIModel;
    entityId: string;
}

export interface SearchCollectTasksRequest {
    collectTaskFilterAPIModel: CollectTaskFilterAPIModel;
    withTotal?: boolean;
}

export interface SearchCollectableAddressBalancesRequest {
    collectableAddressBalanceFilterAPIModel: CollectableAddressBalanceFilterAPIModel;
    withTotal?: boolean;
}

export interface TriggerCollectNowRequest {
    collectNowRequestAPIModel: CollectNowRequestAPIModel;
}

export interface UpdateCollectScheduleRequest {
    collectScheduleAPIModel: CollectScheduleAPIModel;
}

/**
 * CollectableApi - interface
 * 
 * @export
 * @interface CollectableApiInterface
 */
export interface CollectableApiInterface {
    /**
     * Clears collectable data collect schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    deleteCollectScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Clears collectable data collect schedule
     */
    deleteCollectSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Returns collectable data collect schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    getCollectScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectScheduleAPIModel>>;

    /**
     * Returns collectable data collect schedule
     */
    getCollectSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectScheduleAPIModel>;

    /**
     * Returns collect task by id
     * @param {string} collectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    getCollectTaskRaw(requestParameters: GetCollectTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectTaskAPIModel>>;

    /**
     * Returns collect task by id
     */
    getCollectTask(collectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectTaskAPIModel>;

    /**
     * Returns collect task transaction
     * @param {string} collectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    getCollectTaskProcessTransactionRaw(requestParameters: GetCollectTaskProcessTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityProcessTransactionAPIModel>>;

    /**
     * Returns collect task transaction
     */
    getCollectTaskProcessTransaction(collectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityProcessTransactionAPIModel>;

    /**
     * Returns collect threshold per asset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    getCollectThresholdsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectThresholdsAPIModel>>;

    /**
     * Returns collect threshold per asset
     */
    getCollectThresholds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectThresholdsAPIModel>;

    /**
     * Returns collectable address balance by entity id
     * @param {CollectableKindAPIModel} kind 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    getCollectableAddressBalanceRaw(requestParameters: GetCollectableAddressBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableAddressBalanceAPIModel>>;

    /**
     * Returns collectable address balance by entity id
     */
    getCollectableAddressBalance(kind: CollectableKindAPIModel, entityId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableAddressBalanceAPIModel>;

    /**
     * Returns collect tasks by filter
     * @param {CollectTaskFilterAPIModel} collectTaskFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    searchCollectTasksRaw(requestParameters: SearchCollectTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCollectTaskSummaryCollectTaskSortByAPIModel>>;

    /**
     * Returns collect tasks by filter
     */
    searchCollectTasks(collectTaskFilterAPIModel: CollectTaskFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCollectTaskSummaryCollectTaskSortByAPIModel>;

    /**
     * Returns collectable address balance by filter
     * @param {CollectableAddressBalanceFilterAPIModel} collectableAddressBalanceFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    searchCollectableAddressBalancesRaw(requestParameters: SearchCollectableAddressBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModel>>;

    /**
     * Returns collectable address balance by filter
     */
    searchCollectableAddressBalances(collectableAddressBalanceFilterAPIModel: CollectableAddressBalanceFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModel>;

    /**
     * Triggers collect for the specific asset
     * @param {CollectNowRequestAPIModel} collectNowRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    triggerCollectNowRaw(requestParameters: TriggerCollectNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Triggers collect for the specific asset
     */
    triggerCollectNow(collectNowRequestAPIModel: CollectNowRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * Updates collectable data collect schedule
     * @param {CollectScheduleAPIModel} collectScheduleAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectableApiInterface
     */
    updateCollectScheduleRaw(requestParameters: UpdateCollectScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectScheduleAPIModel>>;

    /**
     * Updates collectable data collect schedule
     */
    updateCollectSchedule(collectScheduleAPIModel: CollectScheduleAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectScheduleAPIModel>;

}

/**
 * 
 */
export class CollectableApi extends runtime.BaseAPI implements CollectableApiInterface {

    /**
     * Clears collectable data collect schedule
     */
    async deleteCollectScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/schedule`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Clears collectable data collect schedule
     */
    async deleteCollectSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCollectScheduleRaw(initOverrides);
    }

    /**
     * Returns collectable data collect schedule
     */
    async getCollectScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectScheduleAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/schedule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectScheduleAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns collectable data collect schedule
     */
    async getCollectSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectScheduleAPIModel> {
        const response = await this.getCollectScheduleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns collect task by id
     */
    async getCollectTaskRaw(requestParameters: GetCollectTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectTaskAPIModel>> {
        if (requestParameters['collectId'] == null) {
            throw new runtime.RequiredError(
                'collectId',
                'Required parameter "collectId" was null or undefined when calling getCollectTask().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/tasks/{collectId}`.replace(`{${"collectId"}}`, encodeURIComponent(String(requestParameters['collectId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectTaskAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns collect task by id
     */
    async getCollectTask(collectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectTaskAPIModel> {
        const response = await this.getCollectTaskRaw({ collectId: collectId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns collect task transaction
     */
    async getCollectTaskProcessTransactionRaw(requestParameters: GetCollectTaskProcessTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityProcessTransactionAPIModel>> {
        if (requestParameters['collectId'] == null) {
            throw new runtime.RequiredError(
                'collectId',
                'Required parameter "collectId" was null or undefined when calling getCollectTaskProcessTransaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/tasks/{collectId}/tx`.replace(`{${"collectId"}}`, encodeURIComponent(String(requestParameters['collectId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectableEntityProcessTransactionAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns collect task transaction
     */
    async getCollectTaskProcessTransaction(collectId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityProcessTransactionAPIModel> {
        const response = await this.getCollectTaskProcessTransactionRaw({ collectId: collectId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns collect threshold per asset
     */
    async getCollectThresholdsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectThresholdsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/threshold`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectThresholdsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns collect threshold per asset
     */
    async getCollectThresholds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectThresholdsAPIModel> {
        const response = await this.getCollectThresholdsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns collectable address balance by entity id
     */
    async getCollectableAddressBalanceRaw(requestParameters: GetCollectableAddressBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableAddressBalanceAPIModel>> {
        if (requestParameters['kind'] == null) {
            throw new runtime.RequiredError(
                'kind',
                'Required parameter "kind" was null or undefined when calling getCollectableAddressBalance().'
            );
        }

        if (requestParameters['entityId'] == null) {
            throw new runtime.RequiredError(
                'entityId',
                'Required parameter "entityId" was null or undefined when calling getCollectableAddressBalance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/balance/{kind}/{entityId}`.replace(`{${"kind"}}`, encodeURIComponent(String(requestParameters['kind']))).replace(`{${"entityId"}}`, encodeURIComponent(String(requestParameters['entityId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectableAddressBalanceAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns collectable address balance by entity id
     */
    async getCollectableAddressBalance(kind: CollectableKindAPIModel, entityId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableAddressBalanceAPIModel> {
        const response = await this.getCollectableAddressBalanceRaw({ kind: kind, entityId: entityId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns collect tasks by filter
     */
    async searchCollectTasksRaw(requestParameters: SearchCollectTasksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCollectTaskSummaryCollectTaskSortByAPIModel>> {
        if (requestParameters['collectTaskFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'collectTaskFilterAPIModel',
                'Required parameter "collectTaskFilterAPIModel" was null or undefined when calling searchCollectTasks().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/tasks/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollectTaskFilterAPIModelToJSON(requestParameters['collectTaskFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceCollectTaskSummaryCollectTaskSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns collect tasks by filter
     */
    async searchCollectTasks(collectTaskFilterAPIModel: CollectTaskFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCollectTaskSummaryCollectTaskSortByAPIModel> {
        const response = await this.searchCollectTasksRaw({ collectTaskFilterAPIModel: collectTaskFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Returns collectable address balance by filter
     */
    async searchCollectableAddressBalancesRaw(requestParameters: SearchCollectableAddressBalancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModel>> {
        if (requestParameters['collectableAddressBalanceFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'collectableAddressBalanceFilterAPIModel',
                'Required parameter "collectableAddressBalanceFilterAPIModel" was null or undefined when calling searchCollectableAddressBalances().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/balance/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollectableAddressBalanceFilterAPIModelToJSON(requestParameters['collectableAddressBalanceFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns collectable address balance by filter
     */
    async searchCollectableAddressBalances(collectableAddressBalanceFilterAPIModel: CollectableAddressBalanceFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCollectableAddressBalanceCollectableAddressBalanceSortByAPIModel> {
        const response = await this.searchCollectableAddressBalancesRaw({ collectableAddressBalanceFilterAPIModel: collectableAddressBalanceFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

    /**
     * Triggers collect for the specific asset
     */
    async triggerCollectNowRaw(requestParameters: TriggerCollectNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['collectNowRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'collectNowRequestAPIModel',
                'Required parameter "collectNowRequestAPIModel" was null or undefined when calling triggerCollectNow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/collect-now`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollectNowRequestAPIModelToJSON(requestParameters['collectNowRequestAPIModel']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Triggers collect for the specific asset
     */
    async triggerCollectNow(collectNowRequestAPIModel: CollectNowRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerCollectNowRaw({ collectNowRequestAPIModel: collectNowRequestAPIModel }, initOverrides);
    }

    /**
     * Updates collectable data collect schedule
     */
    async updateCollectScheduleRaw(requestParameters: UpdateCollectScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectScheduleAPIModel>> {
        if (requestParameters['collectScheduleAPIModel'] == null) {
            throw new runtime.RequiredError(
                'collectScheduleAPIModel',
                'Required parameter "collectScheduleAPIModel" was null or undefined when calling updateCollectSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/collectable/schedule`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CollectScheduleAPIModelToJSON(requestParameters['collectScheduleAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectScheduleAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates collectable data collect schedule
     */
    async updateCollectSchedule(collectScheduleAPIModel: CollectScheduleAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectScheduleAPIModel> {
        const response = await this.updateCollectScheduleRaw({ collectScheduleAPIModel: collectScheduleAPIModel }, initOverrides);
        return await response.value();
    }

}
