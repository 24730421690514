import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import {
  SettlementIntentCard,
  SettlementIntentTransactionCard,
  SettlementIntentTransactionBreadcrumb,
  SettlementIntentTransactionDetailsCard,
} from './components';
import { withIntentTransactionViewPageParams } from './hocs';

import type React from 'react';

type SettlementIntentPageProps = TestableProps & { pageParams: { transactionId: string } };

const dataTest: string | undefined = 'intent';

const SettlementIntentTransactionPage: React.FC<SettlementIntentPageProps> = ({ pageParams: { transactionId } }) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SettlementIntentTransactionBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        transactionId={transactionId}
        disabled
      />
    }
  >
    <SettlementIntentTransactionCard transactionId={transactionId} data-test={dataTest && `${dataTest}-transaction`} />
    <SettlementIntentCard transactionId={transactionId} data-test={dataTest && `${dataTest}-intent`} />
    <SettlementIntentTransactionDetailsCard
      transactionId={transactionId}
      data-test={dataTest && `${dataTest}-details`}
    />
  </PageContainer>
);

export default withIntentTransactionViewPageParams(SettlementIntentTransactionPage);
