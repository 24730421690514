import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { flatmapStoredState, mapStoredState } from '@/infrastructure/model';
import {
  createNestedNormalizedListSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { mapListState } from '@/infrastructure/model/list/utils';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { toMultipleMerged } from '@/infrastructure/model/single/utils';
import { uniqueBy } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';

import { defaultSubscriptionForPlanState, NAMESPACE } from './types';

import type { Subscription, SubscriptionForPlanListState, SubscriptionWithActions } from './types';

export const {
  makeSelectChargesForSubscription: makeSelectChargeIdsForSubscription,
  makeSelectDirtyChargesForSubscriptionIds,
} = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].charges,
  'ChargesForSubscription' as const,
  undefined,
);

export const makeSelectChargesForSubscription = (paymentId: string) => {
  const selectChargeIdsForSubscription = makeSelectChargeIdsForSubscription(paymentId);
  return createSelector(
    selectChargeIdsForSubscription,
    (state: AppRootState) => state['subscription-charges'].entities,
    (idsState, txsState) => flatmapStoredState(idsState, (ids) => toMultipleMerged(txsState, ids)),
  );
};

const subscriptionActions = (
  subscription: Pick<Subscription, 'status' | 'pendingStatuses'>,
): SubscriptionWithActions['actions'] => ({
  pausable: !subscription.pendingStatuses.length && [SubscriptionStatusAPIModel.Active].includes(subscription.status),
  cancelable:
    !subscription.pendingStatuses.length
    && [
      SubscriptionStatusAPIModel.Active,
      SubscriptionStatusAPIModel.Paused,
      SubscriptionStatusAPIModel.Error,
      SubscriptionStatusAPIModel.Trial,
      SubscriptionStatusAPIModel.Suspended,
    ].includes(subscription.status),
  unpausable: !subscription.pendingStatuses.length && [SubscriptionStatusAPIModel.Paused].includes(subscription.status),
});

export const {
  makeSelectSubscriptionListData: baseMakeSelectSubscriptionListData,
  makeSelectSubscriptionListParameters,
  makeSelectSubscription: baseMakeSelectSubscription,
  makeSelectDirtySubscriptionIds,
  makeSelectMultipleSubscription,
  makeSelectSubscriptionIsDirty,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state) => state[NAMESPACE].entities,
  'Subscription' as const,
  undefined,
);

export const makeSelectSubscription = (id: string) =>
  createSelector(baseMakeSelectSubscription(id), (entity) =>
    mapStoredState(entity, (subscription) => ({ ...subscription, actions: subscriptionActions(subscription) })),
  );

export const makeSelectSubscriptionListData = () =>
  createSelector(baseMakeSelectSubscriptionListData(), (list) =>
    mapListState(list, (subscription) => ({ ...subscription, actions: subscriptionActions(subscription) })),
  );

export const makeSelectSubscriptionListParametersWithNetwork: typeof makeSelectSubscriptionListParameters = () =>
  createSelector(
    makeSelectSubscriptionListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, networkType) => ({ ...parameters, filter: { ...filter, networkType } }),
  );

export const {
  makeSelectSubscriptionsForPlanListData: baseMakeSelectSubscriptionsForPlanListData,
  makeSelectSubscriptionsForPlanListParameters: baseMakeSelectSubscriptionsForPlanListParameters,
} = createNestedNormalizedListSelectors(
  (state: AppRootState, planId: string | undefined): SubscriptionForPlanListState =>
    state[NAMESPACE].byPlan.data[planId!] ?? defaultSubscriptionForPlanState,
  (state) => state[NAMESPACE].byPlan.columnState,
  (state) => state[NAMESPACE].entities,
  'SubscriptionsForPlan' as const,
);

export const makeSelectSubscriptionsForPlanListData = (planId: string) =>
  createSelector(baseMakeSelectSubscriptionsForPlanListData(planId), (list) =>
    mapListState(list, (subscription) => ({ ...subscription, actions: subscriptionActions(subscription) })),
  );

export const makeSelectSubscriptionsForPlanListParameters: typeof baseMakeSelectSubscriptionsForPlanListParameters = (
  planId,
) =>
  createSelector(
    baseMakeSelectSubscriptionsForPlanListParameters(planId),
    makeSelectSelectedNetwork(),
    (
      { filter, ...parameters },
      networkType,
    ): ReturnType<ReturnType<typeof baseMakeSelectSubscriptionsForPlanListParameters>> => ({
      ...parameters,
      filter: { ...filter, planId, networkType },
    }),
  );

const makesSelectFilteredSubscriptions = (filter: (subscription: Subscription) => boolean) => () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].entities,
    (entities) =>
      Object.values(entities)
        .map((subscription) => subscription?.data)
        .filter(notEmpty)
        .filter(filter)
        .filter(uniqueBy(({ id }) => id)),
  );

export const makeSelectPendingSubscriptions = makesSelectFilteredSubscriptions(
  ({ pendingStatuses }) => !!pendingStatuses.length,
);
