import { createReducer } from '@reduxjs/toolkit';

import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { PaymentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { defaultListState } from '@/infrastructure/model/list/types';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markMultiplePaymentDirty,
  markPaymentDirty,
  markPaymentListDirty,
  markTransactionsForPaymentDirty,
  storeMultiplePayment,
  storePayment,
  storePaymentListData,
  storePaymentListParameters,
  storeTransactionsForPayment,
} from './actions';
import { extractPaymentId } from './utils';

import type { PaymentsState, PaymentSortBy } from './types';
import type { Draft } from 'immer';

const defaultSortBy: PaymentSortBy = { [PaymentSortByAPIModel.createdAt]: 'DESC' };

const initialState: PaymentsState = {
  list: defaultListState({}, defaultSortBy),
  entities: {},
  transactions: {},
};

const {
  storeMultiplePaymentReducer,
  storePaymentReducer,
  markPaymentDirtyReducer,
  markMultiplePaymentDirtyReducer,
  storePaymentListParametersReducer,
  markPaymentListDirtyReducer,
  storePaymentListDataReducer,
} = createNormalizedListReducers(
  'Payment' as const,
  (state: Draft<PaymentsState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  // this cast is workaround caused by BigNumber in the store
  (state: Draft<PaymentsState>) => state.entities as PaymentsState['entities'],
  (state, entities) => ({ ...state, entities }),
  extractPaymentId,
);

const { markTransactionsForPaymentDirtyReducer, storeTransactionsForPaymentReducer } = createSingleReducers(
  'TransactionsForPayment' as const,
  (state: Draft<PaymentsState>) => state.transactions,
  (state, transactions) => ({ ...state, transactions }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storePaymentListData, storePaymentListDataReducer)
    .addCase(storePaymentListParameters, storePaymentListParametersReducer)
    .addCase(markPaymentListDirty, markPaymentListDirtyReducer)
    .addCase(storeMultiplePayment, storeMultiplePaymentReducer)
    .addCase(markMultiplePaymentDirty, markMultiplePaymentDirtyReducer)
    .addCase(markPaymentDirty, markPaymentDirtyReducer)
    .addCase(storePayment, storePaymentReducer)

    .addCase(markTransactionsForPaymentDirty, markTransactionsForPaymentDirtyReducer)
    .addCase(storeTransactionsForPayment, storeTransactionsForPaymentReducer)

    .addCase(notifyNetworkUpdated, (state) => ({
      ...initialState,
      list: {
        ...initialState.list,
        columnState: state.list.columnState,
        filter: state.list.filter,
        sortBy: state.list.sortBy,
      },
    }));
});

export default reducer;
