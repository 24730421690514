import React, { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { FormattedMessage, OperationRefresh } from '@/components';
import { markPaymentsDependentDataDirty } from '@/features/payments/actions';
import { usePayment } from '@/features/payments/hooks';
import type { Payment } from '@/features/payments/types';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { suppressPromise } from '@/infrastructure/utils/functions';

import type { OperationRefreshPaymentProps } from './types';

const messages = {
  title: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_TITLE} />,
  tooltip: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_SUCCESS_MESSAGE} />,
  },
  error: {
    message: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_FAILED_MESSAGE} />,
    description: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_FAILED_DESCRIPTION} />,
  },
};

const OperationRefreshPayment: React.FC<OperationRefreshPaymentProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  paymentId,
}) => {
  const { data, forceRefresh } = usePayment(paymentId);
  const dispatch = useAppDispatch();
  const refreshDependantData = useCallback(
    (newData: LoadingStateWithDirty<Payment>) => {
      if (newData.data && newData.data.status !== data.data?.status) {
        suppressPromise(dispatch(markPaymentsDependentDataDirty([newData.data.id])));
      }
    },
    [data.data?.status, dispatch],
  );
  return (
    <OperationRefresh
      data-test={dataTest}
      mode={mode}
      refresh={forceRefresh}
      messages={messages}
      onSuccess={refreshDependantData}
    />
  );
};

const OperationRefreshPaymentMemo = React.memo(OperationRefreshPayment);

export default OperationRefreshPaymentMemo;
