import React from 'react';

import { useAsset } from '@/features/dictionary/blockchain/hooks';

import NetworkLabel from '../NetworkLabel';

import type { AssetNetworkMarkProps } from './types';

const AssetNetworkMark: React.FC<AssetNetworkMarkProps> = ({ 'data-test': dataTest, value }) => {
  const asset = useAsset(value);
  return asset.data.data?.net ? (
    <NetworkLabel data-test={dataTest} tooltipMode="short" mode="medium" net={asset.data.data.net} noSwitcher />
  ) : null;
};

const AssetNetworkMarkMemo = React.memo(AssetNetworkMark) as typeof AssetNetworkMark;

export default AssetNetworkMarkMemo;
