import React from 'react';

import NetworkLabel from '@/features/dictionary/blockchain/components/NetworkLabel';
import { useBlockchain } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

const NetworkMark: React.FC<{ value: BlockchainTypeAPIModel }> = ({ value }) => {
  const blockchain = useBlockchain(value);
  const network = blockchain.data.data?.net;
  return network ? <NetworkLabel tooltipMode="short" mode="short" net={network} noSwitcher /> : null;
};

const NetworkMarkMemo = React.memo(NetworkMark) as typeof NetworkMark;

export default NetworkMarkMemo;
