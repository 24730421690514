import { useCallback, useMemo } from 'react';

import { useMerchantWallets } from '@/features/merchant-wallets/hooks';
import type { MerchantWallet } from '@/features/merchant-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';

const filterWallets = (
  walletsState: LoadingStateWithDirty<MerchantWallet[]>,
  bt: BlockchainTypeAPIModel | undefined,
  address: string | undefined,
) =>
  mapStoredState(walletsState, (wallets) =>
    wallets.find(
      (wallet) => wallet.blockchain === bt && (address ? wallet.address === address : wallet.isFactoryActive),
    ),
  );

export default function useMerchantWallet(bt: BlockchainTypeAPIModel | undefined, address?: string) {
  const { loading, forceRefresh: baseForceRefresh, data: walletsState } = useMerchantWallets();
  const data = useMemo(() => filterWallets(walletsState, bt, address), [address, bt, walletsState]);
  const forceRefresh = useCallback(
    async () => filterWallets(await baseForceRefresh(), bt, address),
    [address, baseForceRefresh, bt],
  );

  return useMemo(() => ({ loading, forceRefresh, data }), [data, forceRefresh, loading]);
}
