import React from 'react';

import type { GasWalletTransactionViewProps } from '@/features/gas-wallets/components';
import { GasWalletTransactionView } from '@/features/gas-wallets/components';

import {
  withSettlementIntentTransactionDetailsAppearance,
  withSettlementIntentTransactionDetailsDataLoading,
} from './hocs';

const SettlementIntentTransactionDetailsCard = withSettlementIntentTransactionDetailsAppearance(
  withSettlementIntentTransactionDetailsDataLoading<GasWalletTransactionViewProps>((props) => (
    <GasWalletTransactionView {...props} columns={1} />
  )),
);
const SettlementIntentTransactionDetailsCardMemo = React.memo(SettlementIntentTransactionDetailsCard);

export default SettlementIntentTransactionDetailsCardMemo;
