/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { EventTypeAPIModel } from './EventTypeAPIModel';
import {
    EventTypeAPIModelFromJSON,
    EventTypeAPIModelFromJSONTyped,
    EventTypeAPIModelToJSON,
} from './EventTypeAPIModel';

/**
 * 
 * @export
 * @interface GetEventTypesResponseAPIModel
 */
export interface GetEventTypesResponseAPIModel {
    /**
     * 
     * @type {Array<EventTypeAPIModel>}
     * @memberof GetEventTypesResponseAPIModel
     */
    eventTypes?: Array<EventTypeAPIModel>;
}

/**
 * Check if a given object implements the GetEventTypesResponseAPIModel interface.
 */
export function instanceOfGetEventTypesResponseAPIModel(value: object): boolean {
    return true;
}

export function GetEventTypesResponseAPIModelFromJSON(json: any): GetEventTypesResponseAPIModel {
    return GetEventTypesResponseAPIModelFromJSONTyped(json, false);
}

export function GetEventTypesResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEventTypesResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'eventTypes': json['eventTypes'] == null ? undefined : ((json['eventTypes'] as Array<any>).map(EventTypeAPIModelFromJSON)),
    };
}

export function GetEventTypesResponseAPIModelToJSON(value?: GetEventTypesResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'eventTypes': value['eventTypes'] == null ? undefined : ((value['eventTypes'] as Array<any>).map(EventTypeAPIModelToJSON)),
    };
}

