/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageWithdrawalSortByAPIModel } from './PageWithdrawalSortByAPIModel';
import {
    PageWithdrawalSortByAPIModelFromJSON,
    PageWithdrawalSortByAPIModelFromJSONTyped,
    PageWithdrawalSortByAPIModelToJSON,
} from './PageWithdrawalSortByAPIModel';
import type { WithdrawalAPIModel } from './WithdrawalAPIModel';
import {
    WithdrawalAPIModelFromJSON,
    WithdrawalAPIModelFromJSONTyped,
    WithdrawalAPIModelToJSON,
} from './WithdrawalAPIModel';

/**
 * 
 * @export
 * @interface SliceWithdrawalWithdrawalSortByAPIModel
 */
export interface SliceWithdrawalWithdrawalSortByAPIModel {
    /**
     * 
     * @type {Array<WithdrawalAPIModel>}
     * @memberof SliceWithdrawalWithdrawalSortByAPIModel
     */
    list?: Array<WithdrawalAPIModel>;
    /**
     * 
     * @type {PageWithdrawalSortByAPIModel}
     * @memberof SliceWithdrawalWithdrawalSortByAPIModel
     */
    page: PageWithdrawalSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceWithdrawalWithdrawalSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceWithdrawalWithdrawalSortByAPIModel interface.
 */
export function instanceOfSliceWithdrawalWithdrawalSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceWithdrawalWithdrawalSortByAPIModelFromJSON(json: any): SliceWithdrawalWithdrawalSortByAPIModel {
    return SliceWithdrawalWithdrawalSortByAPIModelFromJSONTyped(json, false);
}

export function SliceWithdrawalWithdrawalSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceWithdrawalWithdrawalSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(WithdrawalAPIModelFromJSON)),
        'page': PageWithdrawalSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceWithdrawalWithdrawalSortByAPIModelToJSON(value?: SliceWithdrawalWithdrawalSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(WithdrawalAPIModelToJSON)),
        'page': PageWithdrawalSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

