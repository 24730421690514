import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import SettlementIntentTransactionLink from '@/pages/settlements/intent-transaction-view/components/SettlementIntentTransactionLink';
import SettlementViewLink from '@/pages/settlements/settlement-view/components/SettlementViewLink';

import { SettlementIntentCard, SettlementIntentBreadcrumb, SettlementIntentTransactionsCard } from './components';
import { withIntentViewPageParams } from './hocs';

import type React from 'react';

type SettlementIntentPageProps = TestableProps & { pageParams: { intentId: string } };

const dataTest: string | undefined = 'intent';

const SettlementIntentPage: React.FC<SettlementIntentPageProps> = ({ pageParams: { intentId } }) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SettlementIntentBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} intentId={intentId} disabled />
    }
  >
    <SettlementIntentCard intentId={intentId} data-test={dataTest && `${dataTest}-card`} />
    <SettlementIntentTransactionsCard
      intentId={intentId}
      data-test={dataTest && `${dataTest}-transactions`}
      TransactionLink={SettlementIntentTransactionLink}
      SettlementLink={SettlementViewLink}
    />
  </PageContainer>
);

export default withIntentViewPageParams(SettlementIntentPage);
