/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  CollectableEntityTransactionAPIModel,
  RechargeTransactionFilterAPIModel,
  SliceCollectableEntityTransactionPushTransactionSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    CollectableEntityTransactionAPIModelFromJSON,
    CollectableEntityTransactionAPIModelToJSON,
    RechargeTransactionFilterAPIModelFromJSON,
    RechargeTransactionFilterAPIModelToJSON,
    SliceCollectableEntityTransactionPushTransactionSortByAPIModelFromJSON,
    SliceCollectableEntityTransactionPushTransactionSortByAPIModelToJSON,
} from '../models/index';

export interface GetRechargeTransactionRequest {
    txId: string;
}

export interface SearchRechargeTransactionsRequest {
    rechargeTransactionFilterAPIModel: RechargeTransactionFilterAPIModel;
    withTotal?: boolean;
}

/**
 * RechargeTransactionApi - interface
 * 
 * @export
 * @interface RechargeTransactionApiInterface
 */
export interface RechargeTransactionApiInterface {
    /**
     * Returns recharge transaction by id
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechargeTransactionApiInterface
     */
    getRechargeTransactionRaw(requestParameters: GetRechargeTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityTransactionAPIModel>>;

    /**
     * Returns recharge transaction by id
     */
    getRechargeTransaction(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityTransactionAPIModel>;

    /**
     * Returns recharge transactions by filter
     * @param {RechargeTransactionFilterAPIModel} rechargeTransactionFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RechargeTransactionApiInterface
     */
    searchRechargeTransactionsRaw(requestParameters: SearchRechargeTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCollectableEntityTransactionPushTransactionSortByAPIModel>>;

    /**
     * Returns recharge transactions by filter
     */
    searchRechargeTransactions(rechargeTransactionFilterAPIModel: RechargeTransactionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCollectableEntityTransactionPushTransactionSortByAPIModel>;

}

/**
 * 
 */
export class RechargeTransactionApi extends runtime.BaseAPI implements RechargeTransactionApiInterface {

    /**
     * Returns recharge transaction by id
     */
    async getRechargeTransactionRaw(requestParameters: GetRechargeTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CollectableEntityTransactionAPIModel>> {
        if (requestParameters['txId'] == null) {
            throw new runtime.RequiredError(
                'txId',
                'Required parameter "txId" was null or undefined when calling getRechargeTransaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/recharge-transactions/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters['txId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollectableEntityTransactionAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns recharge transaction by id
     */
    async getRechargeTransaction(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CollectableEntityTransactionAPIModel> {
        const response = await this.getRechargeTransactionRaw({ txId: txId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns recharge transactions by filter
     */
    async searchRechargeTransactionsRaw(requestParameters: SearchRechargeTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceCollectableEntityTransactionPushTransactionSortByAPIModel>> {
        if (requestParameters['rechargeTransactionFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'rechargeTransactionFilterAPIModel',
                'Required parameter "rechargeTransactionFilterAPIModel" was null or undefined when calling searchRechargeTransactions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/recharge-transactions/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechargeTransactionFilterAPIModelToJSON(requestParameters['rechargeTransactionFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceCollectableEntityTransactionPushTransactionSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns recharge transactions by filter
     */
    async searchRechargeTransactions(rechargeTransactionFilterAPIModel: RechargeTransactionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceCollectableEntityTransactionPushTransactionSortByAPIModel> {
        const response = await this.searchRechargeTransactionsRaw({ rechargeTransactionFilterAPIModel: rechargeTransactionFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
