import React, { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { OperationRefresh } from '@/components';
import { markMerchantWalletsDependentDataDirty } from '@/features/merchant-wallets/actions';
import { useMerchantWallet } from '@/features/merchant-wallets/hooks';
import type { MerchantWallet } from '@/features/merchant-wallets/types';
import { extractWalletId } from '@/features/merchant-wallets/utils';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { suppressPromise } from '@/infrastructure/utils/functions';

import type { OperationRefreshMerchantWalletProps } from './types';

const OperationRefreshMerchantWallet: React.FC<OperationRefreshMerchantWalletProps> = ({
  'data-test': dataTest,
  bt,
  address,
  title,
  mode = 'inline',
}) => {
  const { data, forceRefresh } = useMerchantWallet(bt, address);
  const dispatch = useAppDispatch();
  const refreshDependantData = useCallback(
    (newData: LoadingStateWithDirty<MerchantWallet>) => {
      if (newData.data && newData.data.status !== data.data?.status) {
        suppressPromise(dispatch(markMerchantWalletsDependentDataDirty([extractWalletId(newData.data)])));
      }
    },
    [data.data?.status, dispatch],
  );
  return (
    <OperationRefresh
      refresh={forceRefresh}
      data-test={dataTest}
      mode={mode}
      messages={{ title }}
      onSuccess={refreshDependantData}
    />
  );
};

const OperationRefreshMerchantWalletMemo = React.memo(OperationRefreshMerchantWallet);

export default OperationRefreshMerchantWalletMemo;
