/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CompanyInfo1APIModel } from './CompanyInfo1APIModel';
import {
    CompanyInfo1APIModelFromJSON,
    CompanyInfo1APIModelFromJSONTyped,
    CompanyInfo1APIModelToJSON,
} from './CompanyInfo1APIModel';
import type { PaymentAPIModel } from './PaymentAPIModel';
import {
    PaymentAPIModelFromJSON,
    PaymentAPIModelFromJSONTyped,
    PaymentAPIModelToJSON,
} from './PaymentAPIModel';

/**
 * 
 * @export
 * @interface PaymentSummaryAPIModel
 */
export interface PaymentSummaryAPIModel {
    /**
     * 
     * @type {PaymentAPIModel}
     * @memberof PaymentSummaryAPIModel
     */
    payment: PaymentAPIModel;
    /**
     * 
     * @type {CompanyInfo1APIModel}
     * @memberof PaymentSummaryAPIModel
     */
    companyInfo: CompanyInfo1APIModel;
}

/**
 * Check if a given object implements the PaymentSummaryAPIModel interface.
 */
export function instanceOfPaymentSummaryAPIModel(value: object): boolean {
    if (!('payment' in value)) return false;
    if (!('companyInfo' in value)) return false;
    return true;
}

export function PaymentSummaryAPIModelFromJSON(json: any): PaymentSummaryAPIModel {
    return PaymentSummaryAPIModelFromJSONTyped(json, false);
}

export function PaymentSummaryAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentSummaryAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'payment': PaymentAPIModelFromJSON(json['payment']),
        'companyInfo': CompanyInfo1APIModelFromJSON(json['companyInfo']),
    };
}

export function PaymentSummaryAPIModelToJSON(value?: PaymentSummaryAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'payment': PaymentAPIModelToJSON(value['payment']),
        'companyInfo': CompanyInfo1APIModelToJSON(value['companyInfo']),
    };
}

