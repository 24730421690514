import type { DateTimeRangeRelativeEnum, DateTimeRelativeQuickFilter } from '@/components';
import type {
  BlockchainNetworkTypeAPIModel,
  DateTimeRangeAPIModel,
  PaymentAPIModel,
  PaymentAuxAmountAPIModel,
  PaymentCompatStatusAPIModel,
  PaymentPredicatesAPIModel,
  PaymentSortByAPIModel,
  PaymentTransactionSearchIdAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/model/api';
import type { ListSortBy, ListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'payments';

export const paymentExportReport = 'invoices';

export interface PaymentAuxAmount extends Omit<PaymentAuxAmountAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export interface Payment extends Omit<PaymentAPIModel, 'amount' | 'metadata' | 'auxAmounts'> {
  amount: AssetAmountValue;
  auxAmounts: PaymentAuxAmount[];
  metadata?: object | string;
}

export interface PaymentFilterPredicate extends PaymentPredicatesAPIModel {
  createdRangeRelative?: DateTimeRelativeQuickFilter;
}

export interface PaymentReportFilterPredicate {
  createdRangeRelative?: DateTimeRangeRelativeEnum;
  statuses?: PaymentCompatStatusAPIModel[];
  paymentAddress?: string;
  createdRange?: DateTimeRangeAPIModel;
  metadata?: string;
  ids?: string[];
  network?: BlockchainNetworkTypeAPIModel;
}

export type PaymentSortBy = ListSortBy<PaymentSortByAPIModel>;
export type PaymentListState = ListState<string, PaymentFilterPredicate, PaymentSortByAPIModel>;

export interface PaymentsState {
  entities: SingleState<Payment>;
  list: PaymentListState;
  transactions: SingleState<PaymentTransactionSearchIdAPIModel[]>; // paymentId -> transaction ids
}
