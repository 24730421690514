import { FormattedMessage } from '@/components';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import { OperationRefreshSettlementIntentTransactionDetails } from '@/features/settlements/components';
import { useSettlementIntentTransactionDetails } from '@/features/settlements/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withSettlementIntentTransactionDetailsDataLoading =
  <T extends { data: GasWalletTransaction } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { transactionId: string }> =>
  (props) => {
    const data = useSettlementIntentTransactionDetails(props.transactionId);
    return withCardDataLoading<GasWalletTransaction, T>({
      ...data,
      'data-test': props['data-test'],
      title: <FormattedMessage id={I18nPageSettlements.INTENT_TRANSACTION_VIEW_COMPONENTS_DETAILS_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: (
          <OperationRefreshSettlementIntentTransactionDetails
            transactionId={props.transactionId}
            data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
          />
        ),
      },
    })(Component)(props);
  };

export default withSettlementIntentTransactionDetailsDataLoading;
