import { createAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import ms from 'ms';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { fetchWeb3Asset, fetchWeb3CompatBlockchainAPI } from '@/features/dictionary/blockchain/actions';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import type { GasWalletTransaction } from '@/features/gas-wallets/types';
import { markReportListDirty, storeReport } from '@/features/reports/actions';
import { markBalancesDirty } from '@/features/statistics/actions';
import type {
  SettlementIntentSortByAPIModel,
  SettlementIntentTransactionSortByAPIModel,
  WithdrawalSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import {
  SettlementIntentTransactionStatusAPIModel,
  SettlementIntentStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { loadingDataError, mapLoadingState } from '@/infrastructure/model';
import { defaultPageFn, withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createNestedListActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { numberFromRaw, numberToRaw } from '@/infrastructure/utils/bigNumber';
import { identity, suppressPromise } from '@/infrastructure/utils/functions';
import { callWithThunkError } from '@/infrastructure/utils/redux';

import {
  querySettlement,
  querySettlementIntent,
  querySettlementIntents,
  querySettlementIntentTransaction,
  querySettlementIntentTransactionDetails,
  querySettlementIntentTransactions,
  querySettlements,
  querySettlementSchedule,
  requestDeleteSettlementSchedule,
  requestExportSettlementReconciliations,
  requestSettleNow,
  requestUpdateSettlementSchedule,
} from './api';
import {
  makeSelectDirtySettlementIntentIds,
  makeSelectDirtySettlementIntentTransactionIds,
  makeSelectDistributeFee,
  makeSelectMultipleSettlementIntent,
  makeSelectMultipleSettlementIntentTransaction,
  makeSelectPendingIntents,
  makeSelectPendingIntentsInitialized,
  makeSelectPendingIntentTransactions,
  makeSelectPendingIntentWithActiveTransactionsCount,
  makeSelectSettlement,
  makeSelectSettlementIntent,
  makeSelectSettlementIntentListData,
  makeSelectSettlementIntentListParametersWithNetwork,
  makeSelectSettlementIntentTransaction,
  makeSelectSettlementIntentTransactionDetails,
  makeSelectSettlementIntentTransactionsForIntentListData,
  makeSelectSettlementIntentTransactionsForIntentListParameters,
  makeSelectSettlementIntentTransactionsForIntents,
  makeSelectSettlementListData,
  makeSelectSettlementListParametersWithNetwork,
  makeSelectSettlementSchedule,
  makeSelectSettlementsForAssetListData,
  makeSelectSettlementsForAssetListParameters,
} from './selectors';
import { NAMESPACE } from './types';
import {
  createDistributeFeeKey,
  extractSettlementId,
  extractSettlementIntentId,
  extractSettlementIntentTransactionId,
} from './utils';
import { queryDistributeFee } from './web3-api';

import type {
  DistributeFeeId,
  FetchDistributeFeePayload,
  Settlement,
  SettlementFilterPredicate,
  SettlementIntent,
  SettlementIntentFilterPredicate,
  SettlementIntentTransaction,
  SettlementIntentTransactionFilterPredicate,
  SettlementSchedule,
  SettlementScheduleUpdate,
} from './types';
import type BigNumber from 'bignumber.js';

export const {
  storeSettlement,
  storeMultipleSettlement,
  markSettlementDirty,
  storeSettlementListData,
  storeSettlementListParameters,
  markSettlementListDirty,
} = createNormalizedListActions<Settlement, 'Settlement', SettlementFilterPredicate, WithdrawalSortByAPIModel>(
  NAMESPACE,
  'Settlement',
);

const settlementFetchLimit = pLimit(1);
export const fetchSettlement = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlement`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    settlementFetchLimit(async () => {
      const saved = makeSelectSettlement(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlement, 'unable to fetch settlement')(id, {
        signal,
      });
      dispatch(storeSettlement({ id, data }));

      return makeSelectSettlement(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const {
  storeSettlementsForAssetListData,
  storeSettlementsForAssetListParameters,
  markSettlementsForAssetListDirty,
} = createNestedListActions<Settlement, 'SettlementsForAsset', SettlementFilterPredicate, WithdrawalSortByAPIModel>(
  NAMESPACE,
  'SettlementsForAsset',
);

const settlementsPerAssetFetchLimit = pLimit(1);
export const fetchSettlementsForAsset = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementsForAsset`,
  async ({ force, asset }: { force?: boolean; asset: string }, { dispatch, getState, signal }) =>
    settlementsPerAssetFetchLimit(async () => {
      const saved = makeSelectSettlementsForAssetListData(asset)(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlements, 'unable to fetch settlements')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectSettlementsForAssetListParameters(asset)(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(
        storeSettlementsForAssetListData({
          parentId: asset,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(storeMultipleSettlement(sliceToMultipleEntities(data.data, extractSettlementId)));
      }

      return makeSelectSettlementsForAssetListData(asset)(getState());
    }),
  { idGenerator: ({ asset }) => asset },
);

const settlementsFetchLimit = pLimit(1);
export const fetchSettlements = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlements`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    settlementsFetchLimit(async () => {
      const saved = makeSelectSettlementListData()(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlements, 'unable to fetch settlements')(
        listStateToSliceRequest({ data: saved, ...makeSelectSettlementListParametersWithNetwork()(getState()) }, force),
        { signal },
      );
      dispatch(storeSettlementListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectSettlementListData()(getState());
    }),
);

export const exportReconciliationsBySettlement = createAppAsyncThunk(
  `${NAMESPACE}/exportReconciliationsBySettlement`,
  async (
    { predicates, includeTransactions }: { predicates: SettlementFilterPredicate; includeTransactions?: boolean },
    { dispatch, signal },
  ) => {
    const data = await withAPICall(requestExportSettlementReconciliations, 'unable to export reconciliation')(
      predicates,
      Boolean(includeTransactions),
      { signal },
    );

    if (data.data) {
      dispatch(storeReport({ id: data.data.id, data }));
      dispatch(markReportListDirty());
    }
    return data;
  },
);

export const {
  storeSettlementIntent,
  storeMultipleSettlementIntent,
  markSettlementIntentDirty,
  markMultipleSettlementIntentDirty,
  storeSettlementIntentListData,
  storeSettlementIntentListParameters,
  markSettlementIntentListDirty,
} = createNormalizedListActions<
  SettlementIntent,
  'SettlementIntent',
  SettlementIntentFilterPredicate,
  SettlementIntentSortByAPIModel
>(NAMESPACE, 'SettlementIntent');

const settlementIntentFetchLimit = pLimit(1);
export const fetchSettlementIntent = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementIntent`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    settlementIntentFetchLimit(async () => {
      const saved = makeSelectSettlementIntent(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementIntent, 'unable to fetch settlement intent')(id, {
        signal,
      });
      dispatch(storeSettlementIntent({ id, data }));

      return makeSelectSettlementIntent(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const settlementIntentsFetchLimit = pLimit(1);
export const fetchSettlementIntents = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementIntents`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    settlementIntentsFetchLimit(async () => {
      const saved = makeSelectSettlementIntentListData()(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementIntents, 'unable to fetch intents')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectSettlementIntentListParametersWithNetwork()(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(storeSettlementIntentListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectSettlementIntentListData()(getState());
    }),
);

const multipleSettlementIntentsFetchLimit = pLimit(1);
export const fetchMultipleSettlementIntent = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleSettlementIntent`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleSettlementIntentsFetchLimit(async () => {
      const absent = makeSelectDirtySettlementIntentIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleSettlementIntent(ids)(getState());
      }

      const data = await withAPICall(querySettlementIntents, 'unable to fetch intents')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleSettlementIntent(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractSettlementIntentId,
            identity,
          ),
        ),
      );

      return makeSelectMultipleSettlementIntent(ids)(getState());
    }),
);

const pendingIntentsFetchLimit = pLimit(1);
export const fetchPendingIntents = createAppAsyncThunk(
  `${NAMESPACE}/fetchPendingIntents`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    pendingIntentsFetchLimit(async () => {
      const saved = makeSelectPendingIntents()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const networkEq = makeSelectSelectedNetwork()(getState());
      const data = await withAPICall(querySettlementIntents, 'unable to fetch intents')(
        { filter: { networkEq, statusIn: [SettlementIntentStatusAPIModel.Pending] }, page: defaultPageFn({}) },
        { signal },
      );
      if (data.data) {
        dispatch(storeMultipleSettlementIntent(sliceToMultipleEntities(data.data, extractSettlementIntentId)));
      }
      return makeSelectPendingIntents()(getState());
    }),
);

export const {
  storeSettlementIntentTransaction,
  storeMultipleSettlementIntentTransaction,
  markSettlementIntentTransactionDirty,
} = createSingleActions<SettlementIntentTransaction, 'SettlementIntentTransaction'>(
  NAMESPACE,
  'SettlementIntentTransaction',
);

const intentTransactionFetchLimit = pLimit(1);
export const fetchSettlementIntentTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementIntentTransaction`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    intentTransactionFetchLimit(async () => {
      const saved = makeSelectSettlementIntentTransaction(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementIntentTransaction, 'unable to fetch intent')(id, {
        signal,
      });
      dispatch(storeSettlementIntentTransaction({ id, data }));

      return makeSelectSettlementIntentTransaction(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const multipleSettlementIntentTransactionFetchLimit = pLimit(1);
export const fetchMultipleSettlementIntentTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleSettlementIntentTransaction`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleSettlementIntentTransactionFetchLimit(async () => {
      const absent = makeSelectDirtySettlementIntentTransactionIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleSettlementIntentTransaction(ids)(getState());
      }

      const data = await withAPICall(querySettlementIntentTransactions, 'unable to fetch intent transactions')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleSettlementIntentTransaction(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractSettlementIntentTransactionId,
            identity,
          ),
        ),
      );

      return makeSelectMultipleSettlementIntentTransaction(ids)(getState());
    }),
);

const PENDING_REFRESH_PERIOD = ms('5s') / 1000;
export const storePendingIntentsRefreshableAfter = createAction<Date>(
  `${NAMESPACE}/storePendingIntentsRefreshableAfter`,
);
export const markPendingIntentsInitialized = createAction<{
  refreshableAfter: Date;
}>(`${NAMESPACE}/markPendingIntentsInitialized`);
const pendingIntentWithActiveTransactionsCountFetchLimit = pLimit(1);
export const fetchPendingIntentWithActiveTransactionsCount = createAppAsyncThunk(
  `${NAMESPACE}/fetchPendingIntentWithActiveTransactionsCount`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    pendingIntentWithActiveTransactionsCountFetchLimit(async () => {
      const isInitialized = makeSelectPendingIntentsInitialized()(getState());
      if (!isInitialized) {
        const networkEq = makeSelectSelectedNetwork()(getState());
        const data = await withAPICall(querySettlementIntentTransactions, 'unable to fetch intent transactions')(
          {
            filter: {
              networkEq,
              statusIn: [
                SettlementIntentTransactionStatusAPIModel.Awaiting,
                SettlementIntentTransactionStatusAPIModel.Pending,
              ],
            },
            page: defaultPageFn({}),
          },
          { signal },
        );
        if (data.data) {
          dispatch(
            storeMultipleSettlementIntentTransaction(
              sliceToMultipleEntities(data.data, extractSettlementIntentTransactionId),
            ),
          );
        }
        dispatch(
          markPendingIntentsInitialized({ refreshableAfter: dayjs().add(PENDING_REFRESH_PERIOD, 's').toDate() }),
        );
        return makeSelectPendingIntentWithActiveTransactionsCount()(getState());
      }

      const saved = makeSelectPendingIntentTransactions()(getState());
      const ids = saved.data?.map(({ id }) => id) ?? [];
      if (saved.isDirty) {
        // updating the update date in advance, we don't want to see duplicate requests if something will go wrong
        dispatch(storePendingIntentsRefreshableAfter(dayjs().add(PENDING_REFRESH_PERIOD, 's').toDate()));
      }
      if ((!force && !saved.isDirty) || !ids.length) {
        return makeSelectPendingIntentWithActiveTransactionsCount()(getState());
      }

      await dispatch(fetchMultipleSettlementIntentTransaction({ force: true, ids })).unwrap();

      return makeSelectPendingIntentWithActiveTransactionsCount()(getState());
    }),
);

export const {
  storeSettlementIntentTransactionsForIntentListData,
  storeSettlementIntentTransactionsForIntentListParameters,
  markSettlementIntentTransactionsForIntentListDirty,
} = createNestedListActions<
  SettlementIntentTransaction,
  'SettlementIntentTransactionsForIntent',
  SettlementIntentTransactionFilterPredicate,
  SettlementIntentTransactionSortByAPIModel
>(NAMESPACE, 'SettlementIntentTransactionsForIntent');

const intentTransactionsForIntentFetchLimit = pLimit(1);
export const fetchSettlementIntentTransactionsForIntent = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementIntentTransactionsForIntent`,
  async ({ force, intent }: { force?: boolean; intent: string }, { dispatch, getState, signal }) =>
    intentTransactionsForIntentFetchLimit(async () => {
      const saved = makeSelectSettlementIntentTransactionsForIntentListData(intent)(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementIntentTransactions, 'unable to fetch transactions')(
        listStateToSliceRequest(
          { data: saved, ...makeSelectSettlementIntentTransactionsForIntentListParameters(intent)(getState()) },
          force,
        ),
        { signal },
      );
      dispatch(
        storeSettlementIntentTransactionsForIntentListData({
          parentId: intent,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(
          storeMultipleSettlementIntentTransaction(
            sliceToMultipleEntities(data.data, extractSettlementIntentTransactionId),
          ),
        );
      }

      return makeSelectSettlementIntentTransactionsForIntentListData(intent)(getState());
    }),
  { idGenerator: ({ intent }) => intent },
);

export const {
  storeSettlementIntentTransactionDetails,
  markSettlementIntentTransactionDetailsDirty,
  storeMultipleSettlementIntentTransactionDetails,
} = createSingleActions<GasWalletTransaction, 'SettlementIntentTransactionDetails'>(
  NAMESPACE,
  'SettlementIntentTransactionDetails',
);

const intentTransactionDetailsFetchLimit = pLimit(1);
export const fetchSettlementIntentTransactionDetails = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementIntentTransactionDetails`,
  async ({ force, id }: { force?: boolean; id: string }, { getState, dispatch, signal }) =>
    intentTransactionDetailsFetchLimit(async () => {
      const stored = makeSelectSettlementIntentTransactionDetails(id)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const data = await withAPICall(querySettlementIntentTransactionDetails, 'unable to fetch transaction details')(
        id,
        { signal },
      );
      dispatch(storeSettlementIntentTransactionDetails({ id, data }));
      return makeSelectSettlementIntentTransactionDetails(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

export const { markSettlementScheduleDirty, storeSettlementSchedule } = createLoadingDataActions<
  SettlementSchedule,
  'SettlementSchedule'
>(NAMESPACE, 'SettlementSchedule');

const settlementScheduleFetchLimit = pLimit(1);
export const fetchSettlementSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchSettlementSchedule`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    settlementScheduleFetchLimit(async () => {
      const saved = makeSelectSettlementSchedule()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettlementSchedule, 'unable to fetch settlement schedule')({ signal });
      dispatch(storeSettlementSchedule(data));

      return makeSelectSettlementSchedule()(getState());
    }),
);

export const updateSettlementSchedule = createAppAsyncThunk(
  `${NAMESPACE}/updateSettlementSchedule`,
  async (newSchedule: SettlementScheduleUpdate | undefined, { dispatch, signal }) => {
    const data = await (newSchedule
      ? withAPICall(requestUpdateSettlementSchedule, 'unable to update settlement schedule')(newSchedule, { signal })
      : withAPICall(requestDeleteSettlementSchedule, 'unable to remove settlement schedule')({ signal }));

    return !data.error
      ? dispatch(fetchSettlementSchedule({ force: true })).unwrap()
      : loadingDataError<SettlementSchedule>(data.error);
  },
);

export const settleNow = createAppAsyncThunk(
  `${NAMESPACE}/settleNow`,
  async ({ asset }: { asset: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestSettleNow, 'unable to trigger the settlement')(asset, { signal });

    if (data.data) {
      dispatch(storeSettlementIntent({ id: data.data.id, data }));
      dispatch(markSettlementIntentListDirty());
      dispatch(markSettlementIntentTransactionsForIntentListDirty(data.data.id));
      dispatch(markBalancesDirty());
      suppressPromise(dispatch(fetchSettlementIntentTransactionsForIntent({ intent: data.data.id, force: true })));
    }

    return data;
  },
);

export const { storeDistributeFee, markDistributeFeeDirty } = createSingleActions<
  BigNumber,
  'DistributeFee',
  DistributeFeeId
>(NAMESPACE, 'DistributeFee');
const distributeFeeFetchLimit = pLimit(1);
export const fetchDistributeFee = createAppAsyncThunk(
  `${NAMESPACE}/fetchDistributeFee`,
  async ({ force, ...key }: FetchDistributeFeePayload, { getState, dispatch }) =>
    distributeFeeFetchLimit(async () => {
      const stored = makeSelectDistributeFee(key)(getState());
      if (!stored.isDirty && !force) {
        return stored;
      }
      const data = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ asset: key.asset, force })).unwrap();
        const asset = await dispatch(fetchWeb3Asset({ asset: key.asset, force })).unwrap();
        return mapLoadingState(
          await withAPICall(queryDistributeFee)(bcMeta, key.wallet, numberToRaw(key.amount, asset.formatDecimals)),
          numberFromRaw,
        );
      });
      dispatch(storeDistributeFee({ id: key, data }));
      return makeSelectDistributeFee(key)(getState());
    }),
  { idGenerator: createDistributeFeeKey },
);

export const markSettlementIntentsDependentDataDirty = createAppAsyncThunk(
  `${NAMESPACE}/markSettlementIntentsDependentDataDirty`,
  (intentIds: string[], { dispatch, getState }) => {
    const transactions = makeSelectSettlementIntentTransactionsForIntents(intentIds)(getState());
    transactions.map(({ id }) => dispatch(markSettlementIntentTransactionDetailsDirty(id)));
    intentIds.forEach((id) => dispatch(markSettlementIntentTransactionsForIntentListDirty(id)));
    dispatch(markSettlementIntentListDirty());
    dispatch(markSettlementListDirty());
    dispatch(markBalancesDirty());
  },
);
