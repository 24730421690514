import uniq from 'lodash-es/uniq';
import { useMemo } from 'react';

import FormattedMessage from '@/components/FormattedMessage';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import type { Asset } from '@/features/dictionary/blockchain/types';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { notEmpty } from '@/infrastructure/utils/ts';

import type { AssetSelectItemProps, AssetSelectItemValidation } from '../types';
import type { Store } from 'rc-field-form/es/interface';

const noRules: AssetSelectItemProps['rules'] = [];
const noAssets: string[] = [];

const useValidationRules = <Values extends Store = Store>({
  allowMultipleBlockchains,
  allowStableCoinMix,
  selectedAssets: selectedAssetCodes = noAssets,
  rules: extraRules = noRules,
}: {
  selectedAssets?: string[];
  rules?: AssetSelectItemProps<Values>['rules'];
} & AssetSelectItemValidation) => {
  const { data } = useListAssets();

  const selectedAssets = useMemo<Asset[]>(
    () => data.data?.filter(({ code }) => selectedAssetCodes.includes(code)) ?? [],
    [data, selectedAssetCodes],
  );

  return useMemo<AssetSelectItemProps<Values>['rules']>(
    () => [
      // forbids the duplicate selection
      {
        validator: async (_, newValue?: string | string[]) => {
          if (!newValue || (Array.isArray(newValue) && !newValue.length)) return;
          if (selectedAssetCodes.filter((asset) => asset === newValue).length > 1) {
            return Promise.reject(new Error());
          }
        },
        message: <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSETS_SELECT_ITEM_ERROR_ALREADY_SELECTED} />,
      },
      // forbids different blockchain selection
      ...(!allowMultipleBlockchains
        ? [
            {
              validator: async (_: unknown, newValue?: string | string[]) => {
                if (!newValue || (Array.isArray(newValue) && !newValue.length)) return;
                const selectedBlockchains = uniq(
                  [selectedAssetCodes, newValue]
                    .map((selectedCode) => data.data?.find(({ code }) => code === selectedCode)?.blockchain)
                    .filter(notEmpty),
                );
                if (selectedBlockchains.length > 1) {
                  return Promise.reject(new Error());
                }
              },
              message: (
                <FormattedMessage
                  id={I18nFeatureDictionary.COMPONENTS_ASSETS_SELECT_ITEM_ERROR_BLOCKCHAIN_INCONSISTENT}
                />
              ),
            },
          ]
        : []),
      // forbids the stable/non-stable mix
      ...(!allowStableCoinMix
        ? [
            {
              validator: async (_: unknown, newValue?: string | string[]) => {
                if (!newValue || (Array.isArray(newValue) && !newValue.length)) return;
                const isSomeStableCoin = !!selectedAssets.find(({ peggedToFiat }) => !!peggedToFiat);
                const isSomeNonStableCoin = !!selectedAssets.find(({ peggedToFiat }) => !peggedToFiat);
                if (isSomeStableCoin && isSomeNonStableCoin) {
                  return Promise.reject(new Error());
                }
              },
              message: (
                <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSETS_SELECT_ITEM_ERROR_STABLECOIN_MIX} />
              ),
            },
          ]
        : []),
      ...extraRules,
    ],
    [allowMultipleBlockchains, allowStableCoinMix, data, extraRules, selectedAssetCodes, selectedAssets],
  );
};

export default useValidationRules;
