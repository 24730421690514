import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useCompanyActiveAssets } from '@/features/company-settings/hooks';
import { MerchantWalletIssue } from '@/features/merchant-wallets/types';
import { nameof } from '@/infrastructure/utils/ts';

import type { CreatePaymentFormData } from '../types';

export default function useInitialValues(): Partial<CreatePaymentFormData | undefined> {
  const { data: companyAssets, loading } = useCompanyActiveAssets();
  return useMemo(
    () =>
      !companyAssets.data && (companyAssets.isDirty || loading)
        ? undefined
        : {
            [nameof<CreatePaymentFormData>('amount')]: {
              asset: companyAssets.data?.find(({ walletIssue }) => walletIssue !== MerchantWalletIssue.INVALID)?.code,
            },
            [nameof<CreatePaymentFormData>('expiresAt')]: dayjs().add(1, 'd'),
            [nameof<CreatePaymentFormData>('fwError')]: {},
          },
    [companyAssets.data, companyAssets.isDirty, loading],
  );
}
