import { Space, Spin } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { MerchantWalletsList, OperationRefreshDeployingMerchantWallet } from '@/features/merchant-wallets/components';
import { useDeployingMerchantWallets } from '@/features/merchant-wallets/hooks';
import { I18nPageAudit } from '@/generated/i18n/i18n';

import type { DeployingMerchantWalletsCardProps } from './types';

const DeployingMerchantWalletsCard: React.FC<DeployingMerchantWalletsCardProps> = (props) => {
  const { data, loading } = useDeployingMerchantWallets();
  const tasks = useMemo(() => data.data ?? [], [data.data]);

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageAudit.WALLETS_DEPLOYING_COMPONENTS_WALLETS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
        </Space>
      }
      extra={<OperationRefreshDeployingMerchantWallet mode="inline" data-test={dataTest && `${dataTest}-opRefresh`} />}
      cardSize="big"
    >
      <Spin spinning={loading}>
        <MerchantWalletsList {...props} data={tasks} />
      </Spin>
    </PageCard>
  );
};

const DeployingMerchantWalletsCardMemo = React.memo(DeployingMerchantWalletsCard);

export default DeployingMerchantWalletsCardMemo;
