import dayjs from 'dayjs';

import { parseReport } from '@/features/reports/api';
import type {
  SettlementIntentAPIModel,
  SettlementIntentPredicatesAPIModel,
  SettlementIntentSortByAPIModel,
  SettlementIntentTransactionAPIModel,
  SettlementIntentTransactionPredicatesAPIModel,
  SettlementIntentTransactionSortByAPIModel,
  UpdateWithdrawalScheduleRequestAPIModel,
  WithdrawalAPIModel,
  WithdrawalPredicatesAPIModel,
  WithdrawalSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { SettlementIntentApi, WithdrawApi, WithdrawScheduleApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/api.provider';
import type { DataSlice, SliceDataRequestWithTotal } from '@/infrastructure/model/api';
import { amountFromAPI, pageToAPI, sliceFromAPI } from '@/infrastructure/model/api';
import { base58ToUUID } from '@/infrastructure/utils/uuid';

import { SettlementType } from './types';

import type { Settlement, SettlementIntent , SettlementIntentTransaction} from './types';

const settlementIntentApi = new SettlementIntentApi(coreConfigurationFactory('auth'));
const settlementApi = new WithdrawApi(coreConfigurationFactory('auth'));
const scheduleApi = new WithdrawScheduleApi(coreConfigurationFactory('auth'));

const parseSettlement = ({
  estimatedAmount,
  estimatedAmountInvoice,
  estimatedAmountPush,
  processingFeeUnpaid,
  fullness,
  companyId,
  collectFullness,
  batchCount,
  collectStatus,
  activeProcessingFeeTx,
  ...response
}: WithdrawalAPIModel): Settlement => ({
  ...response,
  // FIXME: should be provided by backend
  totalAmount: {
    asset: amountFromAPI(estimatedAmount).asset,
    value: amountFromAPI(estimatedAmountInvoice).value.plus(amountFromAPI(estimatedAmountPush).value),
  },
  estimatedAmount: amountFromAPI(estimatedAmount),
  type: response.isPayout ? SettlementType.Payout : SettlementType.Settlement,
});

export const querySettlements = async (
  { page, filter, orderBy }: SliceDataRequestWithTotal<WithdrawalPredicatesAPIModel, WithdrawalSortByAPIModel>,
  initOverrides?: RequestInit,
): Promise<DataSlice<Settlement, WithdrawalSortByAPIModel>> =>
  sliceFromAPI(
    await settlementApi.getWithdrawals({ page: pageToAPI(page, orderBy), predicates: filter ?? {} }, initOverrides),
    parseSettlement,
  );

export const querySettlement = async (settlementId: string, initOverrides?: RequestInit): Promise<Settlement> =>
  parseSettlement(await settlementApi.getWithdrawal(settlementId, initOverrides));

export const requestExportSettlementReconciliations = async (
  predicates: WithdrawalPredicatesAPIModel,
  includeTransactions: boolean,
  initOverrides?: RequestInit,
) =>
  parseReport(
    await (includeTransactions
      ? settlementApi.exportReconciliationTransactionsByWithdrawalPredicates(predicates, initOverrides)
      : settlementApi.exportReconciliationsByWithdrawalPredicates(predicates, initOverrides)),
  );

const parseIntent = ({ settled, ...intent }: SettlementIntentAPIModel): SettlementIntent => ({
  ...intent,
  staleAt: dayjs(intent.processAt).add(6, 'h').toDate(),
  settled: settled ? amountFromAPI(settled) : undefined,
});

const parseIntentTransaction = ({
  settlementId,
  ...transaction
}: SettlementIntentTransactionAPIModel): SettlementIntentTransaction => ({
  ...transaction,
  // TODO: remove me after settlement human id migration
  settlementId: settlementId ? base58ToUUID(settlementId) : undefined,
});

export const querySettlementSchedule = (initOverrides?: RequestInit) =>
  scheduleApi.getWithdrawalSchedule(initOverrides);

export const requestSettleNow = async (asset: string, initOverrides?: RequestInit) =>
  parseIntent(await settlementIntentApi.triggerSettleNow({ asset }, initOverrides));

export const requestUpdateSettlementSchedule = (
  update: UpdateWithdrawalScheduleRequestAPIModel,
  initOverrides?: RequestInit,
) => scheduleApi.updateWithdrawalSchedule(update, initOverrides);

export const requestDeleteSettlementSchedule = (initOverrides?: RequestInit) =>
  scheduleApi.deleteWithdrawalSchedule(initOverrides);

export const querySettlementIntents = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<SettlementIntentPredicatesAPIModel, SettlementIntentSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await settlementIntentApi.searchSettlementIntents(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseIntent,
  );

export const querySettlementIntent = async (intentId: string, initOverrides?: RequestInit) =>
  parseIntent(await settlementIntentApi.getSettlementIntentById(intentId, initOverrides));

export const querySettlementIntentTransactions = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<
    SettlementIntentTransactionPredicatesAPIModel,
    SettlementIntentTransactionSortByAPIModel
  >,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await settlementIntentApi.searchSettlementIntentTransactions(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseIntentTransaction,
  );

export const querySettlementIntentTransaction = async (txId: string, initOverrides?: RequestInit) =>
  parseIntentTransaction(await settlementIntentApi.getSettlementIntentTransaction(txId, initOverrides));

export const querySettlementIntentTransactionDetails = async (txId: string, initOverrides?: RequestInit) =>
  await settlementIntentApi.getSettlementIntentTransactionDetails(txId, initOverrides);
