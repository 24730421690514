import { Descriptions, Space } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { DonationStatusView } from '@/features/donations/components';
import DonationAssetsView from '@/features/donations/components/DonationAssetsView';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationViewProps } from './types';

const DonationView: React.FC<DonationViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
  showAssets,
  Operations,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Descriptions
      style={style}
      className={className}
      styles={descriptionStyles}
      title={<DonationStatusView value={data.isActive} data-test={dataTest && `${dataTest}-status`} mode="full" />}
      column={columns}
      extra={
        Operations && (
          <Space>
            <Operations data-test={dataTest} value={data} />
          </Space>
        )
      }
    >
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_TITLE} />}>
        <ReadonlyComponent value={data.title} data-test={dataTest && `${dataTest}-title`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_DESCRIPTION} />}>
        <ReadonlyComponent value={data.description} data-test={dataTest && `${dataTest}-description`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_CREATED_AT} />}>
        <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
      </Descriptions.Item>
      {showAssets && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_ASSETS} />}>
          <DonationAssetsView
            value={data.addresses}
            donationId={data.id}
            data-test={dataTest && `${dataTest}-assets`}
            mode="tags"
          />
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_IMAGE} />}>
        <img
          src={data.imageUrl}
          alt={formatMessage({ id: I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_IMAGE })}
          style={{ maxWidth: 128, maxHeight: 128 }}
          data-test={dataTest && `${dataTest}-image`}
        />
      </Descriptions.Item>
    </Descriptions>
  );
};

const DonationViewMemo = React.memo(DonationView);

export default DonationViewMemo;
