import { useBlockchain } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainNetworkTypeAPIModel, BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import withBlockchainType from './withBlockchainType';

import type React from 'react';

const emptyComponent = () => null;

const withBlockchainNetworkTypeLoad = <T extends { net: BlockchainNetworkTypeAPIModel }>(
  Component: React.ComponentType<Omit<T, 'net'> & { net: BlockchainNetworkTypeAPIModel }>,
  EmptyComponent: React.ComponentType<Omit<T, 'net'>>,
) =>
  namedHOC<Omit<T, 'net'> & { net: BlockchainNetworkTypeAPIModel }, Omit<T, 'net'> & { bt: BlockchainTypeAPIModel }>(
    Component,
    'WithBlockchainNetworkTypeLoad',
  )((props) => {
    const { bt } = props;
    const bc = useBlockchain(bt);
    return bc.data.data?.net ? <Component {...props} net={bc.data.data.net} /> : <EmptyComponent {...props} />;
  });

const withBlockchainNetworkType = <T extends { net: BlockchainNetworkTypeAPIModel }>(
  Component: React.ComponentType<Omit<T, 'net'> & { net: BlockchainNetworkTypeAPIModel }>,
  EmptyComponent: React.ComponentType<Omit<T, 'net'>> = emptyComponent,
) => {
  const BlockchainComponent = withBlockchainNetworkTypeLoad<T>(Component, EmptyComponent);
  const AssetComponent = withBlockchainType(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    BlockchainComponent,
    EmptyComponent,
  );
  return namedHOC<
    Omit<T, 'net'> & { net: BlockchainNetworkTypeAPIModel },
    | (Omit<T, 'net'> & { net: BlockchainNetworkTypeAPIModel })
    | (Omit<T, 'net'> & { bt: BlockchainTypeAPIModel })
    | (Omit<T, 'net'> & { assetId: string })
  >(
    Component,
    'WithBlockchainNetworkType',
  )((props) => {
    if ('net' in props) return <Component {...props} />;
    if ('bt' in props) return <BlockchainComponent {...props} />;
    if ('assetId' in props) return <AssetComponent {...props} />;
    return null;
  });
};

export default withBlockchainNetworkType;
