import { Descriptions, Space, Tag } from 'antd';
import React from 'react';

import { descriptionItemStyles, FormattedMessage, defaultViewColumns, descriptionStyles } from '@/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import DonationAssetStatusView from '@/features/donations/components/DonationAssetStatusView';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationAssetViewProps } from './types';

const DonationAssetView: React.FC<DonationAssetViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
  Operations,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={<DonationAssetStatusView value={data.isActive} data-test={dataTest && `${dataTest}-status`} mode="full" />}
    column={columns}
    extra={
      Operations && (
        <Space>
          <Operations data-test={dataTest} value={data} />
        </Space>
      )
    }
  >
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureDonations.LABELS_ADDRESS_ROW_TITLE_AMOUNTS} />}>
      {data.defaultAmounts.map((amount, index) => (
        <Tag key={index}>
          <AssetAmount
            data-test={dataTest && `${dataTest}-amount-${amount.asset}`}
            value={amount.value}
            assetId={amount.asset}
            withCurrency
            withBlockchainMark
          />
        </Tag>
      ))}
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureDonations.LABELS_ADDRESS_ROW_TITLE_ADDRESS} />}
      styles={descriptionItemStyles}
    >
      <AddressLink address={data.address} assetId={data.asset} data-test={dataTest && `${dataTest}-address`} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureDonations.LABELS_ADDRESS_ROW_TITLE_BALANCE} />}
      styles={descriptionItemStyles}
    >
      <AssetAmount
        value={data.balance.value}
        assetId={data.balance.asset}
        data-test={dataTest && `${dataTest}-balance`}
        withCurrency
        withBlockchainMark
      />
    </Descriptions.Item>
  </Descriptions>
);

const DonationAssetViewMemo = React.memo(DonationAssetView);

export default DonationAssetViewMemo;
