import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';
import { notEmpty } from '@/infrastructure/utils/ts';

import { NAMESPACE } from './types';

import type { Report } from './types';

export const {
  makeSelectReport,
  makeSelectReportListParameters,
  makeSelectReportListData,
  makeSelectDirtyReportIds,
  makeSelectMultipleReport,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state: AppRootState) => state[NAMESPACE].entities,
  'Report' as const,
  undefined,
);

export const makeSelectGeneratingReportsRefreshableAfter = () => (state: AppRootState) =>
  state[NAMESPACE].generating.refreshableAfter;
export const makeSelectGeneratingReportsInitialized = () => (state: AppRootState) =>
  state[NAMESPACE].generating.initialized;
export const makeSelectGeneratingReports = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].entities,
    makeSelectGeneratingReportsRefreshableAfter(),
    (reports, refreshableAfter): LoadingStateWithDirty<Report[]> => {
      const data = Object.values(reports)
        .map((report) => report?.data)
        .filter(notEmpty)
        .filter((report) => ReportStatusAPIModel.Generating === report.status);
      const isDirty = dayjs(refreshableAfter).isBefore();
      return { data, isDirty };
    },
  );
