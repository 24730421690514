import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AddressLink } from '@/features/dictionary/blockchain/components';
import type { SettlementIntentTransaction } from '@/features/settlements/types';
import { SettlementIntentTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { sortOrderToTable } from '@/infrastructure/model/view';
import { nameof } from '@/infrastructure/utils/ts';

import SettlementIntentTransactionStatusView from '../SettlementIntentTransactionStatusView';
import SettlementIntentTransactionView from '../SettlementIntentTransactionView';

import type { BaseSettlementIntentTransactionFilter, SettlementIntentTransactionsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<SettlementIntentTransaction>('createdAt')]: SettlementIntentTransactionSortByAPIModel.CreatedAt,
};
const extractKey = ({ id }: SettlementIntentTransaction) => id;
const sortColumnToModel = (s: string) => sortMapping[s];

const SettlementIntentTransactionsTable = <
  FilterType extends BaseSettlementIntentTransactionFilter = BaseSettlementIntentTransactionFilter,
>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  TransactionLink,
  TransactionDetailsLink,
  SettlementLink,
  Operations,
}: SettlementIntentTransactionsTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<SettlementIntentTransaction>[] = useMemo(
    () => [
      {
        key: nameof<SettlementIntentTransaction>('wallet'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_WALLET} />,
        dataIndex: nameof<SettlementIntentTransaction>('wallet'),
        render: (_, { id, asset, wallet }) => (
          <AddressLink address={wallet} assetId={asset} data-test={dataTest && `${dataTest}-${id}-wallet`} />
        ),
      },
      {
        key: nameof<SettlementIntentTransaction>('destination'),
        title: (
          <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_DESTINATION} />
        ),
        dataIndex: nameof<SettlementIntentTransaction>('destination'),
        render: (_, { id, asset, destination }) => (
          <AddressLink address={destination} assetId={asset} data-test={dataTest && `${dataTest}-${id}-destination`} />
        ),
      },
      {
        key: nameof<SettlementIntentTransaction>('nextAttemptAt'),
        title: (
          <FormattedMessage
            id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_NEXT_ATTEMPT_AT}
          />
        ),
        dataIndex: nameof<SettlementIntentTransaction>('nextAttemptAt'),
        render: (_, { id, nextAttemptAt }) =>
          nextAttemptAt && (
            <ReadonlyDateTime value={nextAttemptAt} data-test={dataTest && `${dataTest}-${id}-nextAttemptAt`} />
          ),
      },
      {
        key: nameof<SettlementIntentTransaction>('createdAt'),
        title: (
          <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_CREATED_AT} />
        ),
        dataIndex: nameof<SettlementIntentTransaction>('createdAt'),
        sortOrder: sortOrderToTable(sortBy.CreatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime value={createdAt} data-test={dataTest && `${dataTest}-${id}-createdAt`} />
        ),
      },
      {
        key: nameof<SettlementIntentTransaction>('status'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_TRANSACTION_STATUS} />,
        dataIndex: nameof<SettlementIntentTransaction>('status'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { id, status }) => (
          <SettlementIntentTransactionStatusView
            value={status}
            data-test={dataTest && `${dataTest}-${id}-status`}
            mode="icon"
          />
        ),
        width: 60,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {TransactionLink && (
              <TransactionLink value={value.id} data-test={dataTest && `${dataTest}-${value.id}-link`} mode="inline" />
            )}
            {TransactionDetailsLink && (
              <TransactionDetailsLink
                value={value.id}
                data-test={dataTest && `${dataTest}-${value.id}-details`}
                mode="inline"
              />
            )}
            {SettlementLink && value.settlementId && (
              <SettlementLink
                value={value.settlementId}
                data-test={dataTest && `${dataTest}-${value.id}-settlement`}
                mode="inline"
              />
            )}
            {Operations && <Operations value={value} data-test={dataTest && `${dataTest}-${value.id}-op`} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [sortBy.CreatedAt, dataTest, TransactionLink, TransactionDetailsLink, SettlementLink, Operations],
  );

  const preview = useCallback(
    (value: SettlementIntentTransaction) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureSettlements.COMPONENTS_INTENT_TRANSACTION_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            TransactionLink && (
              <TransactionLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <SettlementIntentTransactionView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [TransactionLink, dataTest],
  );

  const { ref, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            filterComponent,
          ]
        : undefined,
    [ExportOperation, FilterForm, dataTest, exportMode, data.data?.total],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<SettlementIntentTransaction, FilterType, SettlementIntentTransactionSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const SettlementIntentTransactionsTableMemo = React.memo(SettlementIntentTransactionsTable);

export default SettlementIntentTransactionsTableMemo;
