import { createReducer, isAnyOf, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import ms from 'ms';

import listenerMiddleware from '@/app/listenerMiddleware';
import { makeSelectIdleReloadBlock, makeSelectIsIdle } from '@/features/global/selectors';
import { InitStatus } from '@/infrastructure/model';
import { suppressPromise } from '@/infrastructure/utils/functions';

import {
  addNotificationToStore,
  globalInit,
  initCookiesAcceptance,
  markReloadOnIdleRequested,
  removeNotificationFromStore,
  storeCookiesAcceptance,
  storeIdleFinished,
  storeIdleStarted,
  storeLoading,
  storeReloadOnIdleBlocked,
  storeReloadOnIdleUnblocked,
  storeViewHeight,
} from './actions';

import type { GlobalState } from './types';

const initialState: GlobalState = {
  notifications: [],
  cookies: { value: false, status: InitStatus.NOT_INITIALIZED },
  loading: { isLoading: false },
  vh: 100,
  pending: {},
  idle: {
    isIdle: false,
    reload: {
      requested: false,
      blockedBy: [],
    },
  },
};

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addNotificationToStore, (state, { payload: notification }) => ({
      ...state,
      notifications: [notification, ...state.notifications],
    }))
    .addCase(removeNotificationFromStore, (state, { payload: id }) => ({
      ...state,
      notifications: [...state.notifications.filter((saved) => saved.id !== id)],
    }))
    .addCase(storeLoading, (state, { payload }) => ({ ...state, loading: payload }))
    .addCase(storeViewHeight, (state, { payload }) => ({ ...state, vh: payload }))
    .addCase(storeCookiesAcceptance, (state, { payload: { accepted } }) => ({
      ...state,
      cookies: { value: accepted, status: InitStatus.FINISHED },
    }))

    .addCase(storeIdleStarted, (state) => ({ ...state, idle: { ...state.idle, isIdle: true } }))
    .addCase(storeIdleFinished, (state) => ({ ...state, idle: { ...state.idle, isIdle: false } }))
    .addCase(markReloadOnIdleRequested, (state) => ({
      ...state,
      idle: { ...state.idle, reload: { ...state.idle.reload, requested: true } },
    }))
    .addCase(storeReloadOnIdleBlocked, (state, { payload: task }) => ({
      ...state,
      idle: { ...state.idle, reload: { ...state.idle.reload, blockedBy: [...state.idle.reload.blockedBy, task] } },
    }))
    .addCase(storeReloadOnIdleUnblocked, (state, { payload: task }) => ({
      ...state,
      idle: {
        ...state.idle,
        reload: { ...state.idle.reload, blockedBy: state.idle.reload.blockedBy.filter((v) => v !== task) },
      },
    }))

    .addMatcher(isAnyOf(isPending, isRejected, isFulfilled), (state, action) => {
      const type = action.type.substring(0, action.type.lastIndexOf('/'));
      const actionPendingRequests = state.pending[type] ?? [];
      if (isPending(action)) {
        return { ...state, pending: { ...state.pending, [type]: [...actionPendingRequests, action.meta.requestId] } };
      }
      return {
        ...state,
        pending: {
          ...state.pending,
          [type]: actionPendingRequests.filter((requestId) => requestId !== action.meta.requestId),
        },
      };
    });

  listenerMiddleware.startListening({
    actionCreator: globalInit,
    effect: (_, listenerApi) => {
      // listenerApi.unsubscribe();
      suppressPromise(listenerApi.dispatch(initCookiesAcceptance()).unwrap());
    },
  });
  listenerMiddleware.startListening({
    actionCreator: markReloadOnIdleRequested,
    effect: async (_, listenerApi) => {
      // listenerApi.unsubscribe();

      const doReload = () => {
        const isIdle = makeSelectIsIdle()(listenerApi.getState());
        const blockedBy = makeSelectIdleReloadBlock()(listenerApi.getState());
        if (isIdle && !blockedBy.length) {
          window.location.reload();
          return true;
        }
        return false;
      };

      for (;;) {
        const isIdle = makeSelectIsIdle()(listenerApi.getState());
        if (!isIdle) {
          await listenerApi.take(storeIdleStarted.match);
        }

        const isNotIdleAnymore = await listenerApi.take(storeIdleFinished.match, ms(window.env.IDLE_WEB3_RELOAD));
        if (isNotIdleAnymore) {
          continue;
        }
        if (doReload()) {
          return;
        }
        const waitIdleFinished = listenerApi.fork(async () => listenerApi.take(storeIdleFinished.match));
        const waitUnblock = listenerApi.fork(async () => {
          for (;;) {
            await listenerApi.take(storeReloadOnIdleUnblocked.match);
            const blockedBy = makeSelectIdleReloadBlock()(listenerApi.getState());
            if (!blockedBy.length) {
              return;
            }
          }
        });

        await Promise.race([waitIdleFinished.result, waitUnblock.result]);
        waitIdleFinished.cancel();
        waitUnblock.cancel();
        if (doReload()) {
          return;
        }
      }
    },
  });
});

export default reducer;
