import { PageContainer } from '@/components';
import SettlementIntentLink from '@/pages/settlements/intent-view/components/SettlementIntentLink';

import { SettlementIntentsCard, SettlementScheduleBreadcrumb, SettlementScheduleCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'schedule';

const SettlementSchedulePage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<SettlementScheduleBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <SettlementScheduleCard data-test={dataTest && `${dataTest}-card`} />
    <SettlementIntentsCard data-test={dataTest && `${dataTest}-intents`} IntentLink={SettlementIntentLink} />
  </PageContainer>
);

export default SettlementSchedulePage;
