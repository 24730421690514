import type {
  SettlementIntentTransactionViewProps} from '@/features/settlements/components';
import {
  SettlementIntentTransactionView,
} from '@/features/settlements/components';

import { withIntentTransactionDataLoading } from './hocs';

export default withIntentTransactionDataLoading<SettlementIntentTransactionViewProps>((props) => (
  <SettlementIntentTransactionView {...props} columns={1} />
));
