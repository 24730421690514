/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { CollectableAddressBalanceStatusAPIModel } from './CollectableAddressBalanceStatusAPIModel';
import {
    CollectableAddressBalanceStatusAPIModelFromJSON,
    CollectableAddressBalanceStatusAPIModelFromJSONTyped,
    CollectableAddressBalanceStatusAPIModelToJSON,
} from './CollectableAddressBalanceStatusAPIModel';
import type { CollectableKindAPIModel } from './CollectableKindAPIModel';
import {
    CollectableKindAPIModelFromJSON,
    CollectableKindAPIModelFromJSONTyped,
    CollectableKindAPIModelToJSON,
} from './CollectableKindAPIModel';

/**
 * 
 * @export
 * @interface CollectableAddressBalanceAPIModel
 */
export interface CollectableAddressBalanceAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CollectableAddressBalanceAPIModel
     */
    id: string;
    /**
     * 
     * @type {CollectableKindAPIModel}
     * @memberof CollectableAddressBalanceAPIModel
     */
    kind: CollectableKindAPIModel;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof CollectableAddressBalanceAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectableAddressBalanceAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof CollectableAddressBalanceAPIModel
     */
    balance: string;
    /**
     * 
     * @type {CollectableAddressBalanceStatusAPIModel}
     * @memberof CollectableAddressBalanceAPIModel
     */
    status: CollectableAddressBalanceStatusAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof CollectableAddressBalanceAPIModel
     */
    calculatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CollectableAddressBalanceAPIModel
     */
    collectTaskId?: string;
}

/**
 * Check if a given object implements the CollectableAddressBalanceAPIModel interface.
 */
export function instanceOfCollectableAddressBalanceAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('kind' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('address' in value)) return false;
    if (!('balance' in value)) return false;
    if (!('status' in value)) return false;
    if (!('calculatedAt' in value)) return false;
    return true;
}

export function CollectableAddressBalanceAPIModelFromJSON(json: any): CollectableAddressBalanceAPIModel {
    return CollectableAddressBalanceAPIModelFromJSONTyped(json, false);
}

export function CollectableAddressBalanceAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectableAddressBalanceAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'kind': CollectableKindAPIModelFromJSON(json['kind']),
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'address': json['address'],
        'balance': json['balance'],
        'status': CollectableAddressBalanceStatusAPIModelFromJSON(json['status']),
        'calculatedAt': (new Date(json['calculatedAt'])),
        'collectTaskId': json['collectTaskId'] == null ? undefined : json['collectTaskId'],
    };
}

export function CollectableAddressBalanceAPIModelToJSON(value?: CollectableAddressBalanceAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'kind': CollectableKindAPIModelToJSON(value['kind']),
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'address': value['address'],
        'balance': value['balance'],
        'status': CollectableAddressBalanceStatusAPIModelToJSON(value['status']),
        'calculatedAt': ((value['calculatedAt']).toISOString()),
        'collectTaskId': value['collectTaskId'],
    };
}

