/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentTransactionStatusAPIModel {
    Confirming = 'Confirming',
    Confirmed = 'Confirmed',
    Invalid = 'Invalid'
}


export function PaymentTransactionStatusAPIModelFromJSON(json: any): PaymentTransactionStatusAPIModel {
    return PaymentTransactionStatusAPIModelFromJSONTyped(json, false);
}

export function PaymentTransactionStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionStatusAPIModel {
    return json as PaymentTransactionStatusAPIModel;
}

export function PaymentTransactionStatusAPIModelToJSON(value?: PaymentTransactionStatusAPIModel | null): any {
    return value as any;
}

