import { Descriptions } from 'antd';
import React from 'react';

import {
  descriptionItemStyles,
  FormattedMessage,
  ReadonlyComponent,
  ReadonlyDateTime,
  defaultViewColumns,
  descriptionStyles,
} from '@/components';
import { TransactionLink } from '@/features/dictionary/blockchain/components';
import { GasWalletAddressLink, GasWalletTransactionStatusView } from '@/features/gas-wallets/components';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';

import type { GasWalletTransactionViewProps } from './types';

const GasWalletTransactionView: React.FC<GasWalletTransactionViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = defaultViewColumns,
}) => (
  <Descriptions
    style={style}
    className={className}
    styles={descriptionStyles}
    title={
      <GasWalletTransactionStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} mode="full" />
    }
    column={columns}
  >
    {data.wallet && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureGasWallets.LABELS_ROW_TITLE_TRANSACTION_ASSIGNED_WALLET} />}
        styles={descriptionItemStyles}
      >
        <GasWalletAddressLink data-test={dataTest && `${dataTest}-wallet`} address={data.wallet} bt={data.blockchain} />
      </Descriptions.Item>
    )}
    {data.sentAt && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasWallets.LABELS_ROW_TITLE_TRANSACTION_SENT_AT} />}>
        <ReadonlyDateTime value={data.sentAt} data-test={dataTest && `${dataTest}-sentAt`} />
      </Descriptions.Item>
    )}
    {data.hash && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureGasWallets.LABELS_ROW_TITLE_TRANSACTION_HASH} />}
        styles={descriptionItemStyles}
      >
        <TransactionLink tx={data.hash} bt={data.blockchain} data-test={dataTest && `${dataTest}-hash`} />
      </Descriptions.Item>
    )}
    {data.blockNum && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasWallets.LABELS_ROW_TITLE_TRANSACTION_BLOCK_NUM} />}>
        <ReadonlyComponent value={data.blockNum} data-test={dataTest && `${dataTest}-blockNum`} />
      </Descriptions.Item>
    )}
    {data.failCount && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureGasWallets.LABELS_ROW_TITLE_TRANSACTION_FAIL_COUNT} />}
      >
        <ReadonlyComponent value={data.failCount} data-test={dataTest && `${dataTest}-failCount`} />
      </Descriptions.Item>
    )}
    {data.errorReason && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureGasWallets.LABELS_ROW_TITLE_TRANSACTION_ERROR_REASON} />}
      >
        <ReadonlyComponent value={data.errorReason} data-test={dataTest && `${dataTest}-errorReason`} />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const GasWalletTransactionViewMemo = React.memo(GasWalletTransactionView);

export default GasWalletTransactionViewMemo;
