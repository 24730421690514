import { Space } from 'antd';
import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { SettlementIntentFilterForm, SettlementIntentsTable } from '@/features/settlements/components';
import { useSettlementIntentListView } from '@/features/settlements/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';

import type { SettlementIntentsCardProps } from './types';

const SettlementIntentsCard = (props: SettlementIntentsCardProps) => {
  const data = useSettlementIntentListView();
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageSettlements.SCHEDULED_COMPONENTS_INTENTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={props['data-test'] && `${props['data-test']}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
    >
      <SettlementIntentsTable FilterForm={SettlementIntentFilterForm} {...props} {...data} />
    </PageCard>
  );
};

const SettlementIntentsCardMemo = React.memo(SettlementIntentsCard);

export default SettlementIntentsCardMemo;
