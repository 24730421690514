/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantWalletTransferSortByAPIModel } from './MerchantWalletTransferSortByAPIModel';
import {
    MerchantWalletTransferSortByAPIModelFromJSON,
    MerchantWalletTransferSortByAPIModelFromJSONTyped,
    MerchantWalletTransferSortByAPIModelToJSON,
} from './MerchantWalletTransferSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeMerchantWalletTransferSortByAPIModel
 */
export interface SortAttributeMerchantWalletTransferSortByAPIModel {
    /**
     * 
     * @type {MerchantWalletTransferSortByAPIModel}
     * @memberof SortAttributeMerchantWalletTransferSortByAPIModel
     */
    attr: MerchantWalletTransferSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeMerchantWalletTransferSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeMerchantWalletTransferSortByAPIModel interface.
 */
export function instanceOfSortAttributeMerchantWalletTransferSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeMerchantWalletTransferSortByAPIModelFromJSON(json: any): SortAttributeMerchantWalletTransferSortByAPIModel {
    return SortAttributeMerchantWalletTransferSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeMerchantWalletTransferSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeMerchantWalletTransferSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': MerchantWalletTransferSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeMerchantWalletTransferSortByAPIModelToJSON(value?: SortAttributeMerchantWalletTransferSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': MerchantWalletTransferSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

