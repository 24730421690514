import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import {
  SettlementIntentTransactionsTable,
} from '@/features/settlements/components';
import { useSettlementIntentTransactionForIntentListView } from '@/features/settlements/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';

import type { SettlementIntentTransactionsCardProps } from './types';

const SettlementIntentTransactionsCard: React.FC<SettlementIntentTransactionsCardProps> = (props) => {
  const data = useSettlementIntentTransactionForIntentListView(props.intentId);
  return (
    <PageCard
      title={<FormattedMessage id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_TRANSACTIONS_CARD_TITLE} />}
      cardSize="big"
    >
      <SettlementIntentTransactionsTable {...props} {...data} />
    </PageCard>
  );
};

export default SettlementIntentTransactionsCard;
