/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DonationAddressAPIModel
 */
export interface DonationAddressAPIModel {
    /**
     * 
     * @type {string}
     * @memberof DonationAddressAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DonationAddressAPIModel
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof DonationAddressAPIModel
     */
    asset: string;
    /**
     * 
     * @type {boolean}
     * @memberof DonationAddressAPIModel
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof DonationAddressAPIModel
     */
    gwtId?: string;
    /**
     * 
     * @type {string}
     * @memberof DonationAddressAPIModel
     */
    balance: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DonationAddressAPIModel
     */
    defaultAmounts?: Array<string>;
}

/**
 * Check if a given object implements the DonationAddressAPIModel interface.
 */
export function instanceOfDonationAddressAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('address' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('isActive' in value)) return false;
    if (!('balance' in value)) return false;
    return true;
}

export function DonationAddressAPIModelFromJSON(json: any): DonationAddressAPIModel {
    return DonationAddressAPIModelFromJSONTyped(json, false);
}

export function DonationAddressAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DonationAddressAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'address': json['address'],
        'asset': json['asset'],
        'isActive': json['isActive'],
        'gwtId': json['gwtId'] == null ? undefined : json['gwtId'],
        'balance': json['balance'],
        'defaultAmounts': json['defaultAmounts'] == null ? undefined : json['defaultAmounts'],
    };
}

export function DonationAddressAPIModelToJSON(value?: DonationAddressAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'address': value['address'],
        'asset': value['asset'],
        'isActive': value['isActive'],
        'gwtId': value['gwtId'],
        'balance': value['balance'],
        'defaultAmounts': value['defaultAmounts'],
    };
}

