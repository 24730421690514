/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollectableAddressLinkAPIModel
 */
export interface CollectableAddressLinkAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CollectableAddressLinkAPIModel
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof CollectableAddressLinkAPIModel
     */
    address: string;
}

/**
 * Check if a given object implements the CollectableAddressLinkAPIModel interface.
 */
export function instanceOfCollectableAddressLinkAPIModel(value: object): boolean {
    if (!('asset' in value)) return false;
    if (!('address' in value)) return false;
    return true;
}

export function CollectableAddressLinkAPIModelFromJSON(json: any): CollectableAddressLinkAPIModel {
    return CollectableAddressLinkAPIModelFromJSONTyped(json, false);
}

export function CollectableAddressLinkAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectableAddressLinkAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'asset': json['asset'],
        'address': json['address'],
    };
}

export function CollectableAddressLinkAPIModelToJSON(value?: CollectableAddressLinkAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'asset': value['asset'],
        'address': value['address'],
    };
}

