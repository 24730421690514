import { createAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import ms from 'ms';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import type {
  BlockchainTypeAPIModel,
  MerchantWalletSignatureCreateAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { withAPICall } from '@/infrastructure/model/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { callWithThunkError } from '@/infrastructure/utils/redux';

import {
  queryMerchantWalletDeployTransactions,
  queryMerchantWallets,
  queryOwnedCompanyWalletSignature,
  requestRefreshMerchantWallet,
  requestSaveOwnedCompanyWalletSignature,
} from './api';
import {
  makeSelectDeployingMerchantWallets,
  makeSelectMerchantWalletDeployTransactions,
  makeSelectMerchantWalletOwnership,
  makeSelectMerchantWallets,
} from './selectors';
import { NAMESPACE } from './types';
import { merchantWalletIdToStoreKey } from './utils';

import type {
  MerchantWalletSignature,
  MerchantWallet,
  MerchantWalletDeployTransaction,
  MerchantWalletId,
} from './types';

export const { storeMerchantWalletOwnership, markMerchantWalletOwnershipDirty } = createLoadingDataActions<
  MerchantWalletSignature,
  'MerchantWalletOwnership'
>(NAMESPACE, 'MerchantWalletOwnership');

const merchantWalletOwnershipFetchLimit = pLimit(1);
export const fetchMerchantWalletOwnership = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletOwnership`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    merchantWalletOwnershipFetchLimit(async () => {
      const saved = makeSelectMerchantWalletOwnership()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryOwnedCompanyWalletSignature, 'unable to fetch signature')({ signal });
      dispatch(storeMerchantWalletOwnership(data));

      return makeSelectMerchantWalletOwnership()(getState());
    }),
);

export const confirmMerchantWalletOwnership = createAppAsyncThunk(
  `${NAMESPACE}/confirmMerchantWalletOwnership`,
  async (signature: MerchantWalletSignatureCreateAPIModel, { dispatch, signal }) => {
    const data = await withAPICall(requestSaveOwnedCompanyWalletSignature, 'unable to save signature')(signature, {
      signal,
    });
    if (data.data) {
      dispatch(storeMerchantWalletOwnership(data));
    }
    return data;
  },
);

export const { storeMerchantWallets, markMerchantWalletsDirty } = createLoadingDataActions<
  MerchantWallet[],
  'MerchantWallets'
>(NAMESPACE, 'MerchantWallets');

const merchantWalletsFetchLimit = pLimit(1);
export const fetchMerchantWallets = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWallets`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    merchantWalletsFetchLimit(async () => {
      const saved = makeSelectMerchantWallets()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryMerchantWallets, 'Unable to fetch wallets')({ signal });
      dispatch(storeMerchantWallets(data));

      return makeSelectMerchantWallets()(getState());
    }),
);

const merchantWalletRefreshLimit = pLimit(1);
export const refreshMerchantWallet = createAppAsyncThunk(
  `${NAMESPACE}/refreshMerchantWallet`,
  async ({ bt, address }: { bt: BlockchainTypeAPIModel; address?: string }, { dispatch, signal }) =>
    merchantWalletRefreshLimit(async () => {
      const data = await withAPICall(requestRefreshMerchantWallet, 'Unable to refresh wallet')(bt, address, { signal });
      if (!data.error) {
        await callWithThunkError(() => dispatch(fetchMerchantWallets({ force: true })).unwrap());
      }
      return data;
    }),
);

export const {
  storeMerchantWalletDeployTransactions,
  markMerchantWalletDeployTransactionsDirty,
  markMultipleMerchantWalletDeployTransactionsDirty,
} = createSingleActions<MerchantWalletDeployTransaction[], 'MerchantWalletDeployTransactions', MerchantWalletId>(
  NAMESPACE,
  'MerchantWalletDeployTransactions',
);

const merchantWalletDeployTransactionsFetchLimit = pLimit(1);
export const fetchMerchantWalletDeployTransactions = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletDeployTransactions`,
  async (
    { bt, address, force }: { bt: BlockchainTypeAPIModel; address: string; force?: boolean },
    { dispatch, getState, signal },
  ) =>
    merchantWalletDeployTransactionsFetchLimit(async () => {
      const saved = makeSelectMerchantWalletDeployTransactions({ bt, address })(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryMerchantWalletDeployTransactions, 'Unable to fetch wallets')(bt, address, {
        signal,
      });
      dispatch(storeMerchantWalletDeployTransactions({ id: { bt, address }, data }));

      return makeSelectMerchantWalletDeployTransactions({ bt, address })(getState());
    }),
  { idGenerator: ({ bt, address }) => merchantWalletIdToStoreKey({ bt, address }) },
);

const DEPLOYING_REFRESH_PERIOD = ms('5s') / 1000;
export const storeDeployingMerchantWalletsRefreshableAfter = createAction<Date>(
  `${NAMESPACE}/storeDeployingMerchantWalletsRefreshableAfter`,
);
const deployingMerchantWalletsFetchLimit = pLimit(1);
export const fetchDeployingMerchantWallets = createAppAsyncThunk(
  `${NAMESPACE}/fetchDeployingMerchantWallets`,
  async ({ force }: { force?: boolean }, { dispatch, getState }) =>
    deployingMerchantWalletsFetchLimit(async () => {
      const saved = makeSelectDeployingMerchantWallets()(getState());
      if (saved.isDirty) {
        // updating the update date in advance, we don't want to see duplicate requests if something will go wrong
        dispatch(storeDeployingMerchantWalletsRefreshableAfter(dayjs().add(DEPLOYING_REFRESH_PERIOD, 's').toDate()));
      }
      const initialized = !makeSelectMerchantWallets()(getState()).isDirty;
      if (force && !saved.isDirty && initialized) {
        return saved;
      }
      if (initialized) {
        const walletsToRefresh = saved.data?.filter(({ staleAt }) => !staleAt || dayjs().isBefore(staleAt));
        if (!walletsToRefresh?.length) {
          return saved;
        }
      }

      await dispatch(fetchMerchantWallets({ force: !initialized })).unwrap();

      return makeSelectDeployingMerchantWallets()(getState());
    }),
);

export const markMerchantWalletsDependentDataDirty = createAppAsyncThunk(
  `${NAMESPACE}/markMerchantWalletsDependentDataDirty`,
  (ids: MerchantWalletId[], { dispatch }) => {
    dispatch(markMultipleMerchantWalletDeployTransactionsDirty(ids));
  },
);
