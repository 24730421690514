import { useSettlementIntentTransaction } from '@/features/settlements/hooks';
import { SettlementIntentTransactionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withSettlementIntentTransactionDetailsAppearance = <
  Props extends { transactionId: string } = {
    transactionId: string;
  },
>(
  Component: React.ComponentType<Props>,
): React.FC<Props> =>
  namedHOC<Props, Props>(
    Component,
    'WithSettlementIntentTransactionDetailsAppearance',
  )((props) => {
    const data = useSettlementIntentTransaction(props.transactionId);
    return data.data.data?.status
      && [SettlementIntentTransactionStatusAPIModel.Pending, SettlementIntentTransactionStatusAPIModel.Failed].includes(
        data.data.data.status,
      ) ? (
      <Component {...props} />
    ) : null;
  });

export default withSettlementIntentTransactionDetailsAppearance;
