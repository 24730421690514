import { Space, Spin } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { CollectTasksList, OperationRefreshPendingCollectTaskSummaries } from '@/features/collectable/components';
import { usePendingCollectTaskSummaries } from '@/features/collectable/hooks';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageCollectable } from '@/generated/i18n/i18n';

import type { CollectPendingTasksCardProps } from './types';

const CollectPendingTasksCard: React.FC<CollectPendingTasksCardProps> = (props) => {
  const { data, loading } = usePendingCollectTaskSummaries();
  const tasks = useMemo(() => data.data ?? [], [data.data]);

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageCollectable.PENDING_COMPONENTS_INTENTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
        </Space>
      }
      extra={
        <OperationRefreshPendingCollectTaskSummaries mode="inline" data-test={dataTest && `${dataTest}-opRefresh`} />
      }
      cardSize="big"
    >
      <Spin spinning={loading}>
        <CollectTasksList {...props} data={tasks} />
      </Spin>
    </PageCard>
  );
};

const CollectPendingTasksCardMemo = React.memo(CollectPendingTasksCard);

export default CollectPendingTasksCardMemo;
