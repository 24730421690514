/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3822
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCompanyInfoAPIModel
 */
export interface UserCompanyInfoAPIModel {
    /**
     * 
     * @type {string}
     * @memberof UserCompanyInfoAPIModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UserCompanyInfoAPIModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyInfoAPIModel
     */
    owner: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCompanyInfoAPIModel
     */
    isKYBFinished: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCompanyInfoAPIModel
     */
    partnerAddress?: string;
}

/**
 * Check if a given object implements the UserCompanyInfoAPIModel interface.
 */
export function instanceOfUserCompanyInfoAPIModel(value: object): boolean {
    if (!('name' in value)) return false;
    if (!('id' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('isKYBFinished' in value)) return false;
    return true;
}

export function UserCompanyInfoAPIModelFromJSON(json: any): UserCompanyInfoAPIModel {
    return UserCompanyInfoAPIModelFromJSONTyped(json, false);
}

export function UserCompanyInfoAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompanyInfoAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'owner': json['owner'],
        'isKYBFinished': json['isKYBFinished'],
        'partnerAddress': json['partnerAddress'] == null ? undefined : json['partnerAddress'],
    };
}

export function UserCompanyInfoAPIModelToJSON(value?: UserCompanyInfoAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'id': value['id'],
        'owner': value['owner'],
        'isKYBFinished': value['isKYBFinished'],
        'partnerAddress': value['partnerAddress'],
    };
}

